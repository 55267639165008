import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  labelText: {
    color: '#909095',
    fontSize: '0.6rem',
    fontWeight: 300,
    textTransform: 'uppercase',
  },
  value: {
    marginTop: '5px',
    fontFamily: 'ApexNew',
    fontWeight: 400,
    fontSize: '1em',
    lineHeight: '1em',
    paddingBottom: '1em',
  },
  icon: {},
}));

class LabelValueProps {
  label!: string;
  value!: string;
  icon?: JSX.Element;
  id?: string = 'label';
}

export default function LabelValue(props: LabelValueProps) {
  const classes = useStyles();
  return (
    <Box>
      <span className={classes.labelText} id={`${props.id}_label`}>
        {props.label}
      </span>
      <Box className={classes.value}>
        {props.icon && (
          <span className={classes.icon}>{props.icon}&nbsp;&nbsp;</span>
        )}
        <span id={`${props.id}_value`}>{props.value}</span>
      </Box>
    </Box>
  );
}
