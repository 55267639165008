import { UserAdherence } from '../../Model/Adherence';

export const SortByUserId = 'sort_user_id';
export const SortByAvgWornCorrectly = 'sort_avg_worn_correctly';

// SortBy defines which column should be taken into consideration when sorting
export type SortBy = typeof SortByUserId | typeof SortByAvgWornCorrectly;

export const SortDirectionAsc = 'asc';
export const SortDirectionDesc = 'desc';

// SortDirection defines which order (ascending/descending) should be taken into consideration when sorting
export type SortDirection = typeof SortDirectionAsc | typeof SortDirectionDesc;

export interface SortInfo {
  by: string;
  direction: SortDirection;
}

/**
 * The sort list function is used to sort a list of UserAdherence items by direction and by column.
 * @param items - The user adherence items to be sorted
 * @param sortDirection - the direction we want our items to be sorted (asc/dec)
 * @param sortBy - the column we want to sort (user_id/avg_worn_correctly)
 * @return the list of sorted UserAdherence objects
 */

export function sortList(
  items: UserAdherence[],
  sortDirection: SortDirection,
  sortBy: SortBy
): UserAdherence[] {
  const sorted = items.sort((a: UserAdherence, b: UserAdherence) => {
    let revertCoefficient = 1;
    if (sortDirection === SortDirectionDesc) {
      revertCoefficient = -1;
    }

    let sortValue = 0;
    switch (sortBy) {
      case SortByAvgWornCorrectly:
        const aWorn = a.avg_device_worn_correctly || 0;
        const bWorn = b.avg_device_worn_correctly || 0;
        sortValue = aWorn - bWorn;

        // in the case the sort value is equal 0, we rely on the user id also
        if (sortValue === 0) {
          const aUserId = a.userId?.toLowerCase() || '';
          const bUserId = b.userId?.toLowerCase() || '';

          if (aUserId < bUserId) {
            sortValue = -revertCoefficient;
          } else {
            sortValue = revertCoefficient;
          }
        }
        break;
      case SortByUserId:
        const aUserId = a.userId?.toLowerCase() || '';
        const bUserId = b.userId?.toLowerCase() || '';
        if (aUserId < bUserId) {
          sortValue = -1;
        } else {
          sortValue = 1;
        }
        break;
    }

    return sortValue * revertCoefficient;
  });

  return sorted;
}
