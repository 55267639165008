import React, { useState, useEffect, useContext } from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import dayjs from 'dayjs';
import Temperatures, {
  BiomarkerTemperatureMin,
  BiomarkerTemperatureMax,
} from '../../Model/Temperature';
import { MillisecondsInMinute } from '../../Model/Biomarker';
import i18next from 'i18next';
import { getChartHoles, getChartMedianLines } from '../../Model/Utils';
import UserContext from '../../Context/UserContext';
import { renderRealtimeTemperatureLegend } from './LegendUtils';
import {
  TimerangeRealtime6h,
  TimerangeRealtime12h,
} from '../../Model/Biomarker';
import SubjectChartTimezoneInfo from './SubjectChartTimezoneInfo';

export function getChartMax(temperatureMax: number | null | undefined): number {
  if (!temperatureMax) {
    return BiomarkerTemperatureMax;
  }
  return Math.round(temperatureMax + 2);
}

export function getChartMin(temperatureMin: number | null | undefined): number {
  if (!temperatureMin) {
    return BiomarkerTemperatureMin;
  }
  return Math.round(temperatureMin - 2);
}

export default function SubjectChartTemperatureMinutePrecision(props: any) {
  const [temperatures, setTemperatures] = useState<Temperatures | undefined>();
  const [chart, setChart] = React.useState(<div>Loading</div>);
  const { config } = useContext(UserContext);

  useEffect(() => {
    setTemperatures(props.temperatures);
  }, [props.temperatures]);

  useEffect(() => {
    if (!temperatures) {
      return;
    }

    const temperatureUnit = config.measurementUnits.temperatureSymbol;

    // Retrieve list of values for the given precision
    const series: Array<number | null> = temperatures.getPrecisionValues(
      props.timerange?.precision
    );

    // Generate labels based on the series
    const labels = series.map((v: any, i: number) => {
      const label = dayjs(temperatures.from + i * MillisecondsInMinute)
        .add(props.timezone.offset, 's')
        .utc()
        .format(config.timeFormat);

      const startOfHour = dayjs(temperatures.from + i * MillisecondsInMinute)
        .add(props.timezone.offset, 's')
        .utc()
        .startOf('hour')
        .format(config.timeFormat);

      if (label !== startOfHour) {
        return null;
      }
      return label;
    });

    // Get the median. Set null if missing (prevents median lines and labels to show in the chart)
    const median = temperatures.median
      ? parseFloat(temperatures.median.toFixed(1))
      : null;
    const compareMedian = temperatures.compareMedian
      ? parseFloat(temperatures.compareMedian.toFixed(1))
      : null;
    const max = getChartMax(temperatures.max);
    const min = getChartMin(temperatures.min);

    // Chart options
    const holes = getChartHoles(
      temperatures.compliance,
      temperatures.complianceHourly,
      temperatures.complianceDaily,
      props.timerange?.precision
    );
    const chartStepSize = 2;
    const lines = getChartMedianLines(
      median,
      compareMedian,
      props.timerange?.precision,
      chartStepSize
    );
    const options = {
      legend: {
        display: false,
      },
      elements: {
        line: {
          tension: 0, // disables bezier curves
        },
      },
      gridLines: {
        color: '#E3E3E3',
      },
      scales: {
        yAxes: [
          {
            ticks: {
              startAtZero: false,
              max: max,
              min: min,
            },
            afterFit: function (scaleInstance: any) {
              scaleInstance.width = 40; // sets the width to 40px
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              userCallback: function (item: any, index: number) {
                if (item) return item;
              },
              autoSkip: false,
            },
          },
        ],
      },
      annotation: {
        annotations: [...holes, ...lines],
      },
      tooltips: {
        mode: 'index',
        intersect: false,
        callbacks: {
          title: function (tooltipItem: any, data: any) {
            return dayjs(
              temperatures.from + tooltipItem[0]['index'] * MillisecondsInMinute
            )
              .add(props.timezone.offset, 's')
              .utc()
              .startOf(props.timerange?.precision)
              .format(`${config.dateFormat} - ${config.timeFormat}`);
          },
          label: function (tooltipItem: any, data: any) {
            var label = data.datasets[tooltipItem.datasetIndex].label;
            var value =
              data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            return label + ': ' + value + temperatureUnit;
          },
        },
      },
      maintainAspectRatio: false,
    };

    var label = i18next.t('MINUTE_VALUES');
    const solid: number[] = [];
    const dataset = [
      {
        label: label,
        borderDash: solid,
        backgroundColor: 'rgba(0,0,0,0)',
        borderColor: 'rgba(255,149,0,1)',
        borderWidth: 1.5,
        hoverBackgroundColor: 'rgba(0,0,0,0)',
        hoverBorderColor: 'rgba(255,149,0,1)',
        pointRadius: 4,
        pointBorderColor: 'transparent',
        pointBackgroundColor: 'transparent',
        pointHoverRadius: 4,
        pointHoverBackgroundColor: 'rgba(255,149,0,1)',
        data: series,
      },
    ];

    if (temperatures.median ?? -1 > 0) {
      const median = new Array(series.length);
      median.fill(temperatures.median);

      let medianLabel = i18next.t('TODAYS_MEDIAN');
      if (props.timerange?.range === TimerangeRealtime6h) {
        medianLabel = i18next.t('LAST_6H_MEDIAN');
      }
      if (props.timerange?.range === TimerangeRealtime12h) {
        medianLabel = i18next.t('LAST_12H_MEDIAN');
      }

      dataset.push({
        label: medianLabel,
        borderDash: [4, 4],
        backgroundColor: 'rgba(0,0,0,0)',
        borderColor: 'rgba(64,64,64,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(0,0,0,0)',
        hoverBorderColor: 'rgba(64,64,64,1)',
        pointRadius: 4,
        pointBorderColor: 'transparent',
        pointBackgroundColor: 'transparent',
        pointHoverRadius: 4,
        pointHoverBackgroundColor: 'rgba(64,64,64,1)',
        data: median,
      });
    }

    // Chart data options and set
    const data = {
      labels: labels,
      legend: {
        display: false,
      },
      tooltip: {
        display: true,
      },
      datasetFill: false,
      datasets: dataset,
    };
    setChart(<Line data={data} options={options} height={320} />);
  }, [temperatures, props.timerange, config, props.timezone]);

  return (
    <span>
      <div>
        {renderRealtimeTemperatureLegend(props.timerange.range)}
        {chart}
      </div>
      <SubjectChartTimezoneInfo offsetInSeconds={props.timezone.offset} />
    </span>
  );
}
