import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Drawer,
  Divider,
  List,
  ListItem,
  Box,
  AppBar,
  Toolbar,
  Grid,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import AppBarLogo from '../../Logo/Logo_white_h.svg';
import DrawerLogo from '../../Logo/Logo_h.svg';
import SiteContext, { SiteConsumer } from '../../Context/SiteContext';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Site from '../../Model/Site';
import { logout } from '../../Model/Utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import ModalEditAccount from '../Modals/ModalEditAccount';
import { RoutePathRegulatory } from '../../Routes/Routes';

const drawerWidth = 320;
const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolbar: {
    minHeight: 50,
    height: 50,
  },
  studyName: {
    marginTop: 15,
  },
  drawerWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '80px 1fr auto auto 74px',
    gridColumnGap: 0,
    gridRowGap: 0,
    height: '100vh',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: 0,
  },
  drawerContent: {
    height: '100%',
  },
  drawerPaper: {
    width: drawerWidth,
    borderRadius: '0px 4px 4px 0px',
  },
  appBarLogo: {
    height: 20,
    marginTop: 15,
  },
  drawerLogoWrapper: {
    textAlign: 'left',
  },
  drawerLogo: {
    width: 200,
    padding: theme.spacing(2),
  },
  label: {
    color: '#969696',
    textAlign: 'left',
    padding: theme.spacing(2),
    display: 'block',
  },
  email: {
    color: '#969696',
    textAlign: 'left',
    padding: theme.spacing(2),
    display: 'block',
  },
  listItem: {
    padding: theme.spacing(2),
  },
  footer: {
    alignSelf: 'end',
    fontSize: '0.5rem',
    textAlign: 'left',
    color: '#969696',
    padding: theme.spacing(1),
  },
}));

const handleLogout = (evt: any) => {
  evt.preventDefault();
  logout();
};

function AppDrawerNav(props: any) {
  const classes = useStyles();
  const { orgId, studyId, siteId, enrollmentId } = useParams<{
    orgId: string;
    studyId: string;
    siteId: string;
    enrollmentId: string;
  }>();
  const { sites } = useContext(SiteContext);
  const [drawerSiteList, setDrawerSiteList] = useState<JSX.Element[]>([]);
  const [userAccountOpen, setUserAccountOpen] = useState(false);
  const [open, setOpen] = useState(props.open);
  const location = useLocation();

  useEffect(() => {
    if (!sites) {
      return;
    }
    const list = sites.map((s: Site) => {
      const sitePath = `/${s.organizationId}/${s.studyId}/${s.id}`;
      return (
        <Link
          component={RouterLink}
          underline="none"
          color="inherit"
          to={sitePath}
          onClick={closeDrawer}
          key={`site-${s.id}`}
          id={`site-${s.id}`}
        >
          <ListItem
            button
            selected={s.id.toString() === siteId}
            className={classes.listItem}
          >
            {s.name}
          </ListItem>
        </Link>
      );
    });
    setDrawerSiteList(list);
  }, [orgId, studyId, siteId, sites, classes.listItem]);

  const closeDrawer = () => {
    setOpen(false);
  };

  const openDrawer = () => {
    setOpen(true);
  };

  const drawerButton = (() => {
    if (orgId && studyId && siteId && enrollmentId) {
      return (
        <IconButton
          id="btn-back"
          color="inherit"
          aria-label="back to site"
          edge="start"
          onClick={() => backToSite()}
          className={classes.menuButton}
        >
          <ArrowBackIosIcon />
        </IconButton>
      );
    }
    return (
      <IconButton
        id="btn-open-drawer"
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={() => openDrawer()}
        className={classes.menuButton}
      >
        <MenuIcon />
      </IconButton>
    );
  })();

  const backToSite = () => {
    if (!orgId || !studyId || !siteId) {
      return;
    }
    const sitePath = `/${orgId}/${studyId}/${siteId}`;
    props.history.push(sitePath);
  };

  const onUserAccountUpdate = () => {
    // For now, no action is required
    return;
  };

  const onUserAccountClose = () => {
    setUserAccountOpen(false);
  };

  const openUserAccountModal = () => {
    closeDrawer();
    setUserAccountOpen(true);
  };

  const drawer = (
    <div>
      <IconButton className={classes.closeButton} onClick={closeDrawer}>
        <CloseIcon />
      </IconButton>
      <Box className={classes.drawerWrapper}>
        <Box className={classes.drawerLogoWrapper}>
          <img
            src={DrawerLogo}
            alt={i18next.t('APP_NAME')}
            className={classes.drawerLogo}
          />
        </Box>

        <Box>
          <label className={classes.label}>Sites</label>
          <Divider />
          <List>{drawerSiteList}</List>
        </Box>

        <Box>
          <label className={classes.label}>
            <Trans i18nKey="DRAWER_USER_ABOUT" />
          </label>
          <Divider />
          <List>
            <Link
              component={RouterLink}
              underline="none"
              color="inherit"
              to={RoutePathRegulatory}
              onClick={closeDrawer}
              id="regulatory"
            >
              <ListItem
                button
                className={classes.listItem}
                selected={location.pathname === RoutePathRegulatory}
              >
                <Trans i18nKey="REGULATORY_PAGE_TITLE" />
              </ListItem>
            </Link>
            <Link
              underline="none"
              color="inherit"
              href="https://support.empatica.com"
              target="_blank"
              rel="noopener noreferrer"
              onClick={closeDrawer}
            >
              <ListItem button>
                <Trans i18nKey="DRAWER_USER_MANAGEMENT_SUPPORT_CENTER" />
                &nbsp;
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </ListItem>
            </Link>
          </List>
        </Box>

        <Box>
          <label className={classes.label}>
            <Trans i18nKey="DRAWER_USER_ACCOUNT" />
          </label>
          <Divider />
          <List>
            <ListItem button onClick={openUserAccountModal}>
              <Trans i18nKey="DRAWER_USER_MANAGEMENT_PROFILE" />
            </ListItem>

            <ListItem button onClick={handleLogout}>
              <Trans i18nKey="DRAWER_USER_MANAGEMENT_LOGOUT" />
            </ListItem>
          </List>
        </Box>

        <Box className={classes.footer}>
          <Box>
            <Trans i18nKey="DRAWER_FOOTER_SYSTEM_STATUS_OPERATIONAL" />
          </Box>
          <Box>
            &copy; 2020 <Trans i18nKey="DRAWER_FOOTER_COPYRIGHT" />
          </Box>
          <Box>
            <span>{process.env.REACT_APP_VERSION || 'dev'}</span>
            {process.env.REACT_APP_BUILD ? (
              <span>&nbsp;({process.env.REACT_APP_BUILD})</span>
            ) : (
              ''
            )}
          </Box>
        </Box>
      </Box>
      <ModalEditAccount
        open={userAccountOpen}
        onClose={onUserAccountClose}
        onChange={onUserAccountUpdate}
      />
    </div>
  );

  return (
    <div>
      <AppBar position="fixed" elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Grid container>
            <Grid item xs={3}>
              <Box textAlign="left">{drawerButton}</Box>
            </Grid>
            <Grid item xs={6}>
              <Box textAlign="center">
                <img
                  src={AppBarLogo}
                  alt={i18next.t('APP_NAME')}
                  className={classes.appBarLogo}
                />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box textAlign="right" className={classes.studyName}>
                <SiteConsumer>
                  {(props: any) => {
                    if (
                      !props ||
                      !props.site ||
                      !props.site.id ||
                      !props.site.study ||
                      !props.site.study.name
                    ) {
                      return '';
                    }
                    return props.site.study.name;
                  }}
                </SiteConsumer>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        anchor="left"
        open={open}
        onClose={closeDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {drawer}
      </Drawer>
    </div>
  );
}

export default withRouter(AppDrawerNav);
