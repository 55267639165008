export class NavigationItem {
  title!: string;
  path!: string;
  isActivePath!: boolean;

  constructor(title: string, path: string, isCurrent: boolean) {
    this.title = title;
    this.path = path;
    this.isActivePath = isCurrent;
  }
}

const PATH_SEPARATOR = '/';
const INITIAL_PATH = '';

export function getNavigationItemsForPath(path: string): NavigationItem[] {
  let pathSplit = path.split(PATH_SEPARATOR);
  pathSplit = pathSplit.slice(0, pathSplit.length - 1);
  let currentFullPath = INITIAL_PATH;

  let navigationItems = pathSplit.map(
    (currentSplitPath: string, index: number) => {
      currentFullPath += currentSplitPath + PATH_SEPARATOR;
      if (index === pathSplit.length - 1) {
        return new NavigationItem(currentSplitPath, currentFullPath, true);
      } else {
        return new NavigationItem(currentSplitPath, currentFullPath, false);
      }
    }
  );

  navigationItems.unshift(
    new NavigationItem('root', INITIAL_PATH, navigationItems.length === 0)
  );
  return navigationItems;
}
