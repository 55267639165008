import React, { useContext } from 'react';
import Device, { DeviceModelE4, DeviceModelEMBP } from '../../Model/Device';
import App from '../../Model/App';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import EmbPlusIcon from '../../Icons/EmbPlusIcon';
import E4Icon from '../../Icons/E4Icon';
import dayjs from 'dayjs';
import { Tooltip } from '@material-ui/core';
import { Trans } from 'react-i18next';
import UserContext from '../../Context/UserContext';

const useStyles = makeStyles((theme) => ({
  appSection: {
    paddingTop: '1.25rem',
  },
  sectionTitle: {
    height: '0.8rem',
    width: '2.18rem',
    color: '#FFFFFF',
    fontSize: '1rem',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '0.8rem',
  },
  tooltipLeftText: {
    fontFamily: 'Lato Light',
    fontSize: '0.75rem',
  },
  tooltipRightText: {
    marginLeft: '0.5rem',
    fontFamily: 'Lato',
    fontSize: '0.75rem',
  },
  deviceIcon: {
    paddingTop: '0.62rem',
    verticalAlign: 'middle',
    paddingRight: '0.5rem',
    '& > img': {
      height: '1.5rem',
    },
  },
  table: {
    tableLayout: 'fixed',
    marginTop: '0.62rem',
    width: '100%',
    borderSpacing: 0,
    '& td': {
      border: '0.3px solid #d3d3d3',
      padding: '4px',
    },
    borderCollapse: 'collapse',
    borderStyle: 'hidden',
  },
  tableLeftContent: {
    width: '35%',
  },
  tableRightContent: {
    width: '65%',
  },
}));

const CustomBackgroundTooltip = withStyles({
  tooltip: {
    padding: '15px 11px 15px 11px',
    backgroundColor: 'rgba(46,56,77,0.95)',
  },
})(Tooltip);

export class SubjectDeviceInfoProps {
  device?: Device;
  app?: App;
}

export default function SubjectDeviceInfo(props: SubjectDeviceInfoProps) {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  if (!props.device) {
    return <div></div>;
  }

  // IMPORTANT! User ID 5354 is owned by ml@empatica.com in the staging environment.
  // This check is preventing the device SN to be displayed.
  // TODO(mb): remove this when demos are over.
  if (user && user.id === 5354) {
    return <div></div>;
  }

  const appBundleMap: { [key: string]: string } = {
    healthmonitor: 'Empatica Care',
  };

  const deviceInfo = (
    <div>
      <span className={classes.sectionTitle}>
        <Trans i18nKey="DEVICE_TITLE" />
      </span>
      <table className={classes.table}>
        <colgroup>
          <col className={classes.tableLeftContent} />
          <col className={classes.tableRightContent} />
        </colgroup>
        <tr>
          <td>
            <span className={classes.tooltipLeftText}>
              <Trans i18nKey="DEVICE_VENDOR" />
            </span>
          </td>
          <td>
            <span className={classes.tooltipRightText}>
              {props.device?.deviceVendor}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className={classes.tooltipLeftText}>
              {' '}
              <Trans i18nKey="DEVICE_MODEL" />
            </span>
          </td>
          <td>
            <span className={classes.tooltipRightText}>
              {props.device?.deviceModel}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className={classes.tooltipLeftText}>
              {' '}
              <Trans i18nKey="DEVICE_SERIAL" />
            </span>
          </td>
          <td>
            <span className={classes.tooltipRightText}>
              {props.device?.deviceSerialNumber}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className={classes.tooltipLeftText}>
              {' '}
              <Trans i18nKey="DEVICE_FIRMWARE" />
            </span>
          </td>
          <td>
            <span className={classes.tooltipRightText}>
              {props.device?.firmwareVersion}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className={classes.tooltipLeftText}>
              <Trans i18nKey="DEVICE_PAIRED" />
            </span>
          </td>
          <td>
            <span className={classes.tooltipRightText}>
              {dayjs(props.device?.timestampStart).format(
                'MMM DD, YYYY - HH:mm:ss'
              )}
            </span>
          </td>
        </tr>
      </table>
    </div>
  );
  let appInfo = <span></span>;
  if (props.app) {
    appInfo = (
      <React.Fragment>
        <div className={classes.appSection}>
          <span className={classes.sectionTitle}>
            <Trans i18nKey="APP_TITLE" />
          </span>
          <table className={classes.table}>
            <colgroup>
              <col className={classes.tableLeftContent} />
              <col className={classes.tableRightContent} />
            </colgroup>
            <tr>
              <td>
                <span className={classes.tooltipLeftText}>
                  <Trans i18nKey="APP_PLATFORM" />
                </span>
              </td>
              <td>
                <span className={classes.tooltipRightText}>
                  {props.app?.platform}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className={classes.tooltipLeftText}>
                  <Trans i18nKey="APP_SDK_VERSION" />
                </span>
              </td>
              <td>
                <span className={classes.tooltipRightText}>
                  {props.app?.sdkVersion}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className={classes.tooltipLeftText}>
                  <Trans i18nKey="APP_ALGO_VERSION" />
                </span>
              </td>
              <td>
                <span className={classes.tooltipRightText}>
                  {props.app?.algorithmLibraryVersion}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className={classes.tooltipLeftText}>
                  <Trans i18nKey="APP_VERSION" />
                </span>
              </td>
              <td>
                <span className={classes.tooltipRightText}>
                  {props.app?.appVersion}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className={classes.tooltipLeftText}>
                  <Trans i18nKey="APP_BUNDLE" />
                </span>
              </td>
              <td>
                <span className={classes.tooltipRightText}>
                  {appBundleMap[props.app?.appBundle] || props.app?.appBundle}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className={classes.tooltipLeftText}>
                  <Trans i18nKey="APP_UPDATED" />
                </span>
              </td>
              <td>
                <span className={classes.tooltipRightText}>
                  {dayjs(props.app?.timestamp).format(
                    'MMM DD, YYYY - HH:mm:ss'
                  )}
                </span>
              </td>
            </tr>
          </table>
        </div>
      </React.Fragment>
    );
  }
  return (
    <CustomBackgroundTooltip
      arrow
      title={
        <React.Fragment>
          {deviceInfo}
          {appInfo}
        </React.Fragment>
      }
      placement="bottom-start"
    >
      <span>
        <span className={classes.deviceIcon}>
          {props.device?.deviceModel === DeviceModelEMBP && <EmbPlusIcon />}
          {props.device?.deviceModel === DeviceModelE4 && <E4Icon />}
        </span>
        <span>{props.device?.deviceModel}</span>
        <span>&nbsp;</span>
        <span>{props.device?.deviceSerialNumber}</span>
      </span>
    </CustomBackgroundTooltip>
  );
}
