import { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core';
import i18next from 'i18next';
import dayjs from 'dayjs';
import { SubjectStatus } from '../../Model/Subject';
import SubjectStatusTooltip, { subjectStatusMap } from './SubjectStatusTooltip';

export interface SubjectStatusLabelProps {
  status: SubjectStatus;
  since?: Date;
}

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'inline-block',
    minWidth: 112,
    padding: theme.spacing(1, 1, 0.5),
    borderRadius: theme.spacing(0.5),
    textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: 500,
    fontFamily: 'ApexNew',
    fontSize: '0.8rem',
  },
}));

const SubjectStatusLabel: FunctionComponent<SubjectStatusLabelProps> = ({
  status,
  since,
}) => {
  const classes = useStyles();

  return (
    <div>
      <SubjectStatusTooltip
        placement="bottom-start"
        title={
          since
            ? `${i18next.t('SITE_VIEW_SUBJECT_STATUS_SINCE_LABEL')}: ${dayjs(
                since
              ).format('MMM DD, YYYY')}`
            : null
        }
        content={i18next.t(subjectStatusMap[status].description)}
      >
        <span
          className={classes.label}
          style={{ background: subjectStatusMap[status].color }}
        >
          {i18next.t(subjectStatusMap[status].label)}
        </span>
      </SubjectStatusTooltip>
    </div>
  );
};

export default SubjectStatusLabel;
