import Api from '../../../Http/Api';
import Device from '../../../Model/Device';

export class ApiResponseUserDevices {
  data!: Device[];
}

export class ApiResponseUserDevice {
  data!: Device;
}

export class ResponseLatestDevicePairingUsers {
  users!: { [key: number]: Device };
}

export class ApiResponseLatestDevicePairingUsers {
  data!: ResponseLatestDevicePairingUsers;
}

export function GetUserDevices(
  userId: number,
  from: number,
  to: number
): Promise<ApiResponseUserDevices> {
  const url = `${process.env.REACT_APP_API_URL}/usage-service/users/${userId}/devices?from=${from}&to=${to}`;
  return Api.get(url);
}

export function GetUserLatestDevice(
  userId: number
): Promise<ApiResponseUserDevice> {
  const url = `${process.env.REACT_APP_API_URL}/usage-service/users/${userId}/devices/latest`;
  return Api.get(url);
}

export function GetLatestDeviceForMultipleUsers(
  userIds: number[]
): Promise<ApiResponseLatestDevicePairingUsers> {
  const url = `${process.env.REACT_APP_API_URL}/usage-service/devices/latest`;
  const payload = {
    users: userIds,
  };
  return Api.post(url, payload);
}
