import dayjs from 'dayjs';
import Site from './Site';
import { AuraEdri } from './Edri';
import Device, { DeviceModelE4, DeviceModelEMBP } from './Device';
import { UserLatestBiomarkersMap } from './Biomarker';

export const SubjectSortByInternalId = 'internalId';
export const SubjectSortByStatus = 'status';
export const SubjectSortByTemperature = 'temperature';
export const SubjectSortByPulseRate = 'pulserate';
export const SubjectSortByLastSync = 'lastsync';

export enum SubjectStatus {
  IDLE = 'idle',
  WAITING = 'waiting',
  MONITORING = 'monitoring',
  EARLY_TERM = 'early_term',
  ENDED = 'ended',
}

export const subjectStatusOrder = [
  SubjectStatus.IDLE,
  SubjectStatus.WAITING,
  SubjectStatus.MONITORING,
  SubjectStatus.EARLY_TERM,
  SubjectStatus.ENDED,
];

export default class Subject {
  id: number;
  internalId?: string;
  userId!: number;
  startedAt?: string;
  endedAt?: string;
  withdrawnAt?: string;
  createdAt?: string;
  lastLoggedAt?: string;
  firstDataAt?: string;
  site?: Site;
  fullInternalId?: string;
  status?: SubjectStatus;

  auraEdriValues?: AuraEdri[];
  latestDevicePairing?: Device;

  isLoadedLatestBiomarkers: boolean = false;
  isLoadedAuraValues: boolean = false;
  isLoadedLatestDevicePairing: boolean = false;

  // TODO(mb) Add site information
  constructor(s?: Subject) {
    if (!s) {
      this.id = 0;
      this.internalId = '-';
      this.userId = 0;
      return;
    }
    this.id = s.id || 0;
    this.internalId = s.internalId || '-';
    this.userId = s.userId || 0;
    this.site = s.site ? new Site(s.site) : undefined;
    this.fullInternalId = this.getFullInternalId();
    this.startedAt = s.startedAt || '';
    this.endedAt = s.endedAt || '';
    this.withdrawnAt = s.withdrawnAt || '';
    this.createdAt = s.createdAt || '';
    this.lastLoggedAt = s.lastLoggedAt || '';
    this.firstDataAt = s.firstDataAt || '';
    this.status = s.status;
    this.isLoadedLatestBiomarkers = Boolean(s.isLoadedLatestBiomarkers);
    this.isLoadedAuraValues = Boolean(s.isLoadedAuraValues);
    this.isLoadedLatestDevicePairing = Boolean(s.isLoadedLatestDevicePairing);
  }

  getFullInternalId(): string {
    let id = this.internalId || '-';
    if (this.site && this.site.internalIdPrefix) {
      id = `${this.site.internalIdPrefix} - ${this.internalId}`;
    }
    return id;
  }

  // checks that we have a valid device pairing field (not biomarker, but the one from usage service) within our subject
  hasValidDevicePairing(): boolean {
    return this.latestDevicePairing?.timestampStart !== undefined;
  }

  getDeviceModel(): string | undefined {
    const deviceModel = this.latestDevicePairing?.deviceModel.toLowerCase();

    switch (deviceModel) {
      case DeviceModelEMBP.toLowerCase():
        return DeviceModelEMBP;
      case DeviceModelE4.toLowerCase():
        return DeviceModelE4;
    }

    return undefined;
  }

  getStatusSinceDate(): Date | undefined {
    switch (this.status) {
      case SubjectStatus.IDLE:
        return this.createdAt ? new Date(this.createdAt) : undefined;
      case SubjectStatus.WAITING:
        return this.startedAt ? new Date(this.startedAt) : undefined;
      case SubjectStatus.MONITORING:
        return this.firstDataAt ? new Date(this.firstDataAt) : undefined;
      case SubjectStatus.EARLY_TERM:
        return this.withdrawnAt ? new Date(this.withdrawnAt) : undefined;
      case SubjectStatus.ENDED:
        return this.endedAt ? new Date(this.endedAt) : undefined;
    }
  }
}

export function getSortableList(
  list: Subject[],
  latestBiomarkers: UserLatestBiomarkersMap,
  sortBy: string
): Subject[] {
  switch (sortBy) {
    case SubjectSortByTemperature:
      return list.filter(
        (s: Subject) =>
          s &&
          latestBiomarkers[s.userId] &&
          latestBiomarkers[s.userId].temperature !== null
      );
    case SubjectSortByPulseRate:
      return list.filter(
        (s: Subject) =>
          s &&
          latestBiomarkers[s.userId] &&
          latestBiomarkers[s.userId].pulseRate !== null
      );
    case SubjectSortByLastSync:
      return list.filter(
        (s: Subject) =>
          s &&
          latestBiomarkers[s.userId] &&
          latestBiomarkers[s.userId].timestamp !== null
      );
    case SubjectSortByInternalId:
    case SubjectSortByStatus:
      // All items are sortable by InternalId and status
      return list;
  }
  return [];
}

export function getUnsortableList(
  list: Subject[],
  latestBiomarkers: UserLatestBiomarkersMap,
  sortBy: string
): Subject[] {
  switch (sortBy) {
    case SubjectSortByTemperature:
      return list.filter(
        (s: Subject) =>
          !(
            s &&
            latestBiomarkers[s.userId] &&
            latestBiomarkers[s.userId].temperature !== null
          )
      );
    case SubjectSortByPulseRate:
      return list.filter(
        (s: Subject) =>
          !(
            s &&
            latestBiomarkers[s.userId] &&
            latestBiomarkers[s.userId].pulseRate !== null
          )
      );
    case SubjectSortByLastSync:
      return list.filter(
        (s: Subject) =>
          !(
            s &&
            latestBiomarkers[s.userId] &&
            latestBiomarkers[s.userId].timestamp !== null
          )
      );
    case SubjectSortByInternalId:
    case SubjectSortByStatus:
      // No item is unsortable by InternalId nor status
      return [];
  }
  return list;
}

const drawText = (
  ctx: CanvasRenderingContext2D,
  text: string,
  config: EnrollmentSetupImageTextConfig
): void => {
  ctx.textAlign = config.textAlign || 'left';
  ctx.textBaseline = config.textBaseline || 'hanging';

  ctx.fillStyle = config.color;
  ctx.font = `${config.fontWeight || 400} ${config.fontSize}px ${config.font}`;
  ctx.fillText(text, config.x, config.y);
};

export interface SetupImageData {
  orgName: string;
  studyInternalId: string;
  siteInternalId: string;
  internalId: string;
  email: string;
  password: string;
}

export interface EnrollmentSetupImageTextConfig {
  x: number;
  y: number;
  color: string;
  textAlign?: CanvasTextAlign;
  textBaseline?: CanvasTextBaseline;
  font: string;
  fontWeight?: number | string;
  fontSize: number;
}

export interface EnrollmentSetupImageConfig {
  background: HTMLImageElement;
  qrCode: {
    x: number;
    y: number;
    size: number;
  };
  email: EnrollmentSetupImageTextConfig;
  password: EnrollmentSetupImageTextConfig;
  generatedAt: EnrollmentSetupImageTextConfig;
  orgName: EnrollmentSetupImageTextConfig;
  participantId: EnrollmentSetupImageTextConfig;
}

export const generateSetupImage = async (
  qrCode: HTMLCanvasElement | HTMLImageElement,
  {
    orgName,
    studyInternalId,
    siteInternalId,
    internalId,
    email,
    password,
  }: SetupImageData,
  {
    background,
    orgName: orgNameConfig,
    participantId: participantIdConfig,
    qrCode: qrCodeConfig,
    email: emailConfig,
    password: passwordConfig,
    generatedAt: generatedAtConfig,
  }: EnrollmentSetupImageConfig
): // The function is currently sync, but we want it to run asynchronously for future-proofing
// eslint-disable-next-line @typescript-eslint/require-await
Promise<HTMLCanvasElement> => {
  const layoutCanvas = document.createElement('canvas');
  layoutCanvas.width = background.width;
  layoutCanvas.height = background.height;

  const layoutCtx = layoutCanvas.getContext('2d');

  if (!layoutCtx) {
    throw new Error('Unable to generate the setup image');
  }

  // Draw the background
  layoutCtx.imageSmoothingEnabled = true;
  layoutCtx.drawImage(background, 0, 0, background.width, background.height);
  // Make the QR code look always crisp regardless of the size
  layoutCtx.imageSmoothingEnabled = false;
  layoutCtx.drawImage(
    qrCode,
    qrCodeConfig.x,
    qrCodeConfig.y,
    qrCodeConfig.size,
    qrCodeConfig.size
  );
  layoutCtx.imageSmoothingEnabled = true;

  const fullInternalId = [
    ...(studyInternalId ? [studyInternalId] : []),
    ...(siteInternalId ? [siteInternalId] : []),
    ...(internalId ? [internalId] : []),
  ].join(' - ');

  drawText(layoutCtx, orgName, orgNameConfig);
  drawText(layoutCtx, fullInternalId, participantIdConfig);
  drawText(layoutCtx, email, emailConfig);
  drawText(layoutCtx, password, passwordConfig);
  drawText(
    layoutCtx,
    dayjs().format('MMM DD, YYYY - hh:mm A UTC'),
    generatedAtConfig
  );

  return layoutCanvas;
};
