import React from 'react';
import ModalBase from './ModalBase';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import Table from '../SubjectView/DenseTable';

const useStyles = makeStyles((theme) => ({
  content: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

class ModalDebugViewProps {
  open!: boolean;
  onClose?: any;
  onChange?: any;
  data?: any;
  timerange?: any;
  exportToCSV?: any;
}

export default function ModalDebugView(props: ModalDebugViewProps) {
  const classes = useStyles();

  const close = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  const component = (
    <React.Fragment>
      <Box className={classes.content}>
        <h2>
          <Trans i18nKey="DEBUG_VIEW_TITLE" />
        </h2>
        <Table data={props.data} exportToCSV={props.exportToCSV}></Table>
      </Box>
    </React.Fragment>
  );

  return (
    <ModalBase
      open={props.open}
      component={component}
      width={1000}
      height={700}
      secondaryAction={{ label: i18next.t('OK'), func: close }}
    />
  );
}
