import { Scope, Role } from '../Model/User';

export const ScopeKeyOrganizations = 'orgs';
export const ScopeKeyStudies = 'studies';
export const ScopeKeySites = 'sites';
export const ScopeValueOwner = 'owner';
export const ScopeValueAdmin = 'admin';
export const ScopeValueManager = 'manager';
export const ScopeValueViewer = 'viewer';

export function isEnrollmentCreationEnabled(
  siteId: number | string,
  scopes: Scope[] | undefined,
  roles: Role[] | undefined
): boolean {
  return (
    isEnrollmentCreationEnabledByScopes(siteId, scopes) ||
    isEnrollmentCreationEnabledByRoles(roles)
  );
}

export function isAuraDebugViewEnabled(roles: Role[] | undefined): boolean {
  if (!roles || roles.length === 0) {
    return false;
  }
  return (
    roles.includes('admin') ||
    roles.includes('data-team-member') ||
    roles.includes('technical-support')
  );
}

export function isBrowserViewEnabled(roles: Role[] | undefined): boolean {
  if (!roles || roles.length === 0) {
    return false;
  }
  return roles.includes('admin');
}

function isEnrollmentCreationEnabledByScopes(
  siteId: number | string,
  scopes: Scope[] | undefined
): boolean {
  if (!siteId || !scopes || scopes.length === 0) {
    return false;
  }

  let enrollmentCreationRoles = [
    ScopeValueOwner,
    ScopeValueAdmin,
    ScopeValueManager,
  ];
  let filtered = scopes.filter((s: Scope) => {
    return (
      s.label === ScopeKeySites &&
      s.variant.toString() === siteId.toString() &&
      s.value &&
      enrollmentCreationRoles.indexOf(s.value?.toString()) >= 0
    );
  });
  return filtered.length > 0;
}

function isEnrollmentCreationEnabledByRoles(
  roles: Role[] | undefined
): boolean {
  if (!roles || roles.length === 0) {
    return false;
  }
  return roles.includes('admin');
}
