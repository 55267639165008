import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { Trans } from 'react-i18next';
import Subject from '../../Model/Subject';
import { exportSubjectsToCsv } from './ExportCompliance';
import { INFO_DOWNLOAD_COMPLIANCE, logger } from '../../Logging/Logger';

import UserContext from '../../Context/UserContext';
import { UserLatestBiomarkersMap } from '../../Model/Biomarker';

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'capitalize',
  },
  buttonAlign: {
    display: 'inline-block',
  },
}));

class ExportComplianceProps {
  enabled: boolean = false;
  subjects!: Subject[];
  latestBiomarkers!: UserLatestBiomarkersMap;
  siteInternalId: string = '';
}

export default function ExportComplianceButton(props: ExportComplianceProps) {
  const { config } = useContext(UserContext);

  const classes = useStyles();

  function downloadCompliance() {
    logger.info(INFO_DOWNLOAD_COMPLIANCE);
    let outputFile = `empatica_compliance_report_${props.siteInternalId}.csv`;
    exportSubjectsToCsv(
      props.subjects,
      props.latestBiomarkers,
      config,
      outputFile
    );
  }

  return (
    <div className={classes.buttonAlign}>
      <Button
        id={'btn-export-data'}
        color="primary"
        onClick={downloadCompliance}
        className={classes.button}
        disabled={!props.enabled}
      >
        <FontAwesomeIcon icon={faDownload} />
        <span>&nbsp;</span>
        <Trans i18nKey="DOWNLOAD_COMPLIANCE_BUTTON_LABEL" />
      </Button>
    </div>
  );
}
