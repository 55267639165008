import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-light-svg-icons';
import ModalInfo from '../../Modals/ModalInfo';
import i18next from 'i18next';
import React from 'react';

export default function AdherenceExpiredView() {
  const iconExpired = (
    <FontAwesomeIcon
      icon={faClock}
      style={{ width: '240px', height: '240px' }}
    />
  );
  return (
    <ModalInfo
      open={true}
      icon={iconExpired}
      title={i18next.t('REPORT_ADHERENCE_ERROR_EXPIRED_TITLE')}
      content={i18next.t('REPORT_ADHERENCE_ERROR_EXPIRED_CONTENT')}
    />
  );
}
