import Api from '../../../Http/Api';
import Subject from '../../../Model/Subject';
import Site from '../../../Model/Site';

export interface SiteSubjectsDataPayload {
  enrollments: Subject[];
}

export interface ApiResponseSiteSubjects {
  status: number;
  statusText: string;
  data: SiteSubjectsDataPayload;
}

export interface SiteDataPayload {
  payload: Site;
}

export interface ApiResponseSite {
  status: string;
  statusText: string;
  data: SiteDataPayload;
}

export function GetSite(
  orgId: number | string,
  studyId: number | string,
  siteId: number | string
): Promise<ApiResponseSite> {
  const url = `${process.env.REACT_APP_API_URL}/v3/organizations/${orgId}/studies/${studyId}/sites/${siteId}`;
  return Api.get(url);
}

export function GetSiteSubjects(
  orgId: number | string,
  studyId: number | string,
  siteId: number | string,
  includeUsage: boolean = false
): Promise<ApiResponseSiteSubjects> {
  const url = `${process.env.REACT_APP_API_URL}/org-service/sites/${siteId}/enrollments`;
  return Api.get(url);
}
