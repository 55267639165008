import React, { useContext, useMemo } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { Box, Paper, makeStyles, Grid } from '@material-ui/core';
import Subject, { SubjectStatus } from '../../Model/Subject';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import TemperatureIcon from '../../Icons/TemperatureIcon';
import PulseRateIcon from '../../Icons/PulseRateIcon';
import EmbPlusIcon from '../../Icons/EmbPlusIcon';
import UserContext from '../../Context/UserContext';
import LoadState from '../../Model/LoadState';
import i18next from 'i18next';
import {
  TimerangeRealtime6h,
  UserLatestBiomarkers,
  getLatestValueSecondsAgo,
} from '../../Model/Biomarker';
import { secondsAgoToElement } from '../../Model/ElementUtils';
import SubjectListItemBiomarkerDetailed from './SubjectListItemBiomarkerDetailed';
import SubjectListItemBiomarkerSimple from './SubjectListItemBiomarkerSimple';
import UserConfig from '../../Model/UserConfig';
import E4Icon from '../../Icons/E4Icon';
import Site from '../../Model/Site';
import { DeviceModelE4, DeviceModelEMBP } from '../../Model/Device';
import SubjectStatusLabel from './SubjectStatusLabel';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderLeft: '1px solid #E4E7EB',
    borderRight: '1px solid #E4E7EB',
    borderBottom: '1px solid #E4E7EB',
    borderTop: '0',
    borderRadius: '0',
    '&:hover': {
      backgroundColor: '#FAFAFA',
    },
  },
  userRow: {
    '& > *': {
      display: 'grid',
      alignContent: 'center',
    },
  },
  chevron: {
    fontSize: '1rem',
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'right',
    color: '#00778B',
  },
}));

class ListItemValue {
  value?: string;
  unit?: string;

  constructor(value: string | undefined, unit: string | undefined) {
    this.value = value;
    this.unit = unit;
  }
}

// TODO(rb): properly test these functions once we have an easy way to mock/test i18n strings
// getListItemForLastSync handles to logic to generate the ListItemValue for a subject last sync
function getListItemForLastSync(
  subject: Subject,
  latestBiomarkers: UserLatestBiomarkers
): ListItemValue | undefined {
  if (
    subject.status === SubjectStatus.IDLE ||
    subject.status === SubjectStatus.WAITING
  ) {
    return new ListItemValue(i18next.t('NEVER_SYNCED'), undefined);
  }

  if (
    subject.status === SubjectStatus.EARLY_TERM ||
    subject.status === SubjectStatus.ENDED
  ) {
    return new ListItemValue(i18next.t('USER_ENDED'), undefined);
  }

  // If there is no device compliance field, we then fallback to the device pairing (else)
  if (latestBiomarkers) {
    return getLastSyncForComplianceField(subject, latestBiomarkers);
  }

  // fallback to device pairing field if no device compliance biomarker is found
  return getLastSyncWithDevicePairingField(subject);
}

// getLastSyncForComplianceField is used to generate a ListItemValue using the Device Compliance field
function getLastSyncForComplianceField(
  subject: Subject,
  latestBiomarkers: UserLatestBiomarkers
): ListItemValue | undefined {
  if (!latestBiomarkers || !latestBiomarkers.timestamp) {
    return undefined;
  }

  const secondsAgo = getLatestValueSecondsAgo(latestBiomarkers.timestamp);
  const secondsAgoElement = secondsAgoToElement(secondsAgo);

  return new ListItemValue(secondsAgoElement.value, secondsAgoElement.unit);
}

// getLastSyncWithDevicePairingField is used to generate a ListItemValue using the latest device pairing field
function getLastSyncWithDevicePairingField(
  subject: Subject
): ListItemValue | undefined {
  if (subject.isLoadedLatestDevicePairing) {
    // scenario where there was at least a device pairing record, so > 30 days
    if (subject.hasValidDevicePairing()) {
      return new ListItemValue(
        i18next.t('MORE_THAN_30'),
        i18next.t('DAY_PLURAL')
      );
    } else {
      // scenario where no device pairing was found, so never
      return new ListItemValue(i18next.t('NEVER_SYNCED'), undefined);
    }
  }

  return undefined;
}

// getListItemForTemperature generates a ListItemValue for a given subject temperature
function getListItemForTemperature(
  subject: Subject,
  latestBiomarkers: UserLatestBiomarkers,
  config: UserConfig
): ListItemValue | undefined {
  if (
    !latestBiomarkers ||
    !latestBiomarkers.temperature ||
    subject.status !== SubjectStatus.MONITORING
  ) {
    return undefined;
  }

  const value = latestBiomarkers.temperature.toFixed(1);
  const unit = value ? config.measurementUnits.temperatureSymbol : '';
  return new ListItemValue(value, unit);
}

// getListItemForPulseRate generates a ListItemValue for a given subject pulse rate
function getListItemForPulseRate(
  subject: Subject,
  latestBiomarkers: UserLatestBiomarkers
): ListItemValue | undefined {
  if (
    !latestBiomarkers ||
    !latestBiomarkers.pulseRate ||
    subject.status !== SubjectStatus.MONITORING
  ) {
    return undefined;
  }

  const value = latestBiomarkers.pulseRate.toFixed(0);
  const unit = value ? i18next.t('BEATS_PER_MINUTE').toUpperCase() : '';
  return new ListItemValue(value, unit);
}

export interface SubjectListItemProps {
  subject: Subject;
  latestBiomarkers: UserLatestBiomarkers;
  site: Site;
  display?: Record<string, boolean>;
  biomarkersLoadState: LoadState;
  first?: boolean;
  last?: boolean;
}

export default function SubjectListItem({
  subject,
  latestBiomarkers,
  site,
  display = {},
  biomarkersLoadState,
  first,
  last,
}: SubjectListItemProps) {
  const classes = useStyles();
  const { orgId, studyId, siteId } = useParams<{
    orgId: string;
    studyId: string;
    siteId: string;
  }>();
  const { config } = useContext(UserContext);

  const subjectInternalId = subject.fullInternalId || subject.internalId || '-';

  const temperature = useMemo(
    () => getListItemForTemperature(subject, latestBiomarkers, config),
    [config, latestBiomarkers, subject]
  );
  const pulseRate = useMemo(
    () => getListItemForPulseRate(subject, latestBiomarkers),
    [latestBiomarkers, subject]
  );
  const lastSync = useMemo(
    () => getListItemForLastSync(subject, latestBiomarkers),
    [latestBiomarkers, subject]
  );

  const defaultViewSubjectItem = TimerangeRealtime6h;

  function getDeviceIcon(): JSX.Element {
    // try to first fetch subject device
    switch (subject.getDeviceModel()) {
      case DeviceModelEMBP:
        return <EmbPlusIcon />;

      case DeviceModelE4:
        return <E4Icon />;
    }

    // if not present, fallback to site device icon
    if (site.hasEmbracePlusDevice()) {
      return <EmbPlusIcon />;
    }

    if (site.hasE4Device()) {
      return <E4Icon />;
    }

    return <span />;
  }

  return (
    <Link
      component={RouterLink}
      underline="none"
      to={`/${orgId}/${studyId}/${siteId}/${subject.id}?view=${defaultViewSubjectItem}`}
      target="_blank"
    >
      <Paper className={classes.paper} elevation={0}>
        <Box p={2}>
          <Grid container className={classes.userRow}>
            <Grid item xs={3}>
              <Box>
                <FontAwesomeIcon icon={faUser} />
                &nbsp;
                <span>{subjectInternalId}</span>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <SubjectStatusLabel
                status={subject.status || SubjectStatus.IDLE}
                since={subject.getStatusSinceDate()}
              />
            </Grid>
            <Grid item xs={2}>
              {/*If there is unit, it is detailed mode rendering */}
              {lastSync?.unit && (
                <SubjectListItemBiomarkerDetailed
                  icon={getDeviceIcon()}
                  primaryValue={lastSync?.value}
                  primaryNote={lastSync?.unit}
                  secondaryNote={i18next.t('TIME_AGO')}
                  isLoading={biomarkersLoadState.isLoading}
                  disabled={subject.status !== SubjectStatus.MONITORING}
                />
              )}
              {/*If there is no unit, it is compact mode rendering */}
              {!lastSync?.unit && (
                <SubjectListItemBiomarkerSimple
                  icon={getDeviceIcon()}
                  value={lastSync?.value}
                  isLoading={biomarkersLoadState.isLoading}
                  disabled={subject.status !== SubjectStatus.MONITORING}
                />
              )}
            </Grid>
            <Grid item xs={2}>
              {display.temperature && (
                <SubjectListItemBiomarkerDetailed
                  icon={<TemperatureIcon />}
                  primaryValue={temperature?.value}
                  primaryNote={''}
                  secondaryNote={temperature?.unit}
                  isLoading={biomarkersLoadState.isLoading}
                  disabled={subject.status !== SubjectStatus.MONITORING}
                />
              )}
            </Grid>
            <Grid item xs={2}>
              {display.pulseRate && (
                <SubjectListItemBiomarkerDetailed
                  icon={<PulseRateIcon />}
                  primaryValue={pulseRate?.value}
                  primaryNote={''}
                  secondaryNote={pulseRate?.unit}
                  isLoading={biomarkersLoadState.isLoading}
                  disabled={subject.status !== SubjectStatus.MONITORING}
                />
              )}
            </Grid>
            <Grid item xs={1} className={classes.chevron}>
              <FontAwesomeIcon icon={faChevronRight} />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Link>
  );
}
