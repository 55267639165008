import Api from '../../../Http/Api';
import { BiomarkersResponse } from '../../../Model/Biomarker';

export class ApiResponseUserBiomarkers {
  data!: BiomarkersResponse;
}

export function GetUserBiomarkers(
  userId: number,
  from: number,
  to: number
): Promise<ApiResponseUserBiomarkers> {
  const url = `${process.env.REACT_APP_API_URL}/biomarkers-service/users/${userId}/biomarkers?from=${from}&to=${to}`;

  // Request the data and then call the mapper function
  return new Promise((resolve, reject) => {
    Api.get(url)
      .then((apiResponse: ApiResponseUserBiomarkers) => {
        let mappedValues = mapUserBiomarkersResponse(apiResponse);
        resolve(mappedValues);
      })
      .catch(() => {
        reject();
      });
  });
}

// TODO(rb): This is a temporary function to support the migration from heart rate to pulse rate. We can remove it
// once the biomarker service is deployed with the new naming convention (pulse rate)
// This function is used to iterate over each received element from the API and properly map the values to accept
// both pulse rate and heart rate values
function mapUserBiomarkersResponse(
  apiResponse: ApiResponseUserBiomarkers
): ApiResponseUserBiomarkers {
  apiResponse.data = new BiomarkersResponse(apiResponse.data);
  return apiResponse;
}
