import React, { useState, useEffect, useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Temperatures, {
  BiomarkerTemperatureMin,
  BiomarkerTemperatureMax,
} from '../../Model/Temperature';
import { MillisecondsInDay } from '../../Model/Biomarker';
import i18next from 'i18next';
import {
  getChartDayPrecisionLabel,
  getChartMedianLines,
} from '../../Model/Utils';
import UserContext from '../../Context/UserContext';
import { renderTrendTemperatureLegend } from './LegendUtils';
import SubjectChartTimezoneInfo from './SubjectChartTimezoneInfo';

dayjs.extend(utc);

export function getChartMax(temperatureMax: number | null | undefined): number {
  if (!temperatureMax) {
    return BiomarkerTemperatureMax;
  }
  return Math.round(temperatureMax + 0.5);
}

export function getChartMin(temperatureMin: number | null | undefined): number {
  if (!temperatureMin) {
    return BiomarkerTemperatureMin;
  }
  return Math.round(temperatureMin - 0.5);
}

export default function SubjectChartTemperatureDayPrecision(props: any) {
  const [temperatures, setTemperatures] = useState<Temperatures | undefined>();
  const [chart, setChart] = React.useState(<div>Loading</div>);
  const { config } = useContext(UserContext);

  useEffect(() => {
    setTemperatures(props.temperatures);
  }, [props.temperatures]);

  useEffect(() => {
    if (!temperatures) {
      return;
    }

    const temperatureUnit = config.measurementUnits.temperatureSymbol;

    // Retrieve list of values for the given precision
    const series: Array<number | null> = temperatures.getPrecisionValues(
      props.timerange?.precision
    );

    // Generate labels based on the series
    const labels = series.map((v: any, i: number) => {
      const label = getChartDayPrecisionLabel(
        temperatures.from + i * MillisecondsInDay,
        props.timezone.offset
      );
      const controlLabel = dayjs
        .utc(temperatures.from + i * MillisecondsInDay)
        .add(props.timezone.offset, 's')
        .format(config.dateFormat);

      const startOfDay = dayjs
        .utc(temperatures.from + i * MillisecondsInDay)
        .add(props.timezone.offset, 's')
        .startOf('day')
        .format(config.dateFormat);

      if (controlLabel !== startOfDay) {
        return null;
      }
      return label;
    });

    // Get the median. Set default value if missing
    const median = temperatures.median
      ? parseFloat(temperatures.median.toFixed(1))
      : null;
    const compareMedian = temperatures.compareMedian
      ? parseFloat(temperatures.compareMedian.toFixed(1))
      : null;
    const max = getChartMax(temperatures.max);
    const min = getChartMin(temperatures.min);

    const chartStepSize = 2;
    let lines = [];
    if (median !== null && compareMedian === null) {
      lines = getChartMedianLines(
        median,
        0,
        props.timerange?.precision,
        chartStepSize
      );
    }
    if (median === null && compareMedian !== null) {
      lines = getChartMedianLines(
        0,
        compareMedian,
        props.timerange?.precision,
        chartStepSize
      );
    }
    if (median !== null && compareMedian !== null) {
      lines = getChartMedianLines(
        median,
        compareMedian,
        props.timerange?.precision,
        chartStepSize
      );
    }

    const options = {
      elements: {
        line: {
          tension: 0, // disables bezier curves
        },
      },
      legend: {
        display: false,
      },
      gridLines: {
        color: '#E3E3E3',
      },
      scales: {
        yAxes: [
          {
            ticks: {
              startAtZero: false,
              max: max,
              min: min,
            },
            afterFit: function (scaleInstance: any) {
              scaleInstance.width = 40; // sets the width to 40px
            },
          },
        ],
        xAxes: [
          {
            stacked: true,
            gridLines: {
              display: false,
              offsetGridLines: true,
            },
            ticks: {
              userCallback: function (item: any, index: number) {
                if (item) return item;
              },
              autoSkip: true,
              beginAtZero: true,
            },
            offset: false,
          },
        ],
      },
      annotation: {
        annotations: lines,
      },
      tooltips: {
        mode: 'index',
        intersect: false,
        callbacks: {
          title: function (tooltipItem: any, data: any) {
            return dayjs(
              temperatures.from + tooltipItem[0]['index'] * MillisecondsInDay
            )
              .add(props.timezone.offset, 's')
              .utc()
              .startOf(props.timerange?.precision)
              .format('MMM DD, YYYY');
          },
          label: function (tooltipItem: any, data: any) {
            var label = data.datasets[tooltipItem.datasetIndex].label;
            var value =
              data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            if (tooltipItem.datasetIndex === 0) {
              return (
                i18next.t('TEMPERATURE_MEDIAN') + ': ' + value + temperatureUnit
              );
            } else if (tooltipItem.datasetIndex === 1) {
              return null;
            } else if (tooltipItem.datasetIndex === 2) {
              return null;
            } else if (tooltipItem.datasetIndex === 3) {
              if (value === null) {
                return null;
              }
              if (value[0] === min && value[1] === max) {
                return null;
              }
              return (
                i18next.t('QUARTILES') +
                ': [' +
                value[0] +
                ', ' +
                value[1] +
                '] ' +
                temperatureUnit
              );
            }
            return label + ': ' + value + temperatureUnit;
          },
        },
      },
      maintainAspectRatio: false,
    };

    let bars = [];
    let barColors = [];
    let hoverBackgroundBarColors = [];
    let tenDaysMedianLine = [];
    let todaysMedianLine = [];
    if (temperatures.aggregations != null) {
      for (let i = 0; i < temperatures.aggregations!.quartile1.length; i += 1) {
        let q1 = temperatures.aggregations!.quartile1[i];
        let q3 = temperatures.aggregations!.quartile3[i];
        if (q1 != null && q3 != null) {
          bars.push([
            temperatures.aggregations!.quartile1[i],
            temperatures.aggregations!.quartile3[i],
          ]);
          barColors.push('#F6941D');
          hoverBackgroundBarColors.push('#F6941D');
        } else {
          bars.push([min, max]);
          barColors.push('#D6D6D6');
          hoverBackgroundBarColors.push('#D6D6D6');
        }
        tenDaysMedianLine.push(compareMedian);
        todaysMedianLine.push(median);
      }
    }

    const data = {
      labels: labels,
      datasets: [
        {
          type: 'line',
          label: 'By day values',
          data: temperatures.aggregations?.median,
          fill: true,
          borderWidth: 1,
          borderColor: '#BE6F00',
          pointBorderColor: '#BE6F00',
          pointBackgroundColor: '#BE6F00',
          backgroundColor: 'transparent',
          pointSize: '10px',
        },
        {
          type: 'line',
          label: "Today's median",
          data: todaysMedianLine,
          borderDash: [4, 4],
          backgroundColor: 'rgba(0,0,0,0)',
          borderColor: 'rgba(64,64,64,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(0,0,0,0)',
          hoverBorderColor: 'rgba(64,64,64,1)',
          pointRadius: 4,
          pointBorderColor: 'transparent',
          pointBackgroundColor: 'transparent',
          pointHoverRadius: 4,
          pointHoverBackgroundColor: 'rgba(64,64,64,1)',
        },
        {
          type: 'line',
          label: 'Last 10 days median',
          data: tenDaysMedianLine,
          borderDash: [4, 8],
          backgroundColor: 'rgba(0,0,0,0)',
          borderColor: 'rgba(64,64,64,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(0,0,0,0)',
          hoverBorderColor: 'rgba(64,64,64,1)',
          pointRadius: 4,
          pointBorderColor: 'transparent',
          pointBackgroundColor: 'transparent',
          pointHoverRadius: 4,
          pointHoverBackgroundColor: 'rgba(64,64,64,1)',
        },
        {
          type: 'bar',
          label: 'Quartiles',
          data: bars,
          backgroundColor: barColors,
          hoverBackgroundColor: hoverBackgroundBarColors,
          categoryPercentage: 0.25,
          barPercentage: 0.85,
          barThickness: 25,
        },
      ],
    };

    setChart(<Bar data={data} options={options} height={320} />);
  }, [temperatures, props.timerange, config, props.timezone]);

  return (
    <span>
      <div>
        {renderTrendTemperatureLegend(props.timerange.range)}
        {chart}
      </div>
      <SubjectChartTimezoneInfo offsetInSeconds={props.timezone.offset} />
    </span>
  );
}
