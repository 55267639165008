import ServerErrorView from './ServerErrorView';
import ModalGenericError from '../Modals/ModalGenericError';
import i18next from 'i18next';
import React from 'react';
import LoadState, {
  LoadStateAuthorizationError,
  LoadStateServerError,
} from '../../Model/LoadState';

const onErrorAction = {
  label: 'Retry',
  func: () => {
    window.location.reload();
  },
};

function getModalGenericError(
  title: string,
  subtitle: string,
  loadState: LoadState
) {
  return (
    <ModalGenericError
      open={loadState.isError}
      title={title}
      content={subtitle}
      primaryAction={onErrorAction}
    />
  );
}

// Used when we need to render an error on the page
export function renderErrorsIfAny(
  loadState: LoadState
): JSX.Element | undefined {
  if (loadState.isError) {
    // Server error
    switch (loadState.constructor) {
      case LoadStateServerError.constructor:
        return <ServerErrorView />;
      case LoadStateAuthorizationError.constructor:
        return getModalGenericError(
          i18next.t('AUTH_ERROR_TITLE'),
          i18next.t('AUTH_ERROR_SUBTITLE'),
          loadState
        );
      default:
        return getModalGenericError(
          i18next.t('GENERIC_ERROR_TITLE'),
          i18next.t('GENERIC_ERROR_SUBTITLE'),
          loadState
        );
    }
  }
}
