import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/pro-light-svg-icons';
import { Trans } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: '0.8rem',
  },
  buttonAlign: {
    display: 'inline-block',
  },
}));

export default function PrintAdherenceReportButton() {
  const classes = useStyles();

  return (
    <div className={classes.buttonAlign}>
      <Button
        id={'btn-export-adherence-report'}
        color="primary"
        onClick={() => window.print()}
        className={classes.button}
      >
        <FontAwesomeIcon icon={faPrint} />
        <span>&nbsp;</span>
        <Trans i18nKey="REPORT_ADHERENCE_ACTION_PRINT_PDF" />
      </Button>
    </div>
  );
}
