import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as Icon } from './ClockIcon.svg';

export default function ClockIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <Icon />
    </SvgIcon>
  );
}
