import React from 'react';
import Site from '../Model/Site';

const SiteContext = React.createContext<{
  site?: Site;
  setSite: any;
  sites?: Site[];
  setSites: any;
}>({
  site: undefined,
  setSite: () => {},
  sites: undefined,
  setSites: () => {},
});

export const SiteProvider = SiteContext.Provider;
export const SiteConsumer = SiteContext.Consumer;

export default SiteContext;
