import React, { useReducer, useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import jsonwebtoken from 'jsonwebtoken';
import * as WebEmbraceApi from './Http/WebEmbraceApi';
import './App.css';
import AppDrawer from './Components/AppDrawer/AppDrawer';
import LoginView from './Components/Login/LoginView';
import AdherenceReportView from './Components/AdherenceReportView/AdherenceReportView';
import './i18n';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import UserReducer from './Reducer/UserReducer';
import SiteReducer from './Reducer/SiteReducer';
import { UserProvider, UserContextData } from './Context/UserContext';
import { SiteProvider } from './Context/SiteContext';
import FullscreenLoading from './Components/Utils/FullscreenLoading';
import Site from './Model/Site';
import SiteManager from './Model/SiteManager';
import LoadState, {
  LoadStateLoading,
  LoadStateSuccess,
  LoadStateUndefined,
  getErrorLoadStateFromApiResponse,
  LoadStateError,
} from './Model/LoadState';
import ResearchConfiguration, {
  StudyAppHealthmonitor,
} from './Model/ResearchConfiguration';
import { Feature, Scope, Role } from './Model/User';
import UserConfig from './Model/UserConfig';
import { logout } from './Model/Utils';
import { AxiosError } from 'axios';
import { renderErrorsIfAny } from './Components/ErrorViews/ErrorViewUtils';

export default function App() {
  const [loadState, setLoadState] = useState(LoadStateLoading);
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useReducer(UserReducer, {
    email: '',
  });
  const [config, setConfig] = useState<UserConfig>(
    new UserConfig(window.navigator.language)
  );
  const [features, setFeatures] = useState<Feature[]>();
  const [scopes, setScopes] = useState<Scope[]>();
  const [roles, setRoles] = useState<Role[]>();
  const [site, setSite] = useReducer(SiteReducer, {
    siteId: 0,
  });
  const [sites, setSites] = useState<Site[]>([]);
  const userContext: UserContextData = {
    user,
    features,
    scopes,
    roles,
    config,
    setUser,
    setFeatures,
    setScopes,
    setRoles,
    setConfig,
  };
  const siteContext = { site, setSite, sites, setSites };

  const loadUser = () => {
    const at: string | null = localStorage.getItem('at');
    if (!at) {
      setLoadState(LoadStateUndefined);
      return;
    }

    const token: any = jsonwebtoken.decode(at);
    // Check if an invalid token has been provided.
    // If so, clean things up and re-request login.
    if (!token || !token.PAYLOAD || !token.PAYLOAD.userId) {
      logout();
      return;
    }

    const tokenScopes = token.PAYLOAD['scopes.v1']
      ? token.PAYLOAD['scopes.v1'].map((s: string) => {
          return new Scope(s);
        })
      : [];
    setScopes(tokenScopes);
    setRoles(token.PAYLOAD.roles ? token.PAYLOAD.roles : []);
    WebEmbraceApi.GetUser(token.PAYLOAD.userId)
      .then((response) => {
        setUser(response.data.payload);
        setLoggedIn(true);
      })
      .catch((err: AxiosError) => {
        let errorLoadState: LoadState = LoadStateError;

        if (err != null && err.response != null) {
          errorLoadState = getErrorLoadStateFromApiResponse(
            err.response.status
          );
        }

        setLoadState(errorLoadState);
        return;
      });
  };

  useEffect(() => {
    loadUser();
  }, []);

  useEffect(() => {
    // TODO(mb) Improve this
    const localSite: string | null = localStorage.getItem('site');
    if (!localSite) {
      return;
    }
    const s = new Site(JSON.parse(localSite));
    setSite(s);
  }, []);

  useEffect(() => {
    const localConfig: any = localStorage.getItem('config');
    if (!localConfig) {
      return;
    }
    const c = JSON.parse(localConfig);
    if (!c || !c.locale) {
      return;
    }
    setConfig(new UserConfig(c.locale));
  }, []);

  useEffect(() => {
    if (!user.id) {
      return;
    }
    const fetchResearchManagers = async () => {
      const at: string | null = localStorage.getItem('at');
      if (!at) {
        return;
      }
      setLoadState(LoadStateLoading);
      WebEmbraceApi.GetUserResearchManagers(user.id)
        .then((resp: WebEmbraceApi.ApiResponseUserResearchManagers) => {
          const sites = resp.data.payload.siteManagers
            ? resp.data.payload.siteManagers
                .filter((m: SiteManager) => {
                  const hasInvitation =
                    !m.invitation || m.invitation.acceptedAt;
                  const isStudyEnabled =
                    m.site.study &&
                    m.site.study.researchConfiguration &&
                    m.site.study.researchConfiguration.filter(
                      (rc: ResearchConfiguration) => {
                        return rc.name === StudyAppHealthmonitor;
                      }
                    ).length > 0;
                  const isActive = !Boolean(m.deletedAt);
                  return hasInvitation && isStudyEnabled && isActive;
                })
                .map((m: SiteManager) => {
                  return new Site(m.site);
                })
            : [];
          setSites(sites);
          setLoadState(LoadStateSuccess);
        })
        .catch((err: AxiosError) => {
          let errorLoadState: LoadState = LoadStateError;

          if (err != null && err.response != null) {
            errorLoadState = getErrorLoadStateFromApiResponse(
              err.response.status
            );
          }

          setLoadState(errorLoadState);
        });
    };

    fetchResearchManagers();
  }, [user]);

  useEffect(() => {
    if (!user.id) {
      return;
    }
    const fetchFeatures = async () => {
      const at: string | null = localStorage.getItem('at');
      if (!at) {
        return;
      }
      WebEmbraceApi.GetUserFeatures(user.id).then(
        (resp: WebEmbraceApi.ApiResponseUserFeatures) => {
          if (!resp.data.payload.features) {
            return;
          }
          const features = resp.data.payload.features.map((f: string) => {
            return new Feature(f);
          });
          setFeatures(features);
        }
      );
    };

    fetchFeatures();
  }, [user]);

  const onLoginSuccess = () => {
    loadUser();
  };

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#00778B',
        contrastText: '#FEFEFE',
      },
      secondary: {
        main: '#F9FAFB',
        contrastText: '#3A3B3F',
      },
    },
    typography: {
      h1: {
        fontSize: '1.3rem',
        fontWeight: 400,
        color: '#00778b',
      },
      h2: {
        fontSize: '1.2rem',
        fontWeight: 400,
        color: '#00778b',
      },
    },
    overrides: {
      MuiTooltip: {
        tooltip: {
          backgroundColor: '#2E384D',
        },
        arrow: {
          color: '#2E384D',
        },
      },
    },
  });

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Switch>
            {/* Routes without login */}
            <Route exact path={'/reports/adherence'}>
              <AdherenceReportView />
            </Route>
            {/* Routes with login */}
            <Route>
              {/*Is loading => Show loading view*/}
              {loadState.isLoading && <FullscreenLoading />}
              {/*Has error => Display error view*/}
              {renderErrorsIfAny(loadState)}
              {/*No error, not loading and logged in => Show main view*/}
              {!loadState.isError && !loadState.isLoading && loggedIn && (
                <UserProvider value={userContext}>
                  <SiteProvider value={siteContext}>
                    <AppDrawer />
                  </SiteProvider>
                </UserProvider>
              )}

              {/*No errors, finished loading and not signed in => Show login view*/}
              {!loadState.isError && !loadState.isLoading && !loggedIn && (
                <LoginView onSuccess={onLoginSuccess} />
              )}
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </div>
  );
}
