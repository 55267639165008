import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { AppBar, Box, Grid, LinearProgress } from '@material-ui/core';
import i18next from 'i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileChartLine } from '@fortawesome/pro-light-svg-icons';
import LogoWhite from '../../../Logo/empatica-logo.svg';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: 'auto',
    /* 279.4mm */
    height: '11in',
    /* 215.9mm */
    width: '8.5in',
    border: '1px solid #d3d3d3',
    background: '#fff',
  },
  appBarLogo: {
    height: 20,
    marginTop: 10,
    marginBottom: 4,
  },
  loadingContainer: {
    marginTop: '9.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingText: {
    marginTop: '31px',
    fontWeight: 100,
    fontSize: '1.5rem',
  },
  loadingProgressBar: {
    marginTop: '25px',
  },
}));

export default function AdherenceLoadingView() {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <AppBar position="relative" elevation={0}>
        <Box textAlign="center">
          <img
            src={LogoWhite}
            alt={i18next.t('APP_NAME')}
            className={classes.appBarLogo}
          />
        </Box>
      </AppBar>

      <Grid container className={classes.loadingContainer}>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <FontAwesomeIcon
            icon={faFileChartLine}
            style={{ width: '177px', height: '155px', color: '#147B8F' }}
          />
          <h3 className={classes.loadingText}>
            {i18next.t('REPORT_ADHERENCE_LOADING')}
          </h3>
          <LinearProgress className={classes.loadingProgressBar} />
        </Grid>
        <Grid item xs={4} />
      </Grid>
    </Box>
  );
}
