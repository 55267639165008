import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1.8rem 8rem 1.6rem 1fr',
    gridTemplateRows: '1fr',
    gridColumnGap: '0px',
    gridRowGap: '0px',
  },
  disabled: {
    filter: 'grayscale(100%) contrast(0)',
  },
  icon: {
    width: '1.6rem',
    height: '1.6rem',
    display: 'block',
    padding: '0.2rem',
    '& > img': {
      width: 'auto',
      height: '100%',
    },
  },
  value: {
    width: 'auto',
    height: '1.6rem',
    display: 'block',
    textAlign: 'left',
  },
  notAvailable: {
    color: '#969696',
  },
  notes: {
    display: 'inline-block',
    marginLeft: '0.2rem',
    '& > *': {
      display: 'block',
    },
  },
  skeleton: {
    display: 'inline-block',
  },
  valueText: {
    fontFamily: 'Inconsolata',
    letterSpacing: '-0.05rem',
    fontWeight: 200,
    fontSize: '1rem',
    lineHeight: '1.6rem',
  },
}));

export interface SubjectListItemBiomarkerSimpleProps {
  icon: JSX.Element;
  value: string | number | undefined;
  isLoading?: boolean;
  disabled?: boolean;
}

export default function SubjectListItemBiomarkerSimple({
  icon,
  value,
  isLoading,
  disabled,
}: SubjectListItemBiomarkerSimpleProps) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Box className={`${classes.wrapper} ${disabled ? classes.disabled : ''}`}>
        <Box className={classes.icon}>
          {isLoading && (
            <Skeleton
              variant="circle"
              animation="wave"
              height="100%"
              width="100%"
            />
          )}
          {!isLoading && icon}
        </Box>
        <Box className={classes.value}>
          {isLoading && (
            <Skeleton animation="wave" height="100%" width="100%" />
          )}
          {!isLoading && (
            <span className={classes.valueText}>
              {value && <span>{value}</span>}
              {!value && <span className={classes.notAvailable}>-</span>}
            </span>
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
}
