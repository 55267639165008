import React, { useState, useMemo, ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import SubjectListAuraHeader from './SubjectListAuraHeader';
import SubjectListAuraItem from './SubjectListAuraItem';
import Subject from '../../Model/Subject';
import LoadState from '../../Model/LoadState';
import Timerange from '../../Model/Timerange';

const useStyles = makeStyles((theme) => ({
  subjectList: {
    paddingBottom: theme.spacing(8),
  },
  paginationWrapper: {
    display: 'block',
    margin: 'auto',
    textAlign: 'center',
    padding: theme.spacing(2),
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: 0,
    backgroundColor: '#FAFAFA',
    borderTop: '1px solid #E4E7EB;',
  },
  pagination: {
    display: 'inline-block',
    margin: 'auto',
  },
}));

export interface SubjectListAuraProps {
  timerange: Timerange;
  list: Subject[];
  display: Record<string, boolean>;
  subjectsLoadState: LoadState;
}

export default function SubjectListAura({
  list,
  timerange,
  subjectsLoadState,
  display,
}: SubjectListAuraProps) {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const pageCount = useMemo(() => Math.ceil(list.length / pageSize), [
    list.length,
  ]);

  const auraSubjectsPage = useMemo(() => {
    const from = (currentPage - 1) * pageSize;
    const to = currentPage * pageSize;
    return list.slice(from, to);
  }, [currentPage, list]);

  const onPageChange = (evt: ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className={classes.subjectList}>
      <Grid container spacing={0}>
        <Grid item xs={12} key="subject-list-header">
          <div>
            <SubjectListAuraHeader timerange={timerange} />
          </div>
        </Grid>
        {subjectsLoadState.isLoading ? (
          <Box>Loading</Box>
        ) : (
          auraSubjectsPage.map((s) => (
            <Grid item xs={12} key={`subject-${s.id}`} id={`subject-${s.id}`}>
              <SubjectListAuraItem timerange={timerange} subject={s} />
            </Grid>
          ))
        )}
      </Grid>
      {!subjectsLoadState.isLoading && (
        <Box className={classes.paginationWrapper}>
          <Box className={classes.pagination}>
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={onPageChange}
              color="primary"
              showFirstButton
              showLastButton
            />
          </Box>
        </Box>
      )}
    </div>
  );
}
