import Api from '../../../Http/Api';
import {
  GetEdriResponse,
  GetAuraEdriResponse,
  AuraEdri,
} from '../../../Model/Edri';

export class ApiResponseUserEdri {
  data!: GetEdriResponse;
}

export class ApiResponseUserAuraEdri {
  data!: GetAuraEdriResponse;
}

// Request payload for a single user when retrieving AURA values for multiple users
export class AuraForUsersSingleUserPayloadRequest {
  timezone!: number;
}

// Request payload for fetching AURA values for multiple users
export class AuraForUsersPayloadRequest {
  fromDay!: string;
  toDay!: string;
  users!: { [key: number]: AuraForUsersSingleUserPayloadRequest };
}

// Response payload for retrieving AURA values for multiple users
export class AuraForUsersApiResponse {
  fromDay!: string;
  toDay!: string;
  users!: { [key: number]: AuraEdri[] };
}

// Response payload wrapper for retrieving AURA values for multiple users
export class ApiResponseAuraForUsers {
  data!: AuraForUsersApiResponse;
}

export function GetUserEdri(
  userId: number,
  from: number,
  to: number
): Promise<ApiResponseUserEdri> {
  const url = `${process.env.REACT_APP_API_URL}/biomarkers-service/users/${userId}/edri?from=${from}&to=${to}`;
  return Api.get(url);
}

export function GetUserAuraEdri(
  userId: number,
  fromDay: string,
  toDay: string,
  tz: number
): Promise<ApiResponseUserAuraEdri> {
  const url = `${process.env.REACT_APP_API_URL}/biomarkers-service/users/${userId}/aura/edri?fromDay=${fromDay}&toDay=${toDay}&tz=${tz}`;
  return Api.get(url);
}

export function GetAuraEdriForUsers(
  payload: AuraForUsersPayloadRequest
): Promise<ApiResponseAuraForUsers> {
  const url = `${process.env.REACT_APP_API_URL}/biomarkers-service/aura/edri`;
  return Api.post(url, payload);
}
