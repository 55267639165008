import Api from '../../../Http/Api';
import User from '../../../Model/User';

class DataPayload {
  payload!: User;
}

export class ApiResponseUser {
  status!: string;
  statusText!: string;
  data!: DataPayload;
}

export function GetUser(userId: number): Promise<ApiResponseUser> {
  const url = `${process.env.REACT_APP_API_URL}/v3/users/${userId}`;
  return Api.get(url);
}

export function ChangePassword(
  userId: number,
  oldPassword: string,
  newPassword: string
): Promise<any> {
  const url = `${process.env.REACT_APP_API_URL}/v3/users/${userId}/password?app=healthmonitor`;
  const payload = {
    oldPassword: oldPassword,
    newPassword: newPassword,
  };
  return Api.put(url, payload);
}
