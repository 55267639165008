import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { Box, Paper, makeStyles } from '@material-ui/core';
import Subject, { SubjectStatus } from '../../Model/Subject';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { Timerange10d } from '../../Model/Biomarker';
import AuraEdriBadge from '../../Items/AuraEdriBadge';
import { Skeleton } from '@material-ui/lab';
import Timerange from '../../Model/Timerange';
import SubjectStatusLabel from './SubjectStatusLabel';
import { AuraEdri } from '../../Model/Edri';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderLeft: '1px solid #E4E7EB',
    borderRight: '1px solid #E4E7EB',
    borderBottom: '1px solid #E4E7EB',
    borderTop: '0',
    borderRadius: '0',
    '&:hover': {
      backgroundColor: '#FAFAFA',
    },
  },
  userRow: {
    '& > *': {
      display: 'grid',
      alignContent: 'center',
    },
  },
  chevron: {
    fontSize: '1rem',
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'right',
    color: '#00778B',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '2fr 140px repeat(11, 1fr) 24px',
    gridTemplateRows: '1fr',
    gridColumnGap: '0px',
    gridRowGap: '0px',
  },
}));

export interface SubjectListAuraItemProps {
  subject: Subject;
  timerange: Timerange;
}

export default function SubjectListAuraItem({
  subject,
  timerange,
}: SubjectListAuraItemProps) {
  const classes = useStyles();
  const { orgId, studyId, siteId } = useParams<{
    orgId: string;
    studyId: string;
    siteId: string;
  }>();
  const subjectInternalId = subject.fullInternalId || subject.internalId || '-';
  const defaultViewSubjectItem = Timerange10d;

  function renderLoading() {
    // TODO(Rb): improve this taking into consideration the timerange
    const columnsLoading = new Array(11).fill(0);
    return columnsLoading.map((value, index) => (
      <Skeleton key={index} animation="wave" height="100%" width="60%" />
    ));
  }

  function renderAuraEdriLoaded(daysAura: any[]) {
    return daysAura.map((value, index) => (
      <Box key={index}>
        <AuraEdriBadge aura={value} status={subject.status} />
      </Box>
    ));
  }

  function renderChevron() {
    return (
      <Box className={classes.chevron}>
        <FontAwesomeIcon icon={faChevronRight} />
      </Box>
    );
  }

  function getEmptyEdriValues(): AuraEdri[] {
    const start = dayjs().subtract(10, 'day');
    const tz = dayjs().utcOffset() * 60;

    return Array.from({ length: 11 }, (value, index) => ({
      day: start.add(index, 'day').format('YYYY-MM-DD'),
      tz,
    }));
  }

  return (
    <Link
      component={RouterLink}
      underline="none"
      to={`/${orgId}/${studyId}/${siteId}/${subject.id}?view=${defaultViewSubjectItem}`}
      target="_blank"
    >
      <Paper className={classes.paper} elevation={0}>
        <Box p={2}>
          <Box className={classes.grid}>
            <Box>
              <FontAwesomeIcon icon={faUser} />
              &nbsp;
              <span>{subjectInternalId}</span>
            </Box>

            <Box>
              <SubjectStatusLabel
                status={subject.status || SubjectStatus.IDLE}
                since={subject.getStatusSinceDate()}
              />
            </Box>

            {subject.isLoadedAuraValues
              ? renderAuraEdriLoaded(
                  subject.auraEdriValues || getEmptyEdriValues()
                )
              : renderLoading()}

            {subject.isLoadedAuraValues && renderChevron()}
          </Box>
        </Box>
      </Paper>
    </Link>
  );
}
