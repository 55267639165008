import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Legend, { LegendProps } from './Legend';

const useStyles = makeStyles((theme) => ({
  legendContainer: {
    marginLeft: '2.8rem',
    verticalAlign: 'middle',
    display: 'flex',
  },
}));

class LegendContainerProps {
  items!: Array<LegendProps>;
}

export default function LegendContainer(props: LegendContainerProps) {
  const classes = useStyles();

  return (
    <div className={classes.legendContainer}>
      {props.items.map((value, index) => {
        return <Legend icon={value.icon} text={value.text} key={index} />;
      })}
    </div>
  );
}
