import React, { useEffect, useState, useContext, useMemo } from 'react';
import { withRouter } from 'react-router';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import SubjectList from './SubjectList';
import SubjectListAura from './SubjectListAura';
import Subject from '../../Model/Subject';
import { AppBar, Toolbar, Grid, Box } from '@material-ui/core';
import { Trans } from 'react-i18next';
import * as WebEmbraceApi from '../../Http/WebEmbraceApi';
import EnrollmentCreation from '../EnrollmentCreation/EnrollmentCreation';
import SiteContext from '../../Context/SiteContext';
import TimerangeSelector from '../TimerangeSelector/TimerangeSelector';
import Site from '../../Model/Site';
import ViewLoading from '../Utils/ViewLoading';
import LoadState, {
  LoadStateLoading,
  LoadStateSuccess,
  LoadStateUndefined,
  LoadStateError,
  getErrorLoadStateFromApiResponse,
} from '../../Model/LoadState';
import {
  S3BrowserView,
  Timerange30d,
  TimerangeAuraEDRITrend,
  UserLatestBiomarkersMap,
} from '../../Model/Biomarker';
import Timerange from '../../Model/Timerange';
import UserContext from '../../Context/UserContext';
import {
  isBrowserViewEnabled,
  isEnrollmentCreationEnabled,
} from '../../Authorization/RolesAndScopes';
import ExportComplianceButton from '../ExportCompliance/ExportComplianceButton';
import { setTitleLoading, updateTitleForSite } from '../Utils/TitleUtils';
import { renderErrorsIfAny } from '../ErrorViews/ErrorViewUtils';
import { AxiosError } from 'axios';
import { useDebouncedEffect } from '../Utils/UseDebouncedEffect';
import { fetchAuraForUsers } from './AuraController';
import BrowserView from '../BrowserView/BrowserView';
import * as BiomarkersService from '../../Http/BiomarkersService';
import { ApiResponseLatestBiomarkers } from '../../Http/BiomarkersService';
import EnrollmentCreationButton from '../EnrollmentCreation/EnrollmentCreationButton';

const useStyles = makeStyles((theme) => ({
  siteName: {
    fontSize: '0.85rem',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: '#147b8f',
  },
  subToolbar: {
    backgroundColor: '#ffffff',
    borderBottom: '1px solid #DFE3E8',
    height: theme.spacing(6),
  },
  subjectList: {
    padding: theme.spacing(0),
  },
  noSubjects: {
    width: '100%',
    height: 'auto',
    display: 'grid',
    textAlign: 'center',
    marginTop: '25%',
  },
}));

function SiteView(props: any) {
  const classes = useStyles();
  const { orgId, studyId, siteId } = useParams<{
    orgId: string;
    studyId: string;
    siteId: string;
  }>();
  const { scopes, roles, config } = useContext(UserContext);

  const [
    enrollmentCreationDialogOpen,
    setEnrollmentCreationDialogOpen,
  ] = useState(false);
  const [originalSubjects, setOriginalSubjects] = useState<Subject[]>([]);
  const [siteLatestBiomarkers, setSiteLatestBiomarkers] = useState<
    UserLatestBiomarkersMap
  >({});
  const [auraEdriValues, setAuraEdriValues] = useState<Subject[]>([]);
  const [auraOffset, setAuraOffset] = useState<number>();

  const { site, setSite } = useContext(SiteContext);
  const [siteLoadState, setSiteLoadState] = useState(LoadStateLoading);
  const [exportDataEnabled, setExportDataEnabled] = useState(false);
  const [auraViewEnabled, setAuraViewEnabled] = useState<boolean>(false);

  const [subjectsLoadState, setSubjectsLoadState] = useState(
    LoadStateUndefined
  );
  const [biomarkersLoadState, setBiomarkersLoadState] = useState(
    LoadStateUndefined
  );

  const [timerange, setTimerange] = useState<Timerange>(
    new Timerange(Timerange30d)
  );

  const USERS_PER_PAGE = 20;
  const DEBOUNCE_MS = 250;

  const display = useMemo(
    () => ({
      temperature: site?.hasBiomarkerTemperatureEnabled?.() || false,
      pulseRate: site?.hasBiomarkerPulseRateEnabled?.() || false,
      edri: false,
    }),
    [site]
  );

  const canCreateEnrollments = useMemo(
    () => siteId && isEnrollmentCreationEnabled(siteId, scopes, roles),
    [roles, scopes, siteId]
  );

  const openEnrollmentCreationDialog = () => {
    setEnrollmentCreationDialogOpen(true);
  };

  const closeEnrollmentCreationDialog = () => {
    setEnrollmentCreationDialogOpen(false);
  };

  useEffect(() => {
    // When component mounts, set loadings as active by default
    setSubjectsLoadState(LoadStateLoading);
    setSiteLoadState(LoadStateLoading);
    setTitleLoading();

    // When component unmounts, reset subject list
    return () => {
      setOriginalSubjects([]);
      setAuraEdriValues([]);
    };
  }, []);

  useEffect(() => {
    if (!site || !site.id) {
      return;
    }
    setSubjectsLoadState(LoadStateLoading);
    const siteId = site.id;
    const studyId = site.studyId;
    const orgId = site.organizationId;
    const fetchSiteEnrollments = async () => {
      WebEmbraceApi.GetSiteSubjects(orgId, studyId, siteId)
        .then((resp) => {
          const subjects = (resp.data.enrollments || []).map((s) => {
            s.site = site;
            return new Subject(s);
          });
          setOriginalSubjects(subjects);
          setAuraEdriValues(subjects);
          getSiteLatestBiomarkers(siteId);
          setSubjectsLoadState(LoadStateSuccess);
        })
        .catch((err: AxiosError) => {
          let errorLoadState: LoadState = LoadStateError;

          if (err != null && err.response != null) {
            errorLoadState = getErrorLoadStateFromApiResponse(
              err.response.status
            );
          }

          setSiteLoadState(errorLoadState);
          return;
        });
    };

    fetchSiteEnrollments();
  }, [site, props.history]);

  const getSiteLatestBiomarkers = (siteId: number) => {
    setBiomarkersLoadState(LoadStateLoading);
    BiomarkersService.GetSiteLatestBiomarkers(siteId)
      .then((result: ApiResponseLatestBiomarkers) => {
        setSiteLatestBiomarkers(result.data.users);
        setBiomarkersLoadState(LoadStateSuccess);
      })
      .catch(() => {
        setBiomarkersLoadState(LoadStateError);
      });
  };

  useEffect(() => {
    if (!orgId || !studyId || !siteId) {
      return;
    }
    // When site changes, set loadings and reset subjects
    setSiteLoadState(LoadStateLoading);
    setSubjectsLoadState(LoadStateLoading);
    setOriginalSubjects([]);
    setAuraEdriValues([]);
    setExportDataEnabled(false);
    const fetchSite = async () => {
      WebEmbraceApi.GetSite(orgId, studyId, siteId)
        .then((resp: any) => {
          const s = new Site(resp.data.payload);
          localStorage.setItem('site', JSON.stringify(s));
          setSite(s);
          updateTitleForSite(s);
          setAuraViewEnabled(Boolean(s && s.hasEdriResearcherDisplayEnabled()));
          setSiteLoadState(LoadStateSuccess);
        })
        .catch((err: AxiosError) => {
          let errorLoadState: LoadState = LoadStateError;

          if (err != null && err.response != null) {
            errorLoadState = getErrorLoadStateFromApiResponse(
              err.response.status
            );
          }

          setSiteLoadState(errorLoadState);
          return;
        });
    };

    fetchSite();
  }, [orgId, studyId, siteId, setSite, props.history]);

  useEffect(() => {
    if (
      !originalSubjects ||
      originalSubjects.length === 0 ||
      !timerange.range
    ) {
      return;
    }

    let usersBatch = originalSubjects.slice(0, USERS_PER_PAGE);
    let userIds = usersBatch.map((s) => s.userId);

    // fetch AURA values
    const timeRangeAura = new Timerange(TimerangeAuraEDRITrend);
    fetchAuraForUsers(userIds, originalSubjects, timeRangeAura)
      .then((response: Subject[]) => {
        setAuraEdriValues(response);
        setAuraOffset(USERS_PER_PAGE);
      })
      .catch(() => {});
  }, [originalSubjects, timerange, config, setAuraEdriValues]);

  useEffect(() => {
    const exportEnabled =
      siteLoadState !== LoadStateLoading &&
      subjectsLoadState !== LoadStateLoading &&
      biomarkersLoadState !== LoadStateLoading;
    setExportDataEnabled(exportEnabled);
  }, [siteLoadState, subjectsLoadState, biomarkersLoadState]);

  useDebouncedEffect(() => {
    if (auraOffset === undefined) {
      return;
    }

    if (auraOffset >= originalSubjects.length) {
      return;
    }

    let missingUsers = originalSubjects.slice(
      auraOffset,
      USERS_PER_PAGE + auraOffset
    );

    let missingIds = missingUsers.map((s) => s.userId);

    const timerangeAura = new Timerange(TimerangeAuraEDRITrend);

    fetchAuraForUsers(missingIds, originalSubjects, timerangeAura).then(
      (response: Subject[]) => {
        let offset = auraOffset + USERS_PER_PAGE;
        setAuraOffset(offset);
        setAuraEdriValues(response);
      }
    );
  }, DEBOUNCE_MS);

  const onTimerangeChange = (s: Timerange) => {
    if (!timerange || timerange.from !== s.from || timerange.to !== s.to) {
      setTimerange(s);
    }
  };

  const onEnrollmentCreation = (newSubject: Subject) => {
    newSubject.site = site;
    // The full internal ID is computed based on the site, so we need to update it too
    newSubject.fullInternalId = newSubject.getFullInternalId();

    setOriginalSubjects((previousOriginalSubjects) => [
      newSubject,
      ...previousOriginalSubjects,
    ]);
    setAuraEdriValues((previousAuraEdriValues) => [
      newSubject,
      ...previousAuraEdriValues,
    ]);
  };

  if (!site) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function renderReportButtonIfHasSubjects(): JSX.Element | undefined {
    let hasSubjects = originalSubjects != null && originalSubjects.length > 0;
    let hasSite = site != null && site.internalId != null;

    if (hasSubjects && hasSite) {
      return (
        <ExportComplianceButton
          siteInternalId={site!.internalId}
          enabled={exportDataEnabled}
          subjects={originalSubjects}
          latestBiomarkers={siteLatestBiomarkers}
        />
      );
    }
  }

  // Check enabled views
  const enabledViews = [Timerange30d];
  if (auraViewEnabled) {
    enabledViews.push(TimerangeAuraEDRITrend);
  }

  if (isBrowserViewEnabled(roles)) {
    enabledViews.push(S3BrowserView);
  }

  // Render
  return (
    <React.Fragment>
      {siteLoadState.isLoading && <ViewLoading />}
      {renderErrorsIfAny(siteLoadState)}
      {siteLoadState.isSuccess && (
        <React.Fragment>
          <AppBar position="static" color="secondary" elevation={0}>
            <Toolbar variant="dense">
              <Grid container>
                <Grid item xs={6}>
                  <Box textAlign="left" className={classes.siteName}>
                    <Trans i18nKey="SITE_VIEW_TITLE" /> | {site.name}
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box textAlign="right">
                    <div>
                      {/* {renderReportButtonIfHasSubjects()} */}
                      {canCreateEnrollments && (
                        <EnrollmentCreationButton
                          id="btn-add-user"
                          onClick={openEnrollmentCreationDialog}
                        />
                      )}
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <AppBar
            position="static"
            color="secondary"
            elevation={0}
            className={classes.subToolbar}
          >
            {originalSubjects.length > 0 && (
              <TimerangeSelector
                onChange={onTimerangeChange}
                enabledViews={enabledViews}
                showTimezoneInfo={false}
              />
            )}
          </AppBar>

          {timerange.range === Timerange30d && (
            <Box className={classes.subjectList}>
              {!subjectsLoadState.isLoading && originalSubjects.length > 0 && (
                <SubjectList
                  list={originalSubjects}
                  latestBiomarkers={siteLatestBiomarkers}
                  display={display}
                  subjectsLoadState={subjectsLoadState}
                  biomarkersLoadState={biomarkersLoadState}
                  site={site}
                />
              )}
              {!subjectsLoadState.isLoading && originalSubjects.length === 0 && (
                <Box className={classes.noSubjects}>
                  <h2>
                    <Trans i18nKey="SITE_VIEW_NO_USERS_TITLE" />
                  </h2>
                  {canCreateEnrollments && (
                    <React.Fragment>
                      <Box>
                        <Trans i18nKey="SITE_VIEW_NO_USERS_SUBTITLE" />
                      </Box>
                      <Box mt={1}>
                        <EnrollmentCreationButton
                          id="btn-add-user-no-users"
                          onClick={openEnrollmentCreationDialog}
                        />
                      </Box>
                    </React.Fragment>
                  )}
                </Box>
              )}
            </Box>
          )}

          {timerange.range === TimerangeAuraEDRITrend && (
            <Box className={classes.subjectList}>
              {!subjectsLoadState.isLoading && auraEdriValues.length > 0 && (
                <SubjectListAura
                  timerange={timerange}
                  list={auraEdriValues}
                  display={display}
                  subjectsLoadState={subjectsLoadState}
                />
              )}
            </Box>
          )}

          {timerange.range === S3BrowserView && (
            <Box className={classes.subjectList}>
              <BrowserView
                organizationInternalId={site.organizationInternalId}
                studyInternalId={site.studyInternalId}
                siteInternalId={site.internalId}
              />
            </Box>
          )}
        </React.Fragment>
      )}

      {canCreateEnrollments && (
        <EnrollmentCreation
          open={enrollmentCreationDialogOpen}
          orgId={orgId || ''}
          studyId={studyId || ''}
          siteId={siteId || ''}
          internalIdPrefix={site.internalIdPrefix}
          onCreation={onEnrollmentCreation}
          onClose={closeEnrollmentCreationDialog}
        />
      )}
    </React.Fragment>
  );
}

export default withRouter(SiteView);
