export default class Device {
  id!: string;
  userId!: number;
  timestampStart!: number;
  deviceVendor!: string;
  deviceModel!: string;
  deviceSerialNumber!: string;
  firmwareVersion!: string;
  metadata?: any;
}

export const DeviceModelE4 = 'E4';
export const DeviceModelEMBP = 'EMBP';
