import React from 'react';
import ModalInfo, { ModalInfoBaseProps } from './ModalInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';

export default function ModalGenericError(props: ModalInfoBaseProps) {
  const icon = (
    <FontAwesomeIcon
      icon={faExclamationTriangle}
      style={{ width: '240px', height: '240px' }}
    />
  );

  return (
    <ModalInfo
      open={props.open}
      icon={icon}
      title={props.title}
      content={props.content}
      primaryAction={props.primaryAction}
      secondaryAction={props.secondaryAction}
    />
  );
}
