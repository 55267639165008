import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  TimerangeRealtime6h,
  TimerangeRealtime12h,
  Timerange2d,
  Timerange10d,
  Timerange30d,
  BiomarkerPrecisionMinute,
  BiomarkerPrecisionHour,
  BiomarkerPrecisionDay,
  TimerangeAuraEDRI,
  TimerangeAuraEDRITrend,
} from './Biomarker';

dayjs.extend(utc);

export default class Timerange {
  from!: number;
  to!: number;
  tz?: number;
  range!: string;
  isRealtime!: boolean;
  isAura!: boolean;
  precision?: string;

  constructor(timerangeType: string, tz: number = 0) {
    let { newFrom, newTo } = this.getTimerangeIntervals(tz, timerangeType);

    const isRealtime =
      timerangeType === TimerangeRealtime6h ||
      timerangeType === TimerangeRealtime12h;

    const isAura = [TimerangeAuraEDRI, TimerangeAuraEDRITrend].includes(
      timerangeType
    );

    const precision = this.getTimeRangePrecision(timerangeType);

    this.from = newFrom;
    this.to = newTo;
    this.tz = tz;
    this.range = timerangeType;
    this.isRealtime = isRealtime;
    this.isAura = isAura;
    this.precision = precision;
  }

  private getTimeRangePrecision(timerangeType: string) {
    return ((view: string): 'minute' | 'hour' | 'day' | undefined => {
      switch (view) {
        case TimerangeRealtime6h:
          return BiomarkerPrecisionMinute;
        case TimerangeRealtime12h:
          return BiomarkerPrecisionMinute;
        case Timerange2d:
          return BiomarkerPrecisionHour;
        case Timerange10d:
          return BiomarkerPrecisionDay;
        case Timerange30d:
          return BiomarkerPrecisionDay;
      }
    })(timerangeType);
  }

  // getTimeRangeIntervals is used to retrieve the time intervals from a given specified time range
  private getTimerangeIntervals(tz: number, timerangeType: string) {
    const todayDate = dayjs.utc().add(tz, 's').format('YYYY-MM-DD HH:mm');

    let { newFrom, newTo } = this.getIntervalInHours(-6);

    switch (timerangeType) {
      case TimerangeRealtime6h:
        return this.getIntervalInHours(-6);
      case TimerangeRealtime12h:
        return this.getIntervalInHours(-12);
      case Timerange2d:
        return this.getIntervalInDays(todayDate, tz, -1);
      case Timerange10d:
        return this.getIntervalInDays(todayDate, tz, -9);
      case Timerange30d:
        return this.getIntervalInDays(todayDate, tz, -29);
      case TimerangeAuraEDRI:
        return this.getIntervalInDays(todayDate, tz, -4);
      case TimerangeAuraEDRITrend:
        return this.getIntervalInDays(todayDate, tz, -10);
    }
    return { newFrom, newTo };
  }

  private getIntervalInDays(todayDate: string, tz: number, days: number) {
    let newFrom =
      dayjs
        .utc(todayDate)
        .startOf('d')
        .add(days, 'd')
        .subtract(tz, 's')
        .unix() * 1000;

    let newTo = dayjs.utc(todayDate).endOf('d').subtract(tz, 's').unix() * 1000;

    return { newFrom, newTo };
  }

  private getIntervalInHours(hours: number) {
    let newFrom = dayjs().add(hours, 'h').startOf('m').unix() * 1000;
    let newTo = dayjs().endOf('m').unix() * 1000;

    return { newFrom, newTo };
  }
}
