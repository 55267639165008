import React, { useState, useEffect, useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import PulseRates from '../../Model/PulseRate';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  BiomarkerPulseRateMin,
  BiomarkerPulseRateMax,
} from '../../Model/PulseRate';
import { getChartMedianLines } from '../../Model/Utils';
import UserContext from '../../Context/UserContext';
import i18next from 'i18next';
import { renderTrendPulseRateLegend } from './LegendUtils';
import {
  getChartHourLabels,
  getReachedNowDayLabels,
} from '../Utils/ChartUtils';
import SubjectChartTimezoneInfo from './SubjectChartTimezoneInfo';

dayjs.extend(utc);

export function getChartMax(pulseRateMax: number | null | undefined): number {
  if (!pulseRateMax) {
    return BiomarkerPulseRateMax;
  }
  return Math.floor(pulseRateMax / 20) * 20 + 20;
}

export function getChartMin(pulseRateMin: number | null | undefined): number {
  if (!pulseRateMin) {
    return BiomarkerPulseRateMin;
  }
  return Math.floor(pulseRateMin / 20) * 20 - 20;
}

export default function SubjectChartPulseRateHourPrecision(props: any) {
  const [pulseRatex, setPulseRates] = useState<PulseRates | undefined>();
  const [chart, setChart] = React.useState(<div>Loading</div>);
  const { config } = useContext(UserContext);

  useEffect(() => {
    setPulseRates(props.values);
  }, [props.values]);

  useEffect(() => {
    if (!pulseRatex) {
      return;
    }

    // Retrieve list of values for the given precision
    const series: Array<number | null> = pulseRatex.getPrecisionValues(
      props.timerange?.precision
    );

    // Generate labels based on the series
    const labels = getChartHourLabels(
      series,
      pulseRatex.from,
      props.timezone.offset,
      config.timeFormat
    );

    const reachedNowDayLabels = getReachedNowDayLabels(
      pulseRatex.from,
      series,
      'hour',
      props.timezone.offset
    );

    // Get the median. Set null if missing (prevents median lines and labels to show in the chart)
    const median = pulseRatex.median
      ? parseFloat(pulseRatex.median.toFixed(1))
      : null;
    const compareMedian = pulseRatex.compareMedian
      ? parseFloat(pulseRatex.compareMedian.toFixed(1))
      : null;
    const max = getChartMax(pulseRatex.max);
    const min = getChartMin(pulseRatex.min);

    const chartStepSize = 10;
    const lines = getChartMedianLines(
      median,
      compareMedian,
      props.timerange?.precision,
      chartStepSize
    );
    const options = {
      elements: {
        line: {
          tension: 0, // disables bezier curves
        },
      },
      legend: {
        display: false,
      },
      gridLines: {
        color: '#E3E3E3',
      },
      scales: {
        yAxes: [
          {
            ticks: {
              startAtZero: false,
              max: max,
              min: min,
            },
            afterFit: function (scaleInstance: any) {
              scaleInstance.width = 40; // sets the width to 40px
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              userCallback: function (item: any, index: number) {
                if (item) return item;
              },
              autoSkip: false,
            },
            gridLines: {
              display: false,
            },
          },
        ],
      },
      annotation: {
        annotations: lines,
      },
      tooltips: {
        mode: 'index',
        intersect: false,
        callbacks: {
          title: function (tooltipItem: any, _: any) {
            return dayjs
              .utc(pulseRatex.from)
              .add(tooltipItem[0]['index'], 'h')
              .add(props.timezone.offset, 's')
              .startOf(props.timerange?.precision)
              .format(`${config.dateFormat} - ${config.timeFormat}`);
          },
          label: function (tooltipItem: any, data: any) {
            var label = data.datasets[tooltipItem.datasetIndex].label;
            var value =
              data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            if (tooltipItem.datasetIndex === 0) {
              return (
                i18next.t('PULSE_RATE_MEDIAN') +
                ': ' +
                value +
                ' ' +
                i18next.t('BPM')
              );
            } else if (tooltipItem.datasetIndex === 1) {
              return null;
            } else if (tooltipItem.datasetIndex === 2) {
              return null;
            } else if (tooltipItem.datasetIndex === 3) {
              if (value === null) {
                return null;
              }
              if (value[0] === min && value[1] === max) {
                return null;
              }
              return (
                i18next.t('QUARTILES') +
                ': [' +
                value[0] +
                ', ' +
                value[1] +
                '] bpm'
              );
            }
            return label + ': ' + value;
          },
        },
      },
      maintainAspectRatio: false,
    };

    let bars = [];
    let barColors = [];
    let hoverBackgroundBarColors = [];
    let yesterdaysMedianLine = [];
    let todaysMedianLine = [];

    let now = dayjs.utc().startOf('hour').add(props.timezone.offset, 's');
    let nowDayLabel = now.format('YYYY-MM-DD HH');
    let reachedNow = false;
    if (pulseRatex.aggregations != null) {
      for (let i = 0; i < pulseRatex.aggregations!.quartile1.length; i += 1) {
        if (labels == null || labels[i] == null) {
          continue;
        }

        let q1 = pulseRatex.aggregations!.quartile1[i];
        let q3 = pulseRatex.aggregations!.quartile3[i];
        if (q1 != null && q3 != null) {
          bars.push([
            pulseRatex.aggregations!.quartile1[i],
            pulseRatex.aggregations!.quartile3[i],
          ]);
          barColors.push('#CF2026');
          hoverBackgroundBarColors.push('#CF2026');
        } else {
          if (reachedNow) {
            bars.push(null);
          } else {
            bars.push([min, max]);
          }
          barColors.push('#D6D6D6');
          hoverBackgroundBarColors.push('#D6D6D6');
        }
        yesterdaysMedianLine.push(compareMedian);
        todaysMedianLine.push(median);

        if (!reachedNow) {
          reachedNow = reachedNowDayLabels[i] === nowDayLabel;
        }
      }
    }

    const data = {
      labels: labels,
      datasets: [
        {
          type: 'line',
          label: 'By hour values',
          data: pulseRatex.aggregations?.median,
          fill: true,
          borderWidth: 1,
          borderColor: '#6F0D11',
          pointBorderColor: '#6F0D11',
          pointBackgroundColor: '#6F0D11',
          backgroundColor: 'transparent',
          pointSize: '10px',
        },
        {
          type: 'line',
          label: "Today's median",
          data: todaysMedianLine,
          borderDash: [4, 4],
          backgroundColor: 'rgba(0,0,0,0)',
          borderColor: 'rgba(64,64,64,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(0,0,0,0)',
          hoverBorderColor: 'rgba(64,64,64,1)',
          pointRadius: 4,
          pointBorderColor: 'transparent',
          pointBackgroundColor: 'transparent',
          pointHoverRadius: 4,
          pointHoverBackgroundColor: 'rgba(64,64,64,1)',
        },
        {
          type: 'line',
          label: "Yesterday's median",
          data: yesterdaysMedianLine,
          borderDash: [4, 8],
          backgroundColor: 'rgba(0,0,0,0)',
          borderColor: 'rgba(64,64,64,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(0,0,0,0)',
          hoverBorderColor: 'rgba(64,64,64,1)',
          pointRadius: 4,
          pointBorderColor: 'transparent',
          pointBackgroundColor: 'transparent',
          pointHoverRadius: 4,
          pointHoverBackgroundColor: 'rgba(64,64,64,1)',
        },
        {
          type: 'bar',
          label: 'Quartiles',
          data: bars,
          backgroundColor: barColors,
          hoverBackgroundColor: hoverBackgroundBarColors,
        },
      ],
    };

    setChart(<Bar data={data} options={options} height={320} />);
  }, [pulseRatex, props.timerange, config, props.timezone]);

  return (
    <span>
      <div>
        {renderTrendPulseRateLegend(props.timerange.range)}
        {chart}
      </div>
      <SubjectChartTimezoneInfo offsetInSeconds={props.timezone.offset} />
    </span>
  );
}
