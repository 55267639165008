import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as Icon } from './Compliance.svg';

export default function ComplianceIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <Icon title="icon-compliance" />
    </SvgIcon>
  );
}
