import Api from '../../Http/Api';

class DataPayload {
  Key!: string;
  Secret!: string;
  Region!: string;
}

export class ApiResponseGetCredentials {
  data!: DataPayload;
}

export function GetCredentials(): Promise<ApiResponseGetCredentials> {
  const url = `${process.env.REACT_APP_API_URL}/data-service/experimentals/data/auth`;
  return Api.get(url);
}
