import React, { FunctionComponent } from 'react';
import { Button, ButtonProps, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/pro-light-svg-icons';
import { Trans } from 'react-i18next';

export type EnrollmentCreationButtonProps = ButtonProps;

const useStyles = makeStyles(() => ({
  button: {
    textTransform: 'capitalize',
  },
  buttonAlign: {
    display: 'inline-block',
  },
}));

const EnrollmentCreationButton: FunctionComponent<EnrollmentCreationButtonProps> = ({
  className,
  ...props
}) => {
  const classes = useStyles();

  return (
    <div className={classes.buttonAlign}>
      <Button
        color="primary"
        className={`${classes.button} ${className || ''}`}
        {...props}
      >
        <FontAwesomeIcon icon={faUserPlus} />
        <span>&nbsp;</span>
        <Trans i18nKey="ENROLLMENT_CREATION_TITLE" />
      </Button>
    </div>
  );
};

export default EnrollmentCreationButton;
