import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockAlt } from '@fortawesome/pro-light-svg-icons';
import { Trans } from 'react-i18next';
import i18next from 'i18next';

export interface EnrollmentCreationStepLoginProps {
  email?: string;
  onClose?(): void;
  onChange?(password: string): void;
}

const useStyles = makeStyles((theme) => ({
  form: {
    background: '#fff',
  },
  header: {
    background: '#F5F8F9',
    borderRadius: '4px',
    textAlign: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  headerIcon: {
    width: '140px !important',
    height: '140px !important',
    color: '#00778b',
  },
}));

export default function EnrollmentCreationStepLogin({
  email = '',
  onClose,
  onChange,
}: EnrollmentCreationStepLoginProps) {
  const classes = useStyles();
  const [password, setPassword] = useState('');
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);

  const submit = (e: any) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    if (!password || password.length < 8) {
      setHasError(true);
      return;
    }
    login(email, password);
  };

  const close = () => {
    onClose?.();
  };

  const login = async (email: string, pwd: string) => {
    setLoading(true);

    try {
      const url = `${process.env.REACT_APP_API_URL}/v3/login`;
      const loginBody = {
        email: email,
        password: pwd,
      };
      const res = await fetch(url, {
        method: 'post',
        body: JSON.stringify(loginBody),
      });

      if (res.status !== 200) {
        throw new Error();
      }

      onChange?.(pwd);
    } catch {
      setHasError(true);
      setLoading(false);
    }
  };

  return (
    <form onSubmit={submit} className={classes.form}>
      <Box className={classes.header}>
        <FontAwesomeIcon icon={faLockAlt} className={classes.headerIcon} />
      </Box>
      <Box pl={12} pr={12} pt={4} pb={4}>
        <h1>
          <Trans i18nKey="ENROLLMENT_CREATION_LOGIN_TITLE" />
        </h1>
        <Box>
          <Trans i18nKey="ENROLLMENT_CREATION_LOGIN_DESCRIPTION" />
        </Box>
        <Box mt={8}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={i18next.t('EMAIL_ADDRESS')}
            name="email"
            autoComplete="email"
            value={email}
            disabled
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={i18next.t('PASSWORD')}
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoFocus
            error={hasError}
          />
        </Box>
      </Box>
      <Box padding={4} textAlign="right">
        <Button
          type="button"
          color="default"
          onClick={close}
          id="btn-credentials-cancel"
        >
          <Trans i18nKey="CANCEL" />
        </Button>
        <span>&nbsp;</span>
        <Button
          id="btn-credentials-confirm"
          disabled={loading}
          type="submit"
          variant="contained"
          color="primary"
          onClick={submit}
        >
          {loading && (
            <CircularProgress
              size={24}
              color="secondary"
              thickness={6}
              variant="indeterminate"
            />
          )}
          {!loading && <Trans i18nKey="UNLOCK" />}
        </Button>
      </Box>
    </form>
  );
}
