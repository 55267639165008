import {
  faArrowLeft,
  faFile,
  faFolder,
  faQuestion,
} from '@fortawesome/pro-light-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export enum ItemType {
  TYPE_DIR,
  TYPE_FILE,
  TYPE_RETURN,
}

export class BrowseItem {
  fullPath: string;
  type?: ItemType;

  constructor(fullPath: string, type: ItemType) {
    this.fullPath = fullPath;
    this.type = type;
  }

  getIcon(): IconDefinition {
    switch (this.type) {
      case ItemType.TYPE_FILE:
        return faFile;
      case ItemType.TYPE_DIR:
        return faFolder;
      case ItemType.TYPE_RETURN:
        return faArrowLeft;
      default:
        return faQuestion;
    }
  }

  isDir(): boolean {
    return this.type === ItemType.TYPE_DIR;
  }

  getPrettyTitle(): string {
    let splitPath = this.fullPath.split('/');

    if (this.isDir()) {
      return splitPath[splitPath.length - 2];
    } else {
      return splitPath[splitPath.length - 1];
    }
  }
}
