import Site from '../../Model/Site';
import Subject from '../../Model/Subject';

export function updateTitleForSite(site: Site) {
  if (site != null && Boolean(site.name)) {
    document.title = `Empatica Care | Site | ${site.name}`;
  } else {
    document.title = `Empatica Care | Site `;
  }
}

export function updateTitleForSubject(subject: Subject) {
  if (subject != null && Boolean(subject.internalId)) {
    document.title = `Empatica Care | User | ${subject.internalId}`;
  } else {
    document.title = `Empatica Care | User `;
  }
}

export function setTitleLoading() {
  document.title = 'Empatica Care | Loading...';
}

export function setCustomTitle(title: string) {
  document.title = title;
}
