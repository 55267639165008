import Api from '../../../Http/Api';
import App from '../../../Model/App';

export class ApiResponseUserApps {
  data!: App[];
}

export class ApiResponseUserApp {
  data!: App;
}

export function GetUserApps(
  userId: number,
  from: number,
  to: number
): Promise<ApiResponseUserApps> {
  const url = `${process.env.REACT_APP_API_URL}/usage-service/users/${userId}/apps?from=${from}&to=${to}`;
  return Api.get(url);
}

export function GetUserLatestApp(userId: number): Promise<ApiResponseUserApp> {
  const url = `${process.env.REACT_APP_API_URL}/usage-service/users/${userId}/apps/latest`;
  return Api.get(url);
}
