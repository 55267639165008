import React, { useContext } from 'react';
import WardSelector from './WardSelector';
import FullscreenLoading from '../Utils/FullscreenLoading';
import SiteContext from '../../Context/SiteContext';

export default function HomeView() {
  const { sites } = useContext(SiteContext);

  return (
    <React.Fragment>
      <FullscreenLoading
        content={<WardSelector sites={sites} loading={false} />}
      />
    </React.Fragment>
  );
}
