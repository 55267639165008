import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography, makeStyles } from '@material-ui/core';
import { Trans } from 'react-i18next';
import Compliances from '../../Model/Compliance';
import ComplianceIcon from '../../Icons/ComplianceIcon';
import SubjectChartComplianceMinutePrecision from './SubjectChartComplianceMinutePrecision';
import SubjectChartComplianceHourPrecision from './SubjectChartComplianceHourPrecision';
import SubjectChartComplianceDayPrecision from './SubjectChartComplianceDayPrecision';
import Timerange from '../../Model/Timerange';
import Timezone from '../../Model/Timezone';
import { ViewTypes } from '../SubjectView/SubjectView';

const useStyles = makeStyles((theme) => ({
  chartGrid: {
    display: 'grid',
    gridTemplateColumns: '12rem 1fr',
    gridTemplateRows: '1fr',
    gridColumnGap: 0,
    gridRowGap: 0,
    width: '100%',
  },
  chartLeft: {
    height: 'auto',
    color: '#485164',
    fontSize: '1rem',
  },
  chartRight: {
    height: '28rem',
  },
}));

export class SubjectViewComplianceProps {
  userId?: number;
  timerange?: Timerange;
  timezone?: Timezone;
  data?: any;
}

export default function SubjectViewCompliance(
  props: SubjectViewComplianceProps
) {
  const classes = useStyles();
  const [compliances, setCompliances] = useState<Compliances>(
    new Compliances(undefined)
  );

  useEffect(() => {
    if (!props.userId || !props.timerange || !props.data) {
      return;
    }
    const comp = new Compliances(
      props?.data?.from,
      props?.data?.to,
      props?.timezone?.offset,
      props?.data?.deviceCompliance.values,
      props?.data?.deviceCompliance.values,
      props.timerange?.precision
    );

    setCompliances(comp);
  }, [props]);

  return (
    <React.Fragment>
      <Box p={2}>
        <Box className={classes.chartGrid}>
          <Box className={classes.chartLeft}>
            <Typography variant="h5" component="h5">
              <Trans i18nKey="COMPLIANCE" />{' '}
            </Typography>
            <Grid container direction="row" spacing={1}>
              <ComplianceIcon style={{ height: '1.4rem', marginTop: '4px' }} />
              <Grid item xs={12} sm container direction="column">
                <Grid container direction="row" spacing={1}>
                  {props.timerange?.range === ViewTypes.view6h && (
                    <Grid
                      item
                      className="numberCharts"
                      style={{ color: 'rgba(64,64,64,1)' }}
                    >
                      {Number(compliances.onWristTimeLastSixHours[0]) < 1 && (
                        <span style={{ color: '#2E384D' }}>
                          <span style={{ color: 'white' }}>
                            {compliances.onWristTimeLastSixHours[0]}
                          </span>
                          <span
                            style={{
                              fontSize: '1.2rem',
                              marginLeft: '3px',
                              fontWeight: 300,
                              color: 'white',
                            }}
                          >
                            h{' '}
                          </span>
                          {compliances.onWristTimeLastSixHours[1]}
                          <span
                            style={{
                              fontSize: '1.2rem',
                              marginLeft: '3px',
                              fontWeight: 300,
                            }}
                          >
                            min{' '}
                          </span>
                        </span>
                      )}
                      {Number(compliances.onWristTimeLastSixHours[0]) >= 1 && (
                        <span style={{ color: '#2E384D' }}>
                          <span style={{ color: '#2E384D' }}>
                            {compliances.onWristTimeLastSixHours[0]}
                          </span>
                          <span
                            style={{
                              color: '#2E384D',
                              fontSize: '1.2rem',
                              marginLeft: '3px',
                              fontWeight: 300,
                            }}
                          >
                            h{' '}
                          </span>
                          {compliances.onWristTimeLastSixHours[1]}
                          <span
                            style={{
                              fontSize: '1.2rem',
                              marginLeft: '3px',
                              fontWeight: 300,
                            }}
                          >
                            min{' '}
                          </span>
                        </span>
                      )}
                    </Grid>
                  )}
                  {props.timerange?.range === ViewTypes.view12h && (
                    <Grid
                      item
                      className="numberCharts"
                      style={{ color: 'rgba(64,64,64,1)' }}
                    >
                      {Number(compliances.onWristTimeLastTwelveHours[0]) <
                        1 && (
                        <span style={{ color: '#2E384D' }}>
                          <span style={{ color: 'white' }}>
                            {compliances.onWristTimeLastTwelveHours[0]}
                          </span>
                          <span
                            style={{
                              fontSize: '1.2rem',
                              marginLeft: '3px',
                              fontWeight: 300,
                              color: 'white',
                            }}
                          >
                            h{' '}
                          </span>
                          {compliances.onWristTimeLastTwelveHours[1]}
                          <span
                            style={{
                              fontSize: '1.2rem',
                              marginLeft: '3px',
                              fontWeight: 300,
                            }}
                          >
                            min{' '}
                          </span>
                        </span>
                      )}
                      {Number(compliances.onWristTimeLastTwelveHours[0]) >=
                        1 && (
                        <span style={{ color: '#2E384D' }}>
                          <span style={{ color: '#2E384D' }}>
                            {compliances.onWristTimeLastTwelveHours[0]}
                          </span>
                          <span
                            style={{
                              color: '#2E384D',
                              fontSize: '1.2rem',
                              marginLeft: '3px',
                              fontWeight: 300,
                            }}
                          >
                            h{' '}
                          </span>
                          {compliances.onWristTimeLastTwelveHours[1]}
                          <span
                            style={{
                              fontSize: '1.2rem',
                              marginLeft: '3px',
                              fontWeight: 300,
                            }}
                          >
                            min{' '}
                          </span>
                        </span>
                      )}
                    </Grid>
                  )}

                  {props.timerange?.range === ViewTypes.view48h && (
                    <Grid
                      item
                      className="numberCharts"
                      style={{ color: 'rgba(64,64,64,1)' }}
                    >
                      {Number(compliances.onWristTimeToday[0]) < 1 && (
                        <span style={{ color: '' }}>
                          <Grid container direction="row" spacing={1}>
                            <Grid
                              item
                              className="numberCharts"
                              style={{ color: '#2E384D' }}
                            >
                              {compliances.onWristTimeToday[0]}
                              <span
                                style={{
                                  fontSize: '1.2rem',
                                  marginLeft: '3px',
                                  fontWeight: 300,
                                }}
                              >
                                h{' '}
                              </span>
                              {compliances.onWristTimeToday[1]}
                            </Grid>
                            <Grid item>
                              <div
                                className="textMicro"
                                style={{ color: '#2E384D', float: 'right' }}
                              >
                                Today
                              </div>
                              <div
                                className="textMicro"
                                style={{ color: '#2E384D' }}
                              >
                                <span
                                  style={{
                                    fontSize: '1.2rem',
                                    fontWeight: 300,
                                  }}
                                >
                                  min
                                </span>
                              </div>
                            </Grid>
                          </Grid>
                        </span>
                      )}
                      {Number(compliances.onWristTimeToday[0]) >= 1 && (
                        <span style={{ color: '' }}>
                          <Grid container direction="row" spacing={1}>
                            <Grid
                              item
                              className="numberCharts"
                              style={{ color: '#2E384D' }}
                            >
                              {compliances.onWristTimeToday[0]}
                              <span
                                style={{
                                  fontSize: '1.2rem',
                                  marginLeft: '3px',
                                  fontWeight: 300,
                                }}
                              >
                                h{' '}
                              </span>
                              {compliances.onWristTimeToday[1]}
                            </Grid>
                            <Grid item>
                              <div
                                className="textMicro"
                                style={{ color: '#2E384D', float: 'right' }}
                              >
                                Today
                              </div>
                              <div
                                className="textMicro"
                                style={{ color: '#2E384D' }}
                              >
                                <span
                                  style={{
                                    fontSize: '1.2rem',
                                    fontWeight: 300,
                                  }}
                                >
                                  min
                                </span>
                              </div>
                            </Grid>
                          </Grid>
                        </span>
                      )}

                      {Number(compliances.onWristTimeYesterday[0]) < 1 && (
                        <span style={{ color: '#969696' }}>
                          <Grid container direction="row" spacing={1}>
                            <Grid
                              item
                              className="numberCharts"
                              style={{ color: 'rgba(150,150,150,1)' }}
                            >
                              {compliances.onWristTimeYesterday[0]}
                              <span
                                style={{
                                  fontSize: '1.2rem',
                                  marginLeft: '3px',
                                  fontWeight: 300,
                                }}
                              >
                                h{' '}
                              </span>
                              {compliances.onWristTimeYesterday[1]}
                            </Grid>
                            <Grid item>
                              <div
                                className="textMicro"
                                style={{
                                  color: 'rgba(150,150,150,1)',
                                  float: 'right',
                                }}
                              >
                                <Trans i18nKey="YESTERDAY_SHORT" />
                              </div>
                              <div
                                className="textMicro"
                                style={{ color: 'rgba(150,150,150,1)' }}
                              >
                                <span
                                  style={{
                                    fontSize: '1.2rem',
                                    fontWeight: 300,
                                  }}
                                >
                                  min
                                </span>
                              </div>
                            </Grid>
                          </Grid>
                        </span>
                      )}
                      {Number(compliances.onWristTimeYesterday[0]) >= 1 && (
                        <span style={{ color: '#969696' }}>
                          <Grid container direction="row" spacing={1}>
                            <Grid
                              item
                              className="numberCharts"
                              style={{ color: 'rgba(150,150,150,1)' }}
                            >
                              {compliances.onWristTimeYesterday[0]}
                              <span
                                style={{
                                  fontSize: '1.2rem',
                                  marginLeft: '3px',
                                  fontWeight: 300,
                                }}
                              >
                                h{' '}
                              </span>
                              {compliances.onWristTimeYesterday[1]}
                            </Grid>
                            <Grid item>
                              <div
                                className="textMicro"
                                style={{
                                  color: 'rgba(150,150,150,1)',
                                  float: 'right',
                                }}
                              >
                                Yesterday
                              </div>
                              <div
                                className="textMicro"
                                style={{ color: 'rgba(150,150,150,1)' }}
                              >
                                <span
                                  style={{
                                    fontSize: '1.2rem',
                                    fontWeight: 300,
                                  }}
                                >
                                  min
                                </span>
                              </div>
                            </Grid>
                          </Grid>
                        </span>
                      )}
                    </Grid>
                  )}
                  {props.timerange?.range === ViewTypes.view10d && (
                    <Grid
                      item
                      className="numberCharts"
                      style={{ color: 'rgba(64,64,64,1)' }}
                    >
                      {Number(compliances.onWristTimeToday[0]) < 1 && (
                        <span style={{ color: '' }}>
                          <Grid container direction="row" spacing={1}>
                            <Grid
                              item
                              className="numberCharts"
                              style={{ color: '#2E384D' }}
                            >
                              {compliances.onWristTimeToday[0]}
                              <span
                                style={{
                                  fontSize: '1.2rem',
                                  marginLeft: '3px',
                                  fontWeight: 300,
                                }}
                              >
                                h{' '}
                              </span>
                              {compliances.onWristTimeToday[1]}
                            </Grid>
                            <Grid item>
                              <div
                                className="textMicro"
                                style={{ color: '#2E384D', float: 'right' }}
                              >
                                Today
                              </div>
                              <div
                                className="textMicro"
                                style={{ color: '#2E384D' }}
                              >
                                <span
                                  style={{
                                    fontSize: '1.2rem',
                                    fontWeight: 300,
                                  }}
                                >
                                  min
                                </span>
                              </div>
                            </Grid>
                          </Grid>
                        </span>
                      )}
                      {Number(compliances.onWristTimeToday[0]) >= 1 && (
                        <span style={{ color: '' }}>
                          <Grid container direction="row" spacing={1}>
                            <Grid
                              item
                              className="numberCharts"
                              style={{ color: '#2E384D' }}
                            >
                              {compliances.onWristTimeToday[0]}
                              <span
                                style={{
                                  fontSize: '1.2rem',
                                  marginLeft: '3px',
                                  fontWeight: 300,
                                }}
                              >
                                h{' '}
                              </span>
                              {compliances.onWristTimeToday[1]}
                            </Grid>
                            <Grid item>
                              <div
                                className="textMicro"
                                style={{ color: '#2E384D', float: 'right' }}
                              >
                                Today
                              </div>
                              <div
                                className="textMicro"
                                style={{ color: '#2E384D' }}
                              >
                                <span
                                  style={{
                                    fontSize: '1.2rem',
                                    fontWeight: 300,
                                  }}
                                >
                                  min
                                </span>
                              </div>
                            </Grid>
                          </Grid>
                        </span>
                      )}

                      {Number(compliances.onWristTimeAverageLast10days[0]) <
                        1 && (
                        <span style={{ color: '#969696' }}>
                          <Grid container direction="row" spacing={1}>
                            <Grid
                              item
                              className="numberCharts"
                              style={{ color: 'rgba(150,150,150,1)' }}
                            >
                              {compliances.onWristTimeAverageLast10days[0]}
                              <span
                                style={{
                                  fontSize: '1.2rem',
                                  marginLeft: '3px',
                                  fontWeight: 300,
                                }}
                              >
                                h{' '}
                              </span>
                              {compliances.onWristTimeAverageLast10days[1]}
                            </Grid>
                            <Grid item>
                              <div
                                className="textMicro"
                                style={{
                                  color: 'rgba(150,150,150,1)',
                                  float: 'right',
                                }}
                              >
                                10 days
                              </div>
                              <div
                                className="textMicro"
                                style={{ color: 'rgba(150,150,150,1)' }}
                              >
                                <span
                                  style={{
                                    fontSize: '1.2rem',
                                    fontWeight: 300,
                                  }}
                                >
                                  min
                                </span>
                              </div>
                            </Grid>
                          </Grid>
                        </span>
                      )}
                      {Number(compliances.onWristTimeAverageLast10days[0]) >=
                        1 && (
                        <span style={{ color: '#969696' }}>
                          <Grid container direction="row" spacing={1}>
                            <Grid
                              item
                              className="numberCharts"
                              style={{ color: 'rgba(150,150,150,1)' }}
                            >
                              {compliances.onWristTimeAverageLast10days[0]}
                              <span
                                style={{
                                  fontSize: '1.2rem',
                                  marginLeft: '3px',
                                  fontWeight: 300,
                                }}
                              >
                                h{' '}
                              </span>
                              {compliances.onWristTimeAverageLast10days[1]}
                            </Grid>
                            <Grid item>
                              <div
                                className="textMicro"
                                style={{
                                  color: 'rgba(150,150,150,1)',
                                  float: 'right',
                                }}
                              >
                                10 days
                              </div>
                              <div
                                className="textMicro"
                                style={{ color: 'rgba(150,150,150,1)' }}
                              >
                                <span
                                  style={{
                                    fontSize: '1.2rem',
                                    fontWeight: 300,
                                  }}
                                >
                                  min
                                </span>
                              </div>
                            </Grid>
                          </Grid>
                        </span>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {props.timerange?.range === ViewTypes.view6h && (
                <Grid container direction="row">
                  <div
                    style={{
                      fontSize: '14px',
                      color: 'grey',
                      marginTop: '10px',
                    }}
                  >
                    <Trans i18nKey="TIME_RANGE_REAL_TIME_6H_COMPLIANCE" />
                  </div>
                </Grid>
              )}
              {props.timerange?.range === ViewTypes.view12h && (
                <Grid container direction="row">
                  <div
                    style={{
                      fontSize: '14px',
                      color: 'grey',
                      marginTop: '10px',
                    }}
                  >
                    <Trans i18nKey="TIME_RANGE_REAL_TIME_12H_COMPLIANCE" />
                  </div>
                </Grid>
              )}
            </Grid>
          </Box>
          <Box>
            {props.timerange?.isRealtime === true && (
              <SubjectChartComplianceMinutePrecision
                values={compliances}
                timerange={props.timerange}
                timezone={props.timezone}
              />
            )}
            {props.timerange?.range === ViewTypes.view48h && (
              <SubjectChartComplianceHourPrecision
                values={compliances}
                timerange={props.timerange}
                timezone={props.timezone}
              />
            )}
            {props.timerange?.range === ViewTypes.view10d && (
              <SubjectChartComplianceDayPrecision
                values={compliances}
                timerange={props.timerange}
                timezone={props.timezone}
              />
            )}
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}
