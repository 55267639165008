import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  legendText: {
    marginLeft: '3px',
    fontSize: '0.6rem',
    verticalAlign: 'middle',
    color: '#878787',
    cursor: 'default',
  },
  legendIcon: {
    height: 13,
    verticalAlign: 'middle',
    marginLeft: 8,
  },
}));

export class LegendProps {
  icon?: string;
  text?: string;

  constructor(icon: string, text: string) {
    this.icon = icon;
    this.text = text;
  }
}

export default function Legend(props: LegendProps) {
  const classes = useStyles();

  return (
    <div>
      <img src={props.icon} alt={props.text} className={classes.legendIcon} />
      <span className={classes.legendText}>{props.text}</span>
    </div>
  );
}
