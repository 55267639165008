export default class Organization {
  id!: number;
  internalId!: string;
  name!: string;

  constructor(o?: any) {
    if (!o) {
      this.id = 0;
      this.internalId = '-';
      this.name = '-';
      return;
    }

    this.id = o.id || 0;
    this.internalId = o.internalId || '-';
    this.name = o.name || '-';
  }
}
