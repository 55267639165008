import React from 'react';
import ModalBase, { ModalBasePropAction } from './ModalBase';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    height: 400,
    background: '#147B8F',
    color: '#FFFFFF',
    borderRadius: '4px 4px 0px 0px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      flex: 'auto',
    },
  },
  content: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '& > h1': {
      color: '#404040',
    },
    '& > div': {
      color: '#969696',
      fontSize: '16px',
    },
  },
}));

export class ModalInfoBaseProps {
  open!: boolean;
  title!: string;
  content!: string;
  primaryAction?: ModalBasePropAction;
  secondaryAction?: ModalBasePropAction;
}

class ModalInfoProps extends ModalInfoBaseProps {
  icon!: any;
}

export default function ModalInfo(props: ModalInfoProps) {
  const classes = useStyles();

  const component = (
    <Box>
      <Box className={classes.header}>{props.icon}</Box>
      <Box className={classes.content}>
        <h1>{props.title}</h1>
        <div>{props.content}</div>
      </Box>
    </Box>
  );

  return (
    <ModalBase
      open={props.open}
      component={component}
      primaryAction={props.primaryAction}
      secondaryAction={props.secondaryAction}
    />
  );
}
