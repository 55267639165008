import React, { useState, useEffect, useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Temperatures, {
  BiomarkerTemperatureMin,
  BiomarkerTemperatureMax,
} from '../../Model/Temperature';
import i18next from 'i18next';
import { getChartMedianLines } from '../../Model/Utils';
import UserContext from '../../Context/UserContext';
import { renderTrendTemperatureLegend } from './LegendUtils';
import {
  getChartHourLabels,
  getReachedNowDayLabels,
} from '../Utils/ChartUtils';
import SubjectChartTimezoneInfo from './SubjectChartTimezoneInfo';

dayjs.extend(utc);

export function getChartMax(temperatureMax: number | null | undefined): number {
  if (!temperatureMax) {
    return BiomarkerTemperatureMax;
  }
  return Math.round(temperatureMax + 0.5);
}

export function getChartMin(temperatureMin: number | null | undefined): number {
  if (!temperatureMin) {
    return BiomarkerTemperatureMin;
  }
  return Math.round(temperatureMin - 0.5);
}

const Chart = require('react-chartjs-2').Chart;

export default function SubjectChartTemperatureHourPrecision(props: any) {
  const [temperatures, setTemperatures] = useState<Temperatures | undefined>();
  const [chart, setChart] = React.useState(<div>Loading</div>);
  const { config } = useContext(UserContext);

  useEffect(() => {
    setTemperatures(props.temperatures);
  }, [props.temperatures]);

  useEffect(() => {
    if (!temperatures) {
      return;
    }
    const temperatureUnit = config.measurementUnits.temperatureSymbol;

    // Retrieve list of values for the given precision
    const series: Array<number | null> = temperatures.getPrecisionValues(
      props.timerange?.precision
    );

    // Generate labels based on the series
    const labels = getChartHourLabels(
      series,
      temperatures.from,
      props.timezone.offset,
      config.timeFormat
    );

    const reachedNowDayLabels = getReachedNowDayLabels(
      temperatures.from,
      series,
      'hour',
      props.timezone.offset
    );

    // Get the median. Set null if missing (prevents median lines and labels to show in the chart)
    const median = temperatures.median
      ? parseFloat(temperatures.median.toFixed(1))
      : null;
    const compareMedian = temperatures.compareMedian
      ? parseFloat(temperatures.compareMedian.toFixed(1))
      : null;
    const max = getChartMax(temperatures.max);
    const min = getChartMin(temperatures.min);

    const chartStepSize = 2;
    const lines = getChartMedianLines(
      median,
      compareMedian,
      props.timerange?.precision,
      chartStepSize
    );
    const options = {
      elements: {
        line: {
          tension: 0, // disables bezier curves
        },
      },
      gridLines: {
        color: '#E3E3E3',
      },
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              startAtZero: false,
              max: max,
              min: min,
            },
            afterFit: function (scaleInstance: any) {
              scaleInstance.width = 40; // sets the width to 40px
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              userCallback: function (item: any, index: number) {
                if (item) return item;
              },
              autoSkip: false,
            },
            gridLines: {
              display: false,
            },
          },
        ],
      },
      annotation: {
        annotations: lines,
      },
      tooltips: {
        mode: 'index',
        intersect: false,
        callbacks: {
          title: function (tooltipItem: any, _: any) {
            return dayjs
              .utc(temperatures.from)
              .add(tooltipItem[0]['index'], 'h')
              .add(props.timezone.offset, 's')
              .startOf(props.timerange?.precision)
              .format(`${config.dateFormat} - ${config.timeFormat}`);
          },
          label: function (tooltipItem: any, data: any) {
            var label = data.datasets[tooltipItem.datasetIndex].label;
            var value =
              data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            if (tooltipItem.datasetIndex === 0) {
              return (
                i18next.t('TEMPERATURE_MEDIAN') + ': ' + value + temperatureUnit
              );
            } else if (tooltipItem.datasetIndex === 1) {
              return null;
            } else if (tooltipItem.datasetIndex === 2) {
              return null;
            } else if (tooltipItem.datasetIndex === 3) {
              if (value === null) {
                return null;
              }
              if (value[0] === min && value[1] === max) {
                return null;
              }
              return (
                i18next.t('QUARTILES') +
                ': [' +
                value[0] +
                ', ' +
                value[1] +
                '] ' +
                temperatureUnit
              );
            }
            return label + ': ' + value + temperatureUnit;
          },
        },
      },
      maintainAspectRatio: false,
    };

    let bars = [];
    let barColors = [];
    let hoverBackgroundBarColors = [];
    let yesterdaysMedianLine = [];
    let todaysMedianLine = [];

    let now = dayjs.utc().startOf('hour').add(props.timezone.offset, 's');
    let nowDayLabel = now.format('YYYY-MM-DD HH');
    let reachedNow = false;
    if (temperatures.aggregations != null) {
      for (let i = 0; i < temperatures.aggregations!.quartile1.length; i += 1) {
        if (labels == null || labels[i] == null) {
          continue;
        }

        let q1 = temperatures.aggregations!.quartile1[i];
        let q3 = temperatures.aggregations!.quartile3[i];
        if (q1 != null && q3 != null) {
          bars.push([
            temperatures.aggregations!.quartile1[i],
            temperatures.aggregations!.quartile3[i],
          ]);
          barColors.push('#F6941D');
          hoverBackgroundBarColors.push('#F6941D');
        } else {
          if (reachedNow) {
            bars.push(null);
          } else {
            bars.push([min, max]);
          }
          barColors.push('#D6D6D6');
          hoverBackgroundBarColors.push('#D6D6D6');
        }
        yesterdaysMedianLine.push(compareMedian);
        todaysMedianLine.push(median);

        if (!reachedNow) {
          reachedNow = reachedNowDayLabels[i] === nowDayLabel;
        }
      }
    }

    const data = {
      labels: labels,
      datasets: [
        {
          label: 'By hour values',
          type: 'line',
          data: temperatures.aggregations?.median,
          fill: true,
          borderWidth: 1,
          borderColor: '#BE6F00',
          pointBorderColor: '#BE6F00',
          pointBackgroundColor: '#BE6F00',
          backgroundColor: 'transparent',
          pointSize: '10px',
        },
        {
          label: "Today's median",
          type: 'line',
          data: todaysMedianLine,
          borderDash: [4, 4],
          backgroundColor: 'rgba(0,0,0,0)',
          borderColor: 'rgba(64,64,64,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(0,0,0,0)',
          hoverBorderColor: 'rgba(64,64,64,1)',
          pointRadius: 4,
          pointBorderColor: 'transparent',
          pointBackgroundColor: 'transparent',
          pointHoverRadius: 4,
          pointHoverBackgroundColor: 'rgba(64,64,64,1)',
        },
        {
          label: "Yesterday's median",
          type: 'line',
          data: yesterdaysMedianLine,
          borderDash: [4, 8],
          backgroundColor: 'rgba(0,0,0,0)',
          borderColor: 'rgba(64,64,64,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(0,0,0,0)',
          hoverBorderColor: 'rgba(64,64,64,1)',
          pointRadius: 4,
          pointBorderColor: 'transparent',
          pointBackgroundColor: 'transparent',
          pointHoverRadius: 4,
          pointHoverBackgroundColor: 'rgba(64,64,64,1)',
        },
        {
          type: 'bar',
          label: 'Quartiles',
          data: bars,
          backgroundColor: barColors,
          hoverBackgroundColor: hoverBackgroundBarColors,
        },
      ],
    };

    setChart(<Bar data={data} options={options} height={320} />);

    Chart.elements.Rectangle.prototype.draw = function () {
      var ctx = this._chart.ctx;
      var vm = this._view;
      var left, right, top, bottom, signX, signY, borderSkipped;
      var borderWidth = vm.borderWidth;
      // Set Radius Here
      // If radius is large enough to cause drawing errors a max radius is imposed
      var cornerRadius = 20;

      left = vm.x - vm.width / 2;
      right = vm.x + vm.width / 2;
      top = vm.y;
      bottom = vm.base;
      signX = 1;
      signY = bottom > top ? 1 : -1;
      borderSkipped = vm.borderSkipped || 'bottom';

      // Canvas doesn't allow us to stroke inside the width so we can
      // adjust the sizes to fit if we're setting a stroke on the line
      if (borderWidth) {
        // borderWidth should be less than bar width and bar height.
        var barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
        borderWidth = borderWidth > barSize ? barSize : borderWidth;
        var halfStroke = borderWidth / 2;
        // Adjust borderWidth when bar top position is near vm.base(zero).
        var borderLeft =
          left + (borderSkipped !== 'left' ? halfStroke * signX : 0);
        var borderRight =
          right + (borderSkipped !== 'right' ? -halfStroke * signX : 0);
        var borderTop =
          top + (borderSkipped !== 'top' ? halfStroke * signY : 0);
        var borderBottom =
          bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0);
        // not become a vertical line?
        if (borderLeft !== borderRight) {
          top = borderTop;
          bottom = borderBottom;
        }
      }

      ctx.beginPath();
      ctx.fillStyle = vm.backgroundColor;
      ctx.strokeStyle = vm.borderColor;
      ctx.lineWidth = borderWidth;

      // Corner points, from bottom-left to bottom-right clockwise
      var corners = [
        [left, bottom],
        [left, top],
        [right, top],
        [right, bottom],
      ];

      // Find first (starting) corner with fallback to 'bottom'
      var borders = ['bottom', 'left', 'top', 'right'];
      var startCorner = borders.indexOf(borderSkipped, 0);
      if (startCorner === -1) {
        startCorner = 0;
      }

      function cornerAt(index: any) {
        return corners[(startCorner + index) % 4];
      }

      // Draw rectangle from 'startCorner'
      var corner = cornerAt(0);
      ctx.moveTo(corner[0], corner[1]);

      for (var i = 1; i < 4; i++) {
        corner = cornerAt(i);
        let nextCornerId = i + 1;
        if (nextCornerId === 4) {
          nextCornerId = 0;
        }

        let width = corners[2][0] - corners[1][0];
        let height = corners[0][1] - corners[1][1];
        let x = corners[1][0];
        let y = corners[1][1];

        let radius = cornerRadius;

        // Fix radius being too large
        if (radius > height / 2) {
          radius = height / 2;
        }

        if (radius > width / 2) {
          radius = width / 2;
        }

        var lastVisible = 0;
        for (
          var findLast = 0, findLastTo = this._chart.data.datasets.length;
          findLast < findLastTo;
          findLast++
        ) {
          if (!this._chart.getDatasetMeta(findLast).hidden) {
            lastVisible = findLast;
          }
        }

        // Temperature and Heart Rate
        if (this._chart.data.datasets.length === 4) {
          ctx.lineTo(x + width - radius, y);
          ctx.moveTo(x + radius, y);
          ctx.lineTo(x + width - radius, y);
          ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
          ctx.lineTo(x + width, y + height - radius);
          ctx.quadraticCurveTo(
            x + width,
            y + height,
            x + width - radius,
            y + height
          );
          ctx.lineTo(x + radius, y + height);
          ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
          ctx.lineTo(x, y + radius);
          ctx.quadraticCurveTo(x, y, x + radius, y);
        } else {
          // Compliance
          if (this._datasetIndex === 2) {
            ctx.moveTo(x + radius, y);
            ctx.lineTo(x + width - radius, y);
            ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
            ctx.lineTo(x + width, y + height);
            ctx.lineTo(x, y + height);
            ctx.lineTo(x, y + radius);
            ctx.quadraticCurveTo(x, y, x + radius, y);
          } else if (this._datasetIndex === 0) {
            radius = 0;
            ctx.moveTo(x + radius, y);
            ctx.lineTo(x + width - radius, y);
            ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
            ctx.lineTo(x + width, y + height);
            ctx.lineTo(x, y + height);
            ctx.lineTo(x, y + radius);
            ctx.quadraticCurveTo(x, y, x + radius, y);
          } else if (lastVisible === 0) {
            ctx.moveTo(x + radius, y);
            ctx.lineTo(x + width - radius, y);
            ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
            ctx.lineTo(x + width, y + height);
            ctx.lineTo(x, y + height);
            ctx.lineTo(x, y + radius);
            ctx.quadraticCurveTo(x, y, x + radius, y);
          } else {
            radius = 0;
            ctx.moveTo(x + radius, y);
            ctx.lineTo(x + width - radius, y);
            ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
            ctx.lineTo(x + width, y + height);
            ctx.lineTo(x, y + height);
            ctx.lineTo(x, y + radius);
            ctx.quadraticCurveTo(x, y, x + radius, y);
          }
        }
      }

      ctx.fill();
      if (borderWidth) {
        ctx.stroke();
      }
    };
  }, [temperatures, props.timerange, config, props.timezone]);

  return (
    <span>
      <div>
        {renderTrendTemperatureLegend(props.timerange.range)}
        {chart}
      </div>
      <SubjectChartTimezoneInfo offsetInSeconds={props.timezone.offset} />
    </span>
  );
}
