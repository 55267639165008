import Api from '../../Http/Api';
import { UserLatestBiomarkers } from '../../Model/Biomarker';

class DataPayload {
  users!: {
    [key: number]: UserLatestBiomarkers;
  };
}

export class ApiResponseLatestBiomarkers {
  data!: DataPayload;
}

export function GetSiteLatestBiomarkers(
  siteId: number
): Promise<ApiResponseLatestBiomarkers> {
  const url = `${process.env.REACT_APP_API_URL}/biomarkers-service/sites/${siteId}/biomarkers/latest`;

  // Request the data and then call the mapper function
  return new Promise((resolve, reject) => {
    Api.get(url)
      .then((apiResponse: ApiResponseLatestBiomarkers) => {
        apiResponse.data.users = mapSiteLatestBiomarkersResponse(apiResponse);
        resolve(apiResponse);
      })
      .catch(() => {
        reject();
      });
  });
}

// TODO(rb): This is a temporary function to support the migration from heart rate to pulse rate. We can remove it
// once the biomarker service is deployed with the new naming convention (pulse rate)
// This function is used to iterate over each received element from the API and properly map the values to accept
// both pulse rate and heart rate values
function mapSiteLatestBiomarkersResponse(
  apiResponse: ApiResponseLatestBiomarkers
) {
  let usersMap = apiResponse.data.users;
  Object.keys(usersMap).forEach((value: string, index: number) => {
    let userId = parseInt(value);
    usersMap[userId] = new UserLatestBiomarkers(usersMap[userId]);
  });
  return usersMap;
}
