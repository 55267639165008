import { Box, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import i18next from 'i18next';
import React, { useState } from 'react';
import { UserAdherence } from '../../Model/Adherence';
import { AdherenceSiteInfo } from '../../Model/AdherenceSiteInfo';
import { renderComplianceLegend } from '../SubjectView/LegendUtils';
import { getPrettyAverage } from '../Utils/ChartUtils';
import AdherenceReportTabs from './AdherenceReportTabs';
import AdherenceReportComplianceChart from './Chart/AdherenceReportComplianceChart';
import {
  SortBy,
  SortDirection,
  SortByAvgWornCorrectly,
  SortByUserId,
  SortDirectionAsc,
  SortDirectionDesc,
  sortList,
} from './SortUtils';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableHeader: {
    fontSize: '0.6rem',
    color: '#909095',
    verticalAlign: 'center',
  },
  adherenceHeader: {
    marginLeft: '3.2rem',
  },
  avgDailyText: {
    fontFamily: 'Inconsolata',
    fontWeight: 400,
    fontSize: '1.2rem',
    lineHeight: '1.5rem',
  },
  label: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    cursor: 'pointer',
  },
  labelIcon: {
    color: 'grey',
    fontSize: '0.9rem',
  },
  labelContent: {
    userSelect: 'none',
    textDecoration: 'none',
    fontSize: '0.6rem',
    color: '#909095',
    whiteSpace: 'pre-wrap',
  },
  labelAdherence: {
    fontSize: '0.6rem',
    color: '#909095',
    marginLeft: '2.8rem',
  },
}));

class AdherenceReportUserListProps {
  siteInfo?: AdherenceSiteInfo;
  data?: UserAdherence[];
}

export default function AdherenceReportUserList({
  data = [],
  siteInfo,
}: AdherenceReportUserListProps) {
  const classes = useStyles();
  const [items, setItems] = useState<UserAdherence[]>(data);
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirectionDesc
  );
  const [sortBy, setSortBy] = useState<SortBy>();

  function renderTableRows(users: UserAdherence[]) {
    let rowRendered = users.map((user: UserAdherence) => {
      return (
        <TableRow key={user.userId}>
          <TableCell align="left">
            <Box>
              <span>{user.userId}</span>
            </Box>
          </TableCell>

          <TableCell align="left">
            <span className={classes.avgDailyText}>
              {getPrettyAverage(user.avg_device_worn_correctly)}
            </span>
          </TableCell>

          <TableCell align="left">
            <AdherenceReportComplianceChart
              siteInfo={siteInfo}
              data={user.adherence_items}
            />
          </TableCell>
        </TableRow>
      );
    });

    return rowRendered;
  }

  function getSortingIcon(newSortBy: SortBy): JSX.Element {
    if (!sortBy || sortBy !== newSortBy) {
      return <UnfoldMoreIcon className={classes.labelIcon} />;
    }
    if (sortDirection && sortDirection === SortDirectionAsc) {
      return <KeyboardArrowUpIcon className={classes.labelIcon} />;
    }
    return <KeyboardArrowDownIcon className={classes.labelIcon} />;
  }

  function performSort(sortBy: SortBy) {
    const newDirection =
      sortDirection === SortDirectionAsc ? SortDirectionDesc : SortDirectionAsc;
    let sortedList = sortList(items, newDirection, sortBy);
    setItems(sortedList);
    setSortDirection(newDirection);
    setSortBy(sortBy);
  }

  function renderData() {
    if (items === undefined || items.length === 0) {
      return <span>No records available</span>;
    }

    return (
      <TableContainer>
        <Table
          className={classes.table}
          aria-label="simple table"
          id="table_adherence"
        >
          <TableHead>
            <TableRow>
              <TableCell align="left" className={classes.tableHeader}>
                <Link
                  id={'sort_by_user_id'}
                  className={classes.label}
                  underline={'none'}
                  onClick={() => performSort(SortByUserId)}
                >
                  <Typography component="span" className={classes.labelContent}>
                    {i18next.t('REPORT_ADHERENCE_COLUMN_USER_ID')}
                  </Typography>

                  {getSortingIcon(SortByUserId)}
                </Link>
              </TableCell>
              <TableCell align="left" className={classes.tableHeader}>
                <Link
                  id={'sort_by_avg_worn_correctly'}
                  className={classes.label}
                  underline={'none'}
                  onClick={() => performSort(SortByAvgWornCorrectly)}
                >
                  <Typography component="span" className={classes.labelContent}>
                    {i18next.t(
                      'REPORT_ADHERENCE_COLUMN_AVG_DAILY_TIME_WORN_CORRECTLY'
                    )}
                  </Typography>
                  {getSortingIcon(SortByAvgWornCorrectly)}
                </Link>
              </TableCell>
              <TableCell align="left" className={classes.tableHeader}>
                <span className={classes.labelAdherence}>
                  {i18next.t('REPORT_ADHERENCE_COLUMN_ADHERENCE')}
                </span>
                {renderComplianceLegend()}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderTableRows(items)}</TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <React.Fragment>
      <AdherenceReportTabs siteInfo={siteInfo} />
      <Box>{renderData()}</Box>
    </React.Fragment>
  );
}
