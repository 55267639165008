import Api from '../../../Http/Api';
import Subject from '../../../Model/Subject';
import dayjs from 'dayjs';

class SiteSubjectDataPayload {
  payload!: Subject;
}

export class ApiResponseSiteSubject {
  status!: string;
  statusText!: string;
  data!: SiteSubjectDataPayload;
}

class CreateSubjectPayload {
  enrollment!: {
    siteId: number;
    internalId: string;
  };
  password!: string;
}

export enum EnrollmentCreationError {
  //InternalIDAlreadyExists is returned when the internal id is already present
  InternalIDAlreadyExists = 1027,
}

class SubjectCreatedDataPayload {
  payload!: {
    enrollment: Subject;
    email: string;
  };
}
export class ApiResponseSubjectCreated {
  status!: string;
  statusText!: string;
  data!: SubjectCreatedDataPayload;
}

export function GetSubject(
  orgId: number | string,
  studyId: number | string,
  siteId: number | string,
  enrollmentId: number | string
): Promise<ApiResponseSiteSubject> {
  const url = `${process.env.REACT_APP_API_URL}/v3/organizations/${orgId}/studies/${studyId}/sites/${siteId}/enrollments/${enrollmentId}`;
  return Api.get(url);
}

export function CreateSubject(
  orgId: number | string,
  studyId: number | string,
  siteId: number | string,
  payload: CreateSubjectPayload
): Promise<ApiResponseSubjectCreated> {
  const url = `${process.env.REACT_APP_API_URL}/v3/organizations/${orgId}/studies/${studyId}/sites/${siteId}/enrollments`;
  return Api.post(url, payload);
}

export function StartEnrollment(
  orgId: number | string,
  studyId: number | string,
  siteId: number | string,
  enrollmentId: number | string,
  enrollment: Subject
): Promise<ApiResponseSiteSubject> {
  const url = `${process.env.REACT_APP_API_URL}/v3/organizations/${orgId}/studies/${studyId}/sites/${siteId}/enrollments/${enrollmentId}`;
  enrollment.startedAt = dayjs().toISOString();
  return Api.put(url, enrollment);
}
