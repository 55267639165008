import React, { useState, useEffect, useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import Compliances from '../../Model/Compliance';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { MillisecondsInDay } from '../../Model/Biomarker';
import UserContext from '../../Context/UserContext';
import i18next from 'i18next';
import { renderComplianceLegend } from './LegendUtils';
import SubjectChartTimezoneInfo from './SubjectChartTimezoneInfo';
import { getChartDayPrecisionLabel } from '../../Model/Utils';

dayjs.extend(utc);

export default function SubjectChartComplianceDayPrecision(props: any) {
  const [compliances, setCompliances] = useState<Compliances | undefined>();
  const [chart, setChart] = React.useState(<div>Loading</div>);
  const { config } = useContext(UserContext);

  useEffect(() => {
    setCompliances(props.values);
  }, [props.values]);

  useEffect(() => {
    if (!compliances) {
      return;
    }

    // Retrieve list of values for the given precision
    const series: Array<number | null> = compliances.getPrecisionValues(
      props.timerange?.precision
    );

    // Generate labels based on the series
    const labels = series.map((v: any, i: number) => {
      const label = getChartDayPrecisionLabel(
        compliances.from + i * MillisecondsInDay,
        props.timezone.offset
      );
      const controlLabel = dayjs
        .utc(compliances.from + i * MillisecondsInDay)
        .add(props.timezone.offset, 's')
        .format(config.dateFormat);

      const startOfDay = dayjs
        .utc(compliances.from + i * MillisecondsInDay)
        .add(props.timezone.offset, 's')
        .startOf('day')
        .format(config.dateFormat);

      if (controlLabel !== startOfDay) {
        return null;
      }
      return label;
    });

    const lines = [
      {
        type: 'line',
        mode: 'vertical',
        scaleID: 'x-axis-0',
        value: 10,
        borderColor: 'rgba(0,0,0,0.0)',
        borderWidth: 0,
        label: {
          backgroundColor: 'rgba(0,0,0,0.7)',
          enabled: true,
          content: i18next.t('NOW'),
          position: 'top',
          xAdjust: 20,
        },
      },
    ];

    // Chart options
    const options = {
      legend: {
        display: false,
      },
      annotation: {
        annotations: lines,
      },
      tooltips: {
        mode: 'label',
        callbacks: {
          title: function (tooltipItem: any, data: any) {
            return dayjs(
              compliances.from + tooltipItem[0]['index'] * MillisecondsInDay
            )
              .add(props.timezone.offset, 's')
              .startOf(props.timerange?.precision)
              .format('MMM DD, YYYY');
          },
          label: function (tooltipItem: any, data: any) {
            var label = data.datasets[tooltipItem.datasetIndex].label;
            var value =
              data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            return label + ': ' + Math.round(value) + 'h';
          },
        },
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            gridLines: {
              display: true,
              offsetGridLines: true,
            },
            ticks: {
              max: 24,
              min: 0,
            },
            afterFit: function (scaleInstance: any) {
              scaleInstance.width = 40; // sets the width to 40px
            },
            beginAtZero: true,
          },
        ],
        xAxes: [
          {
            stacked: true,
            gridLines: {
              display: false,
              offsetGridLines: true,
            },
            ticks: {
              userCallback: function (item: any, index: number) {
                if (item) return item;
              },
              autoSkip: true,
              beginAtZero: true,
            },
            offset: false,
          },
        ],
      },
      maintainAspectRatio: false,
    };

    // Chart data options and se
    const data = {
      labels: labels,
      legend: {
        display: false,
      },
      tooltip: {
        display: true,
      },
      datasetFill: false,
      datasets: [
        {
          label: i18next.t('WORN_CORRECTLY'),
          data: compliances.complianceDailyArrays[0],
          barPercentage: 0.85,
          barThickness: 25,
          categoryPercentage: 0.25,
          backgroundColor: '#147B8F',
        },
        {
          label: i18next.t('NOT_WORN_CORRECTLY'),
          data: compliances.complianceDailyArrays[1],
          barPercentage: 0.85,
          barThickness: 25,
          categoryPercentage: 0.25,
          backgroundColor: '#838383',
        },
        {
          label: i18next.t('NOT_CONNECTED'),
          data: compliances.complianceDailyArrays[2],
          barPercentage: 0.85,
          barThickness: 25,
          categoryPercentage: 0.25,
          backgroundColor: '#F2F2F2',
        },
      ],
    };

    setChart(<Bar data={data} options={options} height={160} />);
  }, [compliances, props.timerange, config, props.timezone]);

  return (
    <span>
      <div>
        {renderComplianceLegend()}
        <div style={{ marginTop: '1.3rem' }}>{chart}</div>
      </div>
      <SubjectChartTimezoneInfo offsetInSeconds={props.timezone.offset} />
    </span>
  );
}
