import winston, { transports } from 'winston';
import { CustomElasticTransport } from '../Transports/CustomElasticTransport';
import { SentryTransport } from '../Transports/SentryTransport';

export enum AppEnvironment {
  production = 'production',
  staging = 'staging',
  test = 'test',
  dev = 'dev',
}

// List of environments that we will be logging to ElasticSearch
const ES_LOGGING_ENVIRONMENTS = [
  AppEnvironment.production.toString(),
  AppEnvironment.staging.toString(),
  AppEnvironment.test.toString(),
];

// List of environments that we will be logging to console
const CONSOLE_LOGGING_ENVIRONMENTS = [
  AppEnvironment.test.toString(),
  AppEnvironment.dev.toString(),
];

const SENTRY_LOGGING_ENVIRONMENTS = [
  //TODO(rb): move here to be only on production environment
  AppEnvironment.production.toString(),
  AppEnvironment.staging.toString(),
];

// The WinstonLoggerFactory is responsible to initialize our Winston logger object,
// along with its Transporters (console, ElasticSearch, Sentry, etc.)
export class WinstonLoggerFactory {
  // Create our Winston logger object along with its transports following
  // a environment based policy
  public createLogger(): winston.Logger {
    const logger = winston.createLogger();

    let environment = process.env.REACT_APP_ENVIRONMENT || AppEnvironment.dev;

    if (ES_LOGGING_ENVIRONMENTS.includes(environment)) {
      logger.add(this.makeElasticLoggingTransport());
    }

    if (CONSOLE_LOGGING_ENVIRONMENTS.includes(environment)) {
      logger.add(this.makeConsoleLoggingTransport());
    }

    if (SENTRY_LOGGING_ENVIRONMENTS.includes(environment)) {
      logger.add(this.makeSentryLoggingTransport());
    }

    return logger;
  }

  private makeElasticLoggingTransport() {
    return new CustomElasticTransport();
  }

  private makeSentryLoggingTransport() {
    return new SentryTransport();
  }

  private makeConsoleLoggingTransport() {
    const consoleOpts = {
      level: 'error',
      format: winston.format.json(),
    };

    return new transports.Console(consoleOpts);
  }
}
