import Study from './Study';
import ResearchConfiguration, {
  ResearchConfigurationFeature,
  EdriResearcherDisplay,
  StudyBiomarkerTemperature,
  StudyBiomarkerHR,
  StudyDeviceEmbracePlus,
  StudyDeviceE4,
  StudyDeviceFeatureFlag,
  StudyDeviceVariantE4,
  StudyDeviceVariantEmbPlus,
} from './ResearchConfiguration';

export class EnrollmentsSummary {
  screening!: number;
  active!: number;
  completed!: number;
  started!: number;
  total!: number;
  withdrawn!: number;
}

export class SiteResponse {
  id!: number;
  internalId!: string;
  name!: string;
  study?: Study;
  enrollmentsSummary!: EnrollmentsSummary;
}

export default class Site {
  id!: number;
  internalId!: string;
  name!: string;
  studyId!: number;
  studyInternalId!: string;
  studyName!: string;
  organizationId!: number;
  organizationInternalId!: string;
  organizationName!: string;
  internalIdPrefix!: string;
  enrollmentsSummary!: any;
  researchConfiguration?: Array<ResearchConfiguration>;
  study?: Study;

  constructor(s: SiteResponse) {
    this.id = s.id || 0;
    this.internalId = s.internalId || '-';
    this.name = s.name || '-';
    this.studyId = 0;
    this.studyInternalId = '-';
    this.studyName = '-';
    this.organizationId = 0;
    this.organizationInternalId = '-';
    this.organizationName = '-';
    this.internalIdPrefix = s.internalId || '';
    this.enrollmentsSummary = s.enrollmentsSummary;
    if (s.study) {
      this.study = new Study(s.study);
      this.studyId = s.study.id || 0;
      this.studyInternalId = s.study.internalId || '-';
      this.studyName = s.study.name || '-';
      this.researchConfiguration = s.study.researchConfiguration || [];

      if (s.study.internalId && this.internalIdPrefix) {
        this.internalIdPrefix = `${s.study.internalId} - ${this.internalIdPrefix}`;
      }
      if (s.study.organization) {
        this.organizationId = s.study.organization.id || 0;
        this.organizationInternalId = s.study.organization.internalId || '-';
        this.organizationName = s.study.organization.name || '-';
      }
    }
  }

  getInternalIdPrefix(): string {
    return `${this.studyInternalId} - ${this.internalId}`;
  }

  hasFeature(feature: string): boolean {
    if (
      !this.researchConfiguration ||
      this.researchConfiguration.length === 0
    ) {
      return false;
    }
    return (
      this.researchConfiguration.filter((c: ResearchConfiguration) => {
        return (
          c.features &&
          c.features.filter((f: ResearchConfigurationFeature) => {
            return f.featureName === feature;
          }).length > 0
        );
      }).length > 0
    );
  }

  hasFeatureWithVariant(feature: string, variant: string): boolean {
    if (
      !this.researchConfiguration ||
      this.researchConfiguration.length === 0
    ) {
      return false;
    }
    return (
      this.researchConfiguration.filter((c: ResearchConfiguration) => {
        return (
          c.features &&
          c.features.filter((f: ResearchConfigurationFeature) => {
            return f.featureName === feature && f.variantName === variant;
          }).length > 0
        );
      }).length > 0
    );
  }

  hasEmbracePlusDevice(): boolean {
    return (
      this.hasFeature(StudyDeviceEmbracePlus) ||
      this.hasFeatureWithVariant(
        StudyDeviceFeatureFlag,
        StudyDeviceVariantEmbPlus
      )
    );
  }

  hasE4Device(): boolean {
    return (
      this.hasFeature(StudyDeviceE4) ||
      this.hasFeatureWithVariant(StudyDeviceFeatureFlag, StudyDeviceVariantE4)
    );
  }

  hasBiomarkerTemperatureEnabled(): boolean {
    return this.hasFeature(StudyBiomarkerTemperature);
  }

  hasBiomarkerPulseRateEnabled(): boolean {
    return this.hasFeature(StudyBiomarkerHR);
  }

  hasEdriResearcherDisplayEnabled(): boolean {
    return this.hasFeature(EdriResearcherDisplay);
  }
}
