import React, { ChangeEvent, FormEventHandler, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ERROR_API, logger } from '../../Logging/Logger';
import { ApiLogEntry, ApiRequest } from '../../Model/LogEntry';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export interface ResetPasswordFormProps {
  requestId: string;
  toggleResetPassword?: Function;
  onSuccess?: Function;
}

const RESET_PASSWORD_BASE_URL = process.env.REACT_APP_API_URL;
const RESET_PASSWORD_ENDPOINT = 'v3/updatePassword';
const RESET_PASSWORD_FULL_URL = `${RESET_PASSWORD_BASE_URL}/${RESET_PASSWORD_ENDPOINT}`;

export enum PasswordResetErrorCode {
  NOT_MATCHING = 1004,
  TOO_LONG = 1007,
  TOO_SHORT = 1028,
  NOT_VALID = 1046,
}

export const getPasswordResetError = (rawError: any): string => {
  if (!rawError) {
    return '';
  }

  switch (rawError?.response?.body?.errorCode) {
    case PasswordResetErrorCode.NOT_MATCHING:
      return i18next.t('PASSWORD_MISMATCH_ERROR_MESSAGE');
    case PasswordResetErrorCode.TOO_LONG:
      return i18next.t('PASSWORD_TOO_LONG_ERROR_MESSAGE');
    case PasswordResetErrorCode.TOO_SHORT:
      return i18next.t('PASSWORD_TOO_SHORT_ERROR_MESSAGE');
    case PasswordResetErrorCode.NOT_VALID:
      return i18next.t('PASSWORD_NOT_VALID_ERROR_MESSAGE');
    default:
      return i18next.t('SERVER_ERROR');
  }
};

export default function ResetPasswordForm(props: ResetPasswordFormProps) {
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [repeatedNewPassword, setRepeatedNewPassword] = useState('');
  const [repeatedNewPasswordError, setRepeatedNewPasswordError] = useState('');

  function handleServerError(error: any) {
    // log entry to ElasticSearch
    if (error != null && error.response != null) {
      let apiRequest = new ApiRequest(
        RESET_PASSWORD_BASE_URL ? RESET_PASSWORD_BASE_URL : '',
        'post',
        RESET_PASSWORD_ENDPOINT
      );
      let logEntry = new ApiLogEntry(error.response.status, apiRequest);
      logger.error(ERROR_API, logEntry);
    }

    setRepeatedNewPasswordError(getPasswordResetError(error));
    setLoading(false);
  }

  function cleanupForm() {
    setRepeatedNewPasswordError('');
    setLoading(true);
  }

  const handleSubmit: FormEventHandler<HTMLFormElement> = (evt) => {
    evt.preventDefault();
    cleanupForm();

    if (newPassword.length < 8) {
      setRepeatedNewPasswordError(
        i18next.t('PASSWORD_TOO_SHORT_ERROR_MESSAGE')
      );
      setLoading(false);
      return;
    }

    const resetPasswordBody = {
      password1: newPassword,
      password2: repeatedNewPassword,
    };

    fetch(`${RESET_PASSWORD_FULL_URL}/${props.requestId}`, {
      method: 'post',
      body: JSON.stringify(resetPasswordBody),
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((groupedResponse) => {
        switch (groupedResponse.status) {
          case 200:
            if (props.onSuccess) {
              props.onSuccess(groupedResponse.body);
            }
            break;
          default:
            throw Object.assign(new Error('Unprocessable response'), {
              response: groupedResponse,
            });
        }
      })
      .catch(function (err) {
        handleServerError(err);
      });
  };

  const handleNewPasswordInput = (e: ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
    setRepeatedNewPasswordError('');
  };

  const handleRepeatedNewPasswordInput = (e: ChangeEvent<HTMLInputElement>) => {
    setRepeatedNewPassword(e.target.value);
    setRepeatedNewPasswordError(
      e.target.value === newPassword
        ? ''
        : i18next.t('PASSWORD_MISMATCH_ERROR_MESSAGE')
    );
  };

  const classes = useStyles();
  return (
    <form className={classes.form} onSubmit={handleSubmit} noValidate>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="new-password"
        label={i18next.t('NEW_PASSWORD')}
        type="password"
        id="new-password"
        autoComplete="new-password"
        value={newPassword}
        error={Boolean(repeatedNewPasswordError)}
        onChange={handleNewPasswordInput}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="repeated-new-password"
        label={i18next.t('CONFIRM_NEW_PASSWORD')}
        type="password"
        id="repeated-new-password"
        autoComplete="new-password"
        value={repeatedNewPassword}
        helperText={repeatedNewPasswordError}
        error={Boolean(repeatedNewPasswordError)}
        onChange={handleRepeatedNewPasswordInput}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={loading}
      >
        {!loading && <Trans i18nKey="RESET_PASSWORD" />}
        {loading && <CircularProgress size="1.6rem" />}
      </Button>
    </form>
  );
}
