import { EffectCallback, useCallback, useEffect } from 'react';

export const useDebouncedEffect = (effect: EffectCallback, delay: number) => {
  const callback = useCallback(() => {
    effect();
  }, [effect]);

  useEffect(() => {
    const handler = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [callback, delay]);
};
