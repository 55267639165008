import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEquals } from '@fortawesome/free-solid-svg-icons';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import {
  ComplianceToleranceForAggregationMinute,
  ComplianceToleranceForAggregationHourly,
  ComplianceToleranceForAggregationDaily,
  BiomarkerPrecisionMinute,
  BiomarkersResponse,
} from './Biomarker';
import dayjs from 'dayjs';
import qr from 'qr.js';
import { ERROR_EXCEPTION, logger } from '../Logging/Logger';
import { ExceptionLogEntry } from './LogEntry';

export function trendToElement(
  trend: number | undefined
): string | JSX.Element {
  if (trend === undefined) {
    return '';
  }

  if (trend > 0) {
    return <FontAwesomeIcon className="color-primary" icon={faCaretUp} />;
  }
  if (trend < 0) {
    return <FontAwesomeIcon className="color-primary" icon={faCaretDown} />;
  }
  return <FontAwesomeIcon className="color-primary" icon={faEquals} />;
}

// getChartHoles given a compliance and a precision, returns the
// list of holes to be drawn in the chart
export function getChartHoles(
  compliance: Array<number | null>,
  complianceHourly: Array<number>,
  complianceDaily: Array<number>,
  precision: string
): Array<any> {
  const holes = [];
  let tolerance = ComplianceToleranceForAggregationMinute;
  let complianceData: Array<any> = compliance || [];
  switch (precision) {
    case 'minute':
      tolerance = ComplianceToleranceForAggregationMinute;
      break;
    case 'hour':
      tolerance = ComplianceToleranceForAggregationHourly;
      complianceData = complianceHourly;
      break;
    case 'day':
      tolerance = ComplianceToleranceForAggregationDaily;
      complianceData = complianceDaily;
      break;
  }

  let holeIsOpen = false;
  let currentHoleStarts = 0;
  const totalCompliancePoints = complianceData.length;
  for (let i = 0; i < totalCompliancePoints; i++) {
    if (complianceData[i] !== null && complianceData[i] > tolerance) {
      if (holeIsOpen) {
        holes.push({
          type: 'box',
          drawTime: 'beforeDatasetsDraw',
          id: `hole-${currentHoleStarts}`,
          xScaleID: 'x-axis-0',
          yScaleID: 'y-axis-0',
          xMin: currentHoleStarts,
          xMax: i,
          backgroundColor: 'rgba(243,243,243,0.8)',
        });
      }
      holeIsOpen = false;
      continue;
    } else {
      if (!holeIsOpen) {
        currentHoleStarts = i === 0 ? 0 : i - 1;
        holeIsOpen = true;
      }
    }
  }
  if (holeIsOpen) {
    holes.push({
      type: 'box',
      drawTime: 'beforeDatasetsDraw',
      id: `hole-${currentHoleStarts}`,
      xScaleID: 'x-axis-0',
      yScaleID: 'y-axis-0',
      xMin: currentHoleStarts,
      xMax: totalCompliancePoints,
      backgroundColor: 'rgba(243,243,243,0.8)',
    });
  }
  return holes;
}

export function getChartMedianLines(
  median?: number | null,
  compareMedian?: number | null,
  precision?: string,
  stepSize?: number
): Array<any> {
  if (!median) {
    return [];
  }
  const hasCompareValues = Boolean(
    precision && compareMedian && precision !== BiomarkerPrecisionMinute
  );
  let medianLabelOffset = 0;
  let compareMedianLabelOffset = 0;
  if (hasCompareValues && compareMedian && stepSize) {
    const labelOffset = Math.abs(median - compareMedian) < stepSize ? 12 : 0;
    medianLabelOffset = median < compareMedian ? labelOffset : labelOffset * -1;
    compareMedianLabelOffset =
      median >= compareMedian ? labelOffset : labelOffset * -1;
  }
  const lines = [
    {
      type: 'line',
      mode: 'horizontal',
      scaleID: 'y-axis-0',
      value: median,
      borderColor: 'rgba(0,0,0,0.0)',
      borderWidth: 0,
      label: {
        backgroundColor: 'rgba(0,0,0,0.7)',
        enabled: true,
        content: median,
        position: 'right',
        yAdjust: medianLabelOffset,
      },
    },
  ];
  if (hasCompareValues && compareMedian) {
    lines.push({
      type: 'line',
      mode: 'horizontal',
      scaleID: 'y-axis-0',
      value: compareMedian,
      borderColor: 'rgba(0,0,0,0.0)',
      borderWidth: 0,
      label: {
        backgroundColor: 'rgba(0,0,0,0.5)',
        enabled: true,
        content: compareMedian,
        position: 'right',
        yAdjust: compareMedianLabelOffset,
      },
    });
  }
  return lines;
}

export function logout() {
  const keys = ['at', 'rt', 'site', 'sites'];
  keys.forEach((k) => {
    localStorage.removeItem(k);
  });
  window.location.pathname = '/';
}

export function exportBiomarkersToCSV(
  biomarkersData: BiomarkersResponse | null | undefined,
  filename?: string
) {
  if (!biomarkersData) {
    return;
  }
  let header = getCsvHeader(biomarkersData);
  let data = getCsvData(biomarkersData);
  let csv = header + data;
  let exportedFilenmae = filename || 'export.csv';
  let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    let link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      let url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilenmae);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

function getCsvValue(v: number | null | undefined): string {
  if (v === null || v === undefined) {
    return '';
  }
  return v.toString();
}

function getCsvHeader(biomarkersData: BiomarkersResponse): string {
  if (!biomarkersData) {
    return '';
  }
  let header = 'timestamp,';
  if (biomarkersData.temperature) {
    header += 'temperature,';
  }
  if (biomarkersData.pulseRate) {
    header += 'pulseRate,';
  }
  if (biomarkersData.deviceCompliance) {
    header += 'deviceCompliance,';
  }
  if (biomarkersData.ppgQuality) {
    header += 'ppgQuality,';
  }
  if (biomarkersData.respiratoryRate) {
    header += 'respiratoryRate,';
  }
  if (biomarkersData.edaScl) {
    header += 'edaScl,';
  }
  if (biomarkersData.stdAccelerometers) {
    header += 'stdAccelerometers,';
  }
  if (biomarkersData.activityCounts) {
    header += 'activityCounts,';
  }
  if (biomarkersData.hrv) {
    header += 'hrv,';
  }
  return `${header.substr(0, header.length - 1)}\r\n`;
}

function getCsvData(biomarkersData: BiomarkersResponse): string {
  if (!biomarkersData) {
    return '';
  }
  let data = '';

  for (let i = 0; i * 60000 + biomarkersData.from <= biomarkersData.to; i++) {
    let line = '';
    line += `${i * 60000 + biomarkersData.from},`;
    if (biomarkersData.temperature) {
      line += `${getCsvValue(biomarkersData.temperature.values[i])},`;
    }
    if (biomarkersData.pulseRate) {
      line += `${getCsvValue(biomarkersData.pulseRate.values[i])},`;
    }
    if (biomarkersData.deviceCompliance) {
      line += `${getCsvValue(biomarkersData.deviceCompliance.values[i])},`;
    }
    if (biomarkersData.ppgQuality) {
      line += `${getCsvValue(biomarkersData.ppgQuality.values[i])},`;
    }
    if (biomarkersData.respiratoryRate) {
      line += `${getCsvValue(biomarkersData.respiratoryRate.values[i])},`;
    }
    if (biomarkersData.edaScl) {
      line += `${getCsvValue(biomarkersData.edaScl.values[i])},`;
    }
    if (biomarkersData.stdAccelerometers) {
      line += `${getCsvValue(biomarkersData.stdAccelerometers.values[i])},`;
    }
    if (biomarkersData.activityCounts) {
      line += `${getCsvValue(biomarkersData.activityCounts.values[i])},`;
    }
    if (biomarkersData.hrv) {
      line += `${getCsvValue(biomarkersData.hrv.values[i])},`;
    }

    data += `${line.substr(0, line.length - 1)}\r\n`;
  }

  return data;
}

export function getChartDayPrecisionLabel(ts: number, tz: number): string {
  const weekday = dayjs.utc(ts).add(tz, 's').format('ddd');
  const date = dayjs.utc(ts).add(tz, 's').format('MMM DD');
  return `${weekday}, ${date}`;
}

export const loadImage = (src: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.addEventListener('load', () => resolve(img));
    img.addEventListener('reject', () => reject());
    img.src = src;
  });

export const canvasToBlob = (
  canvas: HTMLCanvasElement,
  type?: string,
  quality?: number
): Promise<Blob> =>
  new Promise((resolve, reject) =>
    canvas.toBlob(
      (blob) => {
        if (blob) {
          resolve(blob);
        } else {
          const error = new Error("Couldn't create Blob");
          const logEntry = new ExceptionLogEntry(error);
          logger.error(ERROR_EXCEPTION, logEntry);
          reject(error);
        }
      },
      type,
      quality
    )
  );

export const generateQr = (data: string): HTMLCanvasElement => {
  const { modules } = qr(data);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    const error = new Error("Couldn't generate QR code");
    const logEntry = new ExceptionLogEntry(error);
    logger.error(ERROR_EXCEPTION, logEntry);
    throw error;
  }

  const size = modules.length;

  canvas.width = size;
  canvas.height = size;

  modules.forEach((row, x) =>
    row.forEach((cell, y) => {
      ctx.fillStyle = cell ? '#000' : '#fff';
      ctx.fillRect(x, y, 1, 1);
    })
  );

  return canvas;
};
