import React, { useState, useEffect } from 'react';
import jsonwebtoken from 'jsonwebtoken';
import { makeStyles } from '@material-ui/core/styles';
import * as WebEmbraceApi from '../../Http/WebEmbraceApi';
import { Box, Modal, ModalProps } from '@material-ui/core';
import EnrollmentCreationStepLogin from './EnrollmentCreationStepLogin';
import EnrollmentCreationStepIdCreation from './EnrollmentCreationStepIdCreation';
import EnrollmentCreationStepCredentials from './EnrollmentCreationStepCredentials';
import Subject, { SubjectStatus } from '../../Model/Subject';
import { EnrollmentCreationError } from '../../Http/WebEmbraceApi';
import i18next from 'i18next';

const modalTop = 50;
const modalLeft = 50;

const useStyles = makeStyles(() => ({
  modalContainer: {
    position: 'absolute',
    width: 600,
    backgroundColor: '#FFFFFF',
    borderRadius: 4,
    top: `${modalTop}%`,
    left: `${modalLeft}%`,
    transform: `translate(-${modalTop}%, -${modalLeft}%)`,
  },
  fullScreenModalContainer: {
    width: 'auto',
    maxWidth: '90vmin',
    maxHeight: '90vmin',
  },
  modalHeader: {
    backgroundColor: '#01768B',
    color: '#ffffff',
    textAlign: 'center',
    borderRadius: '4px 4px 0 0 ',
  },
  modalBody: {
    backgroundColor: '#FFFFFF',
    color: '#3A3B3F',
    textAlign: 'left',
    fontWeight: 200,
    borderRadius: '4px',
    height: '100%',
  },
  wardName: {
    textAlign: 'left',
    color: '#404040',
    fontWeight: 300,
  },
  wardEnrollmentCount: {
    textAlign: 'right',
    color: '#404040',
    fontWeight: 100,
  },
}));

export function generatePassword(length = 8): string {
  var charset =
    'abcdefghijklmnpqrstuvwxyzABCDEFGHIJKLMNPQRSTUVWXYZ123456789-+#_=';
  var pwd = '';
  for (var i = 0, n = charset.length; i < length; ++i) {
    pwd += charset.charAt(Math.floor(Math.random() * n));
  }
  return pwd;
}

export interface EnrollmentCreationProps
  extends Omit<ModalProps, 'onClose' | 'children'> {
  orgId: string;
  studyId: string;
  siteId: string;
  internalIdPrefix: string;

  onCreation?(subject: Subject): void;

  onClose?(): void;
}

export enum EnrollmentCreationStep {
  LOGIN,
  ID_CREATION,
  CREDENTIALS,
}

export default function EnrollmentCreation({
  orgId,
  studyId,
  siteId,
  internalIdPrefix,
  onCreation,
  onClose,
  ...props
}: EnrollmentCreationProps) {
  const classes = useStyles();
  const [enrollment, setEnrollment] = useState<Subject>();
  const [enrollmentPassword, setEnrollmentPassword] = useState<string>();
  const [enrollmentEmail, setEnrollmentEmail] = useState<string>();
  const [email, setEmail] = useState('');
  const [currentStep, setCurrentStep] = useState<EnrollmentCreationStep>(
    EnrollmentCreationStep.LOGIN
  );
  const [password, setPassword] = useState('');

  const [idCreationErrorMessage, setIdCreationErrorMessage] = useState<
    string | undefined
  >();

  const next = () => {
    setCurrentStep((previousStep) => previousStep + 1);
  };

  const onLoginChange = (password: string) => {
    if (!password) {
      return;
    }
    setPassword(password);
    next();
  };

  const handleClose = () => {
    onClose?.();
    setEnrollment(undefined);
    setEnrollmentPassword(undefined);
    setEnrollmentEmail(undefined);
    setCurrentStep(EnrollmentCreationStep.LOGIN);
    setIdCreationErrorMessage(undefined);
  };

  const onInternalIdSubmit = (internalId: string) => {
    if (!internalId) {
      return;
    }
    internalId = internalId.trim();
    createEnrollment(internalId);
  };

  const onInternalIdChange = () => {
    setIdCreationErrorMessage(undefined);
  };

  const getStep = () => {
    switch (currentStep) {
      case EnrollmentCreationStep.LOGIN:
        return (
          <EnrollmentCreationStepLogin
            email={email}
            onChange={onLoginChange}
            onClose={handleClose}
          />
        );
      case EnrollmentCreationStep.ID_CREATION:
        return (
          <EnrollmentCreationStepIdCreation
            internalIdPrefix={internalIdPrefix}
            onSubmit={onInternalIdSubmit}
            onChange={onInternalIdChange}
            onClose={handleClose}
            requestErrorMessage={idCreationErrorMessage}
          />
        );
      case EnrollmentCreationStep.CREDENTIALS:
        return (
          <EnrollmentCreationStepCredentials
            orgId={orgId}
            studyId={studyId}
            siteId={siteId}
            enrollment={enrollment}
            email={enrollmentEmail}
            password={enrollmentPassword}
            onClose={handleClose}
          />
        );
    }
    return null;
  };

  const createEnrollment = async (internalId: string) => {
    if (!password) {
      return;
    }
    const pwd = generatePassword(8);
    const payload = {
      enrollment: {
        siteId: parseInt(siteId),
        internalId: internalId,
      },
      password: pwd,
    };
    try {
      const resp = await WebEmbraceApi.CreateSubject(
        orgId,
        studyId,
        siteId,
        payload
      );

      const e = resp.data.payload.enrollment;
      e.internalId = internalId;

      await WebEmbraceApi.StartEnrollment(orgId, studyId, siteId, e.id, e);

      const newSubject = new Subject(e);
      newSubject.isLoadedAuraValues = true;
      newSubject.isLoadedLatestBiomarkers = false;
      newSubject.isLoadedLatestDevicePairing = false;
      newSubject.status = SubjectStatus.WAITING;

      setEnrollmentEmail(resp.data.payload.email);
      setEnrollmentPassword(pwd);
      setEnrollment(newSubject);
      onCreation?.(newSubject);
      next();
    } catch (err) {
      const errorCode = err.response?.data?.errorCode || undefined;
      switch (errorCode) {
        case EnrollmentCreationError.InternalIDAlreadyExists:
          setIdCreationErrorMessage(
            i18next.t('ENROLLMENT_CREATION_ID_ERROR_ID_ALREADY_EXISTS')
          );
          break;
        default:
          setIdCreationErrorMessage(
            i18next.t('ENROLLMENT_CREATION_ID_ERROR_UNKNOWN')
          );
          break;
      }
    }
  };

  useEffect(() => {
    const at: string | null = localStorage.getItem('at');
    const token: any = at ? jsonwebtoken.decode(at) : '';
    if (!token) {
      return;
    }
    WebEmbraceApi.GetUser(token.PAYLOAD.userId)
      .then((resp: any) => {
        setEmail(resp.data.payload.email);
      })
      .catch((err) => {
        //TODO(mb)
        console.log(err);
      });
  }, []);

  return (
    <Modal {...props}>
      <div
        className={`${classes.modalContainer} ${
          currentStep === EnrollmentCreationStep.CREDENTIALS
            ? classes.fullScreenModalContainer
            : ''
        }`}
      >
        <Box className={classes.modalBody}>{getStep()}</Box>
      </div>
    </Modal>
  );
}
