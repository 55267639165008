import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ERROR_API, logger } from '../../Logging/Logger';
import { ApiLogEntry, ApiRequest } from '../../Model/LogEntry';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export class LoginFormProps {
  toggleResetPassword?: Function;
  onSuccess?: Function;
}

function validateEmail(email: string) {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const LOGIN_BASE_URL = process.env.REACT_APP_API_URL;
const LOGIN_ENDPOINT = 'v3/login';
const LOGIN_FULL_URL = `${LOGIN_BASE_URL}/${LOGIN_ENDPOINT}`;

export default function LoginForm(props: LoginFormProps) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailHasError, setEmailHasError] = useState(false);
  const [passwordHasError, setPasswordHasError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState('');
  const [passwordHelperText, setPasswordHelperText] = useState('');

  const updateEmail = (email: string) => {
    setEmail(email);
    if (validateEmail(email)) {
      setEmailHelperText('');
      setEmailHasError(false);
    } else {
      setEmailHelperText(i18next.t('INVALID_EMAIL_ERROR_MESSAGE'));
      setEmailHasError(true);
    }
  };

  function handleAuthorizationSuccess(response: any) {
    const token = response.payload.token;
    const refreshToken = response.payload.refreshToken;
    if (!token || !refreshToken) {
      return;
    }
    localStorage.setItem('at', token);
    localStorage.setItem('rt', refreshToken);

    if (props.onSuccess) {
      props.onSuccess();
    }
  }

  function handleAuthorizationFailure() {
    setPasswordHelperText(i18next.t('INVALID_CREDENTIALS'));
    setEmailHasError(true);
    setPasswordHasError(true);
    setLoading(false);
  }

  function handleServerError(error: any) {
    // log entry to ElasticSearch
    if (error != null && error.response != null) {
      let apiRequest = new ApiRequest(
        LOGIN_BASE_URL ? LOGIN_BASE_URL : '',
        'post',
        LOGIN_ENDPOINT
      );
      let logEntry = new ApiLogEntry(error.response.status, apiRequest);
      logger.error(ERROR_API, logEntry);
    }

    setPasswordHelperText(i18next.t('SERVER_ERROR'));
    setEmailHasError(true);
    setPasswordHasError(true);
    setLoading(false);
  }

  function cleanupForm() {
    updateEmail(email);
    setPasswordHasError(false);
    setPasswordHelperText('');
    setLoading(true);
  }

  const handleSubmit = (evt: any) => {
    evt.preventDefault();
    cleanupForm();

    if (password.length < 8) {
      setPasswordHelperText(i18next.t('PASSWORD_LENGTH_ERROR_MESSAGE'));
      setPasswordHasError(true);
      setLoading(false);
      return;
    }

    const loginBody = {
      email: email.trim(),
      password: password,
    };

    fetch(LOGIN_FULL_URL, {
      method: 'post',
      body: JSON.stringify(loginBody),
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((groupedResponse) => {
        switch (groupedResponse.status) {
          case 200:
            handleAuthorizationSuccess(groupedResponse.body);
            break;
          case 401:
            handleAuthorizationFailure();
            break;
          default:
            throw Object.assign(new Error('Unprocessable response'), {
              response: groupedResponse,
            });
        }
      })
      .catch(function (err) {
        handleServerError(err);
      });
  };

  const toggleResetPassword = () => {
    if (!props.toggleResetPassword) {
      return;
    }
    props.toggleResetPassword();
  };

  const classes = useStyles();
  return (
    <form className={classes.form} onSubmit={handleSubmit} noValidate>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label={i18next.t('EMAIL')}
        name="email"
        autoComplete="email"
        value={email}
        error={emailHasError}
        helperText={emailHelperText}
        onChange={(e) => {
          updateEmail(e.target.value);
        }}
        autoFocus
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label={i18next.t('PASSWORD')}
        type="password"
        id="password"
        autoComplete="current-password"
        value={password}
        helperText={passwordHelperText}
        error={passwordHasError}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={loading}
      >
        {!loading && <Trans i18nKey="LOGIN_SIGN_IN" />}
        {loading && <CircularProgress size="1.6rem" />}
      </Button>
      <Grid container>
        <Grid item xs>
          <Link href="#" onClick={toggleResetPassword}>
            <Trans i18nKey="LOGIN_FORGOT_PASSWORD" />
          </Link>
        </Grid>
      </Grid>
    </form>
  );
}
