import React, { useEffect, useState, useContext } from 'react';
import { Grid, Box, Typography, makeStyles } from '@material-ui/core';
import { Trans } from 'react-i18next';
import Temperatures from '../../Model/Temperature';
import TemperatureIcon from '../../Icons/TemperatureIcon';
import SubjectChartTemperatureMinutePrecision from './SubjectChartTemperatureMinutePrecision';
import SubjectChartTemperatureHourPrecision from './SubjectChartTemperatureHourPrecision';
import SubjectChartTemperatureDayPrecision from './SubjectChartTemperatureDayPrecision';
import Timerange from '../../Model/Timerange';
import {
  BiomarkerPrecisionMinute,
  BiomarkerPrecisionDay,
} from '../../Model/Biomarker';
import UserContext from '../../Context/UserContext';
import TemperatureBar from '../../Icons/SkinTempArea.svg';
import SkinTempLineOne from '../../Icons/SkinTempLineOne.svg';
import SkinTempLineTwo from '../../Icons/SkinTempLineTwo.svg';
import { ViewTypes } from '../SubjectView/SubjectView';
import SubjectMedianInfo from './SubjectMedianInfo';
import Timezone from '../../Model/Timezone';

const useStyles = makeStyles((theme) => ({
  chartGrid: {
    display: 'grid',
    gridTemplateColumns: '12rem 1fr',
    gridTemplateRows: '1fr',
    gridColumnGap: 0,
    gridRowGap: 0,
    width: '100%',
  },
  chartLeft: {
    height: 'auto',
    color: '#485164',
  },
  chartRight: {
    height: '28rem',
  },
  table: {
    width: '100%',
    borderSpacing: 0,
    '& td': {
      border: '1px solid #d3d3d3',
      padding: '2px',
    },
  },
}));

export enum days {
  today = 'today',
  yesterday = 'yesterday',
  tenDays = '10 days',
}

export class SubjectViewTemperatureProps {
  timerange?: Timerange;
  userId?: number;
  data?: any;
  timezone?: Timezone;
}

export default function SubjectViewTemperature(
  props: SubjectViewTemperatureProps
) {
  const classes = useStyles();
  const [temperatures, setTemperatures] = useState<Temperatures>(
    new Temperatures(undefined)
  );
  const [lastValue, setLastValue] = useState('-');
  const [median, setMedian] = useState('-');
  const [compareValue, setCompareValue] = useState('-');
  const { config } = useContext(UserContext);
  const temperatureUnit = config.measurementUnits.temperatureSymbol;

  useEffect(() => {
    if (!props.userId || !props.timerange || !props.data) {
      return;
    }

    const temperature = new Temperatures(
      props?.data?.from,
      props?.data?.to,
      props?.timezone?.offset,
      props?.data?.temperature.values,
      props?.data?.deviceCompliance.values,
      props.timerange?.precision
    );

    setTemperatures(temperature);
  }, [props]);

  useEffect(() => {
    if (!temperatures) {
      return;
    }
    if (temperatures.lastValue && temperatures.median) {
      const v = temperatures.lastValue.toFixed(1);
      const a = temperatures.median.toFixed(1);
      if (props.timerange?.precision !== BiomarkerPrecisionMinute) {
        setLastValue(a);
      } else {
        setLastValue(v);
      }
      setMedian(a);
    }

    if (temperatures.compareMedian) {
      const cv = temperatures.compareMedian
        ? temperatures.compareMedian.toString()
        : '-';
      setCompareValue(cv);
    }
  }, [temperatures, props.timerange]);

  return (
    <React.Fragment>
      <Box p={2}>
        <Box className={classes.chartGrid}>
          <Box className={classes.chartLeft}>
            <Typography variant="h5" component="h5">
              <Trans i18nKey="TEMPERATURE" />{' '}
            </Typography>
            <Grid container direction="row" spacing={1}>
              <TemperatureIcon style={{ height: '1.4rem', marginTop: '4px' }} />
              <Grid item xs={12} sm container direction="column">
                <Grid container direction="row" spacing={1}>
                  <Grid
                    item
                    className="numberCharts"
                    style={{ color: 'rgba(64,64,64,1)' }}
                  >
                    <SubjectMedianInfo median={lastValue} day={days.today} />
                  </Grid>
                  <Grid item>
                    <div
                      className="textMicro"
                      style={{ color: 'rgba(64,64,64,1)' }}
                    >
                      <Trans i18nKey="TODAY" />
                    </div>
                    <div
                      className="textMicro"
                      style={{ color: 'rgba(64,64,64,1)' }}
                    >
                      {temperatureUnit}
                    </div>
                  </Grid>
                </Grid>
                {props.timerange?.range === ViewTypes.view48h && (
                  <Grid container direction="row" spacing={1}>
                    <Grid
                      item
                      className="numberCharts"
                      style={{ color: 'rgba(150,150,150,1)' }}
                    >
                      <SubjectMedianInfo
                        median={compareValue}
                        day={days.yesterday}
                      />
                    </Grid>
                    <Grid item>
                      <div
                        className="textMicro"
                        style={{ color: 'rgba(150,150,150,1)' }}
                      >
                        <Trans i18nKey="YESTERDAY_SHORT" />
                      </div>
                      <div
                        className="textMicro"
                        style={{ color: 'rgba(150,150,150,1)' }}
                      >
                        {temperatureUnit}
                      </div>
                    </Grid>
                  </Grid>
                )}
                {props.timerange?.range === ViewTypes.view10d && (
                  <Grid container direction="row" spacing={1}>
                    <Grid
                      item
                      className="numberCharts"
                      style={{ color: 'rgba(150,150,150,1)' }}
                    >
                      <SubjectMedianInfo
                        median={compareValue}
                        day={days.tenDays}
                      />
                    </Grid>
                    <Grid item>
                      <div
                        className="textMicro"
                        style={{ color: 'rgba(150,150,150,1)' }}
                      >
                        10 <Trans i18nKey="DAYS" />
                      </div>
                      <div
                        className="textMicro"
                        style={{ color: 'rgba(150,150,150,1)' }}
                      >
                        {temperatureUnit}
                      </div>
                    </Grid>
                  </Grid>
                )}
                {props.timerange?.isRealtime === false && (
                  <Grid container direction="row" style={{ marginTop: 100 }}>
                    <img
                      src={TemperatureBar}
                      alt="Temperature Area"
                      style={{ height: 60 }}
                    />
                    <div className="column">
                      <img
                        src={SkinTempLineOne}
                        alt="Temperature Area"
                        style={{ paddingBottom: 10 }}
                      />
                      <br></br>
                      <img
                        src={SkinTempLineTwo}
                        alt="Temperature Area"
                        style={{ paddingBottom: 5 }}
                      />
                      <br></br>
                      <img
                        src={SkinTempLineOne}
                        alt="Temperature Area"
                        style={{ paddingBottom: 0 }}
                      />
                    </div>
                    <div className="column">
                      <span style={{ color: '#878787', fontSize: 12 }}>
                        <Trans i18nKey="THIRD_QUARTILE" />
                      </span>
                      <br></br>
                      <span style={{ color: '#878787', fontSize: 12 }}>
                        <Trans
                          i18nKey={
                            props.timerange?.precision === BiomarkerPrecisionDay
                              ? 'DAILY_MEDIAN'
                              : 'HOURLY_MEDIAN'
                          }
                        />
                      </span>
                      <br></br>
                      <span style={{ color: '#878787', fontSize: 12 }}>
                        <Trans i18nKey="FIRST_QUARTILE" />
                      </span>
                    </div>
                  </Grid>
                )}
              </Grid>
              {props.timerange?.isRealtime === true && (
                <Grid container direction="row">
                  <div
                    style={{
                      fontSize: '14px',
                      color: 'grey',
                      marginTop: '5px',
                    }}
                  >
                    <Trans i18nKey="TIME_RANGE_REAL_TIME_6H_MEDIAN" /> {median}
                    {temperatureUnit}
                  </div>
                </Grid>
              )}
            </Grid>
          </Box>
          <Box className={classes.chartRight}>
            {props.timerange?.isRealtime === true && (
              <SubjectChartTemperatureMinutePrecision
                temperatures={temperatures}
                timerange={props.timerange}
                timezone={props.timezone}
              />
            )}
            {props.timerange?.range === ViewTypes.view48h && (
              <SubjectChartTemperatureHourPrecision
                temperatures={temperatures}
                timerange={props.timerange}
                timezone={props.timezone}
              />
            )}
            {props.timerange?.range === ViewTypes.view10d && (
              <SubjectChartTemperatureDayPrecision
                temperatures={temperatures}
                timerange={props.timerange}
                timezone={props.timezone}
              />
            )}
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}
