import dayjs, { OpUnitType } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import i18next from 'i18next';
import {
  MillisecondsInDay,
  MillisecondsInHour,
  MillisecondsInMinute,
} from '../../Model/Biomarker';

dayjs.extend(utc);

export function getReachedNowDayLabels(
  from: number,
  series: Array<number | null>,
  step: 'minute' | 'hour' | 'day',
  tz: number = 0
): Array<string> {
  const stepMap = {
    minute: {
      step: MillisecondsInMinute,
      startOf: 'minute',
      format: 'YYYY-MM-DD HH:mm',
    },
    hour: {
      step: MillisecondsInHour,
      startOf: 'hour',
      format: 'YYYY-MM-DD HH',
    },
    day: {
      step: MillisecondsInDay,
      startOf: 'day',
      format: 'YYYY-MM-DD',
    },
  };
  return series.map((v: any, i: number) => {
    return dayjs
      .utc(from)
      .add(i * stepMap[step].step, 'millisecond')
      .startOf(stepMap[step].startOf as OpUnitType)
      .add(tz, 's')
      .format(stepMap[step].format);
  });
}

export function getChartHourLabels(
  series: any[],
  from: number,
  tz: number,
  format: string
): (string | null)[] {
  return series.map((_: any, hour: number) => {
    const label = dayjs.utc(from).add(hour, 'h').add(tz, 's').format(format);

    const startOfHour = dayjs
      .utc(from)
      .add(hour, 'h')
      .add(tz, 's')
      .startOf('hour')
      .format(format);

    if (label !== startOfHour) {
      return null;
    }
    return label;
  });
}

export function getPrettyAverage(value: number | undefined): string {
  if (!Boolean(value)) {
    return `0${i18next.t('REPORT_UNIT_HOUR')}`;
  }

  const hours = value! / 60;
  const roundedHours = Math.floor(hours);
  const minutes = (hours - roundedHours) * 60;
  const roundedMinutes = Math.round(minutes);

  if (roundedMinutes === 0) {
    return `${roundedHours}` + i18next.t('REPORT_UNIT_HOUR');
  }

  return `${roundedHours}${i18next.t(
    'REPORT_UNIT_HOUR'
  )}${roundedMinutes}${i18next.t('REPORT_UNIT_MINUTE')}`;
}
