import BaseErrorView from './BaseErrorView';
import React, { useState } from 'react';
import i18next from 'i18next';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import {
  API_STATUS_BASE_URL,
  fetchServerStatus,
} from '../../Http/StatusService/StatusService';

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    zIndex: 1998,
    position: 'fixed',
    top: 0,
  },
  serverStatusButton: {
    textTransform: 'none',
  },
  serverStatusText: {
    marginLeft: '11px',
    marginRight: '3px',
  },
  serverStatusIcon: {
    width: '9px',
    height: '9px',
  },
}));

const STATUS_LOADING_COLOR = 'grey';

export default function ServerErrorView() {
  const [operationalStatusText, setOperationalStatusText] = useState<string>(
    'LOADING'
  );
  const [circleColor, setCircleColor] = useState(STATUS_LOADING_COLOR);

  const classes = useStyles();

  const retry = () => {
    window.location.reload();
  };

  const getServerStatusButton = (): JSX.Element => {
    return (
      <Button
        href={API_STATUS_BASE_URL}
        target="_blank"
        rel="noopener noreferrer nofollow"
        type="button"
        color="secondary"
        variant="contained"
        className={classes.serverStatusButton}
      >
        <span>
          <FontAwesomeIcon
            icon={faCircle}
            color={circleColor}
            className={classes.serverStatusIcon}
          />
        </span>
        <span className={classes.serverStatusText}>
          {i18next.t(operationalStatusText)}
        </span>
      </Button>
    );
  };

  fetchServerStatus().then(function (response) {
    setOperationalStatusText(response.getPrettyStatus());
    setCircleColor(response.getColor());
  });

  return (
    <div className={classes.wrapper}>
      <BaseErrorView
        errorCode={'500'}
        title={i18next.t('SERVER_ERROR_TITLE')}
        subtitle={i18next.t('SERVER_ERROR_SUBTITLE')}
        primaryAction={{
          label: i18next.t('GENERIC_ERROR_ACTION'),
          func: retry,
        }}
        extra={getServerStatusButton()}
      />
    </div>
  );
}
