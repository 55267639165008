import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

export function isUrlExpired(urlExpiration: number, currentTime: number) {
  return urlExpiration < currentTime;
}

export function getSignedUrlExpiration(fileUrlDecoded: string): number {
  const url = new URL(fileUrlDecoded);
  let issueDate = url.searchParams.get('X-Amz-Date');
  let expirationTime = url.searchParams.get('X-Amz-Expires');

  if (issueDate === undefined || expirationTime === undefined) {
    throw Error('Invalid url provided');
  }

  issueDate = issueDate!.replace('Z', '+00:00');

  const awsDateFormat = "YYYYMMDD'T'HHmmSSZ";
  let parsedIssueDate = dayjs(issueDate!, awsDateFormat).local();
  let expirationDate = parsedIssueDate
    .add(parseInt(expirationTime!), 's')
    .unix();

  return expirationDate;
}

// getUrlForAdherenceReport is used to generate the url for the adherence report based on the provided signed url
export function getUrlForAdherenceReport(preSignedUrl: string): string {
  const ADHERENCE_REPORT_URL = '/reports/adherence';
  const QUERY_PARAMETER_URL = 'url';
  const encodedUrl = btoa(preSignedUrl);

  return `${ADHERENCE_REPORT_URL}?${QUERY_PARAMETER_URL}=${encodedUrl}`;
}

// isAdherenceReport is used to assess if a given file is an adherence report or not
export function isAdherenceReport(fullPath: string) {
  const FOLDER_ADHERENCE_REPORTS = 'site_reports';
  const SUFFIX_REPORT = 'adherence.csv';
  return (
    fullPath.includes(FOLDER_ADHERENCE_REPORTS) &&
    fullPath.includes(SUFFIX_REPORT)
  );
}
