import { WinstonLogger } from './Winston/WinstonLogger';
import { BaseLogEntry } from '../Model/LogEntry';

export const INFO_API = 'api_req_info';
export const ERROR_API = 'api_req_err';
export const ERROR_EXCEPTION = 'exception';
export const INFO_DOWNLOAD_COMPLIANCE = 'click_download_compliance_button';

// Base interface for any logging component that we may use (such as Winston)
export interface Logger {
  error(message: string, logEntry?: BaseLogEntry): void;

  info(message: string, logEntry?: BaseLogEntry): void;

  debug(message: string, logEntry?: BaseLogEntry): void;
}

export var logger: Logger = new WinstonLogger();
