import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import BrowserList from './BrowserList';

import { BrowseItem, ItemType } from '../../Model/BrowserItem';
import { BrowserApi } from './BrowserApi';
import * as DataService from '../../Http/DataService';
import { ApiResponseGetCredentials } from '../../Http/DataService';
import { getNavigationItemsForPath, NavigationItem } from './Navigator';
import Link from '@material-ui/core/Link';
import i18next from 'i18next';
import LoadState, {
  LoadStateLoading,
  LoadStateSuccess,
} from '../../Model/LoadState';
import CircularProgress from '@material-ui/core/CircularProgress';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getUrlForAdherenceReport,
  isAdherenceReport,
} from '../Utils/AdherenceUtils';

const useStyles = makeStyles((theme) => ({
  navBar: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: '#147b8f',
    backgroundColor: '#ffffff',
    paddingLeft: theme.spacing(2),
  },
  breadCrumbSection: {
    fontSize: '0.85rem',
  },
}));

const PATH_SEPARATOR = '/';

class BrowserViewProps {
  organizationInternalId!: string;
  studyInternalId!: string;
  siteInternalId!: string;
}

export default function BrowserView(props: BrowserViewProps) {
  const classes = useStyles();
  const initialPath = `${props.organizationInternalId}/${props.studyInternalId}/${props.siteInternalId}/`;

  const [loadState, setLoadState] = useState<LoadState>(LoadStateLoading);
  const [objectList, setObjectList] = useState<BrowseItem[]>([]);
  const [currentPath, setCurrentPath] = useState<string>(initialPath);

  function makeBrowserApi(
    credentialsResponse: ApiResponseGetCredentials
  ): BrowserApi {
    return new BrowserApi(
      process.env.REACT_APP_DATA_ACCESS_REGION || '',
      process.env.REACT_APP_DATA_ACCESS_BUCKET || '',
      credentialsResponse.data.Key,
      credentialsResponse.data.Secret
    );
  }

  function downloadFile(item: BrowseItem) {
    DataService.GetCredentials().then((response) => {
      const browserApi = makeBrowserApi(response);

      browserApi.getObjectSignedUrl(item.fullPath).then((downloadUrl) => {
        let url = downloadUrl;
        if (isAdherenceReport(item.fullPath)) {
          url = getUrlForAdherenceReport(downloadUrl);
        }

        window.open(url);
      });
    });
  }

  function getParentPath(path: string) {
    let currentPathSplit = path
      .substring(0, path.length - 1)
      .split(PATH_SEPARATOR);
    let previousPath = currentPathSplit
      .slice(0, currentPathSplit.length - 1)
      .join(PATH_SEPARATOR);

    if (previousPath !== initialPath) {
      previousPath = previousPath + PATH_SEPARATOR;
    }
    return previousPath;
  }

  const handleClick = (item: BrowseItem) => {
    switch (item.type) {
      case ItemType.TYPE_DIR:
        setCurrentPath(item.fullPath);
        break;
      case ItemType.TYPE_FILE:
        downloadFile(item);
        break;
      case ItemType.TYPE_RETURN:
        let parentPath = getParentPath(currentPath);
        setCurrentPath(parentPath);
        break;
    }
  };

  const handleNavigationClick = (navItem: NavigationItem) => {
    const browseItem = new BrowseItem(navItem.path, ItemType.TYPE_DIR);
    handleClick(browseItem);
  };

  function renderNavigationItem(
    navigationItem: NavigationItem,
    isSelectable: boolean
  ): JSX.Element {
    return (
      <span>
        {' '}
        {PATH_SEPARATOR}&nbsp;
        <Link
          onClick={() =>
            !navigationItem.isActivePath && !isSelectable
              ? handleNavigationClick(navigationItem)
              : false
          }
          style={{
            cursor: navigationItem.isActivePath ? 'default' : 'pointer',
            fontWeight: navigationItem.isActivePath ? 'bold' : 'normal',
            textDecoration: 'none',
          }}
        >
          <span>{navigationItem.title}</span>
        </Link>
      </span>
    );
  }

  function renderNavigation(path: string): JSX.Element {
    let navigationItems = getNavigationItemsForPath(path);
    const navigationItemsRendered = navigationItems.map(
      (item: NavigationItem, index: number) => {
        const NUMBER_SUBDIRECTORIES = 3; // org/study/site
        const isSelectable = index < NUMBER_SUBDIRECTORIES;
        return renderNavigationItem(item, isSelectable);
      }
    );

    return <span>{navigationItemsRendered}</span>;
  }

  useEffect(() => {
    setLoadState(LoadStateLoading);
    DataService.GetCredentials().then((response) => {
      const browserApi = makeBrowserApi(response);

      browserApi.retrieveObjects(currentPath).then((items: BrowseItem[]) => {
        if (currentPath !== initialPath) {
          items.unshift(
            new BrowseItem(
              i18next.t('BROWSER_NAVIGATION_RETURN'),
              ItemType.TYPE_RETURN
            )
          );
        }

        setObjectList(items);
        setLoadState(LoadStateSuccess);
      });
    });
  }, [currentPath, initialPath]);

  return (
    <div>
      <Grid container spacing={0}>
        <Grid container className={classes.navBar}>
          <Grid item xs={10} className={classes.breadCrumbSection}>
            <div>{renderNavigation(currentPath)}</div>
          </Grid>
          <Grid item xs={2}>
            <FontAwesomeIcon icon={faExclamationTriangle} />
            &nbsp;
            <span>{i18next.t('BROWSER_NAVIGATION_MESSAGE')}</span>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div>
            {loadState.isSuccess && (
              <BrowserList handleClick={handleClick} items={objectList} />
            )}

            {loadState.isLoading && (
              <span>
                <CircularProgress size="1.6rem" />
              </span>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
