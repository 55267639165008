import React, { useState } from 'react';
import { Box, TextField, Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagic } from '@fortawesome/free-solid-svg-icons';
import { Trans } from 'react-i18next';
import i18next from 'i18next';

const maxLengthInternalId = 10;

export function generateInternalId(length = 5): string {
  var charset = 'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789';
  var id = '';
  for (var i = 0, n = charset.length; i < length; ++i) {
    id += charset.charAt(Math.floor(Math.random() * n));
  }
  return id;
}

export function isValidInternalId(
  internalId?: string | null
): internalId is string {
  if (!internalId) {
    return false;
  }

  // The following regex is going to match a single character present with the given conditions:
  // A-Z range (A-Z)
  // a-z range (a-z)
  // 0-9 range (0-9)
  // - hyphen (-)
  // _ underscore (_)
  // Up to maxLengthInternalId times {1, maxLengthInternalId}
  // With a global modifier (g flag), so we will return only a single match
  var regexString = '([A-Za-z0-9-_]{1,' + maxLengthInternalId + '})';
  var regex = new RegExp(regexString, 'g');

  var match = internalId.match(regex);

  if (match && match.length === 1) {
    return match[0].length === internalId.length;
  }

  return false;
}

export interface EnrollmentCreationStepIdCreationProps {
  internalIdPrefix?: string;

  onSubmit?(internalId: string): void;

  onChange?(): void;

  onClose?(): void;

  requestErrorMessage?: string;
}

export default function EnrollmentCreationStepIdCreation({
  onSubmit,
  onChange,
  onClose,
  requestErrorMessage,
}: EnrollmentCreationStepIdCreationProps) {
  const [internalId, setInternalId] = useState('');
  const [hasValidationError, setHasValidationError] = useState(false);

  const submit = () => {
    if (!isValidInternalId(internalId)) {
      setHasValidationError(true);
      return;
    }
    onSubmit?.(internalId);
  };

  const close = () => {
    onClose?.();
  };

  const setRandomId = () => {
    const randomId = generateInternalId(5);
    setInternalId(randomId);
    onChangeInternalId(randomId);
  };

  function onChangeInternalId(newInternalId: string) {
    setInternalId(newInternalId);
    const validInternalId = isValidInternalId(newInternalId);
    setHasValidationError(!validInternalId);

    onChange?.();
  }

  function getErrorMessage(): string | undefined {
    if (hasValidationError) {
      return i18next.t('ENROLLMENT_CREATION_ID_ERROR_VALIDATION');
    }

    if (requestErrorMessage) {
      return requestErrorMessage;
    }

    return undefined;
  }

  return (
    <Box>
      <Box pl={12} pr={12} pt={4} pb={4}>
        <h1>
          <Trans i18nKey="ENROLLMENT_CREATION_ID_TITLE" />
        </h1>
        <Box>
          <Trans i18nKey="ENROLLMENT_CREATION_ID_DESCRIPTION" />
        </Box>
        <Box mt={3} className="textMicro">
          <Trans i18nKey="ENROLLMENT_CREATION_ID_INPUT_DESCRIPTION" />
        </Box>
        <Box mt={3}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="internalId"
            label={i18next.t('USER_ID')}
            name="internalId"
            autoComplete="internalId"
            value={internalId}
            onChange={(e) => onChangeInternalId(e.target.value.trim())}
            error={hasValidationError || requestErrorMessage ? true : false}
            helperText={getErrorMessage()}
          />
        </Box>
        <Box mt={6}>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            onClick={setRandomId}
            id="btn-generate-random-id"
          >
            <FontAwesomeIcon icon={faMagic} />
            &nbsp; <Trans i18nKey="ENROLLMENT_CREATION_ID_GENERATE" />
          </Button>
        </Box>
      </Box>
      <Box padding={4} textAlign="right">
        <Button
          type="button"
          color="default"
          onClick={close}
          id="btn-add-user-cancel"
        >
          <Trans i18nKey="CANCEL" />
        </Button>
        <span>&nbsp;</span>
        <Button
          id={'btn-add-user-confirm'}
          type="submit"
          variant="contained"
          color="primary"
          onClick={submit}
        >
          <Trans i18nKey="CONFIRM" />
        </Button>
      </Box>
    </Box>
  );
}
