import React, { useEffect, useState, useContext } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import 'date-fns';
import { Box, Grid, Divider, makeStyles, Typography } from '@material-ui/core';
import * as BiomarkersService from '../../Http/BiomarkersService';
import { AuraEdri } from '../../Model/Edri';
import { ApiResponseUserAuraEdri } from '../../Http/BiomarkersService';
import AuraIcon from '../../Icons/AuraIcon';
import SubjectChartTimezoneInfo from './SubjectChartTimezoneInfo';
import UserContext from '../../Context/UserContext';
import AuraEdriBadge from '../../Items/AuraEdriBadge';
import Timezone from '../../Model/Timezone';
import { Trans } from 'react-i18next';
import Timerange from '../../Model/Timerange';
import { SubjectStatus } from '../../Model/Subject';

dayjs.extend(utc);

export enum AuraResultTypes {
  finalResult = 'Final Result',
  temporaryResult = 'Temporary Result',
  notEnoughData = 'Not enough data',
}

const useStyles = makeStyles((theme) => ({
  chartGrid: {
    display: 'grid',
    gridTemplateColumns: '12rem 1fr',
    gridTemplateRows: '1fr',
    gridColumnGap: 0,
    gridRowGap: 0,
    width: '100%',
  },
  chartLeft: {
    height: 'auto',
    color: '#485164',
    fontSize: '1rem',
  },
  chartRight: {
    height: '6rem',
  },
  auraGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(10, 1fr)',
    gridTemplateRows: '1fr',
    gridColumnGap: 0,
    gridRowGap: 0,
    width: '103%',
    textAlign: 'center',
    marginLeft: '-10px',
  },
  dashLine: {
    marginTop: '1rem',
    width: '1px',
    height: '1rem',
    border: '1px solid #FFF',
    borderRight: '0px',
    borderBottom: '0px',
    borderTop: '0px',
    marginLeft: '50%',
  },
  auraIcon: {
    height: '1.4rem',
    marginLeft: '0.7rem',
  },
  latestAuraEdri: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: '1rem',
    marginLeft: '1rem',
    maxWidth: '9.5rem',
  },
  latestAuraEdriTitle: {
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  latestAuraEdriSubtitle: {
    color: '#969696',
    fontSize: '0.75rem',
    marginTop: '0.2rem',
  },
}));

class SubjectChartAuraDayViewProps {
  userId?: number;
  userStatus?: SubjectStatus;
  timezone?: Timezone;
  timerange?: Timerange;
}

export default function SubjectChartAuraDayView(
  props: SubjectChartAuraDayViewProps
) {
  const tzOffset =
    props.timezone && props.timezone.offset
      ? props.timezone.offset
      : dayjs().utcOffset() * 60;
  const [auraEdri, setAuraEdri] = useState<AuraEdri[] | null>([]);
  const [auraGrid, setAuraGrid] = useState<any>(null);
  const [auraLatestResult, setAuraLatestResult] = useState<
    AuraEdri | undefined
  >();
  const classes = useStyles();
  const { config } = useContext(UserContext);
  const [auraLatestResultType, setAuraLatestResultType] = useState<
    string | undefined
  >();

  useEffect(() => {
    if (!props.userId) {
      return;
    }

    const getUserAuraEdri = (userId: number) => {
      const tzOffset =
        props.timezone && props.timezone.offset
          ? props.timezone.offset
          : dayjs().utcOffset() * 60;
      const today =
        props.timerange && props.timerange.to
          ? dayjs(props.timerange.to).toDate()
          : dayjs().toDate();
      const fromDay = dayjs(today).subtract(9, 'd').startOf('day');
      const fromDayLabel = fromDay.format('YYYY-MM-DD');
      const toDay = dayjs(today).endOf('day');
      const toDayLabel = toDay.format('YYYY-MM-DD');

      BiomarkersService.GetUserAuraEdri(
        userId,
        fromDayLabel,
        toDayLabel,
        tzOffset
      ).then((response: ApiResponseUserAuraEdri) => {
        const data = response.data.values || [];
        setAuraEdri(data);
      });
    };

    getUserAuraEdri(props.userId);
  }, [props.userId, props.timezone, props.timerange]);

  useEffect(() => {
    if (!auraEdri || auraEdri.length === 0) {
      return;
    }
    const last = auraEdri[auraEdri.length - 1];
    setAuraLatestResultType(AuraResultTypes.notEnoughData);
    if (last.value) {
      setAuraLatestResult(last);
      if (last.value.riskLevel === 12 || last.value.riskLevel === 22) {
        setAuraLatestResultType(AuraResultTypes.finalResult);
      } else if (last.value.riskLevel === 11 || last.value.riskLevel === 21) {
        setAuraLatestResultType(AuraResultTypes.temporaryResult);
      }
    }
    const grid = auraEdri.map((a: AuraEdri) => {
      return (
        <Box>
          <Box className={classes.dashLine}></Box>
          <AuraEdriBadge aura={a} status={props.userStatus} />
          <br></br>
          <Box style={{ color: '#696969', fontSize: '0.75rem' }}>
            <Box style={{ marginTop: '5px' }}>
              {dayjs(a.day).format('ddd')}, {dayjs(a.day).format('MMM DD')}
            </Box>
          </Box>
        </Box>
      );
    });
    setAuraGrid(grid);
  }, [auraEdri, config, classes.dashLine, props.userStatus]);

  if (!props.userId) {
    return <></>;
  }

  return (
    <Box p={2}>
      <Box className={classes.chartGrid}>
        <Box className={classes.chartLeft}>
          <Grid
            item
            className="numberCharts"
            style={{ color: 'rgba(64,64,64,1)' }}
          >
            <Grid container direction="row" spacing={1}>
              <Grid item className="numberCharts" style={{ color: '#2E384D' }}>
                <Typography variant="h5" component="h5">
                  Aura
                </Typography>
              </Grid>
              <Grid container direction="row" spacing={1}>
                <AuraIcon className={classes.auraIcon} />
                <Box className={classes.latestAuraEdri}>
                  {auraLatestResultType === AuraResultTypes.finalResult && (
                    <React.Fragment>
                      <AuraEdriBadge
                        aura={auraLatestResult}
                        status={props.userStatus}
                      />
                      <Box className={classes.latestAuraEdriSubtitle}>
                        <Trans i18nKey="AURA_EDRI_ASSESSMENT_COMPLETED" />
                      </Box>
                    </React.Fragment>
                  )}
                  {auraLatestResultType === AuraResultTypes.temporaryResult && (
                    <React.Fragment>
                      <Box className={classes.latestAuraEdriTitle}>
                        <Trans i18nKey="AURA_EDRI_CALCULATING_RESULTS" />
                      </Box>
                      <Box className={classes.latestAuraEdriSubtitle}>
                        <Trans i18nKey="AURA_EDRI_ASSESSMENT_IN_PROGRESS" />
                      </Box>
                    </React.Fragment>
                  )}
                  {auraLatestResultType === AuraResultTypes.notEnoughData && (
                    <React.Fragment>
                      <Box className={classes.latestAuraEdriTitle}>
                        <Trans i18nKey="AURA_EDRI_DATA_COLLECTION" />
                      </Box>
                      <Box className={classes.latestAuraEdriSubtitle}>
                        <Trans i18nKey="AURA_EDRI_NOT_ENOUGH_DATA" />
                      </Box>
                    </React.Fragment>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.chartRight}>
          <Box className={classes.auraGrid}>{auraGrid}</Box>
          <SubjectChartTimezoneInfo offsetInSeconds={tzOffset} />
        </Box>
      </Box>
      <Divider></Divider>
    </Box>
  );
}
