export class AdherenceCsvRow {
  user_id!: string;
  date_utc!: string;
  weekday!: string;
  device_worn_correctly_min!: number;
  device_not_worn_correctly_min!: number;
  device_not_recording_min!: number;

  constructor(
    user_id: string,
    date_utc: string,
    weekday: string,
    device_worn_correctly_min: number,
    device_not_worn_correctly_min: number,
    device_not_recording_min: number
  ) {
    this.user_id = user_id;
    this.date_utc = date_utc;
    this.weekday = weekday;
    this.device_worn_correctly_min = device_worn_correctly_min;
    this.device_not_worn_correctly_min = device_not_worn_correctly_min;
    this.device_not_recording_min = device_not_recording_min;
  }
}

export class Adherence {
  device_worn_correctly_min!: number;
  device_not_worn_correctly_min!: number;
  device_not_recording_min!: number;

  constructor(
    device_worn_correctly_min: number,
    device_not_worn_correctly_min: number,
    device_not_recording_min: number
  ) {
    this.device_worn_correctly_min = device_worn_correctly_min;
    this.device_not_worn_correctly_min = device_not_worn_correctly_min;
    this.device_not_recording_min = device_not_recording_min;
  }
}

export class UserAdherence {
  userId?: string;
  avg_device_worn_correctly?: number;
  adherence_items: Record<string, Adherence> = {};

  constructor(
    userId?: string,
    avg_device_worn_correctly?: number,
    adherence_items?: Record<string, Adherence>
  ) {
    this.userId = userId;
    this.avg_device_worn_correctly = avg_device_worn_correctly;
    this.adherence_items = adherence_items || {};
  }
}

// groupRecordsByUser is used to parse a list of multiple records for a single user into a grouped structure. It does so
// by mapping the original array into a Record (dict-based) structure where the key is the user ID and the value
// is the list of objects present initially
function groupRecordsByUser(
  data: Array<AdherenceCsvRow>
): Record<string, AdherenceCsvRow[]> {
  let groupedUsers: Record<string, AdherenceCsvRow[]> = {};
  data.forEach((record) => {
    if (record.user_id === null) {
      return;
    }

    if (groupedUsers[record.user_id] === undefined) {
      groupedUsers[record.user_id] = [];
    }

    groupedUsers[record.user_id].push(record);
  });
  return groupedUsers;
}

// parseCsv is used to parse an Array with the AdherenceCsvRow structure into another array of UserAdherence objects:
//
// Initial structure (Array of AdherenceCsvRow):
// [0] => user1,date_utc1,weekday1,device_worn_correctly_min1,device_not_worn_correctly_min1,device_not_recording_min1
// [1] => user1,date_utc2,weekday2,device_worn_correctly_min2,device_not_worn_correctly_min2,device_not_recording_min2
// [2] => user2,date_utc3,weekday3,device_worn_correctly_min3,device_not_worn_correctly_min3,device_not_recording_min3
// [3] => user2,date_utc4,weekday3,device_worn_correctly_min4,device_not_worn_correctly_min4,device_not_recording_min4
//
// Final structure (Array of UserAdherence):
// [0] => user 1 (UserAdherence object)
//        - avg_device_worn_correctly
//        - adherence_list (Record)
//          - [date_utc1]
//            - device_worn_correctly_min1
//            - device_not_worn_correctly_min1
//            - device_not_recording_min1
//          - [date_utc2]
//            - device_worn_correctly_min2
//            - device_not_worn_correctly_min2
//            - device_not_recording_min2
// ...
//
export function parseCsv(data: Array<AdherenceCsvRow>): Array<UserAdherence> {
  // group users into a dict structure
  const groupedUsers = groupRecordsByUser(data);
  const usersParsed = Array<UserAdherence>();

  // iterates over each user in order to generate the other fields
  for (let userKey in groupedUsers) {
    let userAdherence = new UserAdherence();
    userAdherence.userId = userKey;

    let currentUserRecords = groupedUsers[userKey];
    let wornCorrectlySum = 0;

    // iterate over each record for that given user, in order to retrieve all the adherence values over the week
    currentUserRecords.forEach((singleUserRecord) => {
      userAdherence.adherence_items[singleUserRecord.date_utc] = new Adherence(
        singleUserRecord.device_worn_correctly_min,
        singleUserRecord.device_not_worn_correctly_min,
        singleUserRecord.device_not_recording_min
      );

      wornCorrectlySum += singleUserRecord.device_worn_correctly_min;
    });

    userAdherence.avg_device_worn_correctly = wornCorrectlySum / 7;
    usersParsed.push(userAdherence);
  }

  return usersParsed;
}

export function getUsersWithNoData(processedData: Array<UserAdherence>) {
  const usersWithNoData = processedData.filter(
    (v) => v.adherence_items === undefined || v.avg_device_worn_correctly === 0
  );

  return usersWithNoData;
}
