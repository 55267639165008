import { LegendProps } from './Legend';
import i18next from 'i18next';
import LegendTealRectangle from '../../Icons/LegendTealRectangle.svg';
import LegendDarkGreyRectangle from '../../Icons/LegendDarkGreyRectangle.svg';
import LegendLightGreyRectangle from '../../Icons/LegendLightGreyRectangle.svg';

import LegendRedLine from '../../Icons/LegendRedLine.svg';
import LegendOrangeLine from '../../Icons/LegendOrangeLine.svg';

import LegendShortBlackDash from '../../Icons/LegendShortBlackDash.svg';
import LegendLongBlackDash from '../../Icons/LegendLongBlackDash.svg';

import LegendEdriHighRiskDetected from '../../Icons/LegendEdriHighRiskDetected.svg';
import LegendEdriHighRiskTemporary from '../../Icons/LegendEdriHighRiskTemporary.svg';
import LegendEdriLowRiskDetected from '../../Icons/LegendEdriLowRiskDetected.svg';
import LegendEdriLowRiskTemporary from '../../Icons/LegendEdriLowRiskTemporary.svg';
import LegendEdriNoData from '../../Icons/LegendEdriNoData.svg';

import LegendContainer from './LegendContainer';
import React from 'react';
import { ViewTypes } from './SubjectView';

export function renderComplianceLegend() {
  let legends = [
    new LegendProps(LegendTealRectangle, i18next.t('WORN_CORRECTLY')),
    new LegendProps(LegendDarkGreyRectangle, i18next.t('NOT_WORN_CORRECTLY')),
    new LegendProps(LegendLightGreyRectangle, i18next.t('NOT_CONNECTED')),
  ];

  return <LegendContainer items={legends} />;
}

export function renderRealtimeTemperatureLegend(viewType: ViewTypes) {
  let textRange = getTextForRange(viewType);

  let legends = [
    new LegendProps(LegendOrangeLine, i18next.t('MINUTE_VALUES')),
    new LegendProps(LegendShortBlackDash, textRange),
    new LegendProps(LegendLightGreyRectangle, i18next.t('NON_ADHERENCE')),
  ];

  return <LegendContainer items={legends} />;
}

export function renderRealtimePulseRateLegend(viewType: ViewTypes) {
  let textRange = getTextForRange(viewType);

  let legends = [
    new LegendProps(LegendRedLine, i18next.t('MINUTE_VALUES')),
    new LegendProps(LegendShortBlackDash, textRange),
    new LegendProps(LegendLightGreyRectangle, i18next.t('NON_ADHERENCE')),
  ];

  return <LegendContainer items={legends} />;
}

export function renderTrendTemperatureLegend(viewType: ViewTypes) {
  let textRange = getTextForRange(viewType);

  let legends = [
    new LegendProps(LegendShortBlackDash, i18next.t('TODAYS_MEDIAN')),
    new LegendProps(LegendLongBlackDash, textRange),
    new LegendProps(LegendLightGreyRectangle, i18next.t('NON_ADHERENCE')),
  ];

  return <LegendContainer items={legends} />;
}

export function renderTrendPulseRateLegend(viewType: ViewTypes) {
  let textRange = getTextForRange(viewType);

  let legends = [
    new LegendProps(LegendShortBlackDash, i18next.t('TODAYS_MEDIAN')),
    new LegendProps(LegendLongBlackDash, textRange),
    new LegendProps(LegendLightGreyRectangle, i18next.t('NON_ADHERENCE')),
  ];

  return <LegendContainer items={legends} />;
}

export function renderAuraEdriLegend() {
  let legends = [
    new LegendProps(
      LegendEdriHighRiskDetected,
      i18next.t('EDRI_HIGH_RISK_DETECTED')
    ),
    new LegendProps(
      LegendEdriHighRiskTemporary,
      i18next.t('EDRI_HIGH_RISK_TEMPORARY')
    ),
    new LegendProps(
      LegendEdriLowRiskDetected,
      i18next.t('EDRI_LOW_RISK_DETECTED')
    ),
    new LegendProps(
      LegendEdriLowRiskTemporary,
      i18next.t('EDRI_LOW_RISK_TEMPORARY')
    ),
    new LegendProps(LegendEdriNoData, i18next.t('EDRI_NO_DATA')),
  ];

  return <LegendContainer items={legends} />;
}

export function renderAuraTenDayViewLegend() {
  let legends = [
    new LegendProps(
      LegendEdriHighRiskDetected,
      i18next.t('EDRI_HIGH_RISK_DETECTED')
    ),
    new LegendProps(
      LegendEdriLowRiskDetected,
      i18next.t('EDRI_LOW_RISK_DETECTED')
    ),
    new LegendProps(LegendEdriNoData, i18next.t('EDRI_NO_RESULT')),
  ];

  return <LegendContainer items={legends} />;
}

function getTextForRange(range: string) {
  let textRange = '';

  switch (range) {
    case ViewTypes.view10d:
      textRange = i18next.t('LAST_10_DAYS_MEDIAN');
      break;
    case ViewTypes.view48h:
      textRange = i18next.t('YESTERDAYS_MEDIAN');
      break;
    case ViewTypes.view6h:
      textRange = i18next.t('LAST_6H_MEDIAN');
      break;
    case ViewTypes.view12h:
      textRange = i18next.t('LAST_12H_MEDIAN');
      break;
  }
  return textRange;
}
