import React from 'react';
import i18next from 'i18next';
import BaseErrorView from './BaseErrorView';

export default function NotFoundView(props: any) {
  const returnToIndex = () => {
    props.history.push('/');
  };

  return (
    <BaseErrorView
      errorCode={'404'}
      title={i18next.t('NOT_FOUND_TITLE')}
      subtitle={i18next.t('NOT_FOUND_SUBTITLE')}
      primaryAction={{
        label: i18next.t('NOT_FOUND_ACTION'),
        func: returnToIndex,
      }}
    />
  );
}
