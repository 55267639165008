import {
  ServerStatus,
  STATUS_AVAILABLE,
  STATUS_DEGRADED,
  STATUS_MAJOR_OUTAGE,
  STATUS_PARTIAL_OUTAGE,
  STATUS_UNAVAILABLE,
} from '../../Model/ServerStatus';

export const API_STATUS_BASE_URL = 'https://status.empatica.com';
const API_STATUS_URL = API_STATUS_BASE_URL + '/api/v2/components.json';

export const fetchServerStatus = async () => {
  return fetch(API_STATUS_URL)
    .then((resp) => resp.json())
    .then(function (data) {
      for (let element of data.components) {
        if (element.name === 'Research portal') {
          switch (element.status) {
            case 'operational':
              return new ServerStatus(STATUS_AVAILABLE);
            case 'degraded_performance':
              return new ServerStatus(STATUS_DEGRADED);
            case 'partial_outage':
              return new ServerStatus(STATUS_PARTIAL_OUTAGE);
            case 'major_outage':
              return new ServerStatus(STATUS_MAJOR_OUTAGE);
          }
          break;
        }
      }
      return new ServerStatus(STATUS_UNAVAILABLE);
    })
    .catch(function () {
      return new ServerStatus(STATUS_UNAVAILABLE);
    });
};
