import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { Trans } from 'react-i18next';
import RequestPasswordResetForm from './RequestPasswordResetForm';
import ResetPasswordForm from './ResetPasswordForm';

const RESET_PASSWORD_REQUEST_RETRIEVE_BASE_URL = process.env.REACT_APP_API_URL;
const RESET_PASSWORD_REQUEST_RETRIEVE_ENDPOINT = 'v3/resetPassword';
const RESET_PASSWORD_REQUEST_RETRIEVE_FULL_URL = `${RESET_PASSWORD_REQUEST_RETRIEVE_BASE_URL}/${RESET_PASSWORD_REQUEST_RETRIEVE_ENDPOINT}`;

enum ResetPasswordStatus {
  REQUESTING_RESET,
  RESET_REQUESTED,
  FETCHING_PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_REQUEST_INVALID,
  RESETTING_PASSWORD,
  PASSWORD_RESET,
}

export interface ResetPasswordProps {
  requestId?: string;
  toggleResetPassword?(): void;
}

export default function ResetPassword(props: ResetPasswordProps) {
  const [status, setStatus] = useState(
    props.requestId
      ? ResetPasswordStatus.FETCHING_PASSWORD_RESET_REQUEST
      : ResetPasswordStatus.REQUESTING_RESET
  );

  useEffect(() => {
    if (props.requestId) {
      fetch(`${RESET_PASSWORD_REQUEST_RETRIEVE_FULL_URL}/${props.requestId}`)
        .then((res) => {
          if (res.status >= 400) {
            throw new Error(res.statusText);
          }
          setStatus(ResetPasswordStatus.RESETTING_PASSWORD);
        })
        .catch(() => {
          setStatus(ResetPasswordStatus.PASSWORD_RESET_REQUEST_INVALID);
        });
    }
  }, [props.requestId]);

  const toggleResetPassword = () => {
    if (!props.toggleResetPassword) {
      return;
    }
    props.toggleResetPassword();
  };

  return (
    <Box>
      <Box mt={2} mb={2}>
        {status === ResetPasswordStatus.REQUESTING_RESET && (
          <RequestPasswordResetForm
            onSuccess={() => setStatus(ResetPasswordStatus.RESET_REQUESTED)}
          />
        )}
        {status === ResetPasswordStatus.RESET_REQUESTED && (
          <Typography>
            <Trans i18nKey="PASSWORD_RESET_CHECK_INBOX" />
          </Typography>
        )}
        {status === ResetPasswordStatus.FETCHING_PASSWORD_RESET_REQUEST && (
          <CircularProgress size="1.6rem" />
        )}
        {status === ResetPasswordStatus.PASSWORD_RESET_REQUEST_INVALID && (
          <Typography>
            <Trans i18nKey="PASSWORD_RESET_INVALID_LINK" />
          </Typography>
        )}
        {status === ResetPasswordStatus.RESETTING_PASSWORD &&
          props.requestId && (
            <ResetPasswordForm
              onSuccess={() => setStatus(ResetPasswordStatus.PASSWORD_RESET)}
              requestId={props.requestId}
            />
          )}
        {status === ResetPasswordStatus.PASSWORD_RESET && (
          <Typography>
            <Trans i18nKey="PASSWORD_RESET_SUCCESS" />
          </Typography>
        )}
      </Box>
      <Box>
        <Link
          component={RouterLink}
          to="/"
          onClick={toggleResetPassword}
          variant="body2"
        >
          <Trans i18nKey="BACK_TO_LOGIN" />
        </Link>
      </Box>
    </Box>
  );
}
