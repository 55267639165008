import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Logo from '../../Logo/Logo_outline.svg';
import { Trans } from 'react-i18next';
import { setTitleLoading } from './TitleUtils';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100vw',
    height: '100vh',
    background: '#00778b',
    color: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    zIndex: 1998,
    position: 'fixed',
    top: 0,
  },
  content: {
    width: '100vw',
    height: '100vh',
    background: '#00778b',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1999,
    position: 'fixed',
    top: 0,
  },
}));

export default function FullscreenLoading(props: any) {
  const classes = useStyles();
  const [content, setContent] = useState(<div></div>);

  useEffect(() => {
    setTitleLoading();
    if (props.content) {
      setContent(<div className={classes.content}>{props.content}</div>);
    }
  }, [props.content, classes]);

  return (
    <div>
      <div className={classes.wrapper}>
        <div>
          <div>
            <img src={Logo} alt="Empatica Care" />
          </div>
          <h2>
            <Trans i18nKey="LOADING_DOTS" />
          </h2>
        </div>
      </div>
      <div>{content}</div>
    </div>
  );
}
