import { ModalBasePropAction } from '../../Modals/ModalBase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import ModalInfo from '../../Modals/ModalInfo';
import i18next from 'i18next';
import React from 'react';

export default function AdherenceParseErrorView() {
  function reloadPage() {
    window.location.reload();
  }

  const action = new ModalBasePropAction();
  action.label = i18next.t('REPORT_ADHERENCE_PARSE_ERROR_RELOAD');
  action.func = reloadPage;

  const iconError = (
    <FontAwesomeIcon
      icon={faTimesCircle}
      style={{ width: '240px', height: '240px' }}
    />
  );

  return (
    <ModalInfo
      open={true}
      icon={iconError}
      title={i18next.t('REPORT_ADHERENCE_PARSE_ERROR_LOAD_TITLE')}
      content={i18next.t('REPORT_ADHERENCE_PARSE_ERROR_LOAD_CONTENT')}
      primaryAction={action}
    />
  );
}
