import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import i18next from 'i18next';
import Logo from '../../Logo/Logo_v.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import LogoWhite from '../../Logo/empatica-logo.svg';
import LoginForm from './LoginForm';
import ResetPassword from './ResetPassword';
import {
  API_STATUS_BASE_URL,
  fetchServerStatus,
} from '../../Http/StatusService/StatusService';
import { setCustomTitle } from '../Utils/TitleUtils';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    margin: theme.spacing(1),
    height: 160,
  },
  footer: {
    textAlign: 'center',
    backgroundColor: '#00778C',
    color: 'white',
    bottom: '0px',
    position: 'absolute',
    width: '100%',
  },
  footerText: {
    fontSize: '9px',
    padding: '20px',
  },
  footerMainText: {
    marginLeft: '3px',
    marginRight: '3px',
  },
  link: {
    color: '#ffffff',
    textDecoration: 'none',
    '&:hover': {
      color: '#ffffff',
      textDecoration: 'underline',
    },
    '&:focus': {
      color: '#ffffff',
      textDecoration: 'underline',
    },
    '&:visited': {
      color: '#ffffff',
    },
  },
}));

export class LoginViewProps {
  onSuccess?: Function;
}

export default function LoginView(props: LoginViewProps) {
  const year = new Date().getFullYear();
  const resettingPasswordRouteMatch = useRouteMatch<{ requestId?: string }>(
    '/reset-password/:requestId'
  );
  const requestId = resettingPasswordRouteMatch?.params.requestId;
  const [resetPassword, setResetPassword] = useState(!!requestId);
  const [operationalStatusText, setOperationalStatusText] = useState('');
  const [circleColor, setCircleColor] = useState('lightgreen');

  useEffect(() => {
    setCustomTitle(i18next.t('TITLE_LOGIN'));
  });

  const toggleResetPassword = () => {
    setResetPassword(!resetPassword);
  };

  fetchServerStatus().then(function (response) {
    setOperationalStatusText(response.getPrettyStatus());
    setCircleColor(response.getColor());
  });

  const classes = useStyles();
  return (
    <div>
      <Container component="main" maxWidth="xs">
        <div className={classes.wrapper}>
          <div>
            <img
              src={Logo}
              alt={i18next.t('APP_NAME')}
              className={classes.logo}
            />
          </div>
          {!resetPassword && (
            <LoginForm
              toggleResetPassword={toggleResetPassword}
              onSuccess={props.onSuccess}
            />
          )}
          {resetPassword && (
            <ResetPassword
              toggleResetPassword={toggleResetPassword}
              requestId={requestId}
            />
          )}
        </div>
      </Container>
      <footer className={classes.footer}>
        <p className={classes.footerText}>
          <span>
            <FontAwesomeIcon icon={faCircle} color={circleColor} />
          </span>
          <span className={classes.footerMainText}>
            {i18next.t(operationalStatusText)}{' '}
            <a
              className={classes.link}
              href={API_STATUS_BASE_URL}
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              View more
            </a>
            <span>. © {year} Empatica Inc - All rights reserved |</span>
          </span>
          <span>
            <img
              src={LogoWhite}
              alt={i18next.t('APP_NAME')}
              height="14px"
              style={{ verticalAlign: 'middle' }}
            />
          </span>
        </p>
      </footer>
    </div>
  );
}
