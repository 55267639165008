import { Biomarker } from './Biomarker';

export const BiomarkerComplianceDefaultDelta = 10;
export const BiomarkerComplianceDefault = 0;
export const BiomarkerComplianceMin = 0;
export const BiomarkerComplianceMax = 100;
export const MinutesInHour = 60;
export const MinutesInSixHours = 3600;
export const MinutesInTwelveHours = 7200;
export const MinutesInDay = 1440;

export const BiomarkerComplianceComplianceThreshold = 75;
export const ComplianceToleranceForAggregationHourly = 25;

export default class Compliances extends Biomarker {
  complianceHourlyArrays: Array<number[]>;
  complianceDailyArrays: Array<Object | null>;
  complianceRealtimeArrays: Array<Object | null>;
  onWristTimeLastSixHours: [string, string];
  onWristTimeLastTwelveHours: [string, string];
  onWristTimeToday: [string, string];
  onWristTimeYesterday: [string, string];
  onWristTimeAverageLast10days: [string, string];

  constructor(
    from?: number,
    to?: number,
    tz?: number,
    values?: Array<number | null>,
    compliance?: Array<number | null>,
    precision?: string
  ) {
    super(from, to, tz, values, compliance, precision);
    this.complianceHourlyArrays = this.computeComplianceHourlyArrays(
      this.getComplianceMinuteValues()
    );
    this.complianceDailyArrays = this.computeComplianceDailyArrays(
      this.getComplianceMinuteValues()
    );
    this.onWristTimeLastSixHours = this.computeOnWristTimeLastSixHours(
      this.getPrecisionValues('minute')
    );
    this.onWristTimeLastTwelveHours = this.computeOnWristTimeLastTwelveHours(
      this.getPrecisionValues('minute')
    );
    this.onWristTimeToday = this.computeOnWristTimeToday(
      this.extractTodayValues(this.from, this.getPrecisionValues('minute'))
    );
    this.onWristTimeYesterday = this.computeOnWristTimeYesterday(
      this.extractYesterdayValues(this.from, this.getPrecisionValues('minute'))
    );
    this.onWristTimeAverageLast10days = this.computeOnWristTimeAverageLast10days(
      this.getPrecisionValues('minute')
    );
    this.complianceRealtimeArrays = this.getCompliancesForRealtimeChart(
      this.getComplianceMinuteValues()
    );
  }

  processValue(value?: number): number | null {
    if (value == null) return null;
    return Math.round(value);
  }

  complianceThreshold(): number {
    return BiomarkerComplianceComplianceThreshold;
  }

  computeComplianceHourlyArrays(
    complianceMinute: Array<number | null>
  ): Array<number[]> {
    let complianceCompliantValues = [];
    let complianceNonCompliantValues = [];
    let complianceNullValues = [];
    for (let i = 0; i < complianceMinute.length - 1; i += MinutesInHour) {
      const subset = complianceMinute.slice(i, i + MinutesInHour);
      if (subset.length > 0) {
        let numberOfCompliantValues = 0;
        let numberOfNonCompliantValues = 0;
        let numberOfNullValues = 0;
        for (let j = 0; j < MinutesInHour; j += 1) {
          let v = subset[j];
          if (v == null) {
            numberOfNullValues += 1;
          } else if (v! < BiomarkerComplianceComplianceThreshold) {
            numberOfNonCompliantValues += 1;
          } else {
            numberOfCompliantValues += 1;
          }
        }
        complianceCompliantValues.push(numberOfCompliantValues);
        complianceNonCompliantValues.push(numberOfNonCompliantValues);
        complianceNullValues.push(numberOfNullValues);
      }
    }
    // For empty state, edge case.
    const compliantValuesAreAllZero = complianceCompliantValues.every(
      (item) => item === 0
    );
    const nonCompliantValuesAreAllZero = complianceNonCompliantValues.every(
      (item) => item === 0
    );
    if (compliantValuesAreAllZero && nonCompliantValuesAreAllZero) {
      complianceNullValues = new Array(49).fill(0);
    }

    return [
      complianceCompliantValues,
      complianceNonCompliantValues,
      complianceNullValues,
    ];
  }

  computeComplianceDailyArrays(
    complianceMinute: Array<number | null>
  ): Array<Object> {
    let complianceCompliantValues = [];
    let complianceNonCompliantValues = [];
    let complianceNullValues = [];
    for (let i = 0; i < complianceMinute.length - 1; i += MinutesInDay) {
      const subset = complianceMinute.slice(i, i + MinutesInDay);

      if (subset.length > 0) {
        let numberOfCompliantValues = 0;
        let numberOfNonCompliantValues = 0;
        let numberOfNullValues = 0;
        for (let j = 0; j < MinutesInDay; j += 1) {
          let v = subset[j];
          if (v == null) {
            numberOfNullValues += 1;
          } else if (v! < BiomarkerComplianceComplianceThreshold) {
            numberOfNonCompliantValues += 1;
          } else {
            numberOfCompliantValues += 1;
          }
        }
        complianceCompliantValues.push(numberOfCompliantValues / 60);
        complianceNonCompliantValues.push(numberOfNonCompliantValues / 60);
        complianceNullValues.push(numberOfNullValues / 60);
      }
    }

    // For empty state, edge case.
    const compliantValuesAreAllZero = complianceCompliantValues.every(
      (item) => item === 0
    );
    const nonCompliantValuesAreAllZero = complianceNonCompliantValues.every(
      (item) => item === 0
    );
    if (compliantValuesAreAllZero && nonCompliantValuesAreAllZero) {
      complianceNullValues = new Array(10).fill(0);
    }

    return [
      complianceCompliantValues,
      complianceNonCompliantValues,
      complianceNullValues,
    ];
  }

  computeOnWristTimeLastSixHours(
    complianceMinute: Array<number | null>
  ): [string, string] {
    let numberOfCompliantValues = 0;
    for (let i = 0; i < complianceMinute.length; i += MinutesInSixHours) {
      const subset = complianceMinute.slice(i, i + MinutesInSixHours);

      if (subset.length > 0) {
        for (let j = 0; j < MinutesInSixHours; j += 1) {
          if (subset[j] == null) {
            continue;
          } else if (subset[j]! >= BiomarkerComplianceComplianceThreshold) {
            numberOfCompliantValues += 1;
          }
        }
      }
    }
    let minutes = '';
    if (numberOfCompliantValues % 60 < 10) {
      minutes = '0' + Math.floor(numberOfCompliantValues % 60).toString();
    } else {
      minutes = Math.floor(numberOfCompliantValues % 60).toString();
    }
    let hours = Math.floor(numberOfCompliantValues / 60).toString();
    return [hours, minutes];
  }

  computeOnWristTimeLastTwelveHours(
    complianceMinute: Array<number | null>
  ): [string, string] {
    let numberOfCompliantValues = 0;
    for (let i = 0; i < complianceMinute.length; i += MinutesInTwelveHours) {
      const subset = complianceMinute.slice(i, i + MinutesInTwelveHours);

      if (subset.length > 0) {
        for (let j = 0; j < MinutesInTwelveHours; j += 1) {
          if (subset[j] == null) {
            continue;
          } else if (subset[j]! >= BiomarkerComplianceComplianceThreshold) {
            numberOfCompliantValues += 1;
          }
        }
      }
    }
    let minutes = '';
    if (numberOfCompliantValues % 60 < 10) {
      minutes = '0' + Math.floor(numberOfCompliantValues % 60).toString();
    } else {
      minutes = Math.floor(numberOfCompliantValues % 60).toString();
    }
    let hours = Math.floor(numberOfCompliantValues / 60).toString();
    return [hours, minutes];
  }

  computeOnWristTimeToday(
    todaysValues: Array<number | null>
  ): [string, string] {
    let numberOfCompliantValues = 0;
    for (let i = 0; i < todaysValues.length; i += MinutesInDay) {
      const subset = todaysValues.slice(i, i + MinutesInDay);

      if (subset.length > 0) {
        for (let j = 0; j < MinutesInDay; j += 1) {
          if (subset[j] == null) {
            continue;
          } else if (subset[j]! >= BiomarkerComplianceComplianceThreshold) {
            numberOfCompliantValues += 1;
          }
        }
      }
    }
    let minutes = '';
    if (numberOfCompliantValues % 60 < 10) {
      minutes = '0' + Math.floor(numberOfCompliantValues % 60).toString();
    } else {
      minutes = Math.floor(numberOfCompliantValues % 60).toString();
    }
    let hours = Math.floor(numberOfCompliantValues / 60).toString();
    return [hours, minutes];
  }

  computeOnWristTimeYesterday(
    yesterdayCompliances: Array<number | null>
  ): [string, string] {
    let numberOfCompliantValues = 0;
    for (let i = 0; i < yesterdayCompliances.length; i += MinutesInDay) {
      const subset = yesterdayCompliances.slice(i, i + MinutesInDay);

      if (subset.length > 0) {
        for (let j = 0; j < MinutesInDay; j += 1) {
          if (subset[j] == null) {
            continue;
          } else if (subset[j]! >= BiomarkerComplianceComplianceThreshold) {
            numberOfCompliantValues += 1;
          }
        }
      }
    }
    let hours = Math.floor(numberOfCompliantValues / 60).toString();
    let minutes = '';
    if (numberOfCompliantValues % 60 < 10) {
      minutes = '0' + (numberOfCompliantValues % 60).toString();
    } else {
      minutes = (numberOfCompliantValues % 60).toString();
    }

    return [hours, minutes];
  }

  computeOnWristTimeAverageLast10days(
    complianceMinutes: Array<number | null>
  ): [string, string] {
    let numberOfCompliantValues = 0;
    for (let i = 0; i < complianceMinutes.length; i += MinutesInDay) {
      const subset = complianceMinutes.slice(i, i + MinutesInDay);

      if (subset.length > 0) {
        for (let j = 0; j < MinutesInDay; j += 1) {
          if (subset[j] == null) {
            continue;
          } else if (subset[j]! >= BiomarkerComplianceComplianceThreshold) {
            numberOfCompliantValues += 1;
          }
        }
      }
    }
    numberOfCompliantValues = numberOfCompliantValues / 10;
    let hours = Math.floor(numberOfCompliantValues / 60).toString();
    let minutes = '';
    if (numberOfCompliantValues % 60 < 10) {
      minutes = '0' + Math.floor(numberOfCompliantValues % 60).toString();
    } else {
      minutes = Math.floor(numberOfCompliantValues % 60).toString();
    }

    return [hours, minutes];
  }

  getCompliancesForRealtimeChart(
    complianceMinute: Array<number | null>
  ): Array<Object> {
    let compliantValues: any[] = [];
    let nonCompliantValues: any[] = [];
    let noDataValues: any[] = [];
    for (var i = 0; i < complianceMinute.length; i++) {
      compliantValues[i] = BiomarkerComplianceDefault;
      nonCompliantValues[i] = BiomarkerComplianceDefault;
      noDataValues[i] = BiomarkerComplianceDefault;

      if (complianceMinute[i] == null) {
        compliantValues[i] = null;
        nonCompliantValues[i] = null;
        noDataValues[i] = 100;
      } else if (
        complianceMinute[i]! >= BiomarkerComplianceComplianceThreshold
      ) {
        compliantValues[i] = 100;
        nonCompliantValues[i] = null;
        noDataValues[i] = null;
      } else if (
        complianceMinute[i]! < BiomarkerComplianceComplianceThreshold
      ) {
        compliantValues[i] = null;
        nonCompliantValues[i] = 100;
        noDataValues[i] = null;
      }
    }
    return [compliantValues, nonCompliantValues, noDataValues];
  }
}
