import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    width: '100vw',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  left: {
    display: 'grid',
    backgroundColor: '#00778b',
    color: '#ffffff',
    fontFamily: 'Inconsolata',
    fontSize: '240px',
    textAlign: 'center',
    alignItems: 'center',
  },
  right: {
    display: 'grid',
    alignItems: 'center',
    backgroundColor: '#f5f8f9',
    padding: theme.spacing(8),
  },
  content: {
    textAlign: 'left',
  },
  action: {
    position: 'absolute',
    bottom: theme.spacing(8),
    right: theme.spacing(4),
  },
  extra: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export class ErrorPropAction {
  label!: string;
  func!: any;
}

export class BaseErrorProps {
  errorCode!: string;
  title!: string;
  subtitle!: string;
  extra?: JSX.Element;
  primaryAction?: ErrorPropAction;
}

const getActionBtn = (
  pa: ErrorPropAction | undefined,
  color: 'primary' | 'default' | 'inherit' | 'secondary' | undefined
): JSX.Element | null => {
  if (!pa) {
    return null;
  }
  const variant = color === 'primary' ? 'contained' : undefined;
  return (
    <Button type="button" color={color} variant={variant} onClick={pa.func}>
      {pa.label}
    </Button>
  );
};

export default function BaseErrorView(props: BaseErrorProps) {
  const classes = useStyles();

  const primaryActionBtn = getActionBtn(props.primaryAction, 'primary');

  return (
    <React.Fragment>
      <Grid container className={classes.container}>
        <Grid item xs={6} className={classes.left}>
          <Box>{props.errorCode}</Box>
        </Grid>
        <Grid item xs={6} className={classes.right}>
          <Box className={classes.content}>
            <h1>{props.title}</h1>
            <Box className="subtitle">{props.subtitle}</Box>

            <Box className={classes.extra}>{props.extra}</Box>
          </Box>
        </Grid>
      </Grid>

      <Box className={classes.action}>{primaryActionBtn}</Box>
    </React.Fragment>
  );
}
