import React from 'react';
import { Tooltip } from '@material-ui/core';
import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import {
  getTimezoneDifferenceHours,
  getTimezoneRepresentationString,
} from '../Utils/TimezoneUtils';
import { makeStyles } from '@material-ui/core/styles';

export class SubjectViewTimezoneInfoProps {
  offsetInSeconds?: number;
}

const useStyles = makeStyles((theme) => ({
  timezoneInfoContainer: {
    marginLeft: '10px',
    fontSize: '12px',
    color: 'grey',
  },
  timezoneIcon: {
    width: '0.9rem',
    height: '0.9rem',
    verticalAlign: 'middle',
    marginRight: '0.3rem',
  },
}));

// makeTimezoneTextForTooltip generates the text for the tooltip component
function makeTimezoneTextForTooltip(
  localOffsetInSeconds: number,
  offsetInSeconds: number
) {
  const timezoneRepresentation = getTimezoneRepresentationString(
    localOffsetInSeconds,
    offsetInSeconds
  );
  const timezoneDifference = getTimezoneDifferenceHours(
    localOffsetInSeconds,
    offsetInSeconds
  );

  const initText = i18next.t('TIMEZONE_WARNING_TEXT_NAVBAR_ONE');
  const endText = i18next.t('TIMEZONE_WARNING_TEXT_NAVBAR_TWO');
  return `${initText} (${timezoneRepresentation}): ${timezoneDifference} ${endText}`;
}

// makeTimezoneTextForTooltip generates the text for the warning component
function makeTimezoneTextForWarning(
  localOffsetInSeconds: number,
  offsetInSeconds: number
) {
  const timezoneRepresentation = getTimezoneRepresentationString(
    localOffsetInSeconds,
    offsetInSeconds
  );
  const timezoneDifference = getTimezoneDifferenceHours(
    localOffsetInSeconds,
    offsetInSeconds
  );

  const initText = i18next.t('SUBJECT_VIEW_USER_TIMEZONE_WARNING');
  return `${initText} ${timezoneRepresentation} (${timezoneDifference})`;
}

export default function SubjectViewTimezoneInfo(
  props: SubjectViewTimezoneInfoProps
) {
  const classes = useStyles();
  let localOffsetInSeconds = new Date().getTimezoneOffset() * 60 * -1;

  if (localOffsetInSeconds === props.offsetInSeconds) {
    return <span></span>;
  }

  let tooltipText = '';
  let warningText = '';

  if (props.offsetInSeconds !== null && props.offsetInSeconds !== undefined) {
    tooltipText = makeTimezoneTextForTooltip(
      localOffsetInSeconds,
      props.offsetInSeconds
    );
    warningText = makeTimezoneTextForWarning(
      localOffsetInSeconds,
      props.offsetInSeconds
    );
  }

  return (
    <Tooltip
      title={
        <React.Fragment>
          <span>
            <span>{tooltipText}</span>
          </span>
        </React.Fragment>
      }
      placement="bottom-start"
    >
      <span>
        <span className={classes.timezoneInfoContainer}>
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className={classes.timezoneIcon}
          />
          <span>{warningText}</span>
        </span>
      </span>
    </Tooltip>
  );
}
