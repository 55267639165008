import React, { useState, useMemo, ChangeEvent, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import SubjectListItem from './SubjectListItem';
import SubjectListHeader from './SubjectListHeader';
import Subject, {
  getSortableList,
  getUnsortableList,
  SubjectSortByStatus,
  SubjectStatus,
  subjectStatusOrder,
} from '../../Model/Subject';
import {
  SubjectSortByInternalId,
  SubjectSortByTemperature,
  SubjectSortByPulseRate,
  SubjectSortByLastSync,
} from '../../Model/Subject';
import LoadState from '../../Model/LoadState';
import Site from '../../Model/Site';
import { UserLatestBiomarkersMap } from '../../Model/Biomarker';
import {
  SortInfo,
  SortDirectionAsc,
  SortDirectionDesc,
} from '../AdherenceReportView/SortUtils';

const useStyles = makeStyles((theme) => ({
  subjectList: {
    paddingBottom: theme.spacing(8),
  },
  paginationWrapper: {
    display: 'block',
    margin: 'auto',
    textAlign: 'center',
    padding: theme.spacing(2),
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: 0,
    backgroundColor: '#FAFAFA',
    borderTop: '1px solid #E4E7EB;',
  },
  pagination: {
    display: 'inline-block',
    margin: 'auto',
  },
}));

export interface SubjectListProps {
  list: Subject[];
  latestBiomarkers: UserLatestBiomarkersMap;
  display: Record<string, boolean>;
  subjectsLoadState: LoadState;
  biomarkersLoadState: LoadState;
  site: Site;
}

export default function SubjectList({
  list,
  latestBiomarkers,
  display,
  subjectsLoadState,
  biomarkersLoadState,
  site,
}: SubjectListProps) {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState<SortInfo>({
    by: 'internalId',
    direction: SortDirectionAsc,
  });
  const pageSize = 10;

  const sortList = useCallback(() => {
    // Prevent sort by biomarkers if the biomarkers have not loaded yet
    if (sort.by !== SubjectSortByInternalId && biomarkersLoadState.isLoading) {
      return list;
    }

    let sortableList: Subject[] = getSortableList(
      list,
      latestBiomarkers,
      sort.by
    );
    let unsortableList: Subject[] = getUnsortableList(
      list,
      latestBiomarkers,
      sort.by
    );
    const sorted = sortableList.sort((a: Subject, b: Subject) => {
      let revertCoefficient = 1;
      if (sort.direction === SortDirectionDesc) {
        revertCoefficient = -1;
      }
      let sortValue = 0;
      switch (sort.by) {
        case SubjectSortByTemperature:
          const aTemp = latestBiomarkers[a.userId]?.temperature || 0;
          const bTemp = latestBiomarkers[b.userId]?.temperature || 0;
          sortValue = aTemp - bTemp;
          break;
        case SubjectSortByPulseRate:
          const aHr = latestBiomarkers[a.userId]?.pulseRate || 0;
          const bHr = latestBiomarkers[b.userId]?.pulseRate || 0;
          sortValue = aHr - bHr;
          break;
        case SubjectSortByLastSync:
          const aCompliance = latestBiomarkers[a.userId]?.timestamp || 0;
          const bCompliance = latestBiomarkers[b.userId]?.timestamp || 0;
          sortValue = bCompliance - aCompliance;
          break;
        case SubjectSortByInternalId:
          const aInternalId = a.internalId || '';
          const bInternalId = b.internalId || '';
          if (aInternalId < bInternalId) {
            sortValue = -1;
          } else {
            sortValue = 1;
          }
          break;
        case SubjectSortByStatus:
          const aStatus = a.status || '';
          const bStatus = b.status || '';
          sortValue =
            subjectStatusOrder.indexOf(aStatus as SubjectStatus) -
            subjectStatusOrder.indexOf(bStatus as SubjectStatus);
          break;
      }
      return sortValue * revertCoefficient;
    });

    return [...sorted, ...unsortableList];
  }, [
    biomarkersLoadState.isLoading,
    latestBiomarkers,
    list,
    sort.by,
    sort.direction,
  ]);

  const subjectsPage = useMemo(() => {
    const sortedList = sortList();

    const from = (currentPage - 1) * pageSize;
    const to = currentPage * pageSize;
    return sortedList.slice(from, to);
  }, [currentPage, sortList]);

  const pageCount = useMemo(() => Math.ceil(list.length / pageSize), [
    list.length,
  ]);

  const onPageChange = (evt: ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const onSortChange = (newSort: SortInfo) => {
    setSort(newSort);
  };

  return (
    <div className={classes.subjectList}>
      <Grid container spacing={0}>
        <Grid item xs={12} key="subject-list-header">
          <div>
            <SubjectListHeader
              sortInfo={sort}
              display={display}
              onChange={onSortChange}
              biomarkersLoadState={biomarkersLoadState}
            />
          </div>
        </Grid>
        {subjectsLoadState.isLoading ? (
          <Box>Loading</Box>
        ) : (
          subjectsPage.map((s, i) => (
            <Grid item xs={12} key={`subject-${s.id}`} id={`subject-${s.id}`}>
              <SubjectListItem
                subject={s}
                latestBiomarkers={latestBiomarkers[s.userId]}
                site={site}
                display={display}
                biomarkersLoadState={biomarkersLoadState}
                last={i === subjectsPage.length - 1}
              />
            </Grid>
          ))
        )}
      </Grid>
      {!subjectsLoadState.isLoading && (
        <Box className={classes.paginationWrapper}>
          <Box className={classes.pagination}>
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={onPageChange}
              color="primary"
              showFirstButton
              showLastButton
            />
          </Box>
        </Box>
      )}
    </div>
  );
}
