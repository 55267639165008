import React, { FormEventHandler, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ERROR_API, logger } from '../../Logging/Logger';
import { ApiLogEntry, ApiRequest } from '../../Model/LogEntry';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export interface RequestPasswordResetFormProps {
  onSuccess?: Function;
}

function validateEmail(email: string) {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const REQUEST_PASSWORD_RESET_BASE_URL = process.env.REACT_APP_API_URL;
const REQUEST_PASSWORD_RESET_ENDPOINT = 'v3/resetPassword';
const REQUEST_PASSWORD_RESET_FULL_URL = `${REQUEST_PASSWORD_RESET_BASE_URL}/${REQUEST_PASSWORD_RESET_ENDPOINT}?app=care`;

export default function RequestPasswordResetForm(
  props: RequestPasswordResetFormProps
) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailHasError, setEmailHasError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState('');

  const updateEmail = (email: string) => {
    setEmail(email);
    if (validateEmail(email)) {
      setEmailHelperText('');
      setEmailHasError(false);
    } else {
      setEmailHelperText(i18next.t('INVALID_EMAIL_ERROR_MESSAGE'));
      setEmailHasError(true);
    }
  };

  function handleAuthorizationFailure() {
    setEmailHasError(true);
    setLoading(false);
  }

  function handleServerError(error: any) {
    // log entry to ElasticSearch
    if (error != null && error.response != null) {
      let apiRequest = new ApiRequest(
        REQUEST_PASSWORD_RESET_BASE_URL ? REQUEST_PASSWORD_RESET_BASE_URL : '',
        'post',
        REQUEST_PASSWORD_RESET_ENDPOINT
      );
      let logEntry = new ApiLogEntry(error.response.status, apiRequest);
      logger.error(ERROR_API, logEntry);
    }

    setEmailHasError(true);
    setLoading(false);
  }

  function cleanupForm() {
    updateEmail(email);
    setLoading(true);
  }

  const handleSubmit: FormEventHandler<HTMLFormElement> = (evt) => {
    evt.preventDefault();
    cleanupForm();

    fetch(REQUEST_PASSWORD_RESET_FULL_URL, {
      method: 'post',
      body: JSON.stringify({ email: email.trim() }),
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((groupedResponse) => {
        switch (groupedResponse.status) {
          case 200:
            if (props.onSuccess) {
              props.onSuccess(groupedResponse.body);
            }
            break;
          case 401:
            handleAuthorizationFailure();
            break;
          default:
            throw Object.assign(new Error('Unprocessable response'), {
              response: groupedResponse,
            });
        }
      })
      .catch(function (err) {
        handleServerError(err);
      });
  };

  const classes = useStyles();
  return (
    <form className={classes.form} onSubmit={handleSubmit} noValidate>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label={i18next.t('EMAIL')}
        name="email"
        autoComplete="email"
        value={email}
        error={emailHasError}
        helperText={emailHelperText}
        onChange={(e) => {
          updateEmail(e.target.value);
        }}
        autoFocus
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={loading || !email || emailHasError}
      >
        {!loading && <Trans i18nKey="SEND_RESET_LINK" />}
        {loading && <CircularProgress size="1.6rem" />}
      </Button>
    </form>
  );
}
