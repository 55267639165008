import Api from '../../../Http/Api';
import Timezone from '../../../Model/Timezone';

export class ApiResponseUserTimezones {
  data!: Timezone[];
}

export class ApiResponseUserTimezone {
  data!: Timezone;
}

export function GetUserTimezones(
  userId: number,
  from: number,
  to: number
): Promise<ApiResponseUserTimezones> {
  const url = `${process.env.REACT_APP_API_URL}/usage-service/users/${userId}/timezones?from=${from}&to=${to}`;
  return Api.get(url);
}

export function GetUserLatestTimezone(
  userId: number
): Promise<ApiResponseUserTimezone> {
  const url = `${process.env.REACT_APP_API_URL}/usage-service/users/${userId}/timezones/latest`;
  return Api.get(url);
}
