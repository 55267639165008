import Subject from '../../Model/Subject';
import * as BiomarkersService from '../../Http/BiomarkersService';
import {
  ApiResponseAuraForUsers,
  AuraForUsersPayloadRequest,
  AuraForUsersSingleUserPayloadRequest,
} from '../../Http/BiomarkersService';
import Timerange from '../../Model/Timerange';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

// the appendAuraValuesIntoOriginalSubjects method is used to append the fetched list of the AURA values
// into the existing original subjects one
// originalSubjects: the list containing all the subjects in this given site
// userIdsToFetch: the list containing the user ids to fetch and preprocess the data
// apiResponse: the AURA values response received
function appendAuraValuesIntoOriginalSubjects(
  originalSubjects: Subject[],
  userIdsToFetch: number[],
  apiResponse: ApiResponseAuraForUsers
): Subject[] {
  let listFetchedSubjects = Array<Subject>();

  // 1) Iterate over the original subjects array
  // 2) Check if the current user ID is present in the userIdsToFetch param
  //  2.1) If yes, we set the auraEdriValues field with the fetched AURA values and set the flag `isLoadedAuraValues` to true
  //  2.2) If not, nothing changes for that given subject
  // 3) Add that subject into the `listFetchedSubjects` list, which will be our return one
  originalSubjects.forEach((subject, index) => {
    let currentSubjectId = subject.userId;
    let isInCurrentBatch = userIdsToFetch.includes(currentSubjectId);

    if (isInCurrentBatch) {
      let auraValuesForUser = apiResponse.data.users[currentSubjectId];
      subject.auraEdriValues = auraValuesForUser;
      subject.isLoadedAuraValues = true;
    }

    listFetchedSubjects.push(subject);
  });

  return listFetchedSubjects;
}

// fetchAuraForUsers is used to fetch the list of the AURA values for a list of users
// This method will the return the original list of subjects with the updated user ids that were
// provided over the userIds parameter
// userIds: an array containing the id of the users to fetch
// originalSubjects: an array containing the original list of Subjects which will be used to return
// timerange: which timerange are we fetching data (10d, 1m, etc)
export async function fetchAuraForUsers(
  userIds: number[],
  originalSubjects: Subject[],
  timerange: Timerange
): Promise<Subject[]> {
  //TODO(rb): fetch timezones from usage service
  const tzOffset = dayjs().utcOffset() * 60;
  let timezones = new Array(userIds.length).fill(tzOffset);
  let users: { [key: number]: AuraForUsersSingleUserPayloadRequest } = {};

  // create payload for each user taking into consideration the userID and its timezone
  userIds.forEach((value: number, index: number) => {
    let userPayload = new AuraForUsersSingleUserPayloadRequest();
    userPayload.timezone = timezones[index];

    users[value] = userPayload;
  });

  let payload = new AuraForUsersPayloadRequest();

  const fromDay = dayjs(timerange.from).format('YYYY-MM-DD');
  const toDay = dayjs(timerange.to).subtract(1, 'd').format('YYYY-MM-DD');

  payload.fromDay = fromDay;
  payload.toDay = toDay;
  payload.users = users;

  return BiomarkersService.GetAuraEdriForUsers(payload).then((apiResponse) => {
    let subjectWithAuraValues = appendAuraValuesIntoOriginalSubjects(
      originalSubjects,
      userIds,
      apiResponse
    );
    return Promise.resolve(subjectWithAuraValues);
  });
}
