import Organization from './Organization';
import ResearchConfiguration from './ResearchConfiguration';

export default class Study {
  id!: number;
  internalId!: string;
  name!: string;
  organization?: Organization;
  researchConfiguration?: Array<ResearchConfiguration>;

  constructor(s?: any) {
    if (!s) {
      this.id = 0;
      this.internalId = '-';
      this.name = '-';
      return;
    }
    this.id = s.id || 0;
    this.internalId = s.internalId || '-';
    this.name = s.name || '-';
    this.organization = new Organization(s.organization);
    this.researchConfiguration = s.researchConfiguration || [];
  }
}
