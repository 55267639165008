export const LANGUAGE_EN = 'en';
export const LOCALE_DEFAULT = 'en';
export const LOCALE_EN_US = 'en-US';
export const MEASUREMENT_UNIT_CELSIUS = 'celsius';
export const MEASUREMENT_UNIT_FAHRENHEIT = 'fahrenheit';
export const TIME_FORMAT_DEFAULT = 'HH:mm';
export const TIME_FORMAT_US = 'hh:mm a';
export const DATE_FORMAT_DEFAULT = 'DD/MM/YYYY';
export const DATE_FORMAT_US = 'MM/DD/YYYY';

export default class UserConfig {
  locale!: string;
  language!: string;
  timeFormat!: string;
  dateFormat!: string;
  measurementUnits!: UserConfigMeasurementUnits;

  constructor(locale?: string | undefined | null) {
    if (!locale) {
      locale = LOCALE_DEFAULT;
    }
    this.locale = locale;
    this.language = this.getLocaleLanguage(locale);
    this.timeFormat = this.getLocaleTimeFormat(locale);
    this.dateFormat = this.getLocaleDateFormat(locale);
    this.measurementUnits = new UserConfigMeasurementUnits(locale);
  }

  getLocaleLanguage(locale: string): string {
    let split = locale.split('_');
    if (!split || split.length === 0) {
      return LANGUAGE_EN;
    }
    return split[0];
  }

  getLocaleTimeFormat(locale: string): string {
    if (locale === LOCALE_EN_US) {
      return TIME_FORMAT_US;
    }
    return TIME_FORMAT_DEFAULT;
  }

  getLocaleDateFormat(locale: string): string {
    if (locale === LOCALE_EN_US) {
      return DATE_FORMAT_US;
    }
    return DATE_FORMAT_DEFAULT;
  }
}

export class UserConfigMeasurementUnits {
  temperature!: string;
  temperatureSymbol!: string;

  constructor(locale?: string | undefined | null) {
    if (!locale) {
      locale = LOCALE_DEFAULT;
    }
    this.temperature = this.getTemperature(locale);
    this.temperatureSymbol = this.getTemperatureSymbol(this.temperature);
  }

  getTemperature(locale: string): string {
    if (locale === LOCALE_EN_US) {
      return MEASUREMENT_UNIT_FAHRENHEIT;
    }
    return MEASUREMENT_UNIT_CELSIUS;
  }

  getUnitTemperature(t: number): number {
    if (this.temperature === MEASUREMENT_UNIT_FAHRENHEIT) {
      return parseFloat(this.celsiusToFahrenheit(t).toFixed(2));
    }
    return t;
  }

  celsiusToFahrenheit(c: number): number {
    return (c * 9) / 5 + 32;
  }

  getTemperatureSymbol(t: string): string {
    switch (t) {
      case MEASUREMENT_UNIT_CELSIUS:
        return '°C';
      case MEASUREMENT_UNIT_FAHRENHEIT:
        return '°F';
    }
    return '°';
  }
}

export const UserConfigVariantEnglishCelsius = {
  label: 'English | Celsius | DD/MM/YYYY HH:mm (e.g. 31/12/2020 21:45)',
  locale: 'en',
  config: new UserConfig('en'),
};

export const UserConfigVariantEnglishFahrenheit = {
  label: 'English | Fahrenheit | MM/DD/YYYY hh:mm a (e.g. 12/31/2020 09:45 pm)',
  locale: 'en-US',
  config: new UserConfig('en-US'),
};
