import Api from '../../../Http/Api';

class DataPayload {
  payload!: {
    organizationManagers: any[];
    studyManagers: any[];
    siteManagers: any[];
  };
}

export class ApiResponseUserResearchManagers {
  status!: string;
  statusText!: string;
  data!: DataPayload;
}

export function GetUserResearchManagers(
  userId: number
): Promise<ApiResponseUserResearchManagers> {
  const url = `${process.env.REACT_APP_API_URL}/v3/users/${userId}/researchManagers`;
  return Api.get(url);
}
