import React from 'react';
import { AppBar, Box, Tab, Tabs, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import i18next from 'i18next';
import { faAlarmExclamation } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AdherenceSiteInfo } from '../../Model/AdherenceSiteInfo';

const useStyles = makeStyles((theme) => ({
  appBar: {
    marginTop: '24px',
    backgroundColor: '#ffffff',
    borderBottom: '1px solid #DFE3E8',
    height: theme.spacing(6),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tab: {
    letterSpacing: 0,
    display: 'block',
  },
  bold: {
    fontWeight: 'bold',
  },
  expirationDate: {
    textTransform: 'uppercase',
    fontFamily: 'ApexNew',
    fontWeight: 400,
    fontSize: '0.8em',
  },
}));

export interface AdherenceReportTabsProps {
  siteInfo?: AdherenceSiteInfo;
}

export default function AdherenceReportTabs({
  siteInfo,
}: AdherenceReportTabsProps) {
  const classes = useStyles();
  return (
    <Box>
      <AppBar
        position="static"
        color="secondary"
        elevation={0}
        className={classes.appBar}
      >
        <Tabs value="adherence" indicatorColor="primary" textColor="primary">
          <Tab
            id="adherence"
            value="adherence"
            label={
              <React.Fragment>
                <Box className={classes.tab}>
                  <span className={classes.bold}>
                    {i18next.t('REPORT_ADHERENCE_TAB_TITLE')}
                  </span>
                  <span>&nbsp;</span>
                  <span>{i18next.t('REPORT_ADHERENCE_TAB_SUBTITLE')}</span>
                </Box>
              </React.Fragment>
            }
          />
        </Tabs>
        {siteInfo && (
          <Tooltip
            arrow
            title={
              <span>
                {i18next.t('REPORT_ADHERENCE_DATE_EXPIRATION_HOVER_TEXT')}
              </span>
            }
            placement="bottom-start"
          >
            <Box>
              <FontAwesomeIcon icon={faAlarmExclamation} color="#969696" />
              &nbsp;&nbsp;
              <span className={classes.expirationDate}>
                {i18next.t('REPORT_ADHERENCE_DATE_EXPIRATION')}:{' '}
                <span id="expiration_value">
                  {siteInfo.getExpirationDate()}
                </span>
              </span>
            </Box>
          </Tooltip>
        )}
      </AppBar>
    </Box>
  );
}
