export const StudyAppHealthmonitor = 'study_app_healthmonitor';
export const StudyBiomarkerTemperature = 'biomarker_temperature';
export const StudyBiomarkerHR = 'biomarker_hr';
export const EdriResearcherDisplay = 'edri_researcher_display';

// device related feature flags

export const StudyDeviceFeatureFlag = 'device_bt';
export const StudyDeviceVariantE4 = 'e4';
export const StudyDeviceVariantEmbPlus = 'embplus';

/**
 * @deprecated The `StudyDeviceE4` is deprecated in favor of the `StudyDeviceFeatureFlag` with the
 * `StudyDeviceVariantE4` variant.
 */
export const StudyDeviceE4 = 'device_e4_bt';

/**
 * @deprecated The `StudyDeviceEmbracePlus` is deprecated in favor of the `StudyDeviceFeatureFlag` with the
 * `StudyDeviceVariantEmbPlus` variant.
 */
export const StudyDeviceEmbracePlus = 'device_embplus_bt';

export class ResearchConfigurationFeature {
  id!: number;
  featureId!: number;
  variantId!: number;
  configurationId!: number;
  featureName!: string;
  featureDescription!: string;
  variantName!: string;
  variantDescription!: string;
}

export default class ResearchConfiguration {
  id!: number;
  name!: string;
  description?: string;
  tag?: string;
  features?: Array<ResearchConfigurationFeature>;
}
