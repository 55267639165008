import React, { useEffect, useState } from 'react';
import { AppBar, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AdherenceReportUserList from './AdherenceReportUserList';
import i18next from 'i18next';
import LogoWhite from '../../Logo/empatica-logo.svg';

import Papa, { ParseError, ParseResult } from 'papaparse';
import {
  AdherenceCsvRow,
  parseCsv,
  UserAdherence,
} from '../../Model/Adherence';
import { AdherenceSiteInfo, makeSiteInfo } from '../../Model/AdherenceSiteInfo';
import AdherenceReportLoadState, {
  AdherenceLoadStateParseError,
  AdherenceLoadStateExpired,
  AdherenceLoadStateLoading,
  AdherenceLoadStateSuccess,
  AdherenceLoadStateUrlError,
} from './States/AdherenceReportLoadState';

import { withRouter } from 'react-router';
import AdherenceLoadingView from './States/AdherenceLoadingView';
import AdherenceParseErrorView from './States/AdherenceParseErrorView';
import AdherenceUrlErrorView from './States/AdherenceUrlErrorView';
import AdherenceExpiredView from './States/AdherenceExpiredView';
import AdherenceHeader from './AdherenceHeader';
import dayjs from 'dayjs';
import { getSignedUrlExpiration, isUrlExpired } from '../Utils/AdherenceUtils';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: 'auto',
    /* 279.4mm */
    // height: '11in',
    /* 215.9mm */
    width: '8.5in',
    border: '1px solid #d3d3d3',
    background: '#fff',
  },
  appBarLogo: {
    height: 20,
    marginTop: 10,
    marginBottom: 4,
  },
  content: {
    padding: '1em',
    fontSize: '14px',
  },
}));

function AdherenceReportView(props: any) {
  const encodedSignedUrl =
    new URLSearchParams(props.location.search).get('url') || '';

  const [loadState, setLoadState] = useState<AdherenceReportLoadState>(
    AdherenceLoadStateLoading
  );
  const [usersAdherenceReport, setUsersAdherenceReport] = useState<
    UserAdherence[]
  >();
  const [siteInfo, setSiteInfo] = useState<AdherenceSiteInfo>();

  const classes = useStyles();

  function processData(
    results: ParseResult<AdherenceCsvRow>,
    file: File,
    expirationDate: number
  ) {
    try {
      let processedData = parseCsv(results.data);
      let siteInfo = makeSiteInfo(
        processedData,
        file.toString(),
        expirationDate
      );
      setSiteInfo(siteInfo);
      setUsersAdherenceReport(processedData);
      setLoadState(AdherenceLoadStateSuccess);
    } catch (error) {
      setLoadState(AdherenceLoadStateParseError);
    }
  }

  useEffect(() => {
    let expirationDate: number;
    let fileUrlDecoded: string;

    try {
      fileUrlDecoded = atob(encodedSignedUrl);
      expirationDate = getSignedUrlExpiration(fileUrlDecoded);
    } catch (e) {
      setLoadState(AdherenceLoadStateUrlError);
      return;
    }

    const currentDate = dayjs().utc().unix();
    if (isUrlExpired(expirationDate, currentDate)) {
      setLoadState(AdherenceLoadStateExpired);
      return;
    }

    Papa.parse(fileUrlDecoded, {
      header: true,
      dynamicTyping: true,
      download: true,
      error(error: ParseError, file?: File) {
        setLoadState(AdherenceLoadStateUrlError);
      },
      complete(results: ParseResult<AdherenceCsvRow>, file?: File) {
        if (results.data !== undefined && file !== undefined) {
          processData(results, file, expirationDate);
        }
        //TODO(rb): Handle state change when data == 0 or undefined
      },
    });
  }, [encodedSignedUrl]);

  function renderReport(): JSX.Element {
    return (
      <Box className={classes.wrapper}>
        {/*App Bar*/}
        <AppBar position="relative" elevation={0}>
          <Box textAlign="center">
            <img
              src={LogoWhite}
              alt={i18next.t('APP_NAME')}
              className={classes.appBarLogo}
            />
          </Box>
        </AppBar>

        {/*Content*/}
        <Box className={classes.content}>
          <Box textAlign="left">
            {siteInfo && <AdherenceHeader siteInfo={siteInfo} />}
            <AdherenceReportUserList
              siteInfo={siteInfo}
              data={usersAdherenceReport}
            />
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <React.Fragment>
      {loadState.isUrlError && <AdherenceUrlErrorView />}
      {loadState.isParseError && <AdherenceParseErrorView />}
      {loadState.isExpired && <AdherenceExpiredView />}
      {loadState.isLoading && <AdherenceLoadingView />}
      {loadState.isSuccess && renderReport()}
    </React.Fragment>
  );
}

export default withRouter(AdherenceReportView);
