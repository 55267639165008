// EDRILowRiskTempValue Low risk, temporary value
export const EDRILowRiskTempValue = 11;
// EDRILowRiskFinalValue Low risk, final value
export const EDRILowRiskFinalValue = 12;
// EDRIHighRiskTempValue High risk, temporary value
export const EDRIHighRiskTempValue = 21;
// EDRIHighRiskFinalValue High risk, final value
export const EDRIHighRiskFinalValue = 22;

export class Edri {
  runAt!: number;
  value?: number;
  riskLevel?: number;
}

export class GetEdriResponse {
  from!: number;
  to!: number;
  values?: Edri[];
}

export class AuraEdri {
  day!: string;
  tz!: number;
  value?: Edri;
}

export class GetAuraEdriResponse {
  fromDay!: string;
  toDay!: string;
  tz!: number;
  values?: AuraEdri[];
}

export function getEdriNegativeValueReason(value: number): string[] {
  switch (value) {
    case -1:
      return [
        `Data Detection: ✗`,
        `Data Baseline: ✗`,
        `Sleep Detection: ✗`,
        `Sleep Baseline: ✗`,
      ];
    case -2:
      return [
        `Data Detection: ✗`,
        `Data Baseline: ✓`,
        `Sleep Detection: ✓`,
        `Sleep Baseline: ✓`,
      ];
    case -3:
      return [
        `Data Detection: ✓`,
        `Data Baseline: ✗`,
        `Sleep Detection: ✓`,
        `Sleep Baseline: ✓`,
      ];
    case -4:
      return [
        `Data Detection: ✗`,
        `Data Baseline: ✗`,
        `Sleep Detection: ✓`,
        `Sleep Baseline: ✓`,
      ];
    case -5:
      return [
        `Data Detection: ✓`,
        `Data Baseline: ✓`,
        `Sleep Detection: ✗`,
        `Sleep Baseline: ✓`,
      ];
    case -6:
      return [
        `Data Detection: ✗`,
        `Data Baseline: ✓`,
        `Sleep Detection: ✗`,
        `Sleep Baseline: ✓`,
      ];
    case -7:
      return [
        `Data Detection: ✓`,
        `Data Baseline: ✗`,
        `Sleep Detection: ✗`,
        `Sleep Baseline: ✓`,
      ];
    case -8:
      return [
        `Data Detection: ✗`,
        `Data Baseline: ✗`,
        `Sleep Detection: ✗`,
        `Sleep Baseline: ✓`,
      ];
    case -9:
      return [
        `Data Detection: ✓`,
        `Data Baseline: ✓`,
        `Sleep Detection: ✓`,
        `Sleep Baseline: ✗`,
      ];
    case -10:
      return [
        `Data Detection: ✗`,
        `Data Baseline: ✓`,
        `Sleep Detection: ✓`,
        `Sleep Baseline: ✗`,
      ];
    case -11:
      return [
        `Data Detection: ✓`,
        `Data Baseline: ✗`,
        `Sleep Detection: ✓`,
        `Sleep Baseline: ✗`,
      ];
    case -12:
      return [
        `Data Detection: ✗`,
        `Data Baseline: ✗`,
        `Sleep Detection: ✓`,
        `Sleep Baseline: ✗`,
      ];
    case -13:
      return [
        `Data Detection: ✓`,
        `Data Baseline: ✓`,
        `Sleep Detection: ✗`,
        `Sleep Baseline: ✗`,
      ];
    case -14:
      return [
        `Data Detection: ✗`,
        `Data Baseline: ✓`,
        `Sleep Detection: ✗`,
        `Sleep Baseline: ✗`,
      ];
    case -15:
      return [
        `Data Detection: ✓`,
        `Data Baseline: ✗`,
        `Sleep Detection: ✗`,
        `Sleep Baseline: ✗`,
      ];
    case -16:
      return [
        `Data Detection: ✗`,
        `Data Baseline: ✗`,
        `Sleep Detection: ✗`,
        `Sleep Baseline: ✗`,
      ];
  }
  return [];
}

export function getEdriChartData(
  edri: Edri[],
  fromUnix: number,
  toUnix: number,
  tz: number = 0
): Edri[] {
  if (toUnix < fromUnix) {
    return [];
  }
  // Apply timezone
  const tzOffset = tz * 1000;
  const from = fromUnix;
  const to = toUnix;
  const millisIn30Minute = 30 * 60 * 1000; // minutes * seconds * millis

  // Create a dataset with only empty values for the given timeframe
  const diffInMillis = to - from;
  const minutesInDiff = Math.round(diffInMillis / millisIn30Minute);
  let chartData: Edri[] = Array(minutesInDiff)
    .fill(null)
    .map((_: any, i: number) => {
      return {
        runAt: from + i * millisIn30Minute,
        value: 0,
      };
    });

  // If no EDRI input data, return an empty values array
  if (!edri || edri.length === 0) {
    return chartData;
  }

  // For each EDRI point, find the correct bucket to assign it to
  edri.forEach((e: Edri) => {
    e.runAt += tzOffset;
    if (e.runAt >= from && e.runAt <= to) {
      const pointDiffInMillis = e.runAt - from;
      const pointIndex = Math.round(pointDiffInMillis / millisIn30Minute);
      chartData[pointIndex] = e;
    }
  });

  return chartData;
}
