import React, { useContext, useState } from 'react';
import ModalBase from './ModalBase';
import {
  Box,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import * as WebEmbraceApi from '../../Http/WebEmbraceApi';
import UserContext from '../../Context/UserContext';
import Snackbar from '@material-ui/core/Snackbar';
import User from '../../Model/User';
import {
  UserConfigVariantEnglishCelsius,
  UserConfigVariantEnglishFahrenheit,
  LOCALE_DEFAULT,
  LOCALE_EN_US,
} from '../../Model/UserConfig';

const useStyles = makeStyles((theme) => ({
  content: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  localeForm: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  localeSelect: {
    '& .MuiSelect-select': {
      fontSize: '0.75rem',
    },
  },
}));

class ModalEditAccountProps {
  open!: boolean;
  onClose?: any;
  onChange?: any;
}

export default function ModalEditAccount(props: ModalEditAccountProps) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [repeatPasswordError, setRepeatPasswordError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [passwordChangeSuccess, setPasswordChangeSuccess] = useState<boolean>(
    false
  );
  const user: User = useContext(UserContext)?.user;
  const { config, setConfig } = useContext(UserContext);
  let selectedUserLocale =
    config.locale === LOCALE_EN_US ? LOCALE_EN_US : LOCALE_DEFAULT;

  function changePassword(oldPassword: string, newPassword: string) {
    if (!user) {
      return;
    }
    return WebEmbraceApi.ChangePassword(user.id, oldPassword, newPassword);
  }

  const validateForm = (): boolean => {
    setErrorMessage(null);
    let isValid = true;
    setOldPasswordError(false);
    setNewPasswordError(false);
    setRepeatPasswordError(false);
    if (!oldPassword) {
      setOldPasswordError(true);
      isValid = false;
    }
    if (!newPassword || newPassword.length < 8) {
      setNewPasswordError(true);
      isValid = false;
    }
    if (!repeatPassword || repeatPassword.length < 8) {
      setRepeatPasswordError(true);
      isValid = false;
    }
    if (newPassword !== repeatPassword) {
      setNewPasswordError(true);
      setRepeatPasswordError(true);
      setErrorMessage('EDIT_ACCOUNT_RESET_PASSWORD_ERROR_NO_MATCH');
      isValid = false;
    }
    if (newPassword.length < 8) {
      setNewPasswordError(true);
      setRepeatPasswordError(true);
      setErrorMessage('PASSWORD_LENGTH_ERROR_MESSAGE');
      isValid = false;
    }
    return isValid;
  };

  const handlePasswordSubmit = (evt: any) => {
    evt.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    changePassword(oldPassword, newPassword)
      ?.then((resp) => {
        if (props.onChange) {
          props.onChange();
        }
        setPasswordChangeSuccess(true);
      })
      .catch((err) => {
        setErrorMessage('EDIT_ACCOUNT_RESET_PASSWORD_ERROR_SUBMIT');
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleLocaleChange = (evt: any) => {
    evt.preventDefault();
    const locale =
      evt.target.value === LOCALE_EN_US ? LOCALE_EN_US : LOCALE_DEFAULT;
    const config =
      evt.target.value === LOCALE_EN_US
        ? UserConfigVariantEnglishFahrenheit.config
        : UserConfigVariantEnglishCelsius.config;

    selectedUserLocale = locale;
    setConfig(config);
    localStorage.setItem('config', JSON.stringify({ locale: locale }));
  };

  const close = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  const handleCloseError = () => {
    setErrorMessage(null);
  };

  const handleCloseSuccess = () => {
    setPasswordChangeSuccess(false);
  };

  const component = (
    <React.Fragment>
      <Box className={classes.content}>
        <h2>
          <Trans i18nKey="EDIT_ACCOUNT_TITLE" />
        </h2>
        <Box mb={1}>
          <TextField
            variant="outlined"
            margin="normal"
            size="small"
            required
            fullWidth
            id="email"
            label={i18next.t('EMAIL_ADDRESS')}
            name="email"
            autoComplete="email"
            value={user.email}
            disabled={true}
          />
        </Box>
        <form onSubmit={handlePasswordSubmit} noValidate>
          <h2>
            <Trans i18nKey="EDIT_ACCOUNT_RESET_PASSWORD_TITLE" />
          </h2>
          <TextField
            variant="outlined"
            margin="normal"
            size="small"
            required
            fullWidth
            name="password"
            label={i18next.t('EDIT_ACCOUNT_RESET_PASSWORD_OLD')}
            type="password"
            id="old-password"
            autoComplete="old-password"
            error={oldPasswordError}
            onChange={(e) => setOldPassword(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            size="small"
            required
            fullWidth
            name="password"
            label={i18next.t('EDIT_ACCOUNT_RESET_PASSWORD_NEW')}
            type="password"
            id="new-password"
            autoComplete="new-password"
            error={newPasswordError}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            size="small"
            required
            fullWidth
            name="password"
            label={i18next.t('EDIT_ACCOUNT_RESET_PASSWORD_NEW_CONFIRM')}
            type="password"
            id="repeat-password"
            autoComplete="repeat-password"
            error={repeatPasswordError}
            onChange={(e) => setRepeatPassword(e.target.value)}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {!loading && <Trans i18nKey="EDIT_ACCOUNT_RESET_PASSWORD_ACTION" />}
            {loading && <CircularProgress size="1.6rem" />}
          </Button>
        </form>
        <Box>
          <h2>
            <Trans i18nKey="EDIT_ACCOUNT_SETTINGS_TITLE" />
          </h2>
          <FormControl
            size="small"
            variant="outlined"
            fullWidth
            className={classes.localeForm}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              <Trans i18nKey="EDIT_ACCOUNT_SETTINGS_DROPDOWN_TITLE" />
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={selectedUserLocale}
              onChange={handleLocaleChange}
              className={classes.localeSelect}
              fullWidth
              label={i18next.t('EDIT_ACCOUNT_SETTINGS_DROPDOWN_TITLE')}
            >
              <MenuItem value={UserConfigVariantEnglishCelsius.locale}>
                {UserConfigVariantEnglishCelsius.label}
              </MenuItem>
              <MenuItem value={UserConfigVariantEnglishFahrenheit.locale}>
                {UserConfigVariantEnglishFahrenheit.label}
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        key="snackbar-error"
        open={Boolean(errorMessage)}
        onClose={handleCloseError}
        autoHideDuration={6000}
        message={errorMessage ? i18next.t(errorMessage) : i18next.t('ERROR')}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        key="snackbar-success"
        open={passwordChangeSuccess}
        onClose={handleCloseSuccess}
        autoHideDuration={6000}
        message={i18next.t('EDIT_ACCOUNT_RESET_PASSWORD_SUCCESS')}
      />
    </React.Fragment>
  );

  return (
    <ModalBase
      open={props.open}
      component={component}
      secondaryAction={{ label: i18next.t('CLOSE'), func: close }}
    />
  );
}
