import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Box, Grid, CircularProgress } from '@material-ui/core';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { Trans } from 'react-i18next';
import { logout } from '../../Model/Utils';
import Site from '../../Model/Site';
import i18next from 'i18next';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '600px',
    height: '600px',
    background: '#ffffff',
    borderRadius: '4px',
    position: 'relative',
  },
  wardName: {
    textAlign: 'left',
    color: '#404040',
    fontWeight: 300,
  },
  wardEnrollmentCount: {
    textAlign: 'right',
    color: '#404040',
    fontWeight: 100,
  },
  logout: {
    textAlign: 'right',
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  subtitle: {
    color: '#969696',
  },
  description: {
    color: '#969696',
  },
}));

export class WardSelectorProps {
  sites?: Site[];
  loading?: boolean;
}

export default function WardSelector(props: WardSelectorProps) {
  const classes = useStyles();
  const [wards, setWards] = useState<any>([]);
  const [rendering, setRendering] = useState<boolean>(true);
  let history = useHistory();

  useEffect(() => {
    setRendering(true);

    document.title = i18next.t('TITLE_MAIN');

    if (!props.sites) {
      setRendering(false);
      return;
    }
    if (props.sites.length === 1) {
      const site = props.sites[0];
      const sitePath = `/${site.organizationId}/${site.studyId}/${site.id}`;
      history.push(sitePath);
      return;
    }
    const wards = props.sites.map((s: any) => {
      return (
        <Box mb={2} key={`ward-${s.id}`} id={`ward-${s.id}`}>
          <Link
            component={RouterLink}
            underline="none"
            to={`/${s.organizationId}/${s.studyId}/${s.id}`}
          >
            <Paper>
              <Box pl={2} pr={2}>
                <Grid container>
                  <Grid item xs={6} className={classes.wardName}>
                    <h3>{s.name}</h3>
                  </Grid>
                  <Grid item xs={6} className={classes.wardEnrollmentCount}>
                    <Box pt={2}>
                      {s.enrollmentsSummary.total}{' '}
                      <Trans i18nKey="WARD_SELECTOR_USERS_LABEL" />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Link>
        </Box>
      );
    });

    setWards(wards);
    setRendering(false);
  }, [props.sites, classes, history]);

  if (rendering) {
    return null;
  }

  return (
    <Box className={classes.wrapper}>
      <Box padding={4}>
        <h1>
          <Trans i18nKey="WARD_SELECTOR_TITLE" />
        </h1>
        <h3 className={classes.subtitle}>
          <Trans i18nKey="WARD_SELECTOR_SUBTITLE" />
        </h3>
        <div className={classes.description}>
          <Trans i18nKey="WARD_SELECTOR_DESCRIPTION" />
        </div>
      </Box>
      <Box padding={4}>
        <Box mt={2}>
          {props.loading && (
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          )}
          {!props.loading && wards.length > 0 && <Box>{wards}</Box>}
          {!props.loading && wards.length === 0 && (
            <Box>
              <Trans i18nKey="WARD_SELECTOR_EMPTY_SET" />
            </Box>
          )}
        </Box>
      </Box>
      <Box className={classes.logout}>
        <Link onClick={logout}>
          <Trans i18nKey="LOGOUT" />
        </Link>
      </Box>
    </Box>
  );
}
