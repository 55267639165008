import React from 'react';
import {
  Box,
  Paper,
  Typography,
  makeStyles,
  Grid,
  Link,
} from '@material-ui/core';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  SubjectSortByInternalId,
  SubjectSortByTemperature,
  SubjectSortByPulseRate,
  SubjectSortByLastSync,
  SubjectSortByStatus,
} from '../../Model/Subject';
import { Trans } from 'react-i18next';
import LoadState from '../../Model/LoadState';
import {
  SortInfo,
  SortDirectionAsc,
  SortDirectionDesc,
} from '../AdherenceReportView/SortUtils';
import SubjectStatusTooltip from './SubjectStatusTooltip';

const useStyles = makeStyles((theme) => ({
  header: {
    borderBottom: '1px solid #E4E7EB',
  },
  label: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    cursor: 'pointer',
  },
  labelContent: {
    color: 'grey',
    fontSize: '0.8rem',
  },
  labelIcon: {
    color: 'grey',
    fontSize: '1rem',
  },
}));

export interface SubjectListHeaderProps {
  sortInfo?: SortInfo;
  display?: Record<string, boolean>;
  onChange?(sortInfo: SortInfo): void;
  biomarkersLoadState: LoadState;
}

export default function SubjectListHeader({
  sortInfo = {
    by: 'internalId',
    direction: SortDirectionAsc,
  },
  display = {},
  onChange,
  biomarkersLoadState,
}: SubjectListHeaderProps) {
  const classes = useStyles();

  const setSortBy = (by: string) => {
    if (biomarkersLoadState.isLoading) {
      return;
    }

    const newSortDirection =
      by === sortInfo.by && sortInfo.direction === SortDirectionAsc
        ? SortDirectionDesc
        : SortDirectionAsc;

    onChange?.({
      by: by || '',
      direction: newSortDirection,
    });
  };

  const getSortIcon = (column: string) => {
    if (sortInfo.by !== column) {
      return <UnfoldMoreIcon className={classes.labelIcon} />;
    }

    return sortInfo.direction === SortDirectionAsc ? (
      <KeyboardArrowUpIcon className={classes.labelIcon} />
    ) : (
      <KeyboardArrowDownIcon className={classes.labelIcon} />
    );
  };

  return (
    <Paper className={classes.header} elevation={0}>
      <Box pt={1} pb={1} pl={2} pr={2}>
        <Grid container>
          <Grid item xs={3}>
            <Link
              className={classes.label}
              onClick={() => setSortBy(SubjectSortByInternalId)}
              style={{
                cursor: biomarkersLoadState.isLoading ? 'progress' : 'pointer',
              }}
            >
              <Typography component="span" className={classes.labelContent}>
                <Trans i18nKey="SITE_VIEW_SUBJECT_ID_LABEL" />
              </Typography>

              {getSortIcon(SubjectSortByInternalId)}
            </Link>
          </Grid>
          <Grid item xs={2}>
            <Link
              className={classes.label}
              onClick={() => setSortBy(SubjectSortByStatus)}
              style={{
                cursor: biomarkersLoadState.isLoading ? 'progress' : 'pointer',
              }}
            >
              <Typography component="span" className={classes.labelContent}>
                <Trans i18nKey="SITE_VIEW_SUBJECT_STATUS_LABEL" />
              </Typography>

              {getSortIcon(SubjectSortByStatus)}

              <SubjectStatusTooltip arrow>
                <InfoOutlinedIcon className={classes.labelIcon} />
              </SubjectStatusTooltip>
            </Link>
          </Grid>
          <Grid item xs={2}>
            <Link
              className={classes.label}
              onClick={() => setSortBy(SubjectSortByLastSync)}
              style={{
                cursor: biomarkersLoadState.isLoading ? 'progress' : 'pointer',
              }}
            >
              <Typography component="span" className={classes.labelContent}>
                <Trans i18nKey="SITE_VIEW_SUBJECT_LAST_SYNC_LABEL" />
              </Typography>

              {getSortIcon(SubjectSortByLastSync)}
            </Link>
          </Grid>
          <Grid item xs={2}>
            {display.temperature && (
              <Link
                className={classes.label}
                onClick={() => setSortBy(SubjectSortByTemperature)}
                style={{
                  cursor: biomarkersLoadState.isLoading
                    ? 'progress'
                    : 'pointer',
                }}
              >
                <Typography component="span" className={classes.labelContent}>
                  <Trans i18nKey="SITE_VIEW_SUBJECT_SKIN_TEMP_LABEL" />
                </Typography>

                {getSortIcon(SubjectSortByTemperature)}
              </Link>
            )}
          </Grid>
          <Grid item xs={2}>
            {display.pulseRate && (
              <Link
                className={classes.label}
                onClick={() => setSortBy(SubjectSortByPulseRate)}
                style={{
                  cursor: biomarkersLoadState.isLoading
                    ? 'progress'
                    : 'pointer',
                }}
              >
                <Typography component="span" className={classes.labelContent}>
                  <Trans i18nKey="SITE_VIEW_SUBJECT_PULSE_RATE_LABEL" />
                </Typography>

                {getSortIcon(SubjectSortByPulseRate)}
              </Link>
            )}
          </Grid>
          <Grid item xs={1}>
            <Box>&nbsp;</Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
