const featureSeparator: string = ':';

export default class User {
  id!: number;
  email!: string;
}

export class Feature {
  label: string;
  variant: string;

  constructor(feature: string) {
    this.label = '';
    this.variant = '';
    if (!feature) {
      return;
    }
    let featureItems = feature.split(featureSeparator);
    if (featureItems.length !== 2) {
      return;
    }
    this.label = featureItems[0];
    this.variant = featureItems[1];
  }
}

export type Role = string;

export class Scope {
  label: string;
  variant: string;
  value?: string | number;

  constructor(feature: string) {
    this.label = '';
    this.variant = '';
    if (!feature) {
      return;
    }
    let featureItems = feature.split(featureSeparator);
    if (featureItems.length < 2) {
      return;
    }
    this.label = featureItems[0];
    this.variant = featureItems[1];
    if (featureItems.length === 3) {
      this.value = featureItems[2];
    }
  }
}
