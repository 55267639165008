import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import dayjs from 'dayjs';
import { Box, Button } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import UserContext from '../../Context/UserContext';

interface Column {
  id: 'timestamp' | 'compliance' | 'temperature' | 'pulseRate';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'timestamp', label: 'Timestamp', minWidth: 170 },
  { id: 'compliance', label: 'Compliance (%)', minWidth: 100 },
  { id: 'temperature', label: 'Temperature', minWidth: 100 },
  { id: 'pulseRate', label: 'Pulse Rate', minWidth: 100 },
];

interface Data {
  timestamp: string;
  compliance: number;
  temperature: number;
  pulseRate: number;
}

function createData(
  timestamp: string,
  compliance: number,
  temperature: number,
  pulseRate: number
): Data {
  return { timestamp, compliance, temperature, pulseRate };
}

var rows: Data[] = [];

const populateTable = (data: any, dateFormat: string) => {
  rows = [];
  for (let i = 0; i < data['deviceCompliance']['values'].length; i++) {
    var date = dayjs(data['from'] + i * 60000).format(dateFormat);
    rows.push(
      createData(
        date,
        data['deviceCompliance']['values'][i],
        data['temperature']['values'][i],
        data['pulseRate']['values'][i]
      )
    );
  }
  return rows;
};

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

export class TableProps {
  data!: any;
  subject?: any;
}

export default function StickyHeadTable(props: any) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const [rows, setRows] = useState<Data[]>([]);

  const { config } = useContext(UserContext);
  const format = `${config.dateFormat} ${config.timeFormat}`;

  if (rows.length === 0) {
    setRows(populateTable(props.data, format));
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader size="small" aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, i) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Box textAlign="right" p={2}>
        <Button color="default" onClick={props.exportToCSV}>
          <FontAwesomeIcon icon={faFileDownload} />
          <span>&nbsp;</span>
          <Trans i18nKey="EXPORT_CSV" />
        </Button>
      </Box>
    </Paper>
  );
}
