import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Paper } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { BrowseItem } from '../../Model/BrowserItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderLeft: '1px solid #E4E7EB',
    borderRight: '1px solid #E4E7EB',
    borderBottom: '1px solid #E4E7EB',
    borderTop: '0',
    borderRadius: '0',
    '&:hover': {
      backgroundColor: '#FAFAFA',
    },
  },
  userRow: {
    '& > *': {
      display: 'grid',
      alignContent: 'center',
    },
  },
  chevron: {
    fontSize: '1rem',
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'right',
    color: '#00778B',
  },
}));

class BrowseProps {
  items!: BrowseItem[];
  handleClick?: CallableFunction;
}

export default function BrowserList(props: BrowseProps) {
  const classes = useStyles();
  const [itemsRendered, setItemsRendered] = useState<any>(); // TODO(rb): use proper type

  useEffect(() => {
    const itemsRendered = props.items.map((item: BrowseItem) => {
      return (
        <Grid item xs={12} key={item.fullPath}>
          <Link
            onClick={() => props.handleClick!(item)}
            style={{
              cursor: 'pointer',
              textDecoration: 'none',
            }}
          >
            <Paper elevation={0} className={classes.paper}>
              <Box p={2}>
                <Grid container xs={12}>
                  <Box>
                    <FontAwesomeIcon icon={item.getIcon()} />
                    &nbsp;
                    <span>{item.getPrettyTitle()}</span>
                  </Box>
                </Grid>
              </Box>
            </Paper>
          </Link>
        </Grid>
      );
    });

    setItemsRendered(itemsRendered);
  }, [props.items, classes.paper, props.handleClick]);

  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <div>{itemsRendered}</div>
        </Grid>
      </Grid>
    </div>
  );
}
