import React from 'react';
import User, { Feature, Scope, Role } from '../Model/User';
import UserConfig from '../Model/UserConfig';

export class UserContextData {
  user!: User;
  config!: UserConfig;
  features?: Feature[];
  scopes?: Scope[];
  roles?: Role[];
  setUser!: Function;
  setFeatures!: Function;
  setScopes!: Function;
  setRoles!: Function;
  setConfig!: Function;
}
export const EmptyUserContext: UserContextData = {
  user: { id: 0, email: '' },
  features: [],
  scopes: [],
  roles: [],
  config: new UserConfig('en'),
  setUser: () => {},
  setFeatures: () => {},
  setScopes: () => {},
  setRoles: () => {},
  setConfig: () => {},
};
const UserContext = React.createContext<UserContextData>(EmptyUserContext);

export const UserProvider = UserContext.Provider;
export const UserConsumer = UserContext.Consumer;

export default UserContext;
