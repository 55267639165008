import React, { FunctionComponent, Fragment, useMemo, ReactNode } from 'react';
import {
  Tooltip,
  TooltipProps,
  Typography,
  makeStyles,
} from '@material-ui/core';
import i18next from 'i18next';
import { SubjectStatus } from '../../Model/Subject';

export const subjectStatusMap: Record<
  SubjectStatus,
  { color: string; label: string; description: string }
> = {
  [SubjectStatus.IDLE]: {
    color: '#ebebea',
    label: 'SITE_VIEW_SUBJECT_STATUS_IDLE_LABEL',
    description: 'SITE_VIEW_SUBJECT_STATUS_IDLE_DESCRIPTION',
  },
  [SubjectStatus.WAITING]: {
    color: '#ffda9e',
    label: 'SITE_VIEW_SUBJECT_STATUS_WAITING_LABEL',
    description: 'SITE_VIEW_SUBJECT_STATUS_WAITING_DESCRIPTION',
  },
  [SubjectStatus.MONITORING]: {
    color: '#e4eff1',
    label: 'SITE_VIEW_SUBJECT_STATUS_MONITORING_LABEL',
    description: 'SITE_VIEW_SUBJECT_STATUS_MONITORING_DESCRIPTION',
  },
  [SubjectStatus.EARLY_TERM]: {
    color: '#e7e4ff',
    label: 'SITE_VIEW_SUBJECT_STATUS_EARLY_TERM_LABEL',
    description: 'SITE_VIEW_SUBJECT_STATUS_EARLY_TERM_DESCRIPTION',
  },
  [SubjectStatus.ENDED]: {
    color: '#cce3ff',
    label: 'SITE_VIEW_SUBJECT_STATUS_ENDED_LABEL',
    description: 'SITE_VIEW_SUBJECT_STATUS_ENDED_DESCRIPTION',
  },
};

export interface SubjectStatusTooltipProps extends Omit<TooltipProps, 'title'> {
  title?: ReactNode;
  content?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  container: {
    fontFamily: 'ApexNew',
    padding: theme.spacing(1),
    fontWeight: 300,
    fontSize: '0.7rem',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    gap: theme.spacing(2),
    padding: theme.spacing(1, 2),
  },
  title: {
    textTransform: 'uppercase',
    fontSize: '0.95rem',
    marginBottom: theme.spacing(1),
  },
  name: {
    display: 'block',
    marginBottom: theme.spacing(1),
    fontWeight: 400,
  },
}));

const SubjectStatusTooltip: FunctionComponent<SubjectStatusTooltipProps> = ({
  title,
  content,
  ...props
}) => {
  const classes = useStyles();

  const statuses = useMemo(
    () =>
      Object.entries(subjectStatusMap).map(
        ([status, { label, color, description }]) => (
          <Fragment key={status}>
            <div>
              <span className={classes.name}>{i18next.t(label)}</span>
              <svg viewBox="0 0 18 18" width={18} height={18}>
                <rect width="18" height="18" rx="4" fill={color} />
              </svg>
            </div>
            <span>{i18next.t(description)}</span>
          </Fragment>
        )
      ),
    [classes.name]
  );

  return (
    <Tooltip
      placement="bottom-start"
      title={
        <div className={classes.container}>
          {title !== null && (
            <Typography variant="h6" className={classes.title}>
              {title ?? i18next.t('SITE_VIEW_SUBJECT_STATUS_LABEL')}
            </Typography>
          )}
          {content || <div className={classes.grid}>{statuses}</div>}
        </div>
      }
      {...props}
    />
  );
};

export default SubjectStatusTooltip;
