import React from 'react';
import 'date-fns';
import { Box, makeStyles } from '@material-ui/core';
import i18next from 'i18next';
import { AuraEdri } from '../Model/Edri';
import dayjs from 'dayjs';
import { SubjectStatus } from '../Model/Subject';

const useStyles = makeStyles((theme) => ({
  auraEdriBadge: {
    border: '1px solid transparent',
    borderRadius: '1rem',
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    color: '#fff',
    display: 'inline-block',
    padding: '0 0.5rem',
    minWidth: '5.5rem',
    textAlign: 'center',
  },
}));

function getAuraEdriLabel(aura?: AuraEdri, status?: SubjectStatus): string {
  if (!aura?.value || (status && status !== SubjectStatus.MONITORING)) {
    return i18next.t('AURA_NO_DATA_LABEL');
  }
  switch (aura.value.riskLevel) {
    case 11:
    case 12:
      return i18next.t('AURA_LOW_RISK_INFECTION_LABEL');
    case 21:
    case 22:
      return i18next.t('AURA_HIGH_RISK_INFECTION_LABEL');
  }
  return i18next.t('AURA_NO_DATA_LABEL');
}

function getAuraEdriStyle(
  aura?: AuraEdri,
  status?: SubjectStatus
): React.CSSProperties {
  if (!aura || (status && status !== SubjectStatus.MONITORING)) {
    return {
      borderColor: '#CCCCCC',
      background: '#FFFFFF',
    };
  }
  if (!aura.value) {
    if (isTodayValue(aura)) {
      return {
        borderColor: '#CCCCCC',
        background: '#FFFFFF',
      };
    }
    return {
      background: '#969696',
    };
  }
  let bgColor = 'transparent';
  switch (aura.value.riskLevel) {
    case 11:
    case 12:
      bgColor = '#00D077';
      break;
    case 21:
    case 22:
      bgColor = '#FF3B30';
      break;
  }
  return {
    background: bgColor,
  };
}

function isTodayValue(aura: AuraEdri) {
  const today = dayjs().format('YYYY-MM-DD');
  return aura.day === today;
}

class AuraEdriBadgeProps {
  aura?: AuraEdri;
  status?: SubjectStatus;
}

export default function AuraEdriBadge(props: AuraEdriBadgeProps) {
  const classes = useStyles();
  if (!props.aura) {
    return <></>;
  }
  const label = getAuraEdriLabel(props.aura, props.status);
  const style = getAuraEdriStyle(props.aura, props.status);
  const badge = (
    <Box className={classes.auraEdriBadge} style={style}>
      {label}
    </Box>
  );

  return badge;
}
