const ERROR_API_SERVER = 500;
const ERROR_API_UNAUTHORIZED = 403;

export default class LoadState {
  state!: undefined | 'loading' | 'error' | 'success';
  isLoading!: boolean;
  isError!: boolean;
  isSuccess!: boolean;

  constructor(state?: undefined | 'loading' | 'error' | 'success') {
    this.state = state;
    this.isLoading = state === 'loading';
    this.isError = state === 'error';
    this.isSuccess = state === 'success';
  }
}

export class ServerErrorState extends LoadState {
  description: string = 'server_error';

  constructor() {
    super('error');
  }
}

export class AuthorizationErrorState extends LoadState {
  description: string = 'authorization_error';

  constructor() {
    super('error');
  }
}

export const LoadStateUndefined = new LoadState();
export const LoadStateLoading = new LoadState('loading');
export const LoadStateSuccess = new LoadState('success');

// Error related states
export const LoadStateError = new LoadState('error');
export const LoadStateServerError = new ServerErrorState();
export const LoadStateAuthorizationError = new AuthorizationErrorState();

// Used to retrieve an error load state from a given API response status code
export function getErrorLoadStateFromApiResponse(
  statusCode: number
): LoadState {
  switch (statusCode) {
    case ERROR_API_SERVER:
      return LoadStateServerError;
    case ERROR_API_UNAUTHORIZED:
      return LoadStateAuthorizationError;
    default:
      return LoadStateError;
  }
}
