import axios, { AxiosResponse } from 'axios';
import jsonwebtoken from 'jsonwebtoken';
import { ERROR_API, INFO_API, logger } from '../Logging/Logger';
import { ApiLogEntry } from '../Model/LogEntry';

const appName = `healthmonitor-${process.env.REACT_APP_ENVIRONMENT || 'dev'}`;
const appVersion = `${process.env.REACT_APP_VERSION || 'dev'}`;
const appPlatform = 'web';
const atKey = 'at';
const rtKey = 'rt';

function clearTokens() {
  localStorage.removeItem(atKey);
  localStorage.removeItem(rtKey);
}

function setTokens(at: string, rt: string) {
  localStorage.setItem(atKey, at);
  localStorage.setItem(rtKey, rt);
}

function arrayEquals(a: Array<String>, b: Array<String>) {
  if (Array.isArray(a) && Array.isArray(b)) {
    a = a.sort();
    b = b.sort();

    if (a.length === b.length && a.every((val, index) => val === b[index])) {
      return true;
    }
  }
  return false;
}

function logApiError(err: any) {
  return new Promise((resolve) => {
    let status = err?.response?.status;

    // Any status for 2xx, 3xx, and 4xx categories, up to 404
    // should not be considered as error. Log as info.
    if (status && status >= 200 && status <= 404) {
      let ignoredErrors = [401, 403, 404];

      if (!ignoredErrors.includes(status)) {
        logger.info(INFO_API, ApiLogEntry.fromApiResponse(err));
      }

      return resolve(true);
    }

    // Any other status code, or uncatched error, should be logged as error
    logger.error(ERROR_API, ApiLogEntry.fromApiResponse(err));
    return resolve(false);
  });
}

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    'x-emp-app-name': appName,
    'x-emp-app-version': appVersion,
    'x-emp-app-platform': appPlatform,
    Authorization: `Bearer ${localStorage.getItem(atKey)}`,
  },
});

export default instance;

instance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (err: any) => {
    logApiError(err);
    if (err?.response?.status !== 401) {
      return Promise.reject(err);
    }
    return new Promise((resolve, reject) => {
      const originalReq = err.config;
      if (err.response.status === 401 && err.config && !err.config._retry) {
        originalReq._retry = true;

        let res = fetch(`${process.env.REACT_APP_API_URL}/v3/accessToken`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem(rtKey)}`,
          },
          redirect: 'follow',
          body: JSON.stringify({}),
        })
          .then((res) => res.json())
          .then((res) => {
            const updatedAccessToken: any = jsonwebtoken.decode(
              res.payload.accessToken
            );
            const localAccessToken: string | null = localStorage.getItem('at');

            if (localAccessToken && updatedAccessToken) {
              const updatedAccessTokenScopes =
                updatedAccessToken.PAYLOAD['scopes.v1'];
              const updatedAccessTokenRoles =
                updatedAccessToken.PAYLOAD['roles'];

              const oldAccessToken: any = jsonwebtoken.decode(localAccessToken);
              const oldAccessTokenScopes = oldAccessToken.PAYLOAD['scopes.v1'];
              const oldAccessTokenRoles = oldAccessToken.PAYLOAD['roles'];

              setTokens(res.payload.accessToken, res.payload.refreshToken);

              if (
                updatedAccessTokenScopes &&
                updatedAccessTokenRoles &&
                updatedAccessTokenRoles &&
                oldAccessTokenScopes
              ) {
                if (
                  !arrayEquals(
                    updatedAccessTokenScopes,
                    oldAccessTokenScopes
                  ) ||
                  !arrayEquals(updatedAccessTokenRoles, oldAccessTokenRoles)
                ) {
                  if (window.confirm('Please refresh your page.')) {
                    localStorage.removeItem('site');
                    window.location.reload();
                    return;
                  }
                }
              }
            }

            instance.defaults.headers.Authorization = `Bearer ${res.payload.accessToken}`;
            setTokens(res.payload.accessToken, res.payload.refreshToken);
            originalReq.headers[
              'Authorization'
            ] = `Bearer ${res.payload.accessToken}`;
            return axios(originalReq);
          })
          .catch(() => {
            clearTokens();
          });

        resolve(res);
      } else {
        logger.error(ERROR_API, ApiLogEntry.fromApiResponse(err));
        return Promise.reject(err);
      }
    });
  }
);
