import i18next from 'i18next';

export const STATUS_AVAILABLE = 'SYSTEM_STATUS_AVAILABLE';
export const STATUS_DEGRADED = 'SYSTEM_STATUS_DEGRADED';
export const STATUS_PARTIAL_OUTAGE = 'SYSTEM_STATUS_PARTIAL_OUTAGE';
export const STATUS_MAJOR_OUTAGE = 'SYSTEM_STATUS_MAJOR_OUTAGE';
export const STATUS_UNAVAILABLE = 'SYSTEM_STATUS_UNAVAILABLE';

export const COLOR_AVAILABLE = '#00D077';
export const COLOR_DEGRADED = 'lightorange';
export const COLOR_PARTIAL_OUTAGE = 'orange';
export const COLOR_MAJOR_OUTAGE = 'red';
export const COLOR_UNAVAILABLE = 'grey';

export class ServerStatus {
  code!: string;

  constructor(code: string) {
    this.code = code;
  }

  getPrettyStatus(): string {
    return i18next.t(this.code);
  }

  getColor(): string {
    switch (this.code) {
      case STATUS_AVAILABLE:
        return COLOR_AVAILABLE;
      case STATUS_DEGRADED:
        return COLOR_DEGRADED;
      case STATUS_PARTIAL_OUTAGE:
        return COLOR_PARTIAL_OUTAGE;
      case STATUS_MAJOR_OUTAGE:
        return COLOR_MAJOR_OUTAGE;
      case STATUS_UNAVAILABLE:
        return COLOR_UNAVAILABLE;
      default:
        return COLOR_UNAVAILABLE;
    }
  }
}
