import React, { useMemo } from 'react';
import { Box, Paper, Typography, makeStyles, Link } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Trans } from 'react-i18next';
import Timerange from '../../Model/Timerange';
import dayjs from 'dayjs';
import SubjectStatusTooltip from './SubjectStatusTooltip';

const useStyles = makeStyles((theme) => ({
  header: {
    borderBottom: '1px solid #E4E7EB',
  },
  label: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    cursor: 'pointer',
  },
  labelContent: {
    color: 'grey',
    fontSize: '0.8rem',
  },
  labelIcon: {
    marginLeft: '0.25rem',
    color: 'grey',
    fontSize: '1rem',
  },
  headerGrid: {
    display: 'grid',
    gridTemplateColumns: '2fr 140px repeat(11, 1fr) 24px',
    gridTemplateRows: '1fr',
    gridColumnGap: '0px',
    gridRowGap: '0px',
  },
}));

export interface SubjectListAuraHeaderProps {
  timerange: Timerange;
}

export default function SubjectListAuraHeader({
  timerange,
}: SubjectListAuraHeaderProps) {
  const classes = useStyles();

  const headDays = useMemo(() => {
    const start = dayjs().subtract(10, 'day');

    return Array.from({ length: 11 }, (value, index) => {
      const key = start.add(index, 'day').format('YYYY-MM-DD');
      const weekDay = start.add(index, 'day').format('ddd');
      const date = start.add(index, 'day').format('MMM DD');

      return (
        <Box key={key}>
          <Typography component="span" className={classes.labelContent}>
            <div>{weekDay}</div>
            <div>{date}</div>
          </Typography>
        </Box>
      );
    });
  }, [classes.labelContent]);

  return (
    <Paper className={classes.header} elevation={0}>
      <Box pt={1} pb={1} pl={2} pr={2}>
        <Box className={classes.headerGrid}>
          <Box>
            <Link className={classes.label}>
              <Typography component="span" className={classes.labelContent}>
                <Trans i18nKey="SITE_VIEW_SUBJECT_ID_LABEL" />
              </Typography>
            </Link>
          </Box>
          <Box>
            <Link className={classes.label}>
              <Typography component="span" className={classes.labelContent}>
                <Trans i18nKey="SITE_VIEW_SUBJECT_STATUS_LABEL" />
              </Typography>

              <SubjectStatusTooltip arrow>
                <InfoOutlinedIcon className={classes.labelIcon} />
              </SubjectStatusTooltip>
            </Link>
          </Box>
          {headDays}
          <Box>&nbsp;</Box>
        </Box>
      </Box>
    </Paper>
  );
}
