import AWS, { AWSError } from 'aws-sdk';
import { PromiseResult } from 'aws-sdk/lib/request';
import { ListObjectsV2Output } from 'aws-sdk/clients/s3';
import { BrowseItem, ItemType } from '../../Model/BrowserItem';

export class BrowserApi {
  private region: string;
  private bucket: string;
  private accessKey: string;
  private secretKey: string;
  private s3: AWS.S3;

  constructor(
    region: string,
    bucket: string,
    accessKey: string,
    secretKey: string
  ) {
    this.region = region;
    this.bucket = bucket;
    this.accessKey = accessKey;
    this.secretKey = secretKey;

    AWS.config.update({
      accessKeyId: this.accessKey,
      secretAccessKey: this.secretKey,
      region: this.region,
    });
    this.s3 = new AWS.S3({ signatureVersion: 'v4' });
  }

  retrieveObjects(path: string): Promise<BrowseItem[]> {
    const params = {
      Bucket: this.bucket,
      Delimiter: '/',
      Prefix: path,
      MaxKeys: 500,
    };

    return new Promise((resolve, reject) => {
      this.s3
        .listObjectsV2(params)
        .promise()
        .then((value: PromiseResult<ListObjectsV2Output, AWSError>) => {
          const folders = value.CommonPrefixes || [];
          const files = value.Contents || [];

          const foldersItems = folders.map((folder) => {
            return new BrowseItem(folder.Prefix!, ItemType.TYPE_DIR);
          });

          const filesItems = files
            .filter((file) => {
              return !(file.Key === path && file.Size === 0);
            })
            .map((file) => {
              return new BrowseItem(file.Key!, ItemType.TYPE_FILE);
            });

          const items = [...foldersItems, ...filesItems];

          resolve(items);
        });
    });
  }

  getObjectSignedUrl(key: string) {
    let params = {
      Bucket: this.bucket,
      Key: key,
      Expires: 604800, // 7 days expiration
    };
    return this.s3.getSignedUrlPromise('getObject', params);
  }
}
