import dayjs from 'dayjs';
import { getUsersWithNoData, UserAdherence } from './Adherence';

export class AdherenceSiteInfo {
  reportUrl!: string;
  site!: string;
  startDate!: Date;
  endDate!: Date;
  expirationDate!: Date;
  totalUsers!: number;
  noDataUsers!: number;

  constructor(
    reportUrl: string,
    site: string,
    year: number,
    week: number,
    totalUsers: number,
    noDataUsers: number,
    expirationDate: number
  ) {
    this.reportUrl = reportUrl;
    this.site = site;
    this.totalUsers = totalUsers;
    this.noDataUsers = noDataUsers;
    this.startDate = this.getDateOfISOWeek(week, year);
    this.endDate = dayjs(this.startDate).add(6, 'd').endOf('d').toDate();
    this.expirationDate = dayjs.unix(expirationDate).toDate();
  }

  getDateOfISOWeek(week: number, year: number) {
    let simple = new Date(year, 0, 1 + (week - 1) * 7);
    let dayOfWeek = simple.getDay();
    let weekStartISO = simple;

    if (dayOfWeek <= 4) {
      weekStartISO.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
      weekStartISO.setDate(simple.getDate() + 8 - simple.getDay());
    }

    return weekStartISO;
  }

  getPrettyDate() {
    const initParsed = dayjs(this.startDate).format('ddd, MMM DD, YYYY');
    const endParsed = dayjs(this.endDate).format('ddd, MMM DD, YYYY');
    return `${initParsed} to ${endParsed}`;
  }

  getExpirationDate() {
    const expirationDate = dayjs(this.expirationDate)
      .utc()
      .format('ddd, MMM DD, YYYY');
    return `${expirationDate}`;
  }
}

export function parseSiteInfo(
  fileUrl: string
): { siteId: string; year: number; week: number } {
  // Example url: https://empatica-sandbox-data.s3.us-east-1.amazonaws.com/EmpaCareTestOrg/EMP/CAM/site_reports/adherence/weekly/EmpaCareTestOrg-EMP-CAM_2021W06_adherence.csv?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWMWWLLDJI4KHXRXX%2F20210319%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20210319T092543Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjECIaCXVzLWVhc3QtMSJHMEUCIQDNSXTLvi2N%2FL3QDnV1SLG112yTsuXOb7Kti6cRNutXKgIgZCQF5CMmPcVhgaCPOcLWLvuhhj74%2BEV8QOc93Unekw8qqAIIWxAAGgw0Mzk2MDk5NDAxNzgiDLSpiq9%2BA7pfGSiwxSqFAtTA%2FvQKL%2BeDn8elFq1OPEBfyZPKgYRRmxgTTYTbqYFrjewHjHrSt%2FJ6NEFYQm4P%2BcDgRRZCo3slez8ddAO%2F7b0kGXFexVFbc1p2Ohv1HQbhBXk%2BtjdyiBlPt%2BSBbl0rN1IX2kI%2FO2GOrS5BnY1ckBoKlY6pOkvQY0hhRBasS14ESTZA9%2BSb3jYZN%2BBVWWJZoYI0Dre%2Fs9sNo%2BvxMBkO6P9IW3bsaDFHuD1H80LR0%2BTfqHL6qDE4BAjkQduPGf%2B6W%2BbbEIT0gw0KJj0%2FlC32nkMUe7V7vhKw7jbsE4EO4zIyAZlCuyB9efwQyCRIQ9ZESvUQPTQIqQm%2F9lnh%2FxT5Akme8%2FYHFTCX3NGCBjqdARwwLBx1WEWqgAlWi0uB30hiYUUcukLffLPsOMdJ61bL8fQ1hdsEtILH7zucfCeZqCdINcowOHHDbSb%2B9X3%2B3wNi6DDWJUPqW1W42XF9jZW6alPfFKgbnTPxYZ1DoGOUcBva5HiAgXpkwnGf6Iw6nD%2BMEDBHStxl3hV7iOG6ZOj8oywULTyJcG4qcO%2FSbLzhRCJPxUW3LFYA6W4qGYA%3D&X-Amz-Signature=09011e3932da6046d4ef11aeee28cb3f5e5f9971035b05cdc13bb4c530110817"
  let urlWithoutParams = fileUrl.split('?')[0];

  // defines where we should slice to exclude the base url information
  const NUMBER_SPLITS_BASE_URL = 4;
  let urlSplitted = urlWithoutParams.split('/');
  let urlWithoutBaseUrl = urlSplitted
    .slice(NUMBER_SPLITS_BASE_URL, urlSplitted.length)
    .join('/');

  // split the filename from the url
  let fileName = urlWithoutBaseUrl.split('/').slice(-1)[0];

  // split the filename to fetch org and study information
  let splittedFileName = fileName.split('-');

  // split the end of the filename to fetch site and report period. Also replace "W" for "_" to easily parse the week and year
  const PREFIX_WEEK = 'W';
  let splittedSuffix = splittedFileName[splittedFileName.length - 1]
    .replace(PREFIX_WEEK, '_')
    .split('_');

  // retrieve site and period information
  let siteId = splittedSuffix[0];

  let year = Number(splittedSuffix[1]);
  let week = Number(splittedSuffix[2]);

  return { siteId, year, week };
}

export function makeSiteInfo(
  processedData: Array<UserAdherence>,
  url: string,
  expirationDate: number
) {
  const totalUsers = processedData.length;
  const usersWithNoData = getUsersWithNoData(processedData);
  const { siteId, year, week } = parseSiteInfo(url);

  return new AdherenceSiteInfo(
    url,
    siteId,
    year,
    week,
    totalUsers,
    usersWithNoData.length,
    expirationDate
  );
}
