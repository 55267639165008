import React, { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button } from '@material-ui/core';
import { Trans } from 'react-i18next';
import {
  EnrollmentSetupImageConfig,
  generateSetupImage,
  SetupImageData,
} from '../../Model/Subject';
import hybridCutBackgroundUrl from './hybrid-cut.png';
import hybridBackgroundUrl from './hybrid.png';
import { generateQr, loadImage } from '../../Model/Utils';
import useWindowSize from '../../Hooks/useWindowSize';
import SiteContext from '../../Context/SiteContext';

export const generateCredentials = (email: string, password: string): string =>
  JSON.stringify({ email, password });

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
  },
  email: {
    '& input': {
      fontSize: '0.9rem',
      color: '#404040',
    },
  },
  password: {
    '& input': {
      fontFamily: 'monospace',
      letterSpacing: '0.5rem',
      color: '#404040',
    },
  },
  qrcode: {
    border: '1px solid #969696',
    borderRadius: '4px',
    color: '#969696',
    padding: '8px',
  },
  qrcodeLabel: {
    marginTop: '-16px',
    fontSize: '12px',
    lineHeight: '12px',
    position: 'absolute',
    background: '#ffffff',
    padding: '0px 4px',
  },
  warning: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '4px',
    color: theme.palette.primary.main,
    fontStyle: 'italic',
    margin: theme.spacing(0, 8),
    padding: theme.spacing(1),
    fontWeight: 500,
    display: 'flex',

    '& > svg': {
      fontSize: '1.25rem',
      margin: theme.spacing(1, 2),
    },
  },
}));

export interface EnrollmentCreationStepCredentialsProps {
  orgId: string;
  studyId: string;
  siteId: string;
  email?: string;
  password?: string;
  enrollment: any;
  onClose?(): void;
}

export default function EnrollmentCreationStepCredentials({
  email = '',
  password = '',
  enrollment,
  onClose,
}: EnrollmentCreationStepCredentialsProps) {
  const classes = useStyles();
  const [imageUrl, setImageUrl] = useState('');
  const { site } = useContext(SiteContext);
  const canvasContainerRef = useRef<HTMLDivElement | null>(null);
  const { width: windowWidth, height: windowHeight } = useWindowSize();

  // Reserve the correct size to the image to avoid dialog resizing
  useEffect(() => {
    if (!canvasContainerRef.current) {
      return;
    }

    const smallestDimension = Math.min(windowWidth, windowHeight);
    // 90% of the smallest dimension
    const imageWidth = smallestDimension * 0.9;
    const imageHeight = (imageWidth / 2480) * 2286;

    canvasContainerRef.current.style.width = `${imageWidth}px`;
    canvasContainerRef.current.style.height = `${imageHeight}px`;
  }, [windowHeight, windowWidth]);

  useEffect(() => {
    const updateImage = async () => {
      if (!canvasContainerRef.current) {
        return;
      }

      // Start downloading the background to use in the image
      const backgroundPromise = loadImage(hybridBackgroundUrl);

      // Generate the preview image to display it as soon as possible
      const cutBackground = await loadImage(hybridCutBackgroundUrl);
      const qrCanvas = generateQr(generateCredentials(email, password));

      const data: SetupImageData = {
        email,
        password,
        orgName: site?.study?.organization?.name?.toUpperCase() || '',
        studyInternalId: site?.study?.internalId || '',
        siteInternalId: site?.internalId || '',
        internalId: enrollment?.internalId || '',
      };

      const baseConfig: Omit<EnrollmentSetupImageConfig, 'background'> = {
        orgName: {
          x: 2309,
          y: 214,
          textAlign: 'right',
          fontSize: 50,
          fontWeight: 'bold',
          font: '"Apex New", sans-serif',
          color: '#008598',
        },
        participantId: {
          x: 2309,
          y: 284,
          textAlign: 'right',
          fontSize: 50,
          font: '"Apex New", sans-serif',
          color: '#202120',
        },
        qrCode: {
          x: 1345,
          y: 1387,
          size: 263,
        },
        email: {
          x: 1734,
          y: 1409,
          fontSize: 26,
          font: '"Inconsolata", monospace',
          color: 'rgba(0, 0, 0, 0.6)',
        },
        password: {
          x: 1734,
          y: 1609,
          fontSize: 26,
          font: '"Inconsolata", monospace',
          color: 'rgba(0, 0, 0, 0.6)',
        },
        generatedAt: {
          x: 1782,
          y: 1205,
          fontSize: 26,
          font: '"Inconsolata", monospace',
          color: 'rgba(0, 0, 0, 0.6)',
        },
      };

      const previewImage = await generateSetupImage(qrCanvas, data, {
        ...baseConfig,
        background: cutBackground,
      });

      previewImage.style.width = previewImage.style.height = '100%';
      canvasContainerRef.current.innerHTML = '';
      canvasContainerRef.current.appendChild(previewImage);

      // Generate the link to the final image
      const background = await backgroundPromise;

      const finalImage = await generateSetupImage(qrCanvas, data, {
        ...baseConfig,
        background,
      });
      const finalImageUrl = finalImage.toDataURL();

      setImageUrl(finalImageUrl);
    };

    updateImage();
  }, [
    email,
    enrollment?.internalId,
    password,
    site?.internalId,
    site?.study?.internalId,
    site?.study?.organization?.name,
  ]);

  const close = () => {
    onClose?.();
  };

  return (
    <div id="subject-credentials" className={classes.container}>
      <div ref={canvasContainerRef} />
      <Box padding={4} textAlign="right" bgcolor="#fff">
        <Button
          type="button"
          color="default"
          onClick={close}
          id="btn-add-user-cancel"
        >
          <Trans i18nKey="CLOSE_WITHOUT_DOWNLOADING" />
        </Button>
        <span>&nbsp;</span>
        <Button
          component="a"
          download={`${site?.study?.organization?.internalId}_${site?.study?.internalId}_${site?.internalId}_${enrollment?.internalId}.png`}
          href={imageUrl}
          variant="contained"
          color="primary"
          onClick={close}
          id="btn-add-user-download"
          disabled={!imageUrl}
        >
          <Trans i18nKey="DOWNLOAD_AND_CLOSE" />
        </Button>
      </Box>
    </div>
  );
}
