import axios from 'axios';
import { BaseLogEntry } from '../../Model/LogEntry';
import { AppEnvironment } from '../../Logging/Winston/WinstonLoggerFactory';

// The ElasticClient class is responsible for directly interacting with our ElasticSearch
// client, as well as transforming the data to fit its requirements
export class ElasticClient {
  private url: string;

  constructor() {
    this.url = this.getEsUrl();
  }

  addDocument(logEntry: BaseLogEntry) {
    const authHeader = {
      Authorization: `Basic ${process.env.REACT_APP_ELASTICSEARCH_SECRET_TOKEN}`,
    };

    if (this.url) {
      axios
        .post(this.url, logEntry, { headers: authHeader })
        .then(() => {
          return;
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  getEsUrl(): string {
    const host = process.env.REACT_APP_ELASTICSEARCH_HOST;
    const index = process.env.REACT_APP_ELASTICSEARCH_INDEX;

    if (
      !host &&
      process.env.REACT_APP_ENVIRONMENT === AppEnvironment.production
    ) {
      console.error(
        'No Elasticsearch host defined. Logging functionalities may not work.'
      );
      return '';
    }

    if (
      !index &&
      process.env.REACT_APP_ENVIRONMENT === AppEnvironment.production
    ) {
      console.error(
        'No Elasticsearch index defined. Logging functionalities may not work.'
      );
      return '';
    }

    return `${host}/${index}/_doc`;
  }
}
