import React, { useState, useEffect, useContext } from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import PulseRates from '../../Model/PulseRate';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  BiomarkerPulseRateMin,
  BiomarkerPulseRateMax,
} from '../../Model/PulseRate';
import { MillisecondsInMinute } from '../../Model/Biomarker';
import i18next from 'i18next';
import { getChartHoles, getChartMedianLines } from '../../Model/Utils';
import UserContext from '../../Context/UserContext';
import { renderRealtimePulseRateLegend } from './LegendUtils';
import {
  TimerangeRealtime6h,
  TimerangeRealtime12h,
} from '../../Model/Biomarker';
import SubjectChartTimezoneInfo from './SubjectChartTimezoneInfo';

dayjs.extend(utc);

export function getChartMax(pulseRateMax: number | null | undefined): number {
  if (!pulseRateMax) {
    return BiomarkerPulseRateMax;
  }
  return Math.floor(pulseRateMax / 20) * 20 + 20;
}

export function getChartMin(pulseRateMin: number | null | undefined): number {
  if (!pulseRateMin) {
    return BiomarkerPulseRateMin;
  }
  return Math.floor(pulseRateMin / 20) * 20 - 20;
}

export default function SubjectChartPulseRateMinutePrecision(props: any) {
  const [pulseRates, setPulseRates] = useState<PulseRates | undefined>();
  const [chart, setChart] = React.useState(<div>Loading</div>);
  const { config } = useContext(UserContext);

  useEffect(() => {
    setPulseRates(props.values);
  }, [props.values]);

  useEffect(() => {
    if (!pulseRates) {
      return;
    }

    // Retrieve list of values for the given precision
    const series: Array<number | null> = pulseRates.getPrecisionValues(
      props.timerange?.precision
    );

    // Generate labels based on the series
    const labels = series.map((v: any, i: number) => {
      const label = dayjs(pulseRates.from + i * MillisecondsInMinute)
        .add(props.timezone.offset, 's')
        .utc()
        .format(config.timeFormat);

      const startOfHour = dayjs(pulseRates.from + i * MillisecondsInMinute)
        .add(props.timezone.offset, 's')
        .utc()
        .startOf('hour')
        .format(config.timeFormat);

      if (label !== startOfHour) {
        return null;
      }
      return label;
    });

    // Get the median. Set null if missing (prevents median lines and labels to show in the chart)
    const median = pulseRates.median
      ? parseFloat(pulseRates.median.toFixed(1))
      : null;
    const compareMedian = pulseRates.compareMedian
      ? parseFloat(pulseRates.compareMedian.toFixed(1))
      : null;
    const max = getChartMax(pulseRates.max);
    const min = getChartMin(pulseRates.min);

    // Chart options
    const holes = getChartHoles(
      pulseRates.compliance,
      pulseRates.complianceHourly,
      pulseRates.complianceDaily,
      props.timerange?.precision
    );
    const chartStepSize = 10;
    const lines = getChartMedianLines(
      median,
      compareMedian,
      props.timerange?.precision,
      chartStepSize
    );
    const options = {
      legend: {
        display: false,
      },
      elements: {
        line: {
          tension: 0, // disables bezier curves
        },
      },
      gridLines: {
        color: '#E3E3E3',
      },
      scales: {
        yAxes: [
          {
            ticks: {
              startAtZero: false,
              max: max,
              min: min,
            },
            afterFit: function (scaleInstance: any) {
              scaleInstance.width = 40; // sets the width to 40px
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              userCallback: function (item: any, index: number) {
                if (item) return item;
              },
              autoSkip: false,
            },
          },
        ],
      },
      annotation: {
        annotations: [...holes, ...lines],
      },
      tooltips: {
        mode: 'index',
        intersect: false,
        callbacks: {
          title: function (tooltipItem: any, data: any) {
            return dayjs(
              pulseRates.from + tooltipItem[0]['index'] * MillisecondsInMinute
            )
              .add(props.timezone.offset, 's')
              .utc()
              .startOf(props.timerange?.precision)
              .format(`${config.dateFormat} - ${config.timeFormat}`);
          },
          label: function (tooltipItem: any, data: any) {
            var label = data.datasets[tooltipItem.datasetIndex].label;
            var value =
              data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            return label + ': ' + value + 'bpm';
          },
        },
      },
      maintainAspectRatio: false,
    };

    var label = i18next.t('MINUTE_VALUES');
    const solid: number[] = [];
    const dataset = [
      {
        label: label,
        borderDash: solid,
        backgroundColor: 'rgba(0,0,0,0)',
        borderColor: '#D0021B',
        borderWidth: 1.5,
        hoverBackgroundColor: 'rgba(0,0,0,0)',
        hoverBorderColor: '#D0021B',
        pointRadius: 4,
        pointBorderColor: 'transparent',
        pointBackgroundColor: 'transparent',
        pointHoverRadius: 4,
        pointHoverBackgroundColor: '#D0021B',
        data: series,
      },
    ];

    if (pulseRates.median ?? -1 > 0) {
      const median = new Array(series.length);
      median.fill(pulseRates.median);

      let medianLabel = i18next.t('TODAYS_MEDIAN');
      if (props.timerange?.range === TimerangeRealtime6h) {
        medianLabel = i18next.t('LAST_6H_MEDIAN');
      }
      if (props.timerange?.range === TimerangeRealtime12h) {
        medianLabel = i18next.t('LAST_12H_MEDIAN');
      }

      dataset.push({
        label: medianLabel,
        borderDash: [4, 4],
        backgroundColor: 'rgba(0,0,0,0)',
        borderColor: 'rgba(64,64,64,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(0,0,0,0)',
        hoverBorderColor: 'rgba(64,64,64,1)',
        pointRadius: 4,
        pointBorderColor: 'transparent',
        pointBackgroundColor: 'transparent',
        pointHoverRadius: 4,
        pointHoverBackgroundColor: 'rgba(64,64,64,1)',
        data: median,
      });
    }

    // Chart data options and set
    const data = {
      labels: labels,
      legend: {
        display: false,
      },
      tooltip: {
        display: true,
      },
      datasetFill: false,
      datasets: dataset,
    };
    setChart(<Line data={data} options={options} height={320} />);
  }, [pulseRates, props.timerange, config, props.timezone]);

  return (
    <div>
      {renderRealtimePulseRateLegend(props.timerange.range)}
      {chart}
      <SubjectChartTimezoneInfo offsetInSeconds={props.timezone.offset} />
    </div>
  );
}
