import React from 'react';
import { Modal, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const defaultWidth: number = 600;
const defaultHeight: number = 600;

function getModalStyle(width: number | undefined, height: number | undefined) {
  const top = 50;
  const left = 50;
  const w = width || defaultWidth;
  const h = height || defaultHeight;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    width: w,
    height: h,
  };
}

const useStyles = makeStyles((theme) => ({
  content: {
    position: 'relative',
    width: 600,
    backgroundColor: '#FFFFFF',
    borderRadius: 4,
  },
  actions: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    padding: theme.spacing(2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(2),
    },
  },
}));

export class ModalBaseProps {
  open!: boolean;
  component!: JSX.Element;
  primaryAction?: ModalBasePropAction;
  secondaryAction?: ModalBasePropAction;
  width?: number;
  height?: number;
}

export class ModalBasePropAction {
  label!: string;
  func!: any;
}

export default function ModalBase(props: ModalBaseProps) {
  const classes = useStyles();
  const modalStyle = getModalStyle(props.width, props.height);

  const getActionBtn = (
    pa: ModalBasePropAction | undefined,
    color: 'primary' | 'default' | 'inherit' | 'secondary' | undefined
  ): JSX.Element | null => {
    if (!pa) {
      return null;
    }
    const variant = color === 'primary' ? 'contained' : undefined;
    return (
      <Button type="button" color={color} variant={variant} onClick={pa.func}>
        {pa.label}
      </Button>
    );
  };

  const primaryActionBtn = getActionBtn(props.primaryAction, 'primary');
  const secondaryActionBtn = getActionBtn(props.secondaryAction, 'default');

  return (
    <Modal open={props.open}>
      <div style={modalStyle} className={classes.content}>
        <Box>{props.component}</Box>
        <Box className={classes.actions}>
          {secondaryActionBtn}
          {primaryActionBtn}
        </Box>
      </div>
    </Modal>
  );
}
