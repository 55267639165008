import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, AppBar, Tabs, Tab, Tooltip } from '@material-ui/core';
import i18next from 'i18next';
import { withRouter } from 'react-router';
import Timerange from '../../Model/Timerange';
import TimerangeItem, {
  TimerangeState,
  getEnabledTimeranges,
  getTimerangeState,
} from './TimerangeItem';
import SubjectViewTimezoneInfo from '../SubjectView/SubjectViewTimezoneInfo';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: '1px solid #DFE3E8',
    backgroundColor: '#ffffff',
  },
  tabs: {
    borderBottom: '1px solid #DFE3E8',
    backgroundColor: '#ffffff',
  },
  tab: {
    fontSize: '12px',
    minWidth: '0',
  },
  tabContent: {
    display: 'block',
    fontSize: '13px',
  },
  tabTitle: {
    fontWeight: 'bold',
    marginRight: '4px',
  },
  tabSubtitle: {
    textTransform: 'lowercase',
  },
}));

function getStatusFromRange(r: string, tz: number): Timerange {
  return new Timerange(r, tz);
}

function getInitialStatus(
  enabled: TimerangeItem[],
  query: string
): TimerangeState {
  if (!enabled || enabled.length === 0) {
    return {
      id: 0,
      key: '',
    };
  }
  var ts: TimerangeState = {
    id: 0,
    key: enabled[0].key,
  };
  const queryTimerange = getTimerangeState(enabled, query);
  if (queryTimerange) {
    ts = queryTimerange;
  }
  return ts;
}

function TimerangeSelector(props: any) {
  const urlQuery =
    new URLSearchParams(props.location.search).get('view') || '6h';
  const offsetInSeconds = props.timezone ? props.timezone.offset : 0;
  const classes = useStyles();
  const enabledTimeranges: TimerangeItem[] = getEnabledTimeranges(
    props.enabledViews
  );
  const [timerangeState, setTimerangeState] = useState<TimerangeState>(
    getInitialStatus(enabledTimeranges, urlQuery)
  );
  const [state, setState] = useState<Timerange>(
    getStatusFromRange(timerangeState.key, offsetInSeconds)
  );

  // Handle initial state
  if (props.onChange) {
    props.onChange(state);
  }

  // Build Tabs
  const timerangeTabs = enabledTimeranges.map(
    (t: TimerangeItem, i: number): any => {
      return (
        <Tooltip
          title={i18next.t(t.description) || ''}
          placement="bottom-start"
          key={`view-selector-${t.key}`}
        >
          <Tab
            className={classes.tab}
            id={t.key}
            value={i}
            label={
              <React.Fragment>
                <div className={classes.tabContent}>
                  <span className={classes.tabTitle}>{i18next.t(t.title)}</span>
                  <span className={classes.tabSubtitle}>
                    {i18next.t(t.subtitle)}
                  </span>
                </div>
              </React.Fragment>
            }
          />
        </Tooltip>
      );
    }
  );

  // Callback for tab change
  const tabChange = (_: React.ChangeEvent<{}>, id: number) => {
    const key = enabledTimeranges[id].key;
    const ts = {
      id: id,
      key: key,
    };
    const s = getStatusFromRange(key, offsetInSeconds);
    setTimerangeState(ts);
    setState(s);
    props.history.push({
      search: `?view=${key}`,
    });
    if (!props.onChange) {
      return;
    }
    props.onChange(s);
  };

  let offset = new Date().getTimezoneOffset() * 60 * -1;
  if (!props.enabledViews || props.enabledViews.length !== 1) {
    offset = offsetInSeconds;
  }

  return (
    <AppBar
      position="static"
      color="secondary"
      elevation={0}
      className={classes.tabs}
    >
      <Grid justify="space-between" container>
        <Grid item>
          <Tabs
            value={timerangeState.id}
            onChange={tabChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="simple tabs example"
          >
            {timerangeTabs}
          </Tabs>
        </Grid>
        <Grid item>
          <Box p={1.5}>
            {props.showTimezoneInfo && (
              <SubjectViewTimezoneInfo offsetInSeconds={offset} />
            )}
          </Box>
        </Grid>
      </Grid>
    </AppBar>
  );
}

export default withRouter(TimerangeSelector);
