import dayjs from 'dayjs';
import i18next from 'i18next';
import {
  TimerangeRealtime6h,
  TimerangeRealtime12h,
  Timerange2d,
  Timerange10d,
  TimerangeAuraEDRI,
  Timerange30d,
  TimerangeAuraEDRITrend,
  S3BrowserView,
} from '../../Model/Biomarker';
import UserConfig, { TIME_FORMAT_DEFAULT } from '../../Model/UserConfig';

export default class TimerangeItem {
  key!: string;
  title!: string;
  subtitle!: string;
  description!: string;
}

export class TimerangeState {
  id!: number;
  key!: string;
}

const timeranges: TimerangeItem[] = [
  {
    key: TimerangeRealtime6h,
    title: 'TIME_RANGE_REAL_TIME_6H_TITLE',
    subtitle: 'TIME_RANGE_REAL_TIME_6H_SUBTITLE',
    description: 'TIME_RANGE_REAL_TIME_6H_DESCRIPTION',
  },
  {
    key: TimerangeRealtime12h,
    title: 'TIME_RANGE_REAL_TIME_12H_TITLE',
    subtitle: 'TIME_RANGE_REAL_TIME_12H_SUBTITLE',
    description: 'TIME_RANGE_REAL_TIME_12H_DESCRIPTION',
  },
  {
    key: Timerange2d,
    title: 'TIME_RANGE_TODAY_TITLE',
    subtitle: 'TIME_RANGE_TODAY_SUBTITLE',
    description: 'TIME_RANGE_TODAY_DESCRIPTION',
  },
  {
    key: Timerange10d,
    title: 'TIME_RANGE_10D_TITLE',
    subtitle: 'TIME_RANGE_10D_SUBTITLE',
    description: 'TIME_RANGE_10D_DESCRIPTION',
  },
  {
    key: TimerangeAuraEDRI,
    title: 'TIME_RANGE_AURAEDRI_TITLE',
    subtitle: 'TIME_RANGE_AURAEDRI_SUBTITLE',
    description: 'TIME_RANGE_AURAEDRI_DESCRIPTION',
  },
  {
    key: Timerange30d,
    title: 'TIME_RANGE_REAL_TIME_30D_TITLE',
    subtitle: 'TIME_RANGE_REAL_TIME_30D_SUBTITLE',
    description: 'TIME_RANGE_REAL_TIME_30D_DESCRIPTION',
  },
  {
    key: TimerangeAuraEDRITrend,
    title: 'TIME_RANGE_AURAEDRI_TREND_TITLE',
    subtitle: 'TIME_RANGE_AURAEDRI_TREND_SUBTITLE',
    description: 'TIME_RANGE_AURAEDRI_TREND_DESCRIPTION',
  },
  {
    key: S3BrowserView,
    title: 'BROWSER_VIEW_TITLE',
    subtitle: 'BROWSER_VIEW_SUBTITLE',
    description: 'BROWSER_VIEW_DESCRIPTION',
  },
];

export function getTimeranges(): TimerangeItem[] {
  return timeranges;
}

export function getEnabledTimeranges(enabled: string[]): TimerangeItem[] {
  if (!enabled || enabled.length === 0) {
    return [];
  }
  return timeranges.filter((t) => {
    return enabled.includes(t.key);
  });
}

export function getTimerangeState(
  enabled: TimerangeItem[],
  key: string
): TimerangeState | null {
  for (let i = 0; i < enabled.length; i++) {
    if (enabled[i].key === key) {
      return {
        id: i,
        key: key,
      };
    }
  }
  return null;
}

export function getTimerangeFormat(range: string, config: UserConfig) {
  const dateFormat = 'MMM DD, YYYY';
  switch (range) {
    case TimerangeRealtime6h:
    case TimerangeRealtime12h:
      return [`${dateFormat} - ${config.timeFormat}`, `${config.timeFormat}`];
    case Timerange2d:
    case Timerange10d:
      return [`${dateFormat}`, `${dateFormat}`];
    case Timerange30d:
      return [`${dateFormat}`, `${dateFormat}`];
    case TimerangeAuraEDRI:
      return [`${dateFormat}`, `${dateFormat}`];
    case TimerangeAuraEDRITrend:
      return [`${dateFormat}`, `${dateFormat}`];
  }
  const defaultFormat = `${dateFormat} - ${TIME_FORMAT_DEFAULT}`;
  return [defaultFormat, defaultFormat];
}

export function getDateIntervalText(
  from: number,
  to: number,
  offsetInSeconds: number,
  formatFrom: string,
  formatTo: string
) {
  let intervalFrom = dayjs
    .utc(from)
    .add(offsetInSeconds, 's')
    .format(formatFrom);
  let intervalTo = dayjs.utc(to).add(offsetInSeconds, 's').format(formatTo);

  return `${i18next.t('INTERVAL')}: ${intervalFrom} ${i18next.t(
    'TO'
  )} ${intervalTo}`;
}
