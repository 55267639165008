import i18next from 'i18next';

export const UndefinedSecondsAgoElement = {
  value: undefined,
  unitKey: undefined,
};

/* Used to return a given element the value (as string), and the unit (as i18n key) */
export function getElementValueAndUnit(seconds: number | undefined) {
  if (seconds === undefined) {
    return UndefinedSecondsAgoElement;
  }

  const oneMinute = 60;
  // < 1 minute => < 1 min
  if (seconds < oneMinute) {
    return {
      value: `< 1`,
      unitKey: 'MINUTE_SINGULAR',
    };
  }

  // < 1 hour => X min
  const oneHour = oneMinute * 60;
  if (seconds < oneHour) {
    const minutes = Math.round(seconds / oneMinute);
    return {
      value: `${minutes}`,
      unitKey: minutes === 1 ? 'MINUTE_SINGULAR' : 'MINUTE_PLURAL',
    };
  }

  // < 1 day => X hrs
  const oneDay = oneHour * 24;
  if (seconds < oneDay) {
    const hours = Math.floor(seconds / oneHour);
    const minutes = Math.round((seconds % oneHour) / oneMinute);
    const minutesPad = String(minutes).padStart(2, '0');

    return {
      value: `${hours}h${minutesPad}`,
      unitKey: minutes === 1 ? 'MINUTE_SINGULAR' : 'MINUTE_PLURAL',
    };
  }

  // < 1 week => X days
  const oneWeek = oneDay * 7;
  if (seconds < oneWeek) {
    const days = Math.round(seconds / oneDay);
    return {
      value: `${days}`,
      unitKey: days === 1 ? 'DAY_SINGULAR' : 'DAY_PLURAL',
    };
  }

  // < 1 month => X weeks
  const oneMonth = oneDay * 30;
  if (seconds <= oneMonth) {
    const weeks = Math.round(seconds / oneWeek);

    return {
      value: `${weeks}`,
      unitKey: weeks === 1 ? 'WEEK_SINGULAR' : 'WEEK_PLURAL',
    };
  }

  // if no previous condition was valid, it means that the last sync was more than 1 month ago
  return {
    value: '> 30',
    unitKey: 'DAY_PLURAL',
  };
}

/** Used to return the given value and unit for a specified amount of seconds **/
export function secondsAgoToElement(seconds: number | undefined) {
  const { value, unitKey } = getElementValueAndUnit(seconds);

  return {
    value: value,
    unit: i18next.t(unitKey ? unitKey : ''),
  };
}
