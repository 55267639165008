import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography, makeStyles } from '@material-ui/core';
import { Trans } from 'react-i18next';
import PulseRates from '../../Model/PulseRate';
import PulseRateIcon from '../../Icons/PulseRateIcon';
import SubjectChartPulseRateMinutePrecision from './SubjectChartPulseRateMinutePrecision';
import SubjectChartPulseRateHourPrecision from './SubjectChartPulseRateHourPrecision';
import SubjectChartPulseRateDayPrecision from './SubjectChartPulseRateDayPrecision';
import Timerange from '../../Model/Timerange';
import {
  BiomarkerPrecisionMinute,
  BiomarkerPrecisionDay,
  BiomarkersResponse,
} from '../../Model/Biomarker';
import PulseRateBar from '../../Icons/PulseRateArea.svg';
import PulseRateLineOne from '../../Icons/PulseRateLineOne.svg';
import PulseRateLineTwo from '../../Icons/PulseRateLineTwo.svg';
import { ViewTypes } from '../SubjectView/SubjectView';
import SubjectMedianInfo from './SubjectMedianInfo';
import Timezone from '../../Model/Timezone';

const useStyles = makeStyles((theme) => ({
  chartGrid: {
    display: 'grid',
    gridTemplateColumns: '12rem 1fr',
    gridTemplateRows: '1fr',
    gridColumnGap: 0,
    gridRowGap: 0,
    width: '100%',
  },
  chartLeft: {
    height: 'auto',
    color: '#485164',
  },
  chartRight: {
    height: '28rem',
  },
}));

export enum days {
  today = 'today',
  yesterday = 'yesterday',
  tenDays = '10 days',
}

export class SubjectViewPulseRateProps {
  userId?: number;
  timerange?: Timerange;
  timezone?: Timezone;
  data?: BiomarkersResponse;
}

export default function SubjectViewPulseRate(props: SubjectViewPulseRateProps) {
  const classes = useStyles();
  const [pulseRates, setPulseRates] = useState<PulseRates>(
    new PulseRates(undefined)
  );
  const [lastValue, setLastValue] = useState('-');
  const [median, setMedian] = useState('-');
  const [compareValue, setCompareValue] = useState('-');

  useEffect(() => {
    if (!props.userId || !props.timerange || !props.data) {
      return;
    }

    const hr = new PulseRates(
      props?.data?.from,
      props?.data?.to,
      props?.timezone?.offset,
      props?.data?.pulseRate?.values,
      props?.data?.deviceCompliance?.values,
      props.timerange?.precision
    );

    setPulseRates(hr);
  }, [props]);

  useEffect(() => {
    if (!pulseRates) {
      return;
    }
    if (pulseRates.lastValue && pulseRates.median) {
      const v = pulseRates.lastValue.toFixed(1);
      const a = pulseRates.median.toFixed(1);
      if (props.timerange?.precision !== BiomarkerPrecisionMinute) {
        setLastValue(a);
      } else {
        setLastValue(v);
      }
      setMedian(a);
    }

    if (pulseRates.compareMedian) {
      const cv = pulseRates.compareMedian
        ? pulseRates.compareMedian.toString()
        : '-';
      setCompareValue(cv);
    }
  }, [pulseRates, props.timerange]);

  return (
    <React.Fragment>
      <Box p={2}>
        <Box className={classes.chartGrid}>
          <Box className={classes.chartLeft}>
            <Typography variant="h5" component="h5">
              <Trans i18nKey="PULSE_RATE" /> <br></br>
            </Typography>
            <Grid container direction="row" spacing={1}>
              <PulseRateIcon style={{ height: '1.4rem', marginTop: '4px' }} />
              <Grid item xs={12} sm container direction="column">
                <Grid container direction="row" spacing={1}>
                  <Grid
                    item
                    className="numberCharts"
                    style={{ color: 'rgba(64,64,64,1)' }}
                  >
                    <SubjectMedianInfo median={lastValue} day={days.today} />
                  </Grid>
                  <Grid item>
                    <div
                      className="textMicro"
                      style={{ color: 'rgba(64,64,64,1)' }}
                    >
                      <Trans i18nKey="TODAY" />
                    </div>
                    <div
                      className="textMicro"
                      style={{ color: 'rgba(64,64,64,1)' }}
                    >
                      BPM
                    </div>
                  </Grid>
                </Grid>
                {props.timerange?.range === ViewTypes.view48h && (
                  <Grid container direction="row" spacing={1}>
                    <Grid
                      item
                      className="numberCharts"
                      style={{ color: 'rgba(150,150,150,1)' }}
                    >
                      <SubjectMedianInfo
                        median={compareValue}
                        day={days.yesterday}
                      />
                    </Grid>
                    <Grid item>
                      <div
                        className="textMicro"
                        style={{ color: 'rgba(150,150,150,1)' }}
                      >
                        <Trans i18nKey="YESTERDAY_SHORT" />
                      </div>
                      <div
                        className="textMicro"
                        style={{ color: 'rgba(150,150,150,1)' }}
                      >
                        BPM
                      </div>
                    </Grid>
                  </Grid>
                )}
                {props.timerange?.range === ViewTypes.view10d && (
                  <Grid container direction="row" spacing={1}>
                    <Grid
                      item
                      className="numberCharts"
                      style={{ color: 'rgba(150,150,150,1)' }}
                    >
                      <SubjectMedianInfo
                        median={compareValue}
                        day={days.tenDays}
                      />
                    </Grid>
                    <Grid item>
                      <div
                        className="textMicro"
                        style={{ color: 'rgba(150,150,150,1)' }}
                      >
                        10 <Trans i18nKey="DAYS" />
                      </div>
                      <div
                        className="textMicro"
                        style={{ color: 'rgba(150,150,150,1)' }}
                      >
                        BPM
                      </div>
                    </Grid>
                  </Grid>
                )}
                {props.timerange?.isRealtime === false && (
                  <div>
                    <Grid container direction="row" style={{ marginTop: 100 }}>
                      <img
                        src={PulseRateBar}
                        alt="Temperature Area"
                        style={{ height: 60 }}
                      />
                      <div className="column">
                        <img
                          src={PulseRateLineOne}
                          alt="Temperature Area"
                          style={{ paddingBottom: 10 }}
                        />
                        <br></br>
                        <img
                          src={PulseRateLineTwo}
                          alt="Temperature Area"
                          style={{ paddingBottom: 5 }}
                        />
                        <br></br>
                        <img
                          src={PulseRateLineOne}
                          alt="Temperature Area"
                          style={{ paddingBottom: 0 }}
                        />
                      </div>
                      <div className="column">
                        <span style={{ color: '#878787', fontSize: 12 }}>
                          <Trans i18nKey="THIRD_QUARTILE" />
                        </span>
                        <br></br>
                        <span style={{ color: '#878787', fontSize: 12 }}>
                          <Trans
                            i18nKey={
                              props.timerange?.precision ===
                              BiomarkerPrecisionDay
                                ? 'DAILY_MEDIAN'
                                : 'HOURLY_MEDIAN'
                            }
                          />
                        </span>
                        <br></br>
                        <span style={{ color: '#878787', fontSize: 12 }}>
                          <Trans i18nKey="FIRST_QUARTILE" />
                        </span>
                      </div>
                    </Grid>
                  </div>
                )}
              </Grid>
              {props.timerange?.isRealtime === true && (
                <Grid container direction="row">
                  <div
                    style={{
                      fontSize: '14px',
                      color: 'grey',
                      marginTop: '5px',
                    }}
                  >
                    <Trans i18nKey="TIME_RANGE_REAL_TIME_6H_MEDIAN" /> {median}
                    {<span style={{ color: 'grey' }}>bpm</span>}
                  </div>
                </Grid>
              )}
            </Grid>
          </Box>
          {props.timerange?.isRealtime === true && (
            <SubjectChartPulseRateMinutePrecision
              values={pulseRates}
              timerange={props.timerange}
              timezone={props.timezone}
            />
          )}
          {props.timerange?.range === ViewTypes.view48h && (
            <SubjectChartPulseRateHourPrecision
              values={pulseRates}
              timerange={props.timerange}
              timezone={props.timezone}
            />
          )}
          {props.timerange?.range === ViewTypes.view10d && (
            <SubjectChartPulseRateDayPrecision
              values={pulseRates}
              timerange={props.timerange}
              timezone={props.timezone}
            />
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
}
