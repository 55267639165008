import React, { useContext } from 'react';
import { withRouter } from 'react-router';
import { AppBar, Grid, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/pro-light-svg-icons';
import { Trans } from 'react-i18next';
import CeMark from '../../Icons/CeMark';
import SiteContext from '../../Context/SiteContext';

const useStyles = makeStyles((theme) => ({
  subToolbar: {
    fontSize: '0.85rem',
    padding: theme.spacing(2),
    background: '#F5F8F9',
    color: '#147b8f',
  },
  heroSection: {
    textAlign: 'center',
    background: '#FAFCFC',
    color: '#147b8f',
    fontSize: '7rem',
  },
  mainContent: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(24),
      paddingRight: theme.spacing(24),
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(48),
      paddingRight: theme.spacing(48),
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: theme.spacing(64),
      paddingRight: theme.spacing(64),
    },
  },
  title: {
    color: '#404040',
    fontWeight: 'bold',
    size: '1.1 rem',
    fontFamily: 'ApexNew',
  },
  label: {
    color: '#969696',
    fontSize: '0.75rem',
  },
  secondaryContent: {
    color: '#B8B8B7',
    fontSize: '0.75rem',
  },
  ceMark: {
    height: '1rem',
    width: 'auto',
    verticalAlign: 'middle',
  },
}));

function RegulatoryView(props: any) {
  const { setSite } = useContext(SiteContext);
  setSite(null);
  const classes = useStyles();
  const appVersion = process.env.REACT_APP_VERSION || 'dev';
  const version = `${appVersion}`;
  const year = new Date().getFullYear();
  const udi = `(01) 00853858006263 (10) ${appVersion}-${year}`;
  return (
    <React.Fragment>
      <AppBar
        position="static"
        color="secondary"
        elevation={0}
        className={classes.subToolbar}
      >
        <Trans i18nKey="REGULATORY_PAGE_TITLE" />
      </AppBar>
      <Box className={classes.heroSection}>
        <FontAwesomeIcon icon={faBook} />
      </Box>
      <Box className={classes.mainContent}>
        {/* General Information */}
        <Box mt={0}>
          <Typography className={classes.title}>
            <Trans i18nKey="REGULATORY_GENERAL_INFORMATION_TITLE" />
          </Typography>
          <Box mt={2}>
            <Grid container>
              <Grid item xs={12} sm={4} md={3}>
                <label className={classes.label}>
                  <Trans i18nKey="REGULATORY_PRODUCT_NAME_LABEL" />
                </label>
                <Box>
                  <Trans i18nKey="REGULATORY_PRODUCT_NAME_CONTENT" />
                </Box>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                <label className={classes.label}>
                  <Trans i18nKey="REGULATORY_VERSION_LABEL" />
                </label>
                <Box>{version}</Box>
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            <Grid container>
              <Grid item xs={12} sm={4} md={3}>
                <label className={classes.label}>
                  <Trans i18nKey="REGULATORY_RELEASE_YEAR_LABEL" />
                </label>
                <Box>{year}</Box>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                <label className={classes.label}>
                  <Trans i18nKey="REGULATORY_UDI_LABEL" />
                </label>
                <Box>{udi}</Box>
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/* Intended use */}
        <Box mt={4}>
          <Typography className={classes.title}>
            <Trans i18nKey="REGULATORY_INTENDED_USE_TITLE" />
          </Typography>
          <Box className={classes.secondaryContent}>
            <Trans i18nKey="REGULATORY_INTENDED_USE_CONTENT" />
          </Box>
        </Box>

        {/* Legal Manufacturer */}
        <Box mt={4}>
          <Typography className={classes.title}>
            <Trans i18nKey="REGULATORY_LEGAL_MANUFACTURER_TITLE" />
          </Typography>
          <Box className={classes.secondaryContent}>
            <Trans i18nKey="REGULATORY_LEGAL_MANUFACTURER_CONTENT" />
          </Box>
        </Box>

        {/* CE Mark */}
        <Box mt={4}>
          <Box>
            <CeMark className={classes.ceMark} /> 0051
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default withRouter(RegulatoryView);
