import React from 'react';
import { Tooltip } from '@material-ui/core';
import i18next from 'i18next';

export class SubjectDeviceInfoProps {
  median?: string;
  day?: string;
}

export default function SubjectDeviceInfo(props: SubjectDeviceInfoProps) {
  if (props.median !== '-') {
    return <div>{props.median}</div>;
  }

  let textToShow = '';
  if (props.day === 'today') {
    textToShow = i18next.t('TODAYS_MEDIAN_TEXT');
  } else if (props.day === 'yesterday') {
    textToShow = i18next.t('YESTERDAYS_MEDIAN_TEXT');
  } else if (props.day === '10 days') {
    textToShow = i18next.t('10DAYS_MEDIAN_TEXT');
  }
  if (textToShow) {
    return (
      <Tooltip
        title={
          <React.Fragment>
            <span>
              <span>{textToShow}</span>
            </span>
          </React.Fragment>
        }
        placement="bottom-start"
      >
        <span>-</span>
      </Tooltip>
    );
  }
  return (
    <Tooltip
      title={
        <React.Fragment>
          <span></span>
        </React.Fragment>
      }
      placement="bottom-start"
    >
      <span>-</span>
    </Tooltip>
  );
}
