import 'chartjs-plugin-annotation';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Adherence } from '../../../Model/Adherence';
import { AdherenceSiteInfo } from '../../../Model/AdherenceSiteInfo';
import { getPrettyAverage } from '../../Utils/ChartUtils';

dayjs.extend(utc);

class AdherenceReportComplianceChartProps {
  data: Record<string, Adherence> = {};

  siteInfo?: AdherenceSiteInfo;
}

function getAdherenceValues(
  values: Record<string, Adherence> | undefined
): {
  notConnected: number[];
  notWornCorrectly: number[];
  wornCorrectly: number[];
  notConnectedMin: number[];
  notWornCorrectlyMin: number[];
  wornCorrectlyMin: number[];
} {
  const arrayWornCorrectly: number[] = [];
  const arrayNotWornCorrectly: number[] = [];
  const arrayNotConnected: number[] = [];

  const arrayWornCorrectlyMin: number[] = [];
  const arrayNotWornCorrectlyMin: number[] = [];
  const arrayNotConnectedMin: number[] = [];

  for (let dataKey in values) {
    let dayValues = values[dataKey]; // all values (compliant, non compliant, ...) for a single day

    arrayWornCorrectly.push(dayValues.device_worn_correctly_min / 60);
    arrayNotWornCorrectly.push(dayValues.device_not_worn_correctly_min / 60);
    arrayNotConnected.push(dayValues.device_not_recording_min / 60);

    arrayWornCorrectlyMin.push(dayValues.device_worn_correctly_min);
    arrayNotWornCorrectlyMin.push(dayValues.device_not_worn_correctly_min);
    arrayNotConnectedMin.push(dayValues.device_not_recording_min);
  }

  const result = {
    wornCorrectly: arrayWornCorrectly,
    notWornCorrectly: arrayNotWornCorrectly,
    notConnected: arrayNotConnected,
    wornCorrectlyMin: arrayWornCorrectlyMin,
    notWornCorrectlyMin: arrayNotWornCorrectlyMin,
    notConnectedMin: arrayNotConnectedMin,
  };

  return result;
}

function getLabelsForDate(
  startDate: number | undefined,
  endDate: number | undefined
) {
  let dateArray = [];
  let currentDate = dayjs(startDate);
  let stopDate = dayjs(endDate);
  while (currentDate <= stopDate) {
    dateArray.push(dayjs(currentDate).format('ddd, MMM DD'));
    currentDate = dayjs(currentDate).add(1, 'd');
  }
  return dateArray;
}

export default function AdherenceReportComplianceChart(
  props: AdherenceReportComplianceChartProps
) {
  const [values, setValues] = useState<Record<string, Adherence> | undefined>();
  const [startDate, setStartDate] = useState<number | undefined>();
  const [endDate, setEndDate] = useState<number | undefined>();
  const [chart, setChart] = useState(<div>Loading</div>);

  useEffect(() => {
    setValues(props.data);

    const startDate = props.siteInfo?.startDate.getTime();
    const endDate = props.siteInfo?.endDate.getTime();
    setStartDate(startDate);
    setEndDate(endDate);
  }, [props.data, props.siteInfo]);

  useEffect(() => {
    const adherenceValues = getAdherenceValues(values);

    const labels = getLabelsForDate(startDate, endDate);

    const lines = [
      {
        type: 'line',
        mode: 'vertical',
        scaleID: 'x-axis-0',
        value: 10,
        borderColor: 'rgba(0,0,0,0.0)',
        borderWidth: 0,
        label: {
          backgroundColor: 'rgba(0,0,0,0.7)',
          enabled: true,
          content: i18next.t('NOW'),
          position: 'top',
          xAdjust: 20,
        },
      },
    ];

    // Chart options
    const options = {
      legend: {
        display: false,
      },
      annotation: {
        annotations: lines,
      },
      tooltips: {
        position: 'nearest',
        mode: 'label',
        callbacks: {
          title: function (item: any, data: any) {
            return item[0]['label'];
          },
          label: function (item: any, data: any) {
            const label = data.datasets[item.datasetIndex].label;
            switch (item.datasetIndex) {
              // WORN_CORRECTLY
              case 0:
                return (
                  label +
                  ': ' +
                  getPrettyAverage(adherenceValues.wornCorrectlyMin[item.index])
                );
              // NOT_WORN_CORRECTLY
              case 1:
                return (
                  label +
                  ': ' +
                  getPrettyAverage(
                    adherenceValues.notWornCorrectlyMin[item.index]
                  )
                );
              // NOT_CONNECTED
              case 2:
                return (
                  label +
                  ': ' +
                  getPrettyAverage(adherenceValues.notConnectedMin[item.index])
                );
              default:
                console.warn(
                  'Unexpected data series in AdherenceReportComplianceChart'
                );
                return label;
            }
          },
        },
      },
      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
              fontSize: 8,
              labelString: 'hour',
            },
            stacked: true,
            gridLines: {
              display: true,
              offsetGridLines: true,
            },
            ticks: {
              fontSize: 9,
              stepSize: 6,
              max: 24,
              min: 0,
            },
            afterFit: function (scaleInstance: any) {
              scaleInstance.width = 40; // sets the width to 40px
            },
            beginAtZero: true,
          },
        ],
        xAxes: [
          {
            stacked: true,
            gridLines: {
              display: false,
              offsetGridLines: true,
            },
            ticks: {
              fontSize: 9,
              userCallback: function (item: any, index: number) {
                if (item) return item;
              },
              autoSkip: true,
            },
          },
        ],
      },
      maintainAspectRatio: false,
    };

    const data = {
      labels: labels,
      legend: {
        display: false,
      },
      tooltip: {
        display: true,
      },
      datasetFill: false,
      datasets: [
        {
          label: i18next.t('WORN_CORRECTLY'),
          data: adherenceValues.wornCorrectly,
          barPercentage: 0.85,
          barThickness: 25,
          categoryPercentage: 0.25,
          backgroundColor: '#147B8F',
        },
        {
          label: i18next.t('NOT_WORN_CORRECTLY'),
          data: adherenceValues.notWornCorrectly,
          barPercentage: 0.85,
          barThickness: 25,
          categoryPercentage: 0.25,
          backgroundColor: '#838383',
        },
        {
          label: i18next.t('NOT_CONNECTED'),
          data: adherenceValues.notConnected,
          barPercentage: 0.85,
          barThickness: 25,
          categoryPercentage: 0.25,
          backgroundColor: '#F2F2F2',
        },
      ],
    };

    setChart(<Bar data={data} options={options} height={104} />);
  }, [values, startDate, endDate]);

  return (
    <span>
      <div>{chart}</div>
    </span>
  );
}
