import { Biomarker } from './Biomarker';

export const BiomarkerTemperatureDefaultDelta = 2;
export const BiomarkerTemperatureDefault = 36;
export const BiomarkerTemperatureMin = 28.0;
export const BiomarkerTemperatureMax = 45.0;

export const BiomarkerTemperatureComplianceThreshold = 75;

export default class Temperatures extends Biomarker {
  processValue(value?: number): number | null {
    if (value == null) return null;
    return parseFloat(value.toFixed(1)); //do nothing, temperature want float
  }

  complianceThreshold(): number {
    return BiomarkerTemperatureComplianceThreshold;
  }
}
