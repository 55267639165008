import React from 'react';
import { Box, Grid } from '@material-ui/core';
import i18next from 'i18next';
import LabelValue from '../../Items/LabelValue';
import { AdherenceSiteInfo } from '../../Model/AdherenceSiteInfo';
import { makeStyles } from '@material-ui/core/styles';
import DownloadAdherenceReportButton from './Actions/DownloadAdherenceReportButton';
import PrintAdherenceReportButton from './Actions/PrintAdherenceReportButton';
import {
  faCalendarAlt,
  faUser,
  faUserSlash,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 100,
  },
  aboutContainer: {},
  sectionTitle: {
    color: '#909095',
    letterSpacing: 0,
    fontSize: '0.6rem',
    fontWeight: 300,
    textTransform: 'uppercase',
  },
  aboutText: {
    marginTop: '14px',
    color: '#2E384D',
    fontSize: '0.8rem',
    fontWeight: 300,
  },
}));

class AdherenceHeaderProps {
  siteInfo!: AdherenceSiteInfo;
}

export default function AdherenceHeader(props: AdherenceHeaderProps) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={8}>
          <h3 className={classes.title}>
            {i18next.t('REPORT_ADHERENCE_TITLE')}
          </h3>
        </Grid>
        <Grid item xs={4}>
          <DownloadAdherenceReportButton
            url={props.siteInfo?.reportUrl}
            enabled={true}
          />
          <PrintAdherenceReportButton />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={1}>
          <LabelValue
            id="site"
            label={i18next.t('REPORT_ADHERENCE_SITE_LABEL')}
            value={props.siteInfo ? props.siteInfo.site : ''}
          />
        </Grid>
        <Grid item xs={5}>
          <LabelValue
            id="date"
            label={i18next.t('REPORT_ADHERENCE_DATE_LABEL')}
            value={props.siteInfo ? props.siteInfo.getPrettyDate() : ''}
            icon={<FontAwesomeIcon icon={faCalendarAlt} color="#969696" />}
          />
        </Grid>
        <Grid item xs={2}>
          <LabelValue
            id="total_users"
            label={i18next.t('REPORT_ADHERENCE_USERS_TOTAL_LABEL')}
            value={props.siteInfo ? props.siteInfo.totalUsers.toString() : ''}
            icon={<FontAwesomeIcon icon={faUser} color="#969696" />}
          />
        </Grid>
        <Grid item xs={2}>
          <LabelValue
            id="inactive_users"
            label={i18next.t('REPORT_ADHERENCE_USERS_INACTIVE_LABEL')}
            value={props.siteInfo ? props.siteInfo.noDataUsers.toString() : ''}
            icon={<FontAwesomeIcon icon={faUserSlash} color="#969696" />}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.aboutContainer}>
        <Grid item xs={12}>
          <span className={classes.sectionTitle}>
            {i18next.t('REPORT_ADHERENCE_ABOUT_LABEL')}
          </span>
          <Box>
            <span className={classes.aboutText}>
              {i18next.t('REPORT_ADHERENCE_ABOUT_CONTENT')}
            </span>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
