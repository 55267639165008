import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import RegulatoryView from '../RegulatoryView/RegulatoryView';
import SubjectView from '../SubjectView/SubjectView';
import SiteView from '../SiteView/SiteView';
import HomeView from '../HomeView/HomeView';
import AppDrawerNav from './AppDrawerNav';
import NotFoundView from '../ErrorViews/NotFoundView';
import ServerErrorView from '../ErrorViews/ServerErrorView';
import {
  RoutePathNotFound,
  RoutePathServerError,
  RoutePathRegulatory,
} from '../../Routes/Routes';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0),
    minHeight: 50,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
  },
  router: {
    width: '100%',
    marginTop: 50,
    textAlign: 'left',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

export default function AppDrawer() {
  const classes = useStyles();

  return (
    <Router>
      <div className={classes.root}>
        <main className={classes.content}>
          <div className={classes.toolbar}>
            <div className={classes.router}>
              <Switch>
                <Route
                  exact
                  path={RoutePathNotFound}
                  component={NotFoundView}
                />
                <Route
                  exact
                  path={RoutePathServerError}
                  component={ServerErrorView}
                />
                <Route exact path={RoutePathRegulatory}>
                  <AppDrawerNav />
                  <RegulatoryView />
                </Route>
                <Route exact path="/:orgId/:studyId/:siteId">
                  <AppDrawerNav />
                  <SiteView />
                </Route>
                <Route exact path="/:orgId/:studyId/:siteId/:enrollmentId">
                  <AppDrawerNav />
                  <SubjectView />
                </Route>
                <Route exact path="/">
                  <HomeView />
                </Route>
                <Route component={NotFoundView} />
              </Switch>
            </div>
          </div>
        </main>
      </div>
    </Router>
  );
}
