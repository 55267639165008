import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import * as BiomarkersService from '../../Http/BiomarkersService';
import * as UsageService from '../../Http/UsageService';
import * as WebEmbraceApi from '../../Http/WebEmbraceApi';
import {
  Paper,
  Box,
  Grid,
  AppBar,
  Toolbar,
  Divider,
  Button,
} from '@material-ui/core';
import SubjectViewTemperature from './SubjectViewTemperature';
import SubjectViewPulseRate from './SubjectViewPulseRate';
import SubjectViewCompliance from './SubjectViewCompliance';
import SubjectViewAuraDayPrecision from './SubjectChartAuraDayPrecision';
import SubjectViewAuraDebugView from './SubjectViewAuraDebugView';
import Subject from '../../Model/Subject';
import { Trans } from 'react-i18next';
import TimerangeSelector from '../TimerangeSelector/TimerangeSelector';
import ViewLoading from '../Utils/ViewLoading';
import Timerange from '../../Model/Timerange';
import Device from '../../Model/Device';
import Timezone from '../../Model/Timezone';
import LoadState, {
  LoadStateLoading,
  LoadStateSuccess,
  LoadStateUndefined,
  getErrorLoadStateFromApiResponse,
  LoadStateError,
} from '../../Model/LoadState';
import {
  BiomarkersResponse,
  MinutesInHour,
  Timerange10d,
  Timerange2d,
  TimerangeAuraEDRI,
  TimerangeRealtime12h,
  TimerangeRealtime6h,
} from '../../Model/Biomarker';
import { exportBiomarkersToCSV } from '../../Model/Utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug } from '@fortawesome/free-solid-svg-icons';
import ModalDebugView from '../Modals/ModalDebugView';
import App from '../../Model/App';
import SubjectDeviceInfo from './SubjectDeviceInfo';
import UserContext from '../../Context/UserContext';
import { renderErrorsIfAny } from '../ErrorViews/ErrorViewUtils';
import { AxiosError } from 'axios';
import { setTitleLoading, updateTitleForSubject } from '../Utils/TitleUtils';
import { isAuraDebugViewEnabled } from '../../Authorization/RolesAndScopes';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: '1px solid #DFE3E8',
    backgroundColor: '#ffffff',
  },
  subjectName: {
    fontSize: '0.85rem',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: '#147b8f',
  },
  serialNumber: {
    color: '#147b8f',
    paddingTop: '4px',
  },
}));

export enum ViewTypes {
  view6h = '6h',
  view12h = '12h',
  view48h = '48h',
  view10d = '10d',
}

async function GetUserBiomarkers(
  userId: number,
  timerange: Timerange
): Promise<BiomarkersService.ApiResponseUserBiomarkers> {
  return await BiomarkersService.GetUserBiomarkers(
    userId,
    timerange.from,
    timerange.to
  );
}

async function GetUserLatestTimezone(
  userId: number
): Promise<UsageService.ApiResponseUserTimezone> {
  return await UsageService.GetUserLatestTimezone(userId);
}

async function GetUserLatestDevice(
  userId: number
): Promise<UsageService.ApiResponseUserDevice> {
  return await UsageService.GetUserLatestDevice(userId);
}

async function GetUserLatestApp(
  userId: number
): Promise<UsageService.ApiResponseUserApp> {
  return await UsageService.GetUserLatestApp(userId);
}

let DefaultTimezone = new Timezone();
let localOffset = new Date().getTimezoneOffset() * MinutesInHour * -1;
DefaultTimezone!.offset = localOffset;

function SubjectView(props: any) {
  const { orgId, studyId, siteId, enrollmentId } = useParams<{
    orgId: string;
    studyId: string;
    siteId: string;
    enrollmentId: string;
  }>();
  const [loadingSubject, setLoadingSubject] = useState(true);
  const [subject, setSubject] = useState<Subject>(new Subject());
  const { config } = useContext(UserContext);
  const [timerange, setTimerange] = useState<Timerange | undefined>();
  const [auraTimerange, setAuraTimerange] = useState<Timerange | undefined>();
  const [device, setDevice] = useState<Device | undefined>();
  const [timezone, setTimezone] = useState<Timezone | undefined>(
    DefaultTimezone
  );
  const [app, setApp] = useState<App | undefined>();
  const [loadState, setLoadState] = useState(LoadStateUndefined);
  const [userAccountOpen, setDebugViewOpen] = useState(false);
  const [auraViewEnabled, setAuraViewEnabled] = useState<boolean>(false);
  const [
    biomarkersApiData,
    setBiomarkersApiData,
  ] = useState<BiomarkersResponse | null>(null);
  const [
    biomarkersData,
    setBiomarkersData,
  ] = useState<BiomarkersResponse | null>(null);
  const classes = useStyles();

  useEffect(() => {
    setTitleLoading();
    const fetchSiteEnrollments = async () => {
      if (!orgId || !studyId || !siteId || !enrollmentId) {
        return;
      }
      WebEmbraceApi.GetSubject(orgId, studyId, siteId, enrollmentId)
        .then((resp: WebEmbraceApi.ApiResponseSiteSubject) => {
          const s = new Subject(resp.data.payload);
          setSubject(s);
          setAuraViewEnabled(
            Boolean(s.site && s.site.hasEdriResearcherDisplayEnabled())
          );
        })
        .catch((err: AxiosError) => {
          let errorLoadState: LoadState = LoadStateError;

          if (err != null && err.response != null) {
            errorLoadState = getErrorLoadStateFromApiResponse(
              err.response.status
            );
          }

          setLoadState(errorLoadState);
          return;
        })
        .finally(() => {
          setLoadingSubject(false);
        });
    };

    fetchSiteEnrollments();
  }, [orgId, studyId, siteId, enrollmentId, props.history]);

  useEffect(() => {
    if (!subject.userId || !timerange || !timerange.range || !timezone) {
      return;
    }

    const fetchBiomarkersData = async (
      timerange: Timerange,
      reload: boolean
    ) => {
      if (reload) {
        setLoadState(LoadStateLoading);
        setBiomarkersData(null);
      }
      GetUserBiomarkers(subject.userId, timerange)
        .then((response) => {
          setBiomarkersApiData(response.data);
        })
        .catch((err: AxiosError) => {
          let errorLoadState: LoadState = LoadStateError;

          if (err != null && err.response != null) {
            errorLoadState = getErrorLoadStateFromApiResponse(
              err.response.status
            );
          }

          setLoadState(errorLoadState);
          setBiomarkersData(null);
        })
        .finally(() => {
          setLoadingSubject(false);
        });
    };

    fetchBiomarkersData(timerange, true);

    if (!timerange.isRealtime) {
      return;
    }

    const interval = setInterval(() => {
      fetchBiomarkersData(
        new Timerange(timerange.range, timezone.offset),
        false
      );
    }, 60000);
    return () => clearInterval(interval);
  }, [subject, timerange, timezone]);

  useEffect(() => {
    if (!biomarkersApiData) {
      return;
    }
    const getUnitBiomarkers = (b: BiomarkersResponse): BiomarkersResponse => {
      if (
        b.temperature &&
        b.temperature.values &&
        b.temperature.values.length > 0
      ) {
        b.temperature = {
          values: b.temperature?.values.map((t: number | null) => {
            if (t === null) {
              return null;
            }
            return config.measurementUnits.getUnitTemperature(t);
          }),
        };
      }
      return b;
    };

    setBiomarkersData(getUnitBiomarkers(biomarkersApiData));
    setLoadState(LoadStateSuccess);
  }, [biomarkersApiData, config]);

  useEffect(() => {
    if (!subject.userId || !timerange || !timerange.range) {
      return;
    }

    updateTitleForSubject(subject);

    GetUserLatestDevice(subject.userId)
      .then((response: UsageService.ApiResponseUserDevice) => {
        if (!response || !response.data) {
          return;
        }
        setDevice(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    GetUserLatestApp(subject.userId)
      .then((response: UsageService.ApiResponseUserApp) => {
        if (!response || !response.data) {
          return;
        }
        setApp(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    GetUserLatestTimezone(subject.userId)
      .then((response: UsageService.ApiResponseUserTimezone) => {
        if (!response || !response.data) {
          return;
        }
        setTimezone(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [subject, timerange]);

  const onTimerangeChange = (s: Timerange) => {
    if (!timerange || timerange.from !== s.from || timerange.to !== s.to) {
      setTimerange(s);
    }
  };

  const onTimerangeAuraChange = (s: Timerange) => {
    setAuraTimerange(s);
  };

  const exportToCSV = () => {
    let subjectId = subject.fullInternalId?.split(' ').join('');
    let filename = `${subjectId}_${timerange?.from}-${timerange?.to}.csv`;
    exportBiomarkersToCSV(biomarkersData, filename);
  };

  const onDebugViewClose = () => {
    setDebugViewOpen(false);
  };

  const openDebugView = () => {
    // closeDrawer();
    setDebugViewOpen(true);
  };

  const enabledViews = [
    TimerangeRealtime6h,
    TimerangeRealtime12h,
    Timerange2d,
    Timerange10d,
  ];
  const { roles } = useContext(UserContext);
  if (isAuraDebugViewEnabled(roles)) {
    enabledViews.push(TimerangeAuraEDRI);
  }

  if (loadingSubject) {
    return <ViewLoading />;
  } else if (loadState.isError) {
    return renderErrorsIfAny(loadState)!;
  } else {
    return (
      <React.Fragment>
        <AppBar position="static" color="secondary" elevation={0}>
          <Toolbar variant="dense" className={classes.toolbar}>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <Box textAlign="left" className={classes.subjectName}>
                  <Trans i18nKey="SUBJECT_VIEW_TITLE" /> | {subject.site?.name}{' '}
                  | {subject.internalId}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box textAlign="right" className={classes.serialNumber}>
                  <SubjectDeviceInfo device={device} app={app} />
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <TimerangeSelector
          onChange={onTimerangeChange}
          externalState={auraTimerange}
          timezone={timezone}
          enabledViews={enabledViews}
          showTimezoneInfo={true}
        />
        {!timerange?.isAura && (
          <Box m={2}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper elevation={1}>
                  {loadState.isLoading && <ViewLoading />}
                  {loadState.isSuccess && (
                    <Box>
                      <SubjectViewCompliance
                        userId={subject.userId}
                        timerange={timerange}
                        timezone={timezone}
                        data={biomarkersData}
                      />
                      <Divider />
                      {auraViewEnabled && timerange?.range === Timerange10d && (
                        <SubjectViewAuraDayPrecision
                          userId={subject.userId}
                          userStatus={subject.status}
                          timerange={timerange}
                          timezone={timezone}
                        />
                      )}
                      <SubjectViewTemperature
                        userId={subject.userId}
                        timerange={timerange}
                        timezone={timezone}
                        data={biomarkersData}
                      />
                      <Divider />
                      <SubjectViewPulseRate
                        userId={subject.userId}
                        timerange={timerange}
                        timezone={timezone}
                        data={biomarkersData ? biomarkersData : undefined}
                      />
                      <ModalDebugView
                        open={userAccountOpen}
                        onClose={onDebugViewClose}
                        data={biomarkersData}
                        timerange={timerange}
                        exportToCSV={exportToCSV}
                      />
                      <Box textAlign="right" p={2}>
                        <Button color="default" onClick={openDebugView}>
                          <FontAwesomeIcon icon={faBug} />
                          <span>&nbsp;</span>
                          <Trans i18nKey="DEBUG" />
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Box>
        )}
        {timerange?.isAura && auraViewEnabled && (
          <SubjectViewAuraDebugView
            userId={subject.userId}
            onChange={onTimerangeAuraChange}
            timezone={timezone}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(SubjectView);
