import Transport from 'winston-transport';
import {
  appName,
  appPlatform,
  appVersion,
  BaseLogEntry,
  getUserId,
  language,
  userAgent,
} from '../../Model/LogEntry';
import * as Sentry from '@sentry/react';
import { AppEnvironment } from '../Winston/WinstonLoggerFactory';
import { Integrations } from '@sentry/tracing';

const DEFAULT_LOG_LEVEL = 'info';
const CONTEXT_NAME = 'info';

// initSentry is responsible for performing the initialization of the Sentry logging tool
export function initSentry() {
  // check if its testing environment or not
  let environment = process.env.REACT_APP_ENVIRONMENT || AppEnvironment.dev;

  if (environment !== AppEnvironment.dev) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
      environment: environment,
    });
  }

  Sentry.setContext(CONTEXT_NAME, makeDefaultInfoForLogging());
}

// makeDefaultInfoForLogging is used to generate the default set of fields to be added
// when logging to Sentry
function makeDefaultInfoForLogging() {
  return {
    userId: (getUserId() || '').toString(),
    environment: process.env.REACT_APP_ENVIRONMENT || AppEnvironment.dev,
    appName: appName || '',
    appPlatform: appPlatform || '',
    appVersion: appVersion || '',
    userAgent: userAgent || '',
    language: language || '',
  };
}

// The SentryTransport class is responsible for interacting with the Sentry logging API
export class SentryTransport extends Transport {
  constructor() {
    const loggingOpts = { level: DEFAULT_LOG_LEVEL };
    super(loggingOpts);
  }

  log(info: BaseLogEntry, next: () => void) {
    setImmediate(() => {
      this.emit('logged', info);
    });

    Sentry.withScope(() => {
      Sentry.setTags(makeDefaultInfoForLogging());

      Sentry.setExtra('info', info);
      Sentry.captureMessage(info.message);
    });

    next();
  }
}
