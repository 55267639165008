import { AxiosRequestConfig, AxiosResponse } from 'axios';
import jsonwebtoken from 'jsonwebtoken';

export const appName = `healthmonitor-${
  process.env.REACT_APP_ENVIRONMENT || 'dev'
}`;
export const appVersion = `${process.env.REACT_APP_VERSION || 'dev'}`;
export const appPlatform = 'web';

export const userAgent = navigator ? navigator.userAgent : '';
export const language = navigator ? navigator.language : '';

export function getUserId(): number | null {
  const at: string | null = localStorage.getItem('at');
  if (at) {
    const token: any = jsonwebtoken.decode(at);
    if (token && token.PAYLOAD && token.PAYLOAD.userId) {
      return token.PAYLOAD.userId;
    }
  }

  return null;
}

// This class is responsible to hold any information that may suit any kind of log entry
export class BaseLogEntry {
  userId?: number | null;
  appName?: string;
  appPlatform?: string;
  appVersion?: string;
  userAgent?: string;
  language?: string;
  message: string = '';

  constructor() {
    this.userId = getUserId();
    this.appName = appName;
    this.appPlatform = appPlatform;
    this.appVersion = appVersion;
    this.userAgent = userAgent;
    this.language = language;
  }
}

// The ApiLogEntry should be used when we are logging errors related to the API usage, such
// as failures on requests (5XX, 4XX status code)
export class ApiLogEntry extends BaseLogEntry {
  responseStatusCode?: number | undefined;
  apiRequest: ApiRequest | undefined;
  error?: any | undefined;

  // Safe initializer for both axios and unknown responses coming from the API
  static fromApiResponse(error: any): ApiLogEntry {
    // we try to parse the received error as an AxiosResponse object
    // but we cannot say that it will always be
    try {
      return this.fromAxios(error.response);
    } catch (e) {
      let apiEntry = new ApiLogEntry();
      apiEntry.error = error;
      return apiEntry;
    }
  }

  // Initializer from a given Axios Request - deprecated for external usage
  private static fromAxios(axiosResponse: AxiosResponse) {
    let status = axiosResponse.status;
    let apiRequest = ApiRequest.fromAxios(axiosResponse.config);
    return new ApiLogEntry(status, apiRequest);
  }

  // Default one - use in case you're not using the default networking library (currently Axios)
  constructor(responseStatusCode?: number, apiRequest?: ApiRequest) {
    super();
    this.responseStatusCode = responseStatusCode;
    this.apiRequest = apiRequest;
  }
}

// Holder to some API request related fields
export class ApiRequest {
  baseURL?: string;
  method?: string;
  url?: string;

  // Initializer from a given Axios Request
  static fromAxios(axiosConfig: AxiosRequestConfig): ApiRequest {
    return new ApiRequest(
      axiosConfig.baseURL!,
      axiosConfig.method!,
      axiosConfig.url!
    );
  }

  constructor(baseURL: string, method: string, url: string) {
    this.baseURL = baseURL;
    this.method = method;
    this.url = url;
  }
}

// The ExceptionLogEntry should be used when we are logging errors related to an exception
export class ExceptionLogEntry extends BaseLogEntry {
  name: string;
  stack: string = '';

  constructor(error: Error) {
    super();
    this.name = error.name;
    this.message = error.message;
    this.stack = error.stack || '';
  }
}
