const STATE_LOADING = 'loading';
const STATE_PARSE_ERROR = 'parse_error';
const STATE_URL_ERROR = 'url_error';
const STATE_EXPIRED = 'expired';
const STATE_SUCCESS = 'success';

type LoadState =
  | typeof STATE_LOADING
  | typeof STATE_PARSE_ERROR
  | typeof STATE_URL_ERROR
  | typeof STATE_EXPIRED
  | typeof STATE_SUCCESS;

export default class AdherenceReportLoadState {
  state!: undefined | LoadState;
  isLoading!: boolean;
  isParseError!: boolean;
  isUrlError!: boolean;
  isExpired!: boolean;
  isSuccess!: boolean;

  constructor(state?: undefined | LoadState) {
    this.state = state;
    this.isLoading = state === STATE_LOADING;
    this.isParseError = state === STATE_PARSE_ERROR;
    this.isUrlError = state === STATE_URL_ERROR;
    this.isExpired = state === STATE_EXPIRED;
    this.isSuccess = state === STATE_SUCCESS;
  }
}

export const AdherenceLoadStateLoading = new AdherenceReportLoadState(
  STATE_LOADING
);
export const AdherenceLoadStateParseError = new AdherenceReportLoadState(
  STATE_PARSE_ERROR
);
export const AdherenceLoadStateUrlError = new AdherenceReportLoadState(
  STATE_URL_ERROR
);
export const AdherenceLoadStateExpired = new AdherenceReportLoadState(
  STATE_EXPIRED
);
export const AdherenceLoadStateSuccess = new AdherenceReportLoadState(
  STATE_SUCCESS
);
