import { Biomarker } from './Biomarker';

export const BiomarkerPulseRateMin = 30;
export const BiomarkerPulseRateMax = 220;

export const BiomarkerPulseRateComplianceThreshold = 75;

export default class PulseRates extends Biomarker {
  processValue(value?: number): number | null {
    if (value == null) return null;
    return Math.round(value);
  }

  complianceThreshold(): number {
    return BiomarkerPulseRateComplianceThreshold;
  }
}
