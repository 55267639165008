import Api from '../../../Http/Api';

class DataPayload {
  payload!: {
    features?: string[];
  };
}
export class ApiResponseUserFeatures {
  status!: string;
  statusText!: string;
  data!: DataPayload;
}

export function GetUserFeatures(
  userId: number
): Promise<ApiResponseUserFeatures> {
  const url = `${process.env.REACT_APP_API_URL}/v3/users/${userId}/features`;
  return Api.get(url);
}
