import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import 'date-fns';
import { Box, Grid, makeStyles } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import * as BiomarkersService from '../../Http/BiomarkersService';
import {
  AuraEdri,
  Edri,
  getEdriChartData,
  getEdriNegativeValueReason,
} from '../../Model/Edri';
import {
  ApiResponseUserAuraEdri,
  ApiResponseUserEdri,
} from '../../Http/BiomarkersService';
import { Line } from 'react-chartjs-2';
import ClockIcon from '../../Icons/ClockIcon';
import AuraIcon from '../../Icons/AuraIcon';
import { renderAuraEdriLegend } from './LegendUtils';
import Timerange from '../../Model/Timerange';
import { TimerangeAuraEDRI } from '../../Model/Biomarker';
import i18next from 'i18next';
import SubjectChartTimezoneInfo from './SubjectChartTimezoneInfo';

dayjs.extend(utc);

const getRiskLevelColor = (riskLevel?: number, y?: number): string => {
  switch (riskLevel) {
    case 11:
      return '#7FE7BB';
    case 12:
      return '#00D077';
    case 21:
      return '#FF9D97';
    case 22:
      return '#FF3B30';
  }

  return y === 0 ? '#DBDBDB' : '#969696';
};

const useStyles = makeStyles((theme) => ({
  debugView: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20%',
    textAlign: 'center',
    borderLeft: '1px solid #ddd',
    borderRight: '1px solid #ddd',
  },
  day: {
    marginBottom: theme.spacing(2),
  },
  label: {
    color: 'white',
    padding: '10px',
    borderRadius: '25px',
    minWidth: '6.5rem',
    textAlign: 'center',
  },
}));

function SubjectViewAuraDebugView(props: any) {
  const classes = useStyles();
  const today = dayjs().toDate();
  const [selectedDate, setSelectedDate] = useState<Date | null>(today);
  const [fromDate, setFromDate] = useState<Date | null>(
    dayjs(today).subtract(4, 'd').startOf('day').toDate()
  );
  const [edri, setEdri] = useState<Edri[] | null>([]);
  const [auraEdri, setAuraEdri] = useState<AuraEdri[] | null>([]);
  const [auraGrid, setAuraGrid] = useState<any>(null);
  const [edriChartData, setEdriChartData] = useState<any>(null);

  const handleDateChange = (date: Date | null) => {
    if (!date) {
      date = dayjs().toDate();
    }
    setSelectedDate(date);
    setFromDate(
      dayjs
        .utc(date)
        .subtract(4, 'd')
        .startOf('day')
        .subtract(props.timezone.offset, 's')
        .toDate()
    );

    if (props.onChange) {
      let t = new Timerange(TimerangeAuraEDRI, props.timezone.offset);
      t.from =
        dayjs
          .utc(date)
          .subtract(4, 'd')
          .startOf('d')
          .subtract(props.timezone.offset, 's')
          .unix() * 1000;
      t.to =
        dayjs.utc(date).endOf('d').subtract(props.timezone.offset, 's').unix() *
        1000;
      props.onChange(t);
    }
  };

  useEffect(() => {
    if (!props.userId || !selectedDate) {
      return;
    }
    const fromDay = dayjs(selectedDate).subtract(4, 'd').startOf('day');
    const fromDayLabel = fromDay.format('YYYY-MM-DD');
    const toDay = dayjs(selectedDate).endOf('day');
    const toDayLabel = toDay.format('YYYY-MM-DD');
    let tz = 0;

    if (props.timezone.offset) {
      tz = props.timezone.offset;
    }

    BiomarkersService.GetUserAuraEdri(
      props.userId,
      fromDayLabel,
      toDayLabel,
      tz
    ).then((response: ApiResponseUserAuraEdri) => {
      const data = response.data.values || [];
      setAuraEdri(data);
    });

    const fromUnix =
      dayjs.utc(selectedDate).subtract(4, 'd').startOf('d').unix() * 1000;
    const toUnix = dayjs.utc(selectedDate).endOf('d').unix() * 1000;
    const from = fromUnix - tz * 1000;
    const to = toUnix - tz * 1000;
    BiomarkersService.GetUserEdri(props.userId, from, to).then(
      (response: ApiResponseUserEdri) => {
        const data = response.data.values || [];
        const edriData = getEdriChartData(data, fromUnix, toUnix, tz);
        setEdri(edriData);
      }
    );
  }, [props.userId, selectedDate, props.timezone.offset]);

  useEffect(() => {
    if (!auraEdri) {
      return;
    }
    const grid = auraEdri.map((a: AuraEdri) => {
      const riskLevel = ((r?: number) => {
        switch (r) {
          case 11:
          case 12:
            return i18next.t('AURA_LOW_RISK_INFECTION_LABEL');
          case 21:
          case 22:
            return i18next.t('AURA_HIGH_RISK_INFECTION_LABEL');
        }
        return i18next.t('AURA_NO_DATA_LABEL');
      })(a?.value?.riskLevel);

      return (
        <Grid item key={a.day} xs="auto" className={classes.debugView}>
          <div className={classes.day}>
            {dayjs(a.day).format('MMM DD, YYYY')}
          </div>
          <div
            className={classes.label}
            style={{ backgroundColor: getRiskLevelColor(a?.value?.riskLevel) }}
          >
            {riskLevel}
          </div>
        </Grid>
      );
    });
    setAuraGrid(grid);
  }, [auraEdri, classes.day, classes.debugView, classes.label]);

  useEffect(() => {
    if (!edri) {
      return;
    }
    const edriChartData = {
      labels: edri.map((e: Edri) => {
        return dayjs.utc(e.runAt).format('MMM DD, HH:mm');
      }),
      datasets: [
        {
          label: 'EDRI',
          fill: false,
          lineTension: 0.0,
          lineOptions: {},
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBackgroundColor: function (context: any) {
            let index = context.dataIndex;
            let item = context.dataset.data[index];
            return getRiskLevelColor(item.riskLevel, item.y);
          },
          pointRadius: 3,
          pointHoverRadius: 5,
          pointHoverBorderWidth: 0,
          pointBorderWidth: 0,
          pointHitRadius: 10,
          data: edri.map((e: Edri) => {
            return {
              y: e.value,
              riskLevel: e.riskLevel,
            };
          }),
        },
      ],
    };
    setEdriChartData(edriChartData);
  }, [edri]);

  const lineOptions = {
    scales: {
      xAxes: [
        {
          display: false,
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          // stacked: true,
          gridLines: {
            display: true,
            color: '#d3d3d3',
            drawBorder: false,
            zeroLineColor: '#000',
            zeroLineWidth: 1,
          },
          ticks: {
            beginAtZero: true,
            suggestedMin: -16,
            suggestedMax: 100,
          },
        },
      ],
    },
    pointBorderWidth: 1,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      callbacks: {
        title: function (tooltipItem: any, data: any) {
          return tooltipItem[0].label;
        },
        label: function (tooltipItem: any, data: any) {
          let value =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          let label = [`EDRI: ${value.y}`];
          switch (value.riskLevel) {
            case 11:
              label.push(i18next.t('AURA_LOW_RISK_TEMPORARY'));
              break;
            case 12:
              label.push(i18next.t('AURA_LOW_RISK_FINAL'));
              break;
            case 21:
              label.push(i18next.t('AURA_HIGH_RISK_TEMPORARY'));
              break;
            case 22:
              label.push(i18next.t('AURA_HIGH_RISK_FINAL'));
              break;
          }

          if (value.y < 0) {
            label = label.concat(getEdriNegativeValueReason(value.y));
          }
          if (value.y === 0) {
            label.push(i18next.t('AURA_NO_DATA_LABEL'));
          }
          return label;
        },
      },
    },
  };

  return (
    <Box m={2}>
      <Grid container>
        <Grid item xs={2}>
          <h1>Date</h1>
          <ClockIcon fontSize="large" />
        </Grid>
        <Grid item xs={10}>
          <span>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                disabled={true}
                variant="inline"
                format="MMM dd, yyyy"
                margin="normal"
                id="date-picker-inline"
                label="From"
                value={fromDate}
                onChange={() => {}}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </span>
          <span>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MMM dd, yyyy"
                margin="normal"
                id="date-picker-inline"
                label="To"
                value={selectedDate}
                maxDate={today}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </span>
        </Grid>
      </Grid>

      <br></br>
      <br></br>

      <Grid container>
        <Grid item xs={2}>
          <h1>Aura + EDRI</h1>
          <AuraIcon style={{ height: '1.4rem', marginLeft: '0.4rem' }} />
        </Grid>

        <Grid item xs={10}>
          <Box>
            {renderAuraEdriLegend()}
            <Line data={edriChartData} options={lineOptions} height={60} />
          </Box>
          <Box>
            <Grid container style={{ width: '100%', paddingLeft: '30px' }}>
              {auraGrid}
            </Grid>
          </Box>
          <SubjectChartTimezoneInfo offsetInSeconds={props.timezone.offset} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default withRouter(SubjectViewAuraDebugView);
