import ModalInfo from '../../Modals/ModalInfo';
import i18next from 'i18next';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  errorMainText: {
    fontSize: '14rem',
    fontFamily: 'inconsolata',
    letterSpacing: 0,
    lineHeight: '31px',
    textAlign: 'center',
    fontWeight: 100,
  },
}));
export default function AdherenceUrlErrorView() {
  const classes = useStyles();
  const iconError = <span className={classes.errorMainText}>404</span>;

  return (
    <ModalInfo
      open={true}
      icon={iconError}
      title={i18next.t('REPORT_ADHERENCE_URL_ERROR_LOAD_TITLE')}
      content={i18next.t('REPORT_ADHERENCE_URL_ERROR_LOAD_CONTENT')}
    />
  );
}
