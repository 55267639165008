import winston from 'winston';
import { Logger } from '../Logger';
import { WinstonLoggerFactory } from './WinstonLoggerFactory';
import { BaseLogEntry } from '../../Model/LogEntry';

// The WinstonLogger class is responsible for initializing our Winston logger
// object along performing any required data transformation with the received input
export class WinstonLogger implements Logger {
  private logger: winston.Logger;

  constructor() {
    this.logger = new WinstonLoggerFactory().createLogger();
  }

  debug(message: string, logEntry?: BaseLogEntry): void {
    this.logger.debug(message, logEntry);
  }

  error(message: string, logEntry?: BaseLogEntry): void {
    this.logger.error(message, logEntry);
  }

  info(message: string, logEntry?: BaseLogEntry): void {
    this.logger.info(message, logEntry);
  }
}
