import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { Trans } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: '0.8rem',
  },
  buttonAlign: {
    display: 'inline-block',
  },
}));

class DownloadAdherenceReportProps {
  url!: string;
  enabled: boolean = false;
}

export default function DownloadAdherenceReportButton(
  props: DownloadAdherenceReportProps
) {
  const classes = useStyles();

  return (
    <div className={classes.buttonAlign}>
      <Button
        id={'btn-export-adherence-report'}
        color="primary"
        className={classes.button}
        disabled={!props.enabled}
        component={'a'}
        href={props.url}
        target={'_blank'}
      >
        <FontAwesomeIcon icon={faDownload} />
        <span>&nbsp;</span>
        <Trans i18nKey="REPORT_ADHERENCE_ACTION_DOWNLOAD_CSV" />
      </Button>
    </div>
  );
}
