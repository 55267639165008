import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const MillisecondsInMinute = 60000;
export const MillisecondsInHour = 3600000;
export const MillisecondsInDay = 3600000 * 24;
export const MinutesInHour = 60;
export const SecondsInMinute = 60;
export const MinutesInDay = 1440;
export const HoursInDay = 24;

export const DailyMedianThresholdInHours = 3.5;
export const TenDayMedianThresholdInDays = 5;

export const BiomarkerPrecisionMinute = 'minute';
export const BiomarkerPrecisionHour = 'hour';
export const BiomarkerPrecisionDay = 'day';

export const TimerangeRealtime6h = '6h';
export const TimerangeRealtime12h = '12h';
export const Timerange2d = '48h';
export const Timerange10d = '10d';
export const Timerange30d = '30d';
export const TimerangeAuraEDRI = 'aura-edri';
export const TimerangeAuraEDRITrend = 'aura-edri-trend';
export const S3BrowserView = 'browserView';

export const ComplianceToleranceForAggregationMinute = 75;
export const ComplianceToleranceForAggregationHourly = 25;
export const ComplianceToleranceForAggregationDaily = 15;

export abstract class Biomarker {
  from: number;
  to: number;
  tz: number;

  precision: string;

  values: Array<number | null>;
  compliance: Array<number | null>;
  validValues: (number | null)[]; // a valid number is a number with a compliance of at least 50%, if not is null
  complianceHourly: number[];
  complianceDaily: number[];

  todayValues: any[];
  yesterdayValues: any[];
  tenDaysValues: any[];
  lastValue?: number | null;

  aggregations?: BiomarkerAggregation;
  average?: number | null;
  median?: number | null;
  compareMedian?: number | null;
  min?: number | null;
  max?: number | null;

  todayDate!: string;
  todayMidnight!: number;
  yesterdayMidnight!: number;

  constructor(
    from?: number,
    to?: number,
    tz?: number,
    values?: Array<number | null>,
    compliance?: Array<number | null>,
    precision?: string
  ) {
    if (!from || !to) {
      this.from = 0;
      this.to = 0;
      this.tz = 0;
      this.values = [];
      this.validValues = [];
      this.compliance = [];
      this.complianceHourly = [];
      this.complianceDaily = [];
      this.todayValues = [];
      this.yesterdayValues = [];
      this.tenDaysValues = [];
      this.precision = '';
      return;
    }

    this.from = from || 0;
    this.to = to || 0;
    this.tz = tz || 0;
    this.values = values || [];
    this.compliance = compliance || [];
    this.precision = precision || '';

    this.todayDate = dayjs.utc().add(this.tz, 's').format('YYYY-MM-DD HH:mm');
    this.todayMidnight =
      dayjs.utc(this.todayDate).startOf('d').subtract(this.tz, 's').unix() *
      1000;
    this.yesterdayMidnight =
      dayjs
        .utc(this.todayDate)
        .subtract(1, 'day')
        .startOf('d')
        .subtract(this.tz, 's')
        .unix() * 1000;

    const valuesWithoutNulls: Array<any> = this.values.filter(
      (v: number | null) => {
        return v !== null;
      }
    );

    this.validValues = this.getValidValues(
      this.values,
      this.compliance,
      this.complianceThreshold()
    );

    this.complianceHourly = this.computeComplianceHourly(this.compliance);
    this.complianceDaily = this.computeComplianceDaily(this.complianceHourly);

    this.todayValues = this.extractTodayValues(this.from, this.validValues);
    this.yesterdayValues = this.extractYesterdayValues(
      this.from,
      this.validValues
    );
    this.tenDaysValues = this.extractTenDaysValues(this.validValues);

    if (valuesWithoutNulls.length === 0) {
      this.median = undefined;
      this.min = undefined;
      this.max = undefined;
      this.lastValue = undefined;
      this.average = undefined;
      return;
    }

    this.average = this.processValue(
      valuesWithoutNulls.reduce((a: number, b: number) => a + b, 0) /
        valuesWithoutNulls.length
    );

    switch (precision) {
      case BiomarkerPrecisionMinute:
        this.aggregations = {
          median: this.computeAggregation(
            this.getMedian,
            this.validValues,
            MinutesInHour,
            ComplianceToleranceForAggregationHourly
          ),
          average: this.computeAggregation(
            this.getAverage,
            this.validValues,
            MinutesInHour,
            ComplianceToleranceForAggregationHourly
          ),
          quartile1: this.computeAggregation(
            this.getQuartile1,
            this.validValues,
            MinutesInHour,
            ComplianceToleranceForAggregationHourly
          ),
          quartile3: this.computeAggregation(
            this.getQuartile3,
            this.validValues,
            MinutesInHour,
            ComplianceToleranceForAggregationHourly
          ),
        };
        this.lastValue = valuesWithoutNulls[valuesWithoutNulls.length - 1];
        this.median = this.processValue(this.getMedian(valuesWithoutNulls));
        break;
      case BiomarkerPrecisionHour:
        this.aggregations = {
          median: this.computeAggregation(
            this.getMedian,
            this.validValues,
            MinutesInHour,
            ComplianceToleranceForAggregationHourly
          ),
          average: this.computeAggregation(
            this.getAverage,
            this.validValues,
            MinutesInHour,
            ComplianceToleranceForAggregationHourly
          ),
          quartile1: this.computeAggregation(
            this.getQuartile1,
            this.validValues,
            MinutesInHour,
            ComplianceToleranceForAggregationHourly
          ),
          quartile3: this.computeAggregation(
            this.getQuartile3,
            this.validValues,
            MinutesInHour,
            ComplianceToleranceForAggregationHourly
          ),
        };

        let validTodayValues = this.todayValues.filter((v) => v !== null);
        this.median = null;
        if (
          validTodayValues.length >=
          DailyMedianThresholdInHours * MinutesInHour
        ) {
          this.median = this.processValue(
            this.getMedian(this.todayValues.filter((v) => v !== null))
          );
        }

        let validYesterdayValues = this.yesterdayValues.filter(
          (v) => v !== null
        );
        this.compareMedian = null;
        if (
          validYesterdayValues.length >=
          DailyMedianThresholdInHours * MinutesInHour
        ) {
          this.compareMedian = this.processValue(
            this.getMedian(this.yesterdayValues.filter((v) => v !== null))
          );
        }

        const validMedian = this.aggregations.median.filter((v) => v !== null);
        this.lastValue = validMedian[validMedian.length - 1] ?? this.median;
        break;
      case BiomarkerPrecisionDay:
        this.aggregations = {
          median: this.computeAggregation(
            this.getMedian,
            this.validValues,
            MinutesInDay,
            ComplianceToleranceForAggregationDaily
          ),
          average: this.computeAggregation(
            this.getAverage,
            this.validValues,
            MinutesInDay,
            ComplianceToleranceForAggregationDaily
          ),
          quartile1: this.computeAggregation(
            this.getQuartile1,
            this.validValues,
            MinutesInDay,
            ComplianceToleranceForAggregationDaily
          ),
          quartile3: this.computeAggregation(
            this.getQuartile3,
            this.validValues,
            MinutesInDay,
            ComplianceToleranceForAggregationDaily
          ),
        };

        let validToday10DaysValues = this.todayValues.filter((v) => v !== null);
        this.median = null;
        if (
          validToday10DaysValues.length >=
          DailyMedianThresholdInHours * MinutesInHour
        ) {
          this.median = this.processValue(
            this.getMedian(this.todayValues.filter((v) => v !== null))
          );
        }

        // TODO (asi): Clean up code

        // Go through the ten days and if a day has more than 3,5 hours of data
        // add it's values to the final array. Also, keep track of how many valid days
        // we have, because we need five valid days to calculate the median
        let valid10DaysValues = this.tenDaysValues.map((day) => {
          return day.filter((v: number | null) => v !== null);
        });
        this.compareMedian = null;
        let validDays = 0;
        let finalValid10DayValues: number[] = [];
        for (let i = 0; i < valid10DaysValues.length; i++) {
          if (
            valid10DaysValues[i].length >=
            DailyMedianThresholdInHours * MinutesInHour
          ) {
            finalValid10DayValues = finalValid10DayValues.concat(
              valid10DaysValues[i]
            );
            validDays++;
          }
        }

        // Check if we have at least 5 days with at least 3,5 hours of valid data
        if (validDays >= TenDayMedianThresholdInDays) {
          let medianValidValues: number[] = finalValid10DayValues.filter(
            (v) => v !== null
          );
          this.compareMedian = this.processValue(
            this.getMedian(medianValidValues)
          );
        }

        const validMedian2 = this.aggregations.median.filter((v) => v !== null);
        this.lastValue = validMedian2[validMedian2.length - 1] ?? this.median;
        break;
    }
    this.computeMinMax(precision);
  }

  abstract processValue(value?: number): number | null;

  abstract complianceThreshold(): number;

  computeMinMax(precision?: string) {
    if (precision == null) return;
    if (precision === BiomarkerPrecisionMinute) {
      const cloneValidValues = Object.assign([], this.validValues);
      const validValuesSorted = cloneValidValues
        .filter((v) => v !== null)
        .sort((a: number, b: number) => {
          return a - b;
        });

      this.min = validValuesSorted[0];
      this.max = validValuesSorted[validValuesSorted.length - 1];
      return;
    }

    if (this.aggregations == null) return;
    const sortedQuartile1 = Object.assign([], this.aggregations.quartile1)
      .filter((v) => v !== null)
      .sort((a: number, b: number) => {
        return a - b;
      });
    const sortedQuartile3 = Object.assign([], this.aggregations.quartile3)
      .filter((v) => v !== null)
      .sort((a: number, b: number) => {
        return a - b;
      });
    this.min = sortedQuartile1[0];
    this.max = sortedQuartile3[sortedQuartile3.length - 1];
  }

  extractTodayValues(
    from: number,
    values: (number | null)[]
  ): (number | null)[] {
    const items = [];
    for (let i = 0; i < values.length; i++) {
      const t = from + i * 60000;
      if (t >= this.todayMidnight) items.push(values[i]);
    }
    return items;
  }

  extractYesterdayValues(
    from: number,
    values: (number | null)[]
  ): (number | null)[] {
    const items = [];
    for (let i = 0; i < values.length; i++) {
      const t = from + i * 60000;
      if (t < this.todayMidnight && t >= this.yesterdayMidnight)
        items.push(values[i]);
    }
    return items;
  }

  extractTenDaysValues(values: (number | null)[]): (number | null)[][] {
    const items = [];
    for (let i = 0; i < values.length; i += HoursInDay * MinutesInHour) {
      items.push(values.slice(i, i + HoursInDay * MinutesInHour));
    }
    return items;
  }

  getValidValues(
    values: Array<number | null>,
    compliance: Array<number | null>,
    complianceThreshold: number
  ): Array<number | null> {
    if (!values || values.length === 0) {
      return [];
    }
    return values.map((value, index) => {
      var v: number | null = value;
      if (compliance != null && compliance.length === values?.length) {
        const valueCompliance = compliance[index];
        if (
          valueCompliance === null ||
          valueCompliance <= complianceThreshold
        ) {
          v = null;
        }
      }
      return v;
    });
  }

  getComplianceMinuteValues(): Array<number | null> {
    return this.values;
  }

  getMedian(values: number[]): number {
    if (!values || values.length === 0) {
      return 0;
    }
    values = values
      .filter((v) => v !== null)
      .sort((a: number, b: number) => {
        return a - b;
      });
    const midIndex = values.length / 2;
    if (values.length % 2) {
      return values[Math.floor(midIndex)];
    }
    return (values[midIndex - 1] + values[midIndex]) / 2.0;
  }

  getQuartile1(values: number[]): number {
    if (!values || values.length === 0) {
      return 0;
    }
    if (values.length < 4) {
      const sum = values.reduce((a: number, b: number) => a + b, 0);
      return values.length ? sum / values.length : 0;
    }
    values = values
      .filter((v) => v !== null)
      .sort((a: number, b: number) => {
        return a - b;
      });
    const quarter = (values.length / 4) * 1;
    if (values.length % 4) {
      return values[Math.floor(quarter)];
    }
    return (values[quarter - 1] + values[quarter]) / 2.0;
  }

  getQuartile3(values: number[]): number {
    if (!values || values.length === 0) {
      return 0;
    }
    if (values.length < 4) {
      const sum = values.reduce((a: number, b: number) => a + b, 0);
      return values.length ? sum / values.length : 0;
    }
    values = values
      .filter((v) => v !== null)
      .sort((a: number, b: number) => {
        return a - b;
      });
    const quarter = (values.length / 4) * 3;
    if (values.length % 4) {
      return values[Math.floor(quarter)];
    }
    return (values[quarter - 1] + values[quarter]) / 2.0;
  }

  getAverage(values: number[]): number {
    if (!values || values.length === 0) {
      return 0;
    }
    values = values.filter((v: number | null) => v !== null);
    const sum = values.reduce((a: number, b: number) => a + b, 0);
    const v = values.length ? sum / values.length : 0;
    return v;
  }

  computeComplianceHourly(complianceMinute: Array<number | null>): number[] {
    complianceMinute = complianceMinute || [];
    let compliance = [];
    for (let i = 0; i < complianceMinute.length; i += MinutesInHour) {
      const subset = complianceMinute.slice(i, i + MinutesInHour);
      if (subset.length > 0) {
        const validSamplesCount = subset
          .map((v) =>
            v === null || v < ComplianceToleranceForAggregationHourly ? 0 : 1
          )
          .reduce((a: any, b: any) => a + b, 0);

        const validSamplesPercentage =
          (validSamplesCount * 100) / subset.length;
        compliance.push(validSamplesPercentage);
      }
    }
    return compliance;
  }

  computeComplianceDaily(complianceHourly: Array<number | null>): number[] {
    complianceHourly = complianceHourly || [];
    let compliance = [];
    for (let i = 0; i < complianceHourly.length; i += 24) {
      const subset = complianceHourly.slice(i, i + 24);
      if (subset.length > 0) {
        const validSamplesCount = subset
          .map((v: number | null) =>
            v === null || v < ComplianceToleranceForAggregationDaily ? 0 : 1
          )
          .reduce((a: any, b: any) => a + b, 0);

        const validSamplesPercentage =
          (validSamplesCount * 100) / subset.length;
        compliance.push(validSamplesPercentage);
      }
    }
    return compliance;
  }

  computeAggregation(
    aggregationfn: (array: number[]) => number,
    values: any[],
    scale: number,
    complianceTolerace: number
  ): Array<number | null> {
    values = values || [];
    const items: Array<number | null> = [];
    for (let i = 0; i < values.length; i += scale) {
      switch (scale) {
        case MinutesInHour:
          if (this.complianceHourly[i / scale] < complianceTolerace) {
            items.push(null);
            continue;
          }
          break;
        case MinutesInDay:
          if (this.complianceDaily[i / scale] < complianceTolerace) {
            items.push(null);
            continue;
          }
          break;
      }
      const subset = values.slice(i, i + scale);
      const value = this.processValue(aggregationfn(subset));
      items.push(value);
    }
    return items;
  }

  getPrecisionValues(precision: string): Array<number | null> {
    switch (precision) {
      case BiomarkerPrecisionMinute:
        return this.validValues;
      case BiomarkerPrecisionHour:
        return this.aggregations && this.aggregations.median
          ? this.aggregations.median
          : [];
      case BiomarkerPrecisionDay:
        return this.aggregations?.median ?? [];
    }
    return [];
  }
}

export class BiomarkersResponse {
  from!: number;
  to!: number;
  temperature?: {
    values: Array<number | null>;
  };
  //TODO(rb): drop heart rate once we have the biomarkers service using pulse rate
  heartRate?: {
    values: Array<number | null>;
  };
  pulseRate?: {
    values: Array<number | null>;
  };
  deviceCompliance?: {
    values: Array<number | null>;
  };
  ppgQuality?: {
    values: Array<number | null>;
  };
  respiratoryRate?: {
    values: Array<number | null>;
  };
  edaScl?: {
    values: Array<number | null>;
  };
  stdAccelerometers?: {
    values: Array<number | null>;
  };
  activityCounts?: {
    values: Array<number | null>;
  };
  hrv?: {
    values: Array<number | null>;
  };

  constructor(apiResponse: BiomarkersResponse) {
    this.from = apiResponse.from;
    this.to = apiResponse.to;
    this.temperature = apiResponse.temperature;
    //TODO(rb): drop heart rate once we have the biomarkers service using pulse rate
    this.pulseRate = apiResponse.pulseRate
      ? apiResponse.pulseRate
      : apiResponse.heartRate;
    this.deviceCompliance = apiResponse.deviceCompliance;
    this.ppgQuality = apiResponse.ppgQuality;
    this.respiratoryRate = apiResponse.respiratoryRate;
    this.edaScl = apiResponse.edaScl;
    this.stdAccelerometers = apiResponse.stdAccelerometers;
    this.activityCounts = apiResponse.activityCounts;
    this.hrv = apiResponse.hrv;
  }
}

export class BiomarkerAggregation {
  median: Array<number | null>;
  average: Array<number | null>;
  quartile1: Array<number | null>;
  quartile3: Array<number | null>;

  constructor() {
    this.median = [];
    this.average = [];
    this.quartile1 = [];
    this.quartile3 = [];
  }
}

// From APIs, temporary while we do not deprecate the heartRate field TODO(rb)
export class UserLatestBiomarkersRemote {
  timestamp?: number;
  temperature?: number;
  //TODO(rb): deprecate heart rate field once the APIs are updated
  heartRate?: number;
  pulseRate?: number;
  deviceCompliance?: number;
}

// To be used internally
export class UserLatestBiomarkers {
  timestamp?: number;
  temperature?: number;
  pulseRate?: number;
  deviceCompliance?: number;

  constructor(latest: UserLatestBiomarkersRemote) {
    this.timestamp = latest.timestamp;
    this.temperature = latest.temperature;
    //TODO(rb): deprecate heart rate field once the APIs are updated
    this.pulseRate = latest.pulseRate ? latest.pulseRate : latest.heartRate;
    this.deviceCompliance = latest.deviceCompliance;
  }
}

export class UserLatestBiomarkersMap {
  [key: number]: UserLatestBiomarkers;
}

export function getLatestValueSecondsAgo(
  timestamp?: number
): number | undefined {
  if (!timestamp) {
    return;
  }
  const now = dayjs().unix();
  return now - Math.round(timestamp / 1000);
}
