import React, { useState, useEffect, useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import Compliances from '../../Model/Compliance';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import UserContext from '../../Context/UserContext';
import i18next from 'i18next';
import { renderComplianceLegend } from './LegendUtils';
import {
  getChartHourLabels,
  getReachedNowDayLabels,
} from '../Utils/ChartUtils';
import SubjectChartTimezoneInfo from './SubjectChartTimezoneInfo';

dayjs.extend(utc);

export default function SubjectChartComplianceHourPrecision(props: any) {
  const [compliances, setCompliances] = useState<Compliances | undefined>();
  const [chart, setChart] = React.useState(<div>Loading</div>);
  const { config } = useContext(UserContext);

  useEffect(() => {
    setCompliances(props.values);
  }, [props.values]);

  useEffect(() => {
    if (!compliances) {
      return;
    }

    // Retrieve list of values for the given precision
    const series: Array<number | null> = compliances.getPrecisionValues(
      props.timerange?.precision
    );

    // Generate labels based on the series
    const labels = getChartHourLabels(
      series,
      compliances.from,
      props.timezone.offset,
      config.timeFormat
    );

    const reachedNowDayLabels = getReachedNowDayLabels(
      compliances.from,
      series,
      'hour',
      props.timezone.offset
    );

    let complianceCompliantValues: Array<number | null> = [];
    if (compliances.complianceHourlyArrays[0] != null) {
      complianceCompliantValues = compliances.complianceHourlyArrays[0];
    }

    let complianceNonCompliantValues: Array<number | null> = [];
    if (compliances.complianceHourlyArrays[1] != null) {
      complianceNonCompliantValues = compliances.complianceHourlyArrays[1];
    }

    let complianceNullValues: Array<number | null> = [];
    if (compliances.complianceHourlyArrays[2] != null) {
      complianceNullValues = compliances.complianceHourlyArrays[2];
    }

    let now = dayjs.utc().startOf('hour').add(props.timezone.offset, 's');
    let nowDayLabel = now.format('YYYY-MM-DD HH');
    let nowHourLabel = now.format('H');
    let reachedNow = false;
    for (let i = 0; i < complianceCompliantValues.length; i++) {
      if (labels == null || labels[i] == null) {
        continue;
      }
      if (reachedNow) {
        complianceCompliantValues[i] = null;
        complianceNonCompliantValues[i] = null;
        complianceNullValues[i] = null;
      } else {
        reachedNow = reachedNowDayLabels[i] === nowDayLabel;
      }
    }

    const lines = [
      {
        type: 'line',
        mode: 'vertical',
        scaleID: 'x-axis-0',
        value: 24 + Number(nowHourLabel),
        borderColor: 'rgba(0,0,0,0.0)',
        borderWidth: 0,
        label: {
          backgroundColor: 'rgba(0,0,0,0.7)',
          enabled: true,
          content: i18next.t('NOW'),
          position: 'top',
        },
      },
    ];

    // Chart options
    const options = {
      legend: {
        display: false,
      },
      annotation: {
        annotations: lines,
      },
      tooltips: {
        mode: 'label',
        callbacks: {
          title: function (tooltipItem: any, _: any) {
            return dayjs
              .utc(compliances.from)
              .add(tooltipItem[0]['index'], 'h')
              .add(props.timezone.offset, 's')
              .startOf(props.timerange?.precision)
              .format(`${config.dateFormat} - ${config.timeFormat}`);
          },
          label: function (tooltipItem: any, data: any) {
            var label = data.datasets[tooltipItem.datasetIndex].label;
            var value =
              data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            if (value === null) {
              return null;
            }
            return label + ': ' + value + 'min';
          },
        },
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            gridLines: {
              display: true,
            },
            ticks: {
              startAtZero: false,
              max: 60,
              min: 0,
            },
            afterFit: function (scaleInstance: any) {
              scaleInstance.width = 40; // sets the width to 40px
            },
          },
        ],
        xAxes: [
          {
            stacked: true,
            gridLines: {
              display: false,
            },
            ticks: {
              userCallback: function (item: any, index: number) {
                if (item) return item;
              },
              beginAtZero: true,
              autoSkip: true,
            },
          },
        ],
      },
      maintainAspectRatio: false,
    };

    const data = {
      labels: labels,
      tooltip: {
        display: true,
      },
      // datasetFill: false,
      datasets: [
        {
          label: i18next.t('WORN_CORRECTLY'),
          data: complianceCompliantValues,
          backgroundColor: '#147B8F',
        },
        {
          label: i18next.t('NOT_WORN_CORRECTLY'),
          data: complianceNonCompliantValues,
          backgroundColor: '#838383',
        },
        {
          label: i18next.t('NOT_CONNECTED'),
          data: complianceNullValues,
          backgroundColor: '#F2F2F2',
        },
      ],
    };

    setChart(<Bar data={data} options={options} height={160} />);
  }, [compliances, props.timerange, config, props.timezone]);

  return (
    <span>
      <div>
        {renderComplianceLegend()}
        <div style={{ marginTop: '1.3rem' }}>{chart}</div>
      </div>
      <SubjectChartTimezoneInfo offsetInSeconds={props.timezone.offset} />
    </span>
  );
}
