import Transport from 'winston-transport';
import { ElasticClient } from './ElasticClient';
import { BaseLogEntry } from '../../Model/LogEntry';

const DEFAULT_LOG_LEVEL = 'error';

// The CustomElasticTransport class is responsible for interacting with our ElasticSearchClient
export class CustomElasticTransport extends Transport {
  private esClient: ElasticClient;

  constructor() {
    const loggingOpts = { level: DEFAULT_LOG_LEVEL };
    super(loggingOpts);
    this.esClient = new ElasticClient();
  }

  log(info: BaseLogEntry, next: () => void) {
    setImmediate(() => {
      this.emit('logged', info);
    });

    this.esClient.addDocument(info);

    next();
  }
}
