import React, { useState, useEffect, useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import Compliances from '../../Model/Compliance';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  BiomarkerComplianceMin,
  BiomarkerComplianceMax,
} from '../../Model/Compliance';
import { MillisecondsInMinute } from '../../Model/Biomarker';
import UserContext from '../../Context/UserContext';
import i18next from 'i18next';
import { renderComplianceLegend } from './LegendUtils';
import SubjectChartTimezoneInfo from './SubjectChartTimezoneInfo';

dayjs.extend(utc);

export default function SubjectChartComplianceMinutePrecision(props: any) {
  const [compliances, setCompliances] = useState<Compliances | undefined>();
  const [chart, setChart] = React.useState(<div>Loading</div>);
  const { config } = useContext(UserContext);

  useEffect(() => {
    setCompliances(props.values);
  }, [props.values]);

  useEffect(() => {
    if (!compliances) {
      return;
    }

    // Retrieve list of values for the given precision
    const series: Array<number | null> = compliances.getPrecisionValues(
      props.timerange?.precision
    );

    // Generate labels based on the series
    const labels = series.map((v: any, i: number) => {
      const label = dayjs(compliances.from + i * MillisecondsInMinute)
        .add(props.timezone.offset, 's')
        .utc()
        .format(config.timeFormat);

      const startOfHour = dayjs(compliances.from + i * MillisecondsInMinute)
        .add(props.timezone.offset, 's')
        .utc()
        .startOf('hour')
        .format(config.timeFormat);

      if (label !== startOfHour) {
        return null;
      }
      return label;
    });

    // Chart options
    const options = {
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          title: function (tooltipItem: any, data: any) {
            return dayjs(
              compliances.from + tooltipItem[0]['index'] * MillisecondsInMinute
            )
              .add(props.timezone.offset, 's')
              .utc()
              .startOf(props.timerange?.precision)
              .format(`${config.dateFormat} - ${config.timeFormat}`);
          },
          label: function (tooltipItem: any, data: any) {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';
            return label;
          },
        },
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            gridLines: {
              display: false,
            },
            ticks: {
              startAtZero: false,
              max: BiomarkerComplianceMax,
              min: BiomarkerComplianceMin,
              fontColor: 'white',
            },
            afterFit: function (scaleInstance: any) {
              scaleInstance.width = 40; // sets the width to 40px
            },
          },
        ],
        xAxes: [
          {
            stacked: true,
            gridLines: {
              display: false,
            },
            ticks: {
              userCallback: function (item: any, index: number) {
                if (item) return item;
              },
              autoSkip: false,
            },
          },
        ],
      },
      maintainAspectRatio: true,
    };

    // Chart data
    const data = {
      labels: labels,
      legend: {
        display: false,
      },
      tooltip: {
        display: true,
      },
      datasetFill: false,
      datasets: [
        {
          label: i18next.t('WORN_CORRECTLY'),
          data: compliances.complianceRealtimeArrays[0],
          backgroundColor: '#147B8F',
          barPercentage: 1.5,
        },
        {
          label: i18next.t('NOT_WORN_CORRECTLY'),
          data: compliances.complianceRealtimeArrays[1],
          backgroundColor: '#838383',
          barPercentage: 1.5,
        },
        {
          label: i18next.t('NOT_CONNECTED'),
          data: compliances.complianceRealtimeArrays[2],
          backgroundColor: '#F2F2F2',
          barPercentage: 1.5,
        },
      ],
    };

    setChart(<Bar data={data} options={options} height={13} />);
  }, [compliances, props.timerange, config, props.timezone]);

  return (
    <span>
      <div>
        {renderComplianceLegend()}
        <div style={{ marginTop: '1.3rem' }}>{chart}</div>
      </div>
      <SubjectChartTimezoneInfo offsetInSeconds={props.timezone.offset} />
    </span>
  );
}
