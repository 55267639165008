// getTimezoneRepresentationString generates the timezone's representation given a local offset in seconds, and an offset
// from another timezone. Format returned: UTC{sign}{offset_hours}
import { MinutesInHour, SecondsInMinute } from '../../Model/Biomarker';
import i18next from 'i18next';

export function getTimezoneRepresentationString(
  localOffsetInSeconds: number,
  offsetInSeconds: number
): string {
  let utcSign = '+';
  if (offsetInSeconds < 0) {
    utcSign = '';
  }

  let utcOffsetInHour = offsetInSeconds / MinutesInHour / SecondsInMinute;
  let timezoneRepresentation = `UTC${utcSign}${utcOffsetInHour}`;

  return timezoneRepresentation;
}

// getTimezoneDifferenceHours generates the timezone difference in hours given a local offset in seconds, and an offset
// from another timezone. Format returned: {sign}{difference_hours}
export function getTimezoneDifferenceHours(
  localOffsetInSeconds: number,
  offsetInSeconds: number
): string {
  let sign = '+';
  if (localOffsetInSeconds - offsetInSeconds >= 0) {
    sign = '';
  }

  let utcDifferenceInHour =
    (offsetInSeconds - localOffsetInSeconds) / MinutesInHour / SecondsInMinute;

  let suffix = i18next.t('HOUR_SINGULAR');
  if (Math.abs(utcDifferenceInHour) > 1) {
    suffix = i18next.t('HOUR_PLURAL');
  }

  let timezoneDifference = `${sign}${utcDifferenceInHour}${suffix}`;

  return timezoneDifference;
}
